import React from "react";

import { Icon } from "ui-kit";

import { Grid, Typography, useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

import { createObjectURL, formatSize } from "../helper";
import { FileItemWrapper } from "./styles";

interface Props {
  file: File;
  removeFile: () => void;
}

export const FileItem: React.FC<Props> = ({ file, removeFile }) => {
  const theme = useTheme();

  if (!file) return null;

  return (
    <FileItemWrapper>
      <Grid container>
        <Grid item xs={1} className="link_delete_icon">
          <Icon name="Paperclip" color={theme.palette.primary.main} />
        </Grid>
        <Grid item xs={10}>
          <a
            href={createObjectURL(file)}
            target="_blank"
            rel="noopener noreferrer"
            className="uploaded_file"
          >
            <Typography variant="body2" fontWeight={600} color="primery.main">
              {`${file.name}`}
            </Typography>
          </a>
          <Typography
            variant="body2"
            fontWeight={600}
            color={hexToRgba(theme.palette.primary.main, 0.45)}
          >
            {file?.size && `(${formatSize(file.size)})`}
          </Typography>
        </Grid>
        <Grid item xs={1} className="link_delete_icon">
          <Icon
            name="X"
            color={theme.palette.primary.main}
            onClick={removeFile}
          />
        </Grid>
      </Grid>
    </FileItemWrapper>
  );
};
