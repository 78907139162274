import { createSlice } from "@reduxjs/toolkit";

interface ICorrespondenceState {
  letterCreationStep: number;
}

export const initialStateCorrespondence: ICorrespondenceState = {
  letterCreationStep: 0,
};

export const correspondenceSlice = createSlice({
  name: "correspondence",
  initialState: initialStateCorrespondence,
  reducers: {
    resetLetterCreationStep: (state) => {
      state.letterCreationStep = 0;
    },
    setChangeLetterCreationStep: (state, action) => {
      state.letterCreationStep = state.letterCreationStep + action.payload;
    },
  },
});

export const { resetLetterCreationStep, setChangeLetterCreationStep } =
  correspondenceSlice.actions;

export default correspondenceSlice.reducer;
