import { ToggleButton } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { TreeItem2 } from "@mui/x-tree-view";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem";

export const StyledTreeItem = styled(TreeItem2)(({ theme }) => ({
  color: theme.palette.text.primary,

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),

    "&.Mui-selected": {
      borderBottom: "none !important",
    },

    [`& .${treeItemClasses.label}`]: {
      fontSize: "14px",
      fontWeight: 400,

      "&.font-bold": {
        fontWeight: 700,
      },
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    width: 20,
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
    backgroundColor: alpha(theme.palette.text.primary, 0.3),
    borderRadius: "50%",
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    paddingLeft: 16,
    marginLeft: 12,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },

  [`li[aria-selected="true"] > .MuiCollapse-entered `]: {
    borderLeft: `1.5px dashed ${alpha(theme.palette.secondary.main, 0.9)}`,
  },
  [`li[aria-selected="true"] > .MuiCollapse-entered ~ .MuiCollapse-entered`]: {
    borderColor: `${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(3),
  width: "48px",
  height: "48px",

  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider} !important`,
  },

  "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider} !important`,
  },
  "&.Mui-selected": {
    backgroundColor: `${alpha(theme.palette.secondary.main, 0.08)}`,
  },
}));
