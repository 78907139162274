import { createSlice } from "@reduxjs/toolkit";
import {
  IApprovalRoute,
  IContractAttachments,
  IContractDirectories,
  IContractEntity,
  IContractPersonalData,
  ISupplementaryAgreements,
  TSupAgreement,
} from "@types";

import { IContractForm } from "../../containers";

interface IContractState {
  contractDirectories: IContractDirectories | null;
  contracts: { items: IContractEntity[]; meta: { totalItems: number } } | null;
  contractById: IContractForm | null;
  personalData: IContractPersonalData | null;
  supplementaryAgreements: ISupplementaryAgreements | null;
  supAgreementById: TSupAgreement | null;
  attachments: IContractAttachments | null;
  standardApprovalRoutes: any | null;
  selectedApprovalRoute: IApprovalRoute | null;
}

export const initialStateContract: IContractState = {
  contractDirectories: null,
  contracts: null,
  contractById: null,
  personalData: null,
  supplementaryAgreements: null,
  attachments: null,
  supAgreementById: null,
  standardApprovalRoutes: null,
  selectedApprovalRoute: null,
};

export const contractsSlice = createSlice({
  name: "contracts",
  initialState: initialStateContract,
  reducers: {
    setContractData: (state, action) => {
      state.contractDirectories = action.payload;
    },
    setContracts: (state, action) => {
      state.contracts = action.payload;
    },
    setContractById: (state, action) => {
      state.contractById = action.payload;
    },
    setPersonalData: (state, action) => {
      state.personalData = action.payload;
    },
    setSupAgreements: (state, action) => {
      state.supplementaryAgreements = action.payload;
    },
    setAttachmentsAndFiles: (state, action) => {
      state.attachments = action.payload;
    },
    setSupAgreementById: (state, action) => {
      state.supAgreementById = action.payload;
    },
    setStandardApprovalRoutes: (state, action) => {
      state.standardApprovalRoutes = action.payload;
    },
    setCheckedApprovalRoute: (state, action) => {
      state.selectedApprovalRoute = action.payload;
    },
  },
});

export const {
  setContractData,
  setContracts,
  setContractById,
  setPersonalData,
  setSupAgreements,
  setSupAgreementById,
  setAttachmentsAndFiles,
  setStandardApprovalRoutes,
  setCheckedApprovalRoute,
} = contractsSlice.actions;

export * from "./operations";
export * from "./route-operations";

export default contractsSlice.reducer;
