import {
  EmployeeMatchingRuleEnum,
  OrganizationMatchingRuleEnum,
  StructuralUnitMatchingRuleEnum,
} from "@types";

export const matchingRulesArray = [
  {
    rule: OrganizationMatchingRuleEnum.notSpecified,
    name: "organizationMatchingRule",
    label: "permissionsSetForm.organization_level",
  },
  {
    rule: StructuralUnitMatchingRuleEnum.notSpecified,
    name: "structuralUnitMatchingRule",
    label: "permissionsSetForm.division_level",
  },
  {
    rule: EmployeeMatchingRuleEnum.notSpecified,
    name: "employeeMatchingRule",
    label: "permissionsSetForm.employee_level",
  },
];

export const matchingRuleOptions = {
  organizationMatchingRule: Object.values(OrganizationMatchingRuleEnum),
  structuralUnitMatchingRule: Object.values(StructuralUnitMatchingRuleEnum),
  employeeMatchingRule: Object.values(EmployeeMatchingRuleEnum),
};
