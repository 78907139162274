import styled from "styled-components";
import {
  FLEX_BETWEEN,
  FLEX_COLUMN_FULL,
  FLEX_ROW,
  FLEX_ROW_ALIGN_CENTER,
} from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const Wrapper = styled.div<{ disabled: boolean }>`
  .dropzone {
    ${FLEX_COLUMN_FULL};
    padding: ${({ theme }) => theme.spacing(8, 5)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    gap: 16px;

    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    border-radius: ${({ theme }) => theme.spacing(4)};

    &__content {
      ${FLEX_ROW_ALIGN_CENTER};
      gap: ${({ theme }) => theme.spacing(2)};
    }
    &__uploadingProcess {
      ${FLEX_BETWEEN};
      padding: ${({ theme }) => theme.spacing(1, 2)};
      background-color: ${({ theme }) =>
        hexToRgba(theme.palette.primary.light, 0.04)};
      border-radius: ${({ theme }) => theme.spacing(2)};
    }
    &__uploadingProcessInArea {
      ${FLEX_BETWEEN};
    }

    &.accept {
      border-color: ${({ theme }) => theme.palette.success.main};
    }

    &.reject {
      border-color: ${({ theme }) => theme.palette.error.main};
    }
  }
  .uploaded_files_section {
    max-height: 210px;
    overflow: auto;
  }
  .uploaded_files_section_with_warning {
    max-height: 200px;
    overflow: auto;
  }
  .uploaded_file {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(2)};
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
