import { startTransition, useCallback, Dispatch, SetStateAction } from "react";

import { GridSortModel } from "@mui/x-data-grid-pro";
import { insertUrlParam, removeUrlParameter } from "@sbm/fe-utils";

interface Props {
  setPage?: Dispatch<SetStateAction<number>>;
  setPageSize?: (arg: number) => void;
  setSortModel?: (arg: string) => void;
  setSearch?: (arg: string) => void;
  setFilter?: Dispatch<SetStateAction<string[]>>;
}

export function useTableQueryMethods({
  setPage,
  setPageSize,
  setSortModel,
  setSearch,
  setFilter,
}: Props) {
  const onPageChange = useCallback(() => {
    if (!setPage) return;

    setPage((prevState) => prevState + 1);
  }, [setPage]);

  const onPageSizeChange = useCallback(
    (value: number) => {
      if (!setPageSize) return;

      setPageSize(value);
    },
    [setPageSize]
  );

  const onSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (!setSortModel) return;

      if (!model?.length) {
        removeUrlParameter("sortBy");
        return setSortModel("");
      }

      const { field, sort } = model[0];

      const sortBy = `${field}:${sort?.toUpperCase()}`;

      setSortModel(sortBy);

      removeUrlParameter("sortBy");
      insertUrlParam("sortBy", sortBy);

      return sortBy;
    },
    [setSortModel]
  );

  const onSearchChange = useCallback(
    (query: string) => {
      if (!setSearch) return;

      setSearch(query);
      removeUrlParameter("search");

      if (query) {
        insertUrlParam("search", query);
      }
    },
    [setSearch]
  );

  const onFilterChange = useCallback(
    (filterBy: string[]) => {
      if (!setFilter) return;

      setFilter((prevState) => {
        // removing url params if filters are reset
        if (prevState?.length && !filterBy.length) {
          removeUrlParameter("filter");
        }

        return filterBy;
      });

      startTransition(() => {
        filterBy.forEach((val, index) => {
          // removing previous params if they are present
          if (index === 0) {
            removeUrlParameter("filter");
          }

          insertUrlParam("filter", val);
        });
      });
    },
    [setFilter]
  );

  return {
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    onSearchChange,
    onFilterChange,
  };
}
