import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import { Switch, Tooltip, Typography } from "@mui/material";
import { TIconNames } from "@types";

import { IconWrapper, WidgetHeaderWrapper } from "./styles";

export interface WidgetHeaderProps {
  title: string;
  icon?: TIconNames | null;
  href?: string;
}

export const WidgetHeader = ({ title, icon, href }: WidgetHeaderProps) => {
  const { t } = useTranslation();

  return (
    <WidgetHeaderWrapper>
      <div className="widget_icon_title">
        {icon ? (
          <IconWrapper>
            <Icon name={icon} size={48} />
          </IconWrapper>
        ) : null}

        <Typography variant="h6" fontWeight={700} sx={{ textAlign: "initial" }}>
          {t(title)}
        </Typography>
      </div>
      {href ? (
        <Tooltip placement="top" title={"Показать на главной странице"}>
          <Switch color={"secondary"} />
        </Tooltip>
      ) : null}
    </WidgetHeaderWrapper>
  );
};
