import styled from "styled-components";

import { Card } from "@mui/material";

export const StyledCard = styled(Card)`
  .MuiDataGrid-cell {
    border-left: 1px solid ${({ theme }) => theme.palette.other.divider};

    &:last-of-type {
      border-right: 1px solid ${({ theme }) => theme.palette.other.divider};
    }
  }

  .centered {
    .MuiDataGrid-columnHeaderTitleContainerContent,
    .MuiDataGrid-columnHeaderTitle {
      width: 100%;
      text-align: center;
    }
  }
`;
