import axios from "axios";

import { environment } from "../../environments/environment";
import { getUser } from "../configs";

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const axiosInstance = axios.create({
  baseURL: `${environment.apiUrl}/api`,
  timeout: 20000,
});

// const firstAttempt = true;

export const axiosService = ({
  url = "",
  endpoint = "",
  body = {},
  method = "GET",
  headers = {},
}: {
  url?: string;
  endpoint?: string;
  body?: any;
  method?: string;
  headers?: any;
}) => {
  const token = getUser()?.access_token;

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const config = {
    method,
    url: `${url}${endpoint}`,
    data: body,
    params: method === "GET" ? body : null,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
    paramsSerializer: {
      indexes: true,
    },
  };

  return axiosInstance(config);
};

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     if (!error) return;
//
//     const originalRequest = error.config;
//     if (!originalRequest) return;
//
//     const state = store.getState();
//
//     if (error.response?.status === 401) {
//       if (
//         !originalRequest._retry &&
//         originalRequest.firstAttempt &&
//         firstAttempt
//       ) {
//         if (state.auth.token) {
//           originalRequest._retry = true;
//           firstAttempt = false;
//           originalRequest.firstAttempt = false;
//
//           return axiosInstance(originalRequest);
//         }
//       } else {
//         // handle logout
//       }
//     }
//
//     return Promise.reject(error.response);
//   }
// );
