import { axiosService } from "app";

export const fetchEmployees = async (
  search = "",
  organizationId?: number,
  subordinate?: boolean,
  limit?: number,
  headOfStructuralUnit?: boolean
) => {
  const params: {
    limit: number;
    page: number;
    search: string;
    "filter.organizationId"?: number;
    "filter.subordinate"?: boolean;
    "filter.headOfStructuralUnit"?: boolean;
  } = {
    limit: limit || 200,
    page: 1,
    search,
  };

  if (organizationId) {
    params["filter.organizationId"] = organizationId;
  }

  if (subordinate) {
    params["filter.subordinate"] = subordinate;
  }

  if (headOfStructuralUnit) {
    params["filter.headOfStructuralUnit"] = headOfStructuralUnit;
  }

  const { data } = await axiosService({
    endpoint: `employees`,
    body: params,
  });

  return data;
};

export const fetchEmployeeById = async (id?: number) => {
  const { data } = await axiosService({
    endpoint: `employees/${id}`,
  });

  return data;
};
