import { store } from "app";

import {
  formatDateToQuery,
  getPersonalDataName,
  StaffAllocationFilterKeys,
} from "@sbm/fe-utils";
import { IEmployee, IPosition, IStructuralUnit } from "@types";

export const getStaffAllocationOptions = (
  field: string,
  positions?: IPosition[],
  employees?: IEmployee[],
  organizationStructuralUnits?: IStructuralUnit[]
) => {
  const positionOptions = !positions?.length
    ? []
    : positions.map((i) => i.namePosition);

  const structuralUnitOptions = !organizationStructuralUnits?.length
    ? []
    : organizationStructuralUnits.map((i) => i.nameOfStructuralUnit);

  const employeeOptions = !employees?.length
    ? []
    : employees.map(({ personalData }) => {
        return getPersonalDataName(personalData);
      });

  switch (field) {
    case StaffAllocationFilterKeys.positionId:
      return positionOptions;
    case StaffAllocationFilterKeys.employeeId:
      return employeeOptions;
    case StaffAllocationFilterKeys.structuralUnitId:
      return structuralUnitOptions;
    default:
      return [];
  }
};

const getFilterIdsByName = (name: string, value: string) => {
  const { positions, employees, organizationStructuralUnits } =
    store.getState().organizations;

  switch (name) {
    case StaffAllocationFilterKeys.positionId:
      return positions?.items?.find((i) => i.namePosition === value)?.id || "";
    case StaffAllocationFilterKeys.employeeId:
      return (
        employees?.items?.find((i) => {
          const name = getPersonalDataName(i.personalData);
          return name === value;
        })?.id || ""
      );
    case StaffAllocationFilterKeys.structuralUnitId:
      return (
        organizationStructuralUnits?.items?.find((i) => {
          return i.nameOfStructuralUnit === value;
        })?.id || ""
      );
    case StaffAllocationFilterKeys.dateOfRecruitment:
      return formatDateToQuery(value);
    default:
      return [];
  }
};

export const getFilterValues = (selectedFilters: string[]) => {
  if (!selectedFilters?.length) return [];

  return selectedFilters.map((filter) => {
    const parts = filter.split("=");
    const filterKey = parts[0];
    const filterValue = parts[1];
    const values = filterValue.split(",");

    let query = `${filterKey}=`;

    values.forEach((value, index) => {
      const isLast = index === values.length - 1;

      const idOfFilter = getFilterIdsByName(filterKey, filterValue);

      if (idOfFilter) {
        query = query + idOfFilter;
        if (!isLast) query = query + ",";
      }
    });

    return query;
  });
};
