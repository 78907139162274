import React from "react";

import { Theme, useMediaQuery } from "@mui/material";

interface MediaTypes {
  smDown: boolean;
  mdDown: boolean;
  lgDown: boolean;
  xlDown: boolean;
  tabletDown: boolean;
}

export const useMedia = (): [MediaTypes] => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm")); // Mobile
  const mdDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "md")
  ); // Tablet
  const lgDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("md", "lg")
  ); // Small Laptop
  const xlDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("lg", "xl")
  ); // Large Laptop

  const tabletDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const media = React.useMemo(() => {
    return { smDown, mdDown, lgDown, xlDown, tabletDown };
  }, [lgDown, mdDown, smDown, tabletDown, xlDown]);

  return [media];
};
