import { getColumnNameFromArray } from "app";
import { TFunction } from "i18next";

import {
  ContractFieldsEnum,
  ContractFieldNamesEnum,
  UsersFieldsEnum,
  UsersFieldNamesEnum,
  StaffAllocationFilterKeys,
  StaffAllocationFilterNames,
} from "@sbm/fe-utils";

export const getFilterOptionName = (word: string) => {
  const splitWord = word.split(":");
  return `${splitWord[0].toLowerCase()}`;
};

export const getFilterAutocompleteName = (key: string, t: TFunction) => {
  if (!key) return "";

  switch (key) {
    case UsersFieldsEnum.staff:
      return t(UsersFieldNamesEnum.staff);
    case UsersFieldsEnum.status:
      return t(UsersFieldNamesEnum.status);

    case ContractFieldsEnum.contractCategoryId:
      return t(ContractFieldNamesEnum.category);
    case ContractFieldsEnum.contractTypeId:
      return t(ContractFieldNamesEnum.type);
    case ContractFieldsEnum.contractSubtypeId:
      return t(ContractFieldNamesEnum.subtype);
    case ContractFieldsEnum.constructionComplexId:
      return t(ContractFieldNamesEnum.constructionComplex);
    case ContractFieldsEnum.dateContract:
      return t(ContractFieldNamesEnum.date);
    case ContractFieldsEnum.dateValidityExpiryContract:
      return t(ContractFieldNamesEnum.expiryDate);
    case ContractFieldsEnum.dateOfSigning:
      return t(ContractFieldNamesEnum.dateOfSigning);
    case ContractFieldsEnum.expirationDate:
      return t(ContractFieldNamesEnum.expiryDate);
    case ContractFieldsEnum.party1OrganizationId:
      return getColumnNameFromArray(
        ContractFieldNamesEnum.party1Organization,
        t
      );
    case ContractFieldsEnum.party2OrganizationId:
      return getColumnNameFromArray(
        ContractFieldNamesEnum.party2Organization,
        t
      );
    case ContractFieldsEnum.party1RoleId:
      return getColumnNameFromArray(ContractFieldNamesEnum.party1Role, t);
    case ContractFieldsEnum.party2RoleId:
      return getColumnNameFromArray(ContractFieldNamesEnum.party2Role, t);

    case StaffAllocationFilterKeys.positionId:
      return t(StaffAllocationFilterNames.positionId);
    case StaffAllocationFilterKeys.employeeId:
      return t(StaffAllocationFilterNames.employeeId);
    case StaffAllocationFilterKeys.structuralUnitId:
      return t(StaffAllocationFilterNames.structuralUnitId);
    case StaffAllocationFilterKeys.dateOfRecruitment:
      return t(StaffAllocationFilterNames.dateOfRecruitment);
    default:
      return t(key);
  }
};

export const getFilterValue = (val: string | string[]) => {
  switch (val) {
    case "yes":
      return "true";
    case "no":
      return "false";
    default:
      return val;
  }
};
