import styled from "styled-components";

export const TableWrapper = styled.div`
  .MuiDataGrid-main {
    max-height: 100%;
  }

  .MuiDataGrid-row {
    &.disabled {
      background: ${({ theme }) => theme.palette.primary["4p"]};
    }
  }
`;
