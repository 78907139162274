import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import ContractCard_image_en from "../../../assets/backgrounds/contract/ContractCard_en.svg";
import ContractCard_image_ru from "../../../assets/backgrounds/contract/ContractCard_ru.svg";

export const CCWrapper = styled.div<{
  $borderColor: string;
  $isMaximized: boolean;
}>`
  max-width: ${({ $isMaximized }) =>
    $isMaximized ? "" : `${UI_CONFIG.contractCardMaxWidth}px`};

  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.palette.gradients["red-blue"]};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  box-shadow: ${({ theme }) => theme.shadows[4]};

  border-left: ${({ theme, $borderColor }) =>
    `${theme.spacing(4)} solid ${$borderColor}`};

  .cc__date-section {
    ${FLEX_ROW};
    justify-content: space-between;

    gap: ${({ theme }) => theme.spacing(5)};
  }
`;

export const CCContent = styled.div`
  ${FLEX_COLUMN};

  .cc__title {
    min-height: 22px;
  }

  .cc__subtitle {
    min-height: 24px;
    max-width: 100px;
  }

  .cc__title-leftside {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(2)};
    flex: 1;

    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
  .cc__title-wrapper {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(3)};
  }

  .cc__title-text {
    ${FLEX_COLUMN};
  }

  .cc__content {
    ${FLEX_COLUMN};
    gap: ${({ theme }) => theme.spacing(4)};
  }

  position: relative;
  z-index: 9;
`;

export const CardImageWrapper = styled.div<{ $isEnglish: boolean }>`
  background-image: ${({ $isEnglish }) =>
    $isEnglish
      ? `url(${ContractCard_image_en})`
      : `url(${ContractCard_image_ru})`};
  background-position: center;
  height: 219px;
  width: 444px;
  background-repeat: no-repeat;
`;

export const ImageWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: center;
  width: 100%;
`;

export const CountWrapper = styled.div`
  width: 40px;
  margin-left: ${({ theme }) => theme.spacing()};
`;
