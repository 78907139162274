import { getStorageItem, STORAGE_KEYS } from "./storage";

export const isValidDate = (d: any) => {
  return !isNaN(d) && d instanceof Date;
};

export const getLocaleDateFormat = (
  date?: Date | string | null,
  timeFormat?: "hh:mm" | "hh:mm:ss",
  fromMessenger?: boolean
) => {
  if (!date || (typeof date !== "string" && !isValidDate(date))) return "";

  const dateToFormat = typeof date === "string" ? new Date(date) : date;

  const selectedLang = getStorageItem(STORAGE_KEYS.lang) || "ru";
  const locale = selectedLang === "ru" ? "ru-RU" : "en-US";

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: selectedLang === "en" ? "short" : "numeric",
    day: "numeric",
  };

  if (timeFormat === "hh:mm:ss") {
    options.hour = "2-digit";
    options.minute = "2-digit";
    options.second = "2-digit";
  } else if (timeFormat === "hh:mm") {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }

  if (!fromMessenger) {
    return new Intl.DateTimeFormat(locale, options).format(dateToFormat);
  }
  const formattedDate = new Intl.DateTimeFormat(locale, options).format(dateToFormat);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const dayBeforeYesterday = new Date(today);
  dayBeforeYesterday.setDate(today.getDate() - 2);

  if (fromMessenger) {
    return formattedDate;
  }

  if (isSameDay(dateToFormat, today)) {
    return selectedLang === "en" ? "Today" : "Сегодня";
  } else if (isSameDay(dateToFormat, yesterday)) {
    return selectedLang === "en" ? "Yesterday" : "Вчера";
  } else if (isSameDay(dateToFormat, dayBeforeYesterday)) {
    return selectedLang === "en" ? "Day before yesterday" : "Позавчера";
  } else {
    return formattedDate;
  }
};

const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}
export const formatDateToQuery = (arg: string, isWeeklyCalendar = false) => {
  const dates = arg.split(" | ");

  let result = "";

  dates.forEach((d, index) => {
    const splitDate = d.split("/");
    // change month and date places because js new Date() handles month at first
    const dateString = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
    const date = new Date(dateString);

    if (isWeeklyCalendar) {
      // add a day for weekly calendar
      date.setDate(date.getDate() + 1);
    }

    const year = date.getFullYear();
    const month = padTo2Digits(date.getMonth() + 1);
    const day = padTo2Digits(date.getDate());
    const withHyphens = [year, month, day].join("-");

    const isLast = index === dates.length - 1;
    const stringToAdd = `${withHyphens}${isLast ? "" : "|"}`;

    result = result.concat(stringToAdd);
  });

  return result;
};
