import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { circularProgressClasses } from "@mui/material";

export const ActionsWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;

  gap: ${({ theme }) => theme.spacing(4)};

  width: 100%;

  margin-top: ${({ theme }) => theme.spacing(10)};

  margin-left: auto;

  .${circularProgressClasses.circle} {
    color: white;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 220px;
  }
`;
