import styled from "styled-components";
import { FLEX_COLUMN_FULL, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import {
  formControlLabelClasses,
  formGroupClasses,
  radioClasses,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

export const Wrapper = styled.div`
  height: 100%;

  form {
    height: 100%;
  }

  .radio__buttons {
    > label {
      margin-left: 0;
    }
  }

  .${formGroupClasses.root} {
    .${formControlLabelClasses.root} {
      .${radioClasses.root} {
        flex: 0;
      }
    }
  }
`;
export const AttachedLinksWrapper = styled.div`
  max-height: 400px;
  margin-top: ${({ theme }) => theme.spacing(8)};
  overflow: auto;
`;

export const LabelWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
`;

export const LinksItemWrapper = styled.div`
  background-color: ${({ theme }) =>
    hexToRgba(theme.palette.secondary.main, 0.04)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2, 4)};
  .link_delete_icon {
    ${FLEX_ROW_ALIGN_CENTER}
  }
`;
