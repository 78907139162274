import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_ROW_FULL } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const AttachedFile = styled.div`
  ${FLEX_BETWEEN};
  width: 100%;
  height: 32px;
  padding: ${({ theme }) => theme.spacing(2, 1.5)};
  cursor: pointer;

  &:hover {
    .download-icon {
      display: block;
    }
  }

  .attached_file_item {
    ${FLEX_ROW_FULL};
  }

  .download-icon {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) =>
      hexToRgba(theme.palette.primary.light, 0.04)};
  }
}
`;
