import styled from "styled-components";

export const WidgetContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(4)};
  height: 160px;
`;

export const WidgetInfoWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  width: 100%;
  height: 60px;
`;
