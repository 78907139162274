import React from "react";
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import { ChangeDefaultRoutePopup, IModal } from "app";

import { IApprover, ICreateStandardRoute, ModalVariants } from "@types";

import { ApproversGanttChart } from "../ApproversGanttChart";
import { AssignmentTable } from "../AssignmentTable";

interface AssignRouteToContractProps {
  approvers?: IApprover[];
  id?: number;
  organizationId?: number;
  setValue: UseFormSetValue<ICreateStandardRoute>;
  setError: UseFormSetError<ICreateStandardRoute>;
  clearErrors: UseFormClearErrors<ICreateStandardRoute>;
  errors: FieldErrors;
}

export const AssignRouteToContract: React.FC<AssignRouteToContractProps> = ({
  approvers,
  id,
  setValue,
  organizationId,
  setError,
  errors,
  clearErrors,
}) => {
  const [modal, setModal] = React.useState<IModal | null>(null);

  const handleCloseModal = React.useCallback(() => {
    setModal(null);
  }, []);

  const handleConfirmTransition = React.useCallback(() => {
    handleCloseModal();

    if (modal?.content?.confirmNavigation) {
      modal.content.confirmNavigation();
    }
  }, [handleCloseModal, modal]);

  const isChangeDefaultRoutePopupOpen = Boolean(
    modal?.open && modal.variant === ModalVariants.changeDefaultRoute
  );

  return (
    <>
      <ApproversGanttChart approvers={approvers} />
      <AssignmentTable
        setValue={setValue}
        organizationId={organizationId}
        setModal={setModal}
        setError={setError}
        errors={errors}
        clearErrors={clearErrors}
      />

      <ChangeDefaultRoutePopup
        onClose={handleCloseModal}
        onConfirm={handleConfirmTransition}
        open={isChangeDefaultRoutePopupOpen}
        data={modal?.content?.data}
      />
    </>
  );
};
