import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  closeDrawer,
  closeDrawerWithData,
  setModal,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { ToggleSection, Drawer, Icon } from "ui-kit";

import { Stack, useTheme } from "@mui/material";
import { Button } from "@sbm/ui-components";
import { IOrganization, IOrganizationEmployee, ModalVariants } from "@types";

import { defaultValues, initialEmployeeFields } from "../../constants";
import { IdentificationForm } from "../IdentificationForm";
import { OrganizationEmployeesForm } from "../OrganizationEmployeesForm";
import { SubmitButton } from "../SubmitButton";
import { ActionsWrapper } from "./styles";

export const OrganizationMainForm: React.FC<{
  defaultData?: IOrganization;
}> = ({ defaultData }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const isEditMode = Boolean(defaultData);
  const organizationId = defaultData?.id;

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<IOrganization>({
    defaultValues:
      {
        ...defaultData,
        employees:
          defaultData?.externalOrganizationStaff?.map((item) => {
            return {
              ...item,
              personalDataId: item.personalData?.id,
            };
          }) || [],
      } || defaultValues,
  });

  const {
    briefTextOrganizationName,
    inn,
    fullTextOrganizationName,
    employees,
    externalOrganizationStaff,
  } = useWatch({ control });

  const [showPrompt, setShowPrompt] = useState(false);
  const { drawer, drawerWithData } = useAppSelector((state) => state.global);

  const isFormDirty = Object.keys(dirtyFields).length > 0;
  const isEmployeesRemoved = Boolean(
    isEditMode &&
      externalOrganizationStaff &&
      employees &&
      externalOrganizationStaff.length > 0 &&
      employees.length === 0
  );

  const isCreateOrganizationRequiredFieldsFilled = Boolean(
    briefTextOrganizationName && inn
  );

  const handleAddSignatory = () => {
    const dataToSet = (
      employees
        ? [...employees, ...[initialEmployeeFields]]
        : [initialEmployeeFields]
    ) as IOrganizationEmployee[];
    setValue("employees", dataToSet, { shouldDirty: true });
  };

  const handleRemoveSignatory = (id: number) => {
    const onRemove = () => {
      const dataToSet = employees?.filter((employee) => employee.id !== id);
      setValue("employees", dataToSet as IOrganizationEmployee[], {
        shouldDirty: true,
        shouldTouch: true,
      });
    };

    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.removeOrganizationEmployee,
        content: {
          onRemove,
        },
      })
    );
  };

  const handleCancel = () => {
    if (isFormDirty) {
      setShowPrompt(true);
      return;
    }
    handleClose();
  };

  const handleConfirmTransition = () => {
    if (isEditMode) {
      dispatch(closeDrawerWithData());
    } else {
      dispatch(closeDrawer());
    }
  };

  const handleClose = () => {
    handleConfirmTransition();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  return (
    <Drawer
      anchor="right"
      open={Boolean(drawer) || Boolean(drawerWithData)}
      onClose={handleCancel}
      resizable={false}
      type="createOrgDrawer"
      title={isEditMode ? fullTextOrganizationName : t("create.organization")}
      actions={
        <ActionsWrapper>
          <div className="main_buttons">
            <Button
              variant="outlined"
              color="secondary"
              autoFocus
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <SubmitButton
              reset={reset}
              handleSubmit={handleSubmit}
              control={control}
              isEditMode={isEditMode}
              dirtyFields={dirtyFields}
              organizationId={organizationId}
              isEmployeesRemoved={isEmployeesRemoved}
            />
          </div>
        </ActionsWrapper>
      }
    >
      <Stack>
        {showPrompt && (
          <TransitionPrompt
            open={showPrompt}
            onClose={handleClosePrompt}
            onConfirm={handleClose}
          />
        )}

        <ToggleSection
          title={t("identification.data").toUpperCase()}
          initialOpenState
          hasDivider
          isExpandable={false}
        >
          <IdentificationForm
            control={control}
            register={register}
            errors={errors}
          />
        </ToggleSection>

        {isEditMode && (
          <ToggleSection
            title={t("org.employees").toUpperCase()}
            hasDivider
            initialOpenState={isEditMode}
            isDisabled={!isCreateOrganizationRequiredFieldsFilled}
            actions={
              <Button
                variant="text"
                size="large"
                color="secondary"
                onClick={handleAddSignatory}
                startIcon={<Icon name="Plus" color={palette.secondary.main} />}
              >
                {t("add")}
              </Button>
            }
          >
            <OrganizationEmployeesForm
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
              employees={employees as IOrganizationEmployee[]}
              onRemove={handleRemoveSignatory}
              organizationId={organizationId!}
            />
          </ToggleSection>
        )}

        {isEditMode && (
          <ToggleSection
            title={t("contact.information").toUpperCase()}
            hasDivider
            isDisabled
          >
            <div />
          </ToggleSection>
        )}

        {isEditMode && (
          <ToggleSection
            title={t("bank.accounts").toUpperCase()}
            hasDivider
            isDisabled
          >
            <div />
          </ToggleSection>
        )}
      </Stack>
    </Drawer>
  );
};
