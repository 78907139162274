import toast from "react-hot-toast";

import {
  fetchCreateEmployee,
  fetchFunctionalDirectionOfTheUnit,
  fetchOrganizationById,
  fetchOrganizationsList,
  fetchOrganizationStaffAllocation,
  fetchUpdateOrganization,
  getPersonalData,
  postExternalOrganizationStaff,
  postPersonalData,
} from "api";
import { closeDrawerWithData, useAppDispatch } from "app";
import { AxiosError } from "axios";

import {
  getAPIErrorMessage,
  getFiltersQueryCacheKey,
  SnackbarMessages,
} from "@sbm/fe-utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  FunctionEnum,
  GenderEnum,
  ICreateEmployee,
  ICurrentUserApprovals,
  IMeta,
  IOrganization,
  IOrganizationEmployee,
  IPersonalData,
  OrganizationsListItem,
} from "@types";

export const useGetOrganizationsList = (sortBy = "", search = "") => {
  return useInfiniteQuery<{
    items: OrganizationsListItem[];
    meta: IMeta;
  }>(
    ["get_organizations_list", search, sortBy],
    (params) => fetchOrganizationsList(params, sortBy, search),
    {
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetStaffAllocations = (
  id: string | undefined,
  search: string,
  filters: (string | undefined)[]
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filters);

  return useQuery<ICurrentUserApprovals[]>(
    ["get_staff_allocations", id, search, ...queryKeyFilter],
    async () => await fetchOrganizationStaffAllocation(id, search, filters),
    {
      retry: false,
      enabled: Boolean(id),
    }
  );
};

export const useGetOrganizationById = (id: number) => {
  return useQuery<IOrganization>(
    ["get_organization", id],
    async () => await fetchOrganizationById(id),
    {
      retry: false,
      enabled: Boolean(id),
    }
  );
};

export const useGetFunctionalDirectionOfTheUnit = (id: number) => {
  return useQuery<FunctionEnum[]>(
    ["get_functional_direction_unit", id],
    async () => await fetchFunctionalDirectionOfTheUnit(id)
  );
};

export const useCreateEmployee = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: ICreateEmployee) => {
      await fetchCreateEmployee(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_staff_allocations"]);
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useUpdateExternalOrganizationStaff = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: {
      organizationId: number;
      staff: IOrganizationEmployee[];
    }) => {
      await postExternalOrganizationStaff(body);
    },
    {
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
      onSuccess: () => {
        dispatch(closeDrawerWithData());
      },
    }
  );
};

export const useUpdateOrganization = (organizationId: number | undefined) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (
      body: Omit<IOrganization, "employees" | "externalOrganizationStaff">
    ) => {
      await fetchUpdateOrganization(body, organizationId);
    },
    {
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries([
          ["get_organization"],
          ["get_organizations_list"],
        ]);
        dispatch(closeDrawerWithData());
      },
    }
  );
};

export const useCreatePersonalDataMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation(
    async (body: {
      name: string;
      surname: string;
      patronymic: string;
      gender: GenderEnum;
    }) => {
      await postPersonalData(body);
    },
    {
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetPersonalData = (search: string) => {
  return useQuery<{ items: IPersonalData[]; meta: IMeta }>(
    ["get_personal_data", search],
    async () => await getPersonalData(search),
    {
      retry: false,
    }
  );
};
