import React, { useCallback, useEffect, useMemo } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  DateHeaderProps,
  Event,
  EventProps,
  View,
  Views,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useTranslation } from "react-i18next";

import {
  axiosService,
  getTaskStatusChipConfig,
  useAppSelector,
  useEffectOnce,
  useQueryParams,
} from "app";
import { format, getDay, isSameDay, parse, startOfWeek } from "date-fns";
import { enUS, ru } from "date-fns/locale";

import { Typography, useTheme } from "@mui/material";
import {
  formatDateToQuery,
  getFilterParams,
  getStorageItem,
  getUniqueListBy,
  STORAGE_KEYS,
} from "@sbm/fe-utils";
import { useQueryClient } from "@tanstack/react-query";
import {
  DrawerVariantsEnum,
  IMeta,
  ITaskForCalendar,
  IWorkingDay,
  StatusForAuthorEnum,
  StatusForExecutorEnum,
  TaskCategoryEnum,
  TypeOfTheTaskEnum,
} from "@types";

import { TaskPreview } from "../../../components";
import {
  TasksTabEnum,
  TaskStatusHierarchyIncoming,
  TaskStatusHierarchyOutgoing,
} from "../../constants";
import { CalendarEvent } from "./CalendarEvent";
import { CalendarToolbar } from "./CalendarToolbar";
import { CalendarWrapper } from "./styles";

const locales = {
  "en-US": enUS,
  ru: ru,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const isDateInArray = (array: Date[], value: Date) => {
  return !!array.find((item) => {
    return isSameDay(item, value);
  });
};

const locale = getStorageItem(STORAGE_KEYS.lang);
const isEnglish = locale === "en";
const dateFormat = `dd/${isEnglish ? "MMM" : "MM"}/yyyy`;

type TCalendarEvent = {
  id: number;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  resource: {
    id: number;
    author: string;
    text: string;
    executor: string | null;
    status?: StatusForExecutorEnum | StatusForAuthorEnum;
    typeOfTheTask: TypeOfTheTaskEnum;
    delayed: boolean;
  };
};

interface MyTasksCalendarProps {
  type: TasksTabEnum;
  data: { items: ITaskForCalendar[]; meta: IMeta } | undefined;
}

export const MyTasksCalendar: React.FC<MyTasksCalendarProps> = ({
  type,
  data,
}) => {
  const { palette } = useTheme();
  const { set, remove, get } = useQueryParams();
  const { t } = useTranslation("tasks");
  const { drawer } = useAppSelector((state) => state.global);
  const queryClient = useQueryClient();

  const filterFromUrl = get("filter") as string;
  const categoryFilterFromUrl = get("task_category", true) as string[];

  const [view, setView] = React.useState<View>(Views.MONTH);
  const [nonWorkingDays, setNonWorkingDays] = React.useState<Date[]>([]);

  const events: TCalendarEvent[] = useMemo(() => {
    return (
      data?.items?.map((item) => ({
        id: item.id,
        title: t(`types.${item.typeOfTheTask}`),
        start: new Date(item.startDate!),
        end: new Date(item.endDate!),
        allDay: true,
        resource: {
          delayed: item.delayed,
          id: item.id,
          author: item.taskAuthorShortName,
          text: item.textOfTheTask,
          executor:
            type === TasksTabEnum.incoming ? null : item.taskExecutorShortName,
          status:
            type === TasksTabEnum.incoming
              ? item.statusForExecutor
              : item.statusForAuthor,
          typeOfTheTask: item.typeOfTheTask,
        },
      })) || []
    );
  }, [data, t, type]);

  const [eventsToShow, setEventsToShow] = React.useState(events);

  const handleSelectCategory = useCallback(
    (categories: TaskCategoryEnum[] | undefined | null) => {
      // handle initial state
      if (categories === undefined) {
        setEventsToShow(events);
        return;
      }
      // handle disabled all buttons state
      if (categories === null) {
        setEventsToShow([]);
        return;
      }

      let dataToSet: TCalendarEvent[] = [];

      categories.forEach((category) => {
        const statusValues =
          type === TasksTabEnum.incoming
            ? TaskStatusHierarchyIncoming[category]
            : TaskStatusHierarchyOutgoing[category];

        const filteredEvents = events.filter((item) => {
          return (
            item.resource.status && statusValues.includes(item.resource.status)
          );
        });

        dataToSet = [...dataToSet, ...filteredEvents] as TCalendarEvent[];
      });

      const uniqueList = getUniqueListBy(dataToSet, "id");
      setEventsToShow(uniqueList);
    },
    [type, events]
  );

  const handleRangeChange = (
    range: Date[] | { start: Date; end: Date },
    view: View | undefined
  ) => {
    if (!range) return;

    let dateToReturn: string | undefined;

    view && setView(view);

    if (Array.isArray(range)) {
      const start = range[0];
      const end = range.at(-1)!;

      dateToReturn =
        format(start, dateFormat) + " | " + format(end, dateFormat);
    } else {
      const { start, end } = range;

      dateToReturn =
        format(start, dateFormat) + " | " + format(end, dateFormat);
    }

    remove("filter");
    set("filter", `dateRange=${dateToReturn}`);
    void queryClient.invalidateQueries(["get_tasks"]);
  };

  const { components, defaultDate } = useMemo(
    () => ({
      components: {
        toolbar: CalendarToolbar,
        event: (props: EventProps) => (
          <CalendarEvent
            isWeekView={view === Views.WEEK}
            tab={type}
            {...props}
          />
        ),
        month: {
          dateHeader: ({ date, label }: DateHeaderProps) => {
            const isNonWorkingDay = isDateInArray(nonWorkingDays, date);

            return (
              <Typography
                variant="body1"
                color={isNonWorkingDay ? "text.disabled" : "text.primary"}
                fontWeight={isNonWorkingDay ? 400 : 600}
              >
                {label}
              </Typography>
            );
          },
        },
        timeSlotWrapper: () => null,
        timeGutterWrapper: () => null,
        timeGutterHeader: () => null,
      },
      defaultDate: new Date(),
    }),
    [type, nonWorkingDays, view]
  );

  const eventPropGetter = useCallback(
    (event: Event, _: Date, __: Date, isSelected: boolean) => ({
      ...(isSelected && {
        style: {
          opacity: 0.8,
        },
      }),
      ...{
        style: getTaskStatusChipConfig(
          event.resource.status,
          palette,
          t,
          false,
          type === TasksTabEnum.outgoing
        ),
      },
    }),
    [palette, t, type]
  );

  useEffectOnce(() => {
    remove("task_category");
    return () => {
      if (filterFromUrl) {
        remove("filter");
      }
    };
  });

  useEffect(() => {
    setEventsToShow(events);
  }, [events]);

  useEffect(() => {
    handleSelectCategory(undefined);
  }, [type, handleSelectCategory]);

  useEffect(() => {
    const taskCategoryFilter = get("task_category") as string;

    if (taskCategoryFilter && taskCategoryFilter === "null") {
      handleSelectCategory(null);
    } else if (categoryFilterFromUrl?.length > 0) {
      handleSelectCategory(categoryFilterFromUrl as TaskCategoryEnum[]);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(categoryFilterFromUrl), handleSelectCategory, get]);

  useEffect(() => {
    // Get Non-Working days when calendar range is selected
    if (!filterFromUrl) return;

    const rangeFilter = filterFromUrl?.replace("dataRange", "range");
    const splitFilter = rangeFilter.split("=");
    const filterValue = formatDateToQuery(splitFilter[1], true);

    if (!filterValue) return;

    const filterToSend = `${splitFilter[0]}=${filterValue}`;
    const filterParams = getFilterParams([filterToSend], ["filter.range"]);

    const getWorkingDaysList = async () => {
      return await axiosService({
        url: "/working-days/list-of-days",
        body: filterParams,
      });
    };

    getWorkingDaysList().then((resp) => {
      const list = (resp.data as IWorkingDay[]) || [];
      const nonWorkingDays = list
        .filter((i) => !i.workingDay)
        .map((i) => new Date(i.date));
      setNonWorkingDays(nonWorkingDays);
    });
    // eslint-disable-next-line
  }, [JSON.stringify(filterFromUrl)]);

  return (
    <>
      <CalendarWrapper $weekView={view === Views.WEEK}>
        <Calendar
          popup={view !== Views.WEEK}
          localizer={localizer}
          defaultDate={defaultDate}
          components={components}
          culture={locale}
          events={eventsToShow}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventPropGetter}
          views={{ month: true, week: true }}
          defaultView={Views.MONTH}
          onRangeChange={handleRangeChange}
          showAllEvents={view === Views.WEEK}
          tooltipAccessor={(event) => {
            if (view === Views.WEEK) {
              return t(`types.${event.resource.typeOfTheTask}`);
            }
            return `${event.resource.author} - ${event.resource.text || ""}`;
          }}
        />
      </CalendarWrapper>

      {drawer === DrawerVariantsEnum.taskPreview && <TaskPreview />}
    </>
  );
};
