import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  height: 100%;
  max-height: 346px;
  overflow-y: auto;

  text-transform: capitalize;

  padding: ${({ theme }) => theme.spacing(2, 4)};
`;
