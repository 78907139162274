import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";

export const Wrapper = styled(Card)`
  border-radius: ${({ theme }) => theme.spacing(4)};

  ${FLEX_COLUMN};
`;
