import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCreatePersonalDataMutation } from "api";

import { Dialog, DialogActions, Grid, Stack, Typography } from "@mui/material";
import { Button, FormRadio, Input } from "@sbm/ui-components";
import { GenderEnum } from "@types";

interface AddEmployeePopupProps {
  open: boolean;
  onClose: () => void;
  organizationId: number;
}

export const AddEmployeePopup: React.FC<AddEmployeePopupProps> = ({
  open,
  onClose,
  organizationId,
}) => {
  const { t } = useTranslation();

  const { mutate: createPersonalData, isLoading } =
    useCreatePersonalDataMutation();

  const { register, control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      patronymic: "",
      gender: GenderEnum.male,
    },
  });

  const { name, surname } = useWatch({ control });

  const genderOptions = [
    { option: t("profilePage.accountTab.male"), value: GenderEnum.male },
    { option: t("profilePage.accountTab.female"), value: GenderEnum.female },
  ];

  const handleClose = () => {
    reset();
    onClose();
  };
  const onSubmit = handleSubmit(
    async (data: {
      name: string;
      surname: string;
      patronymic: string;
      gender: GenderEnum;
    }) => {
      createPersonalData(data);
      handleClose();
    }
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={8}
        >
          <Typography variant="h8" fontWeight={700}>
            {t("add.employee")}
          </Typography>
        </Stack>

        <Grid container columnSpacing={6} rowSpacing={4}>
          <Grid item xs={12}>
            <Input
              required
              label={t("profilePage.accountTab.surname")}
              variant="outlined"
              size="medium"
              maxLength={500}
              {...register("surname")}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required
              label={t("profilePage.accountTab.name")}
              variant="outlined"
              size="medium"
              maxLength={500}
              {...register("name")}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required
              label={t("profilePage.accountTab.patronymic")}
              variant="outlined"
              size="medium"
              maxLength={500}
              {...register("patronymic")}
            />
          </Grid>

          <Grid item xs={12} sx={{ ml: 1 }}>
            <FormRadio name="gender" control={control} values={genderOptions} />
          </Grid>
        </Grid>

        <DialogActions sx={{ pt: 6, gap: 4, justifyContent: "center" }}>
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            loading={isLoading}
            color="secondary"
            type="submit"
            disabled={!name || !surname}
          >
            {t("add")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
