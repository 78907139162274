// Keeping this file for editing mode reuse
import React from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, FormAutocomplete, Input } from "@sbm/ui-components";
import {
  ICreateStaffUnit,
  IStructuralUnit,
  ModalVariants,
  StructuralUnitTypesEnum,
} from "@types";

import { useAppDispatch, useAppSelector, useEffectOnce } from "../../../hooks";
import {
  createStaffUnit,
  getPositions,
  getStructuralUnitsAutocomplete,
  setModal,
  updateStaffUnit,
} from "../../../store";
import { TitleWrapper } from "../CreateStaffMemberContainer/styles";
import { StaffPositionsTable } from "./StaffPositionsTable";
import { ActionsSection, FormWrapper } from "./styles";

interface Props {
  data?: any;
}

export const CreateStaffUnitContainer = ({ data }: Props) => {
  const isViewMode = Boolean(data);

  const theme = useTheme();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [hiddenCheckbox, setHiddenCheckbox] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const [inputPositionValue, setInputPositionValue] = React.useState("");
  const [inputStructuralUnitValue, setInputStructuralUnitValue] =
    React.useState("");

  const [structuralUnitOptions, setStructuralUnitOptions] = React.useState<
    { title: string; id: number; staffUnits: any[]; type: string }[]
  >([]);

  const [positionOptions, setPositionOptions] = React.useState<
    { title: string; id: number }[]
  >([]);

  const { modal } = useAppSelector((state) => state.modals);

  const organizationId = params.id || modal?.content.data.organizationId || "";

  const isStructuralUnitTypeHead = React.useMemo(() => {
    const selectedType = structuralUnitOptions?.find(
      (i) => i.title === inputStructuralUnitValue
    )?.type;

    return (
      selectedType === StructuralUnitTypesEnum.mainGoverningBody ||
      selectedType === StructuralUnitTypesEnum.goveringBody
    );
  }, [inputStructuralUnitValue, structuralUnitOptions]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm<ICreateStaffUnit>({
    defaultValues: {
      structuralUnitId: data?.structuralUnitId,
      positionId: data?.positionId,
      additionalInformation: data?.additionalInformation || "",
      headOfStructuralUnit: Boolean(data?.headOfStructuralUnit),
      validity: data?.validityBoolean === false ? data.validityBoolean : true,
    },
  });

  const {
    structuralUnitId,
    additionalInformation,
    positionId,
    headOfStructuralUnit,
    validity,
  } = useWatch({
    control,
  });

  const { fields: orders } = useFieldArray({
    control,
    name: "orders",
  });

  const isRequiredFieldsFilled = Boolean(structuralUnitId && positionId);

  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const ModalTitle = data
    ? data.nameOfPosition || t("staff.unit.information")
    : t("adding.position");

  const handleStructuralUnitInputChange = (val: string, reason: string) => {
    setInputStructuralUnitValue(val);
    setValue("headOfStructuralUnit", false);

    // Delete action
    if (reason === "clear") {
      clearErrors("structuralUnitId");
      setHiddenCheckbox(false);
      return setValue("structuralUnitId", undefined);
    }
    // Action is select from the list
    if (reason === "reset") {
      const option = structuralUnitOptions.find((i) => i.title === val);

      if (!option) return;

      const isHeadOfDivision = option.staffUnits.some(
        (i) => i?.headOfStructuralUnit
      );

      if (isHeadOfDivision) {
        setValue("headOfStructuralUnit", false);
        setHiddenCheckbox(true);
      }

      return setValue("structuralUnitId", option.id);
    }
  };

  const handlePositionInputChange = (val: string, reason: string) => {
    setInputPositionValue(val);
    // Delete action
    if (reason === "clear") {
      clearErrors("positionId");
      return setValue("positionId", undefined);
    }
    // Action is select from the list
    if (reason === "reset") {
      const option = positionOptions.find((i) => i.title === val);
      if (!option) return;
      return setValue("positionId", option.id);
    }
  };

  const getPositionDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !positionOptions.length) return;

      const position = positionOptions.find((i) => i.id === id);
      if (!position) return;

      return position.title;
    },
    [positionOptions]
  );

  const getStructuralUnitDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !structuralUnitOptions.length) return;

      const structuralUnit = structuralUnitOptions.find((i) => i.id === id);
      if (!structuralUnit) return;

      return structuralUnit.title;
    },
    [structuralUnitOptions]
  );

  const fetchStructuralUnits = React.useCallback(
    async (search: string) => {
      const { items } = await dispatch(
        getStructuralUnitsAutocomplete(search, organizationId as string)
      );

      return items
        .map((i: IStructuralUnit) => ({
          title: i.nameOfStructuralUnit,
          id: i.id,
          staffUnits: i.staffUnits,
          type: i.structuralUnitType,
        }))
        .filter((i: { title: string | undefined }) => Boolean(i.title));
    },
    [dispatch, organizationId]
  );

  const fetchPositions = React.useCallback(
    async (search: string) => {
      const { items } = await dispatch(getPositions(search));

      return items
        .map((i: { namePosition: string; id: number }) => ({
          title: i.namePosition,
          id: i.id,
        }))
        .filter((i: { title: string | undefined }) => Boolean(i.title));
    },
    [dispatch]
  );

  const handleUpdateStaffUnit = async (form: Partial<ICreateStaffUnit>) => {
    if (!data?.staffUnitId || !organizationId) return;

    if (form["validity"] === true) {
      delete form["validity"];
    }

    if (orders.length > 0) {
      form["orders"] = orders.map((i) => {
        return {
          staffUnitId: i.staffUnitId,
          numberForTheList: i.numberForTheList,
        };
      });
    } else {
      delete form["orders"];
    }

    await dispatch(
      updateStaffUnit(form, String(data.staffUnitId), organizationId)
    );

    setLoading(false);
    dispatch(setModal(null));
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    if (isEditMode) {
      const editedData: Partial<ICreateStaffUnit> = {
        additionalInformation: data.additionalInformation,
        validity: data.validity === false ? data.validity : true,
      };

      if (!editedData.validity) {
        return dispatch(
          setModal({
            open: true,
            variant: ModalVariants.confirmDisableStaffUnit,
            content: {
              confirmNavigation: () => handleUpdateStaffUnit(editedData),
            },
          })
        );
      }

      await handleUpdateStaffUnit(editedData);
    } else {
      delete data["validity"];
      delete data["orders"];

      await dispatch(createStaffUnit(data, organizationId));
    }

    setLoading(false);
    dispatch(setModal(null));
  });

  const handleCloseViewMode = () => dispatch(setModal(null));

  useEffectOnce(() => {
    setValue("headOfStructuralUnit", isStructuralUnitTypeHead);
  });

  return (
    <FormWrapper noValidate onSubmit={onSubmit}>
      <TitleWrapper>
        <Typography variant="h6" fontWeight={500}>
          {ModalTitle}
        </Typography>

        {isViewMode ? (
          <Button
            fullWidth={false}
            variant="contained"
            color="inherit"
            size="small"
            onClick={toggleEditMode}
            startIcon={isEditMode ? <ArrowBackIcon /> : <EditIcon />}
          >
            {isEditMode ? t("back") : t("edit")}
          </Button>
        ) : null}
      </TitleWrapper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormAutocomplete
            required
            readOnly={isViewMode}
            inputValue={inputStructuralUnitValue}
            freeSolo={false}
            label={t("structural.unit")}
            defaultSelected={getStructuralUnitDefaultValue(structuralUnitId)}
            fetchData={fetchStructuralUnits}
            onFetchDataSuccess={setStructuralUnitOptions}
            onInputChange={handleStructuralUnitInputChange}
            error={!!errors.structuralUnitId}
          />
        </Grid>

        <Grid item xs={12}>
          <FormAutocomplete
            required
            readOnly={isViewMode}
            inputValue={inputPositionValue}
            freeSolo={false}
            label={t("position")}
            fetchData={fetchPositions}
            defaultSelected={getPositionDefaultValue(positionId)}
            onFetchDataSuccess={setPositionOptions}
            onInputChange={handlePositionInputChange}
            error={!!errors.positionId}
          />
        </Grid>

        {isViewMode ? null : (
          <Grid item xs={12}>
            <FormControlLabel
              label={t("head.of.division")}
              disabled={isViewMode}
              style={{
                opacity: hiddenCheckbox || isStructuralUnitTypeHead ? 0 : 1,
                cursor:
                  hiddenCheckbox || isStructuralUnitTypeHead
                    ? "none"
                    : "pointer",
              }}
              control={
                <Checkbox
                  checked={headOfStructuralUnit}
                  {...register("headOfStructuralUnit", {
                    required: false,
                  })}
                />
              }
            />
          </Grid>
        )}

        <Grid item xs={12} sm={7}>
          <Input
            readOnly={isViewMode && !isEditMode}
            value={additionalInformation}
            label={t("additional.information")}
            maxLength={300}
            {...register("additionalInformation")}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <FormControlLabel
            label={t("actual.position")}
            labelPlacement="start"
            disabled={(isViewMode && !isEditMode) || !isViewMode}
            control={
              <Switch defaultChecked={validity} {...register("validity")} />
            }
          />
        </Grid>
      </Grid>

      {isViewMode ? (
        <Accordion
          sx={{
            mt: 8,
            width: "100%",
            backgroundColor: theme.palette.grey[50],
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" fontWeight={500}>
              {t("position.in.the.list")}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <StaffPositionsTable
              isEditMode={isEditMode}
              organizationId={organizationId}
              staffUnitId={data?.staffUnitId}
              setValue={setValue}
            />
          </AccordionDetails>
        </Accordion>
      ) : null}

      <ActionsSection>
        {isViewMode && !isEditMode ? (
          <Button variant="contained" fullWidth onClick={handleCloseViewMode}>
            {t("close")}
          </Button>
        ) : (
          <Button
            variant="contained"
            type="submit"
            loading={loading}
            disabled={
              loading ||
              !isRequiredFieldsFilled ||
              (isEditMode && !Object.keys(dirtyFields).length && !orders.length)
            }
          >
            {t("save")}
          </Button>
        )}
      </ActionsSection>
    </FormWrapper>
  );
};
