import React from "react";

import { ViewStandardRouteContainer } from "app";
import { Modal } from "ui-kit";

export const ViewStandardRoute = (props: {
  open: boolean;
  routeId?: string;
}) => {
  const { open, routeId } = props;

  const content = {
    title: "",
    body: <ViewStandardRouteContainer id={routeId} />,
  };

  return (
    <Modal isOpen={Boolean(routeId && open)} content={content} sizeLarge />
  );
};
