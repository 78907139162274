import React, { useCallback } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import { ICreateTask } from "@types";

import { FileItem } from "./FileItem";
import { Wrapper } from "./styles";

interface Props {
  control: Control<ICreateTask>;
  setValue: UseFormSetValue<ICreateTask>;
}

export const AttachedFile = ({ control, setValue }: Props) => {
  const { files } = useWatch({
    control,
  });

  const handleFileSelect = useCallback(
    (files: File | File[]) => {
      setValue("files", files as File[]);
    },
    [setValue]
  );

  const removeFile = useCallback(
    (name?: string) => {
      const dataToSet =
        files && Array.isArray(files)
          ? files.filter((i) => {
              if (i.id) {
                return i.name !== name;
              }
              if (i.path) {
                return i.path !== name;
              }
            })
          : [];
      handleFileSelect(dataToSet);
    },
    [files, handleFileSelect]
  );

  return (
    <Wrapper>
      {files ? (
        Array.isArray(files) ? (
          files.map((item) => (
            <FileItem
              key={item.name + item.size}
              file={item}
              removeFile={() => removeFile(item.name)}
            />
          ))
        ) : (
          <FileItem file={files} removeFile={() => removeFile()} />
        )
      ) : null}
    </Wrapper>
  );
};
