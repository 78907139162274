import React from "react";
import { useTranslation } from "react-i18next";

import { getTaskStatusChipConfig, UI_CONFIG } from "app";

import { Chip, useTheme } from "@mui/material";
import { StatusForAuthorEnum, StatusForExecutorEnum } from "@types";

interface TaskStatusChipProps {
  status?: StatusForExecutorEnum | StatusForAuthorEnum;
  isAuthor: boolean;
}

export const TaskStatusChip: React.FC<TaskStatusChipProps> = ({
  status,
  isAuthor,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("tasks");

  const { backgroundColor, color } = getTaskStatusChipConfig(
    status,
    theme.palette,
    t,
    true,
    isAuthor
  );

  return (
    <Chip
      label={t(`statuses.${status}`)}
      sx={{
        width: "100%",
        minWidth: UI_CONFIG.chipWidth,
        fontWeight: 600,
        backgroundColor,
        color,
        borderColor: "transparent",
      }}
    />
  );
};
