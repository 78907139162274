import React, { useCallback, useState } from "react";
import Dropzone, { Accept, FileRejection } from "react-dropzone";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { setChangeTab, useAppDispatch, useAppSelector } from "app";

import { Stack, Typography, useTheme } from "@mui/material";
import { IAttachLink, MessageAttachmentsType } from "@types";

import { Icon } from "../Icon";
import { UploadedFilesSection, UploadedLinksSection } from "./components";
import { EnumFilesErrorMessages, IMessageForm, LINKS_LIST } from "./constants";
import { getRandomValue } from "./helpers";
import { TabsWrapper, Wrapper } from "./styles";

interface Props {
  files?: File | File[] | null;
  types?: Accept;
  maxSize?: number;
  maxFiles?: number;
  multiple?: boolean;
  buttonOnly?: boolean;
  disabled?: boolean;
  onChange?: (arg: File | File[]) => void;
  messageBody?: string;
  register?: UseFormRegister<IMessageForm>;
  attachLinkList?: IAttachLink | null;
  onRemoveAttachedLink?: () => void;
  customizeButton?: boolean;
}

export function MessengerDragAndDrop(props: Props) {
  const {
    files,
    types,
    maxFiles = 1,
    maxSize,
    multiple = false,
    disabled = false,
    buttonOnly = false,
    onChange,
    messageBody,
    register,
    attachLinkList,
    onRemoveAttachedLink,
    customizeButton = false,
  } = props;
  const { t } = useTranslation();
  const { t: tAttachment } = useTranslation("attachments");
  const { t: tTask } = useTranslation("tasks");

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { tab } = useAppSelector((state) => state.message);

  const [hasError, setHasError] = useState(false);
  const [filesCountWarning, setFilesCountWarning] = useState(false);
  const [errorMessages, setErrorMessages] = React.useState<
    { fileName: string; errorMessage: string; id: string }[]
  >([]);

  const [uploadProgress, setUploadProgress] = React.useState<
    {
      name: string;
      progress: number;
    }[]
  >([]);

  const timeoutIdRef = React.useRef<NodeJS.Timeout | null>(null);

  const fileTypes = types || {
    "image/*": [".jpeg", ".png"],
    "application/pdf": [".pdf"],
  };

  const incrementFileProgress = useCallback((name: string) => {
    let progress = 0;

    const interval = setInterval(() => {
      progress += getRandomValue(30, 50);

      progress = Math.min(progress, 100);

      setUploadProgress((prevState) => {
        const updatedProgress = prevState.map((item) =>
          item.name === name ? { ...item, progress } : item
        );
        return updatedProgress;
      });

      if (progress === 100) {
        clearInterval(interval);
      }
    }, 700);

    return progress;
  }, []);

  const startLoading = useCallback(
    (arg: File[]) => {
      setUploadProgress((prevState) => {
        const updatedProgress = prevState.map((item) => {
          const matchingFile = arg.find(
            (newFile) => newFile.name === item.name
          );

          if (matchingFile) {
            return {
              ...item,
              progress: incrementFileProgress(item.name),
            };
          }
          return item;
        });

        const newFilesProgress = arg
          .filter(
            (newFile) => !prevState.some((item) => item.name === newFile.name)
          )
          .map((newFile) => ({
            name: newFile.name,
            progress: incrementFileProgress(newFile.name),
          }));

        return [...updatedProgress, ...newFilesProgress];
      });
    },
    [incrementFileProgress, setUploadProgress]
  );

  const handleDrop = async (acceptedFiles: File | File[]) => {
    if (onChange) {
      const newFiles = Array.isArray(acceptedFiles)
        ? acceptedFiles
        : [acceptedFiles];
      const oldFiles = files as File[];

      const filteredFiles = newFiles.filter((newFile) => {
        return !oldFiles?.some(
          (existingFile) => existingFile.name === newFile.name
        );
      });

      startLoading(filteredFiles);

      const updatedFiles = Array.isArray(files)
        ? [...files, ...filteredFiles] // Append new files to the existing ones
        : filteredFiles;

      if (updatedFiles.length > maxFiles) {
        setHasError(true);
        setErrorMessages([
          {
            fileName: "",
            id: "maxFileCount",
            errorMessage: `${t("wrong.dropped.file.count", {
              fileMaxCount: maxFiles,
            })}`,
          },
        ]);
        return;
      }

      onChange(updatedFiles);
    }
  };

  const removeFile = useCallback(
    (name?: string) => {
      const dataToSet =
        files && Array.isArray(files)
          ? files.filter((i) => i.name !== name)
          : [];
      if (onChange) {
        onChange(dataToSet);
      }
    },
    [files, onChange]
  );

  const removeError = (id: string) => {
    const failedItemsList = errorMessages
      ? errorMessages.filter((i) => i.id !== id)
      : [];
    setErrorMessages(failedItemsList);
  };
  const removeMainErrorMessage = () => {
    setErrorMessages([]);
  };

  React.useEffect(() => {
    if (hasError) {
      timeoutIdRef.current = setTimeout(() => {
        setHasError(false);
        setFilesCountWarning(false);
        setErrorMessages([]);
      }, 10000);
    }
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [setHasError, hasError]);

  const handleDropRejection = (fileRejections: FileRejection[]) => {
    const messages = fileRejections.map((item) => {
      return getErrorMessage(item as any);
    });
    setHasError(true);
    setErrorMessages(messages);
  };

  const getErrorMessage = ({
    file,
    errors,
  }: {
    file: File;
    errors: { message: string; code: string; id: string }[];
  }) => {
    let message = "";
    const errorCode = errors[0].code;

    switch (errorCode) {
      case EnumFilesErrorMessages.type:
        message = t("wrong.dropped.file.type");
        break;
      case EnumFilesErrorMessages.size:
        message = t("wrong.dropped.file.size");
        break;
      case EnumFilesErrorMessages.count:
        message = t("wrong.dropped.file.size");
        setFilesCountWarning(true);
        break;
      default:
        message = "Something went wrong";
    }
    return {
      fileName: file.name,
      errorMessage: message,
      id: `${file.name}${message}`,
    };
  };

  const handleChangeTab = (attachmentType: MessageAttachmentsType) => {
    dispatch(setChangeTab(attachmentType));
  };

  return (
    <Wrapper>
      <Dropzone
        noKeyboard
        noClick={!buttonOnly}
        noDrag={buttonOnly}
        onDrop={handleDrop}
        accept={fileTypes}
        multiple={multiple}
        disabled={disabled}
        maxFiles={maxFiles}
        maxSize={maxSize}
        onDropRejected={(fileRejections: FileRejection[]) =>
          handleDropRejection(fileRejections)
        }
      >
        {({ getRootProps, getInputProps, isDragReject, isDragAccept }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
            ? "reject"
            : "";

          if (customizeButton) {
            return (
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                {...getRootProps()}
                style={{ display: "flex", gap: 2 }}
              >
                <Icon
                  name="Paperclip"
                  size={18}
                  color={theme.palette.secondary.main}
                />
                <Typography
                  fontSize={13}
                  fontWeight={600}
                  color="secondary.main"
                >
                  {tTask("add.file")}
                </Typography>
              </Stack>
            );
          }

          if (buttonOnly) {
            return (
              <section>
                <div
                  {...getRootProps()}
                  style={{ opacity: disabled ? 0.5 : 1 }}
                >
                  <Icon name="Paperclip" color={theme.palette.primary.main} />
                </div>
              </section>
            );
          }

          return (
            <section>
              <div
                {...getRootProps({
                  className: `dropzone ${additionalClass}`,
                })}
              >
                <div className="drop_label">{tAttachment("dragFileHere")}</div>
                <textarea
                  value={messageBody}
                  className={`message__body ${additionalClass}`}
                  maxLength={1000}
                  {...(register ? register("messageBody") : null)}
                />

                {
                  <TabsWrapper>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      className={`attachment_tab ${additionalClass}`}
                      sx={{
                        borderBottom: `2px solid ${
                          tab === MessageAttachmentsType.FILES
                            ? theme.palette.secondary.main
                            : "inherit"
                        }`,
                        paddingBottom:
                          tab === MessageAttachmentsType.FILES ? 0 : "2px",
                      }}
                      color={
                        tab === MessageAttachmentsType.FILES
                          ? theme.palette.secondary.main
                          : ""
                      }
                      onClick={() =>
                        handleChangeTab(MessageAttachmentsType.FILES)
                      }
                    >
                      {`${tAttachment("attachedFiles")} ${
                        files && Array.isArray(files) && files.length
                          ? `(${files.length} / ${maxFiles})`
                          : ""
                      }`}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      className={`attachment_tab ${additionalClass}`}
                      color={
                        tab === MessageAttachmentsType.LINKS
                          ? theme.palette.secondary.main
                          : ""
                      }
                      sx={{
                        borderBottom: `2px solid ${
                          tab === MessageAttachmentsType.LINKS
                            ? theme.palette.secondary.main
                            : "inherit"
                        }`,
                        paddingBottom:
                          tab === MessageAttachmentsType.LINKS ? 0 : "2px",
                      }}
                      onClick={() =>
                        handleChangeTab(MessageAttachmentsType.LINKS)
                      }
                    >
                      {`${tAttachment("attachedLinks")} ${
                        LINKS_LIST.length ? `(${LINKS_LIST.length} / 10)` : ""
                      }`}
                    </Typography>
                  </TabsWrapper>
                }

                {tab === MessageAttachmentsType.FILES ? (
                  <div
                    style={{
                      display:
                        tab !== MessageAttachmentsType.FILES ? "none" : "",
                    }}
                  >
                    <UploadedFilesSection
                      additionalClass={additionalClass}
                      files={files}
                      removeFile={removeFile}
                      removeError={removeError}
                      removeMainErrorMessage={removeMainErrorMessage}
                      hasError={hasError}
                      maxSize={maxSize}
                      errorMessages={errorMessages}
                      maxFiles={maxFiles}
                      uploadProgress={uploadProgress}
                    />
                  </div>
                ) : null}
                {tab === MessageAttachmentsType.LINKS ? (
                  <div
                    style={{
                      display:
                        tab !== MessageAttachmentsType.LINKS ? "none" : "",
                    }}
                  >
                    <UploadedLinksSection
                      additionalClass={additionalClass}
                      links={attachLinkList}
                      onRemoveAttachedLink={onRemoveAttachedLink}
                    />
                  </div>
                ) : null}
              </div>
            </section>
          );
        }}
      </Dropzone>
    </Wrapper>
  );
}
