import styled from "styled-components";

export const SectionWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: ${({ theme }) => theme.spacing(4)};

  &.agreement-queue__actions {
    margin: ${({ theme }) => theme.spacing(8, 0)};
  }
`;
