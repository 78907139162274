import { TFunction } from "i18next";
import { Icon } from "ui-kit";

import { Chip, Stack, Theme, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { chipStyle, COLUMN_SIZE } from "@sbm/fe-utils";

export const getFrameworkContractsTableColumns = (
  t: TFunction,
  theme: Theme
): GridColDef[] => [
  {
    field: "contractNumber",
    headerName: t("contract.number"),
    minWidth: COLUMN_SIZE * 1.5,
    sortable: false,
    filterable: false,
  },
  {
    field: "contractStatus",
    headerName: t("status"),
    minWidth: COLUMN_SIZE * 1.5,
    sortable: true,
    filterable: false,
    renderCell: (params) => {
      return (
        <div className="MuiDataGrid-cellContent">
          <Chip
            style={chipStyle(params.row.contractStatus, theme)}
            label={t(params.row.contractStatus)}
            sx={{ width: "100vh" }}
          />
        </div>
      );
    },
  },
  {
    field: "dateOfSigning",
    headerName: t("registration.date"),
    minWidth: COLUMN_SIZE * 2,
    sortable: true,
    filterable: false,
  },
  {
    field: "party1OrganizationName",
    headerName: `${t("party")} 1`,
    minWidth: COLUMN_SIZE * 2,
    sortable: true,
    filterable: false,
  },
  {
    field: "party2OrganizationName",
    headerName: `${t("party")} 2`,
    minWidth: COLUMN_SIZE * 2,
    sortable: true,
    filterable: false,
  },
  {
    field: "expirationDate",
    headerName: t("expiration.date"),
    minWidth: COLUMN_SIZE * 3,
    sortable: true,
    filterable: false,
  },
  {
    field: "category",
    headerName: t("category"),
    minWidth: COLUMN_SIZE * 3,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const category = params.row.category;

      return (
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="body2">{params.row.category}</Typography>
          <Icon
            name={
              category.includes("Доходный")
                ? "MoveDownLeftIcon"
                : "MoveUpRightIcon"
            }
            color={
              category.includes("Доходный")
                ? theme.palette.success.main
                : theme.palette.error.main
            }
          />
        </Stack>
      );
    },
  },
];

export const tableTitle = (contractCategoryId: number, t: TFunction) => {
  switch (contractCategoryId) {
    case 5:
      return t("income.framework");
    case 6:
      return t("expense.framework");
    default:
      return "";
  }
};
