export function hexToRgba(hex: string, opacity: number) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result && result[1] && result[2] && result[3]) {
    return opacity
      ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`
      : `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`;
  }

  return "transparent";
}

export function isIOS() {
  const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isNotIE = !(("MSStream" in window) as boolean);
  return isIOSDevice && isNotIE;
}
