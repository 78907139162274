import styled from "styled-components";
import {
  FLEX_BETWEEN,
  FLEX_ROW,
  FLEX_ROW_ALIGN_CENTER,
  FLEX_ROW_FULL,
} from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

import EmptyFiles from "../../../assets/backgrounds/EmptyFiles.svg";
import EmptyLinks from "../../../assets/backgrounds/EmptyLinks.svg";

export const LinksItemWrapper = styled.div`
  background-color: ${({ theme }) =>
    hexToRgba(theme.palette.secondary.main, 0.04)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2, 4)};
  .link_delete_icon {
    ${FLEX_ROW_ALIGN_CENTER}
  }
`;

export const Wrapper = styled.div<{ disabled?: boolean }>`
  .dropzone {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    height: 600px;

    background-color: ${({ theme }) => theme.palette.background.default};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    &__content {
      ${FLEX_ROW_ALIGN_CENTER};
    }
    .drop_label {
      display: none;
    }
    button {
      font-size: 13px !important;
    }
    &__uploadingProcess {
      ${FLEX_BETWEEN};
      padding: ${({ theme }) => theme.spacing(1, 2)};
      background-color: ${({ theme }) =>
        hexToRgba(theme.palette.primary.light, 0.04)};
      border-radius: ${({ theme }) => theme.spacing()};
      &.accept,
      &.reject {
        opacity: 0.3;
      }
    }
    &__uploadingProcessInArea {
      ${FLEX_BETWEEN};
    }

    &.accept {
      border-color: ${({ theme }) =>
        hexToRgba(theme.palette.primary.main, 0.3)};
      background-color: ${({ theme }) => theme.palette.primary.contrastText};

      border-width: ${({ theme }) => theme.spacing()};
      border-style: dashed;
      padding: 0;
      .drop_label {
        ${FLEX_ROW_FULL};
        position: absolute;
        bottom: 400px;
        text-align: center;
        width: 100%;
        font-weight: 400;
        font-size: 34px;
        right: 0;
        color: ${({ theme }) => theme.palette.text.disabled};
      }
    }

    &.reject {
      border-color: ${({ theme }) => theme.palette.error.main};
      border-width: ${({ theme }) => theme.spacing()};
      border-style: dashed;
      padding: 0;
      .drop_label {
        ${FLEX_ROW_FULL};
        position: absolute;
        bottom: 400px;
        text-align: center;
        width: 100%;
        font-weight: 400;
        font-size: 34px;
        right: 0;
        color: ${({ theme }) => theme.palette.text.disabled};
      }
    }
  }
  textarea {
    background-color: ${({ theme }) => theme.palette.background.default};
    border-color: ${({ theme }) => theme.palette.background.default};
  }
  .message__body {
    height: 260px;
    &.accept,
    &.reject {
      background-color: ${({ theme }) => theme.palette.primary.contrastText};
      opacity: 0.1;
    }
  }
  .uploaded_section_title {
    margin-bottom: 8px;
    &.accept,
    &.reject {
      opacity: 0.3;
    }
  }

  textarea:focus {
    outline: none;
  }
  .uploaded_files_section {
    max-height: 240px;
    overflow: auto;
    &.accept,
    &.reject {
      opacity: 0.3;
    }
  }
  .uploaded_links_section {
    max-height: 240px;
    overflow: auto;
    &.accept,
    &.reject {
      opacity: 0.3;
    }
  }
  .uploaded_file {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(2)};
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  .uploaded_files_section_with_warning {
    max-height: 240px;
    overflow: auto;
    &.accept,
    &.reject {
      opacity: 0.3;
    }
  }
  .empty__text {
    ${FLEX_ROW_FULL}
  }
`;
export const EmptyFilesSectionWrapper = styled.div`
  background-image: url(${EmptyFiles});
  height: 120px;
  width: auto;
  background-repeat: no-repeat;
  background-position: center;
  margin: ${({ theme }) => theme.spacing(12, 4, 2, 4)};
`;

export const EmptyLinksSectionWrapper = styled.div`
  background-image: url(${EmptyLinks});
  height: 120px;
  width: auto;
  background-repeat: no-repeat;
  background-position: center;
  margin: ${({ theme }) => theme.spacing(12, 4, 2, 4)};
`;

export const TabsWrapper = styled.div`
  ${FLEX_ROW_FULL};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(4)};

  .attachment_tab {
    ${FLEX_ROW_FULL};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    width: 100%;
    cursor: pointer;
    &.accept,
    &.reject {
      opacity: 0.1;
    }
  }
`;
