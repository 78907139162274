import React from "react";

import { MaterialsPage } from "./MaterialsPage";

const UsersManagement = React.lazy(
  () => import(/* webpackChunkName: "pages/users" */ "./user-pages/UsersPage")
);
const UserCreation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/user_create" */ "./user-pages/UserCreatePage"
    )
);

const UserProfile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/user_create" */ "./user-pages/UserProfilePage"
    )
);

const ContractsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/contracts" */ "./contract-pages/ContractsPage"
    )
);

const ContractByIdPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/create_contract" */ "./contract-pages/ContractByIdPage"
    )
);

const CreateSAPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/create_contract" */ "./sa-pages/CreateSAPage"
    )
);

const EditSAPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/create_contract" */ "./sa-pages/EditSAPage"
    )
);

const DocumentsPage = React.lazy(
  () => import(/* webpackChunkName: "pages/documents" */ "./DocumentsPage")
);

const OrganizationsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/organizations" */ "./organizations/Organizations"
    )
);

const OrganizationsListPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/organizations" */ "./organizations/OrganizationsList"
    )
);

const OrganizationByIdPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/organizations" */ "./organizations/OrganizationById"
    )
);

const SettingsListPage = React.lazy(() => import("./settings"));
const ApprovalRoutesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/ApprovalRoutesPage" */ "./contract-pages/ApprovalRoutesPage"
    )
);
const AccessManagementPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/AccessManagementPage" */ "./access-management"
    )
);

const HomePage = React.lazy(
  () => import(/* webpackChunkName: "pages/home" */ "./home")
);

const ViewTaskPage = React.lazy(
  () => import(/* webpackChunkName: "pages/view-task" */ "./tasks/ViewTaskPage")
);

const CorrespondencePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/correspondence" */ "./correspondence/Correspondence"
    )
);

const ViewIncomingDocumentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages/correspondence" */ "./correspondence/DocumentById"
    )
);

export {
  DocumentsPage,
  UsersManagement,
  UserCreation,
  UserProfile,
  ContractsPage,
  ContractByIdPage,
  CreateSAPage,
  EditSAPage,
  OrganizationsPage,
  OrganizationsListPage,
  OrganizationByIdPage,
  SettingsListPage,
  ApprovalRoutesPage,
  AccessManagementPage,
  HomePage,
  ViewTaskPage,
  CorrespondencePage,
  MaterialsPage,
  ViewIncomingDocumentPage,
};
