import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { Card } from "@mui/material";

export const Wrapper = styled.div<{
  $isMaximized?: boolean;
}>`
  display: ${({ $isMaximized }) => ($isMaximized ? "flex" : "")};
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const CardWrapper = styled(Card)`
  ${FLEX_COLUMN};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  .sa_contractTypeId {
    ${FLEX_ROW_ALIGN_CENTER};
    margin-top: ${({ theme }) => theme.spacing(4)};
    gap: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;
