import React from "react";

import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { capitalize, getFilterOptionName } from "@sbm/fe-utils";

import { IFilterValue } from "../CustomFilters";
import { FilterRangePicker } from "../FilterRangePicker";
import { Wrapper } from "./styles";

interface FilterValueProps extends IFilterValue {
  error: boolean;
  filterValue: string | string[];
  onChange: (arg: string | string[]) => void;
}

export const FilterValue = (props: FilterValueProps) => {
  const { field, options, optionType, filterValue, onChange, multiSelect } =
    props;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
      // Handling on change for checkbox types of filters
      if (!optionType) {
        if (multiSelect) {
          let valuesToSet: string[];

          if (filterValue.includes(newValue)) {
            valuesToSet = (filterValue as string[]).filter(
              (i) => i !== newValue
            );
          } else {
            valuesToSet = [...filterValue, newValue];
          }

          onChange(valuesToSet);
          return;
        }

        onChange(newValue);
        return;
      }

      // Handling on change for radio types of filters
      onChange(event.target.value);
    },
    [filterValue, multiSelect, onChange, optionType]
  );

  const handleRangeChange = React.useCallback(
    (date: string) => {
      return onChange(date);
    },
    [onChange]
  );

  const Content = React.useMemo(() => {
    if (!options || (!options.length && optionType !== "range")) return;

    if (optionType === "range") {
      return (
        <FilterRangePicker
          filterValue={filterValue}
          onChange={handleRangeChange}
        />
      );
    }

    if (optionType === "radio") {
      return (
        <RadioGroup
          value={getFilterOptionName(filterValue)}
          onChange={handleChange}
          aria-labelledby="custom-filters-radio-group-label"
          name="custom-filters__radio-buttons-group"
        >
          {options.map((item, index) => {
            const name = getFilterOptionName(item);
            const checked =
              typeof filterValue === "string"
                ? filterValue === name
                : filterValue.includes(name);

            return (
              <FormControlLabel
                key={`${name}-${index}`}
                checked={checked}
                value={name}
                control={<Radio color="secondary" />}
                label={capitalize(name || "")}
              />
            );
          })}
        </RadioGroup>
      );
    }

    return (
      <Stack sx={{ maxWidth: 245 }}>
        {options.map((option, index) => {
          const name = getFilterOptionName(option);

          return (
            <FormControlLabel
              key={name + index}
              label={name}
              sx={{
                alignItems: "flex-start",
                ".MuiTypography-root": { mt: 2 },
              }}
              control={
                <Checkbox
                  color="secondary"
                  checked={
                    typeof filterValue === "string"
                      ? filterValue === name
                      : filterValue.includes(name)
                  }
                  value={name}
                  onChange={(e) => handleChange(e, name)}
                />
              }
            />
          );
        })}
      </Stack>
    );
  }, [filterValue, handleChange, handleRangeChange, optionType, options]);

  if (!field) return null;

  return <Wrapper>{Content}</Wrapper>;
};
