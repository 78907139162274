import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const ActionsWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;
  .main_buttons {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(2)};
    justify-content: flex-end;
    > button {
      min-width: ${UI_CONFIG.modalButton}px;
    }
  }
`;
