import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { Typography } from "@mui/material";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};

  padding-top: ${({ theme }) => theme.spacing(6)};
`;

export const CCWrapper = styled.div`
  max-width: 33%;

  margin-bottom: ${({ theme }) => theme.spacing(10)};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 100%;
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: 0.7;
`;
