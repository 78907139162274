import styled from "styled-components";

import { Card } from "@mui/material";

export const WidgetWrapper = styled(Card)`
  padding: ${({ theme }) => theme.spacing(0, 4, 4, 4)};
  min-height: 200px;
  border-bottom: 4px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(4)};;
 };
`;
