import styled from "styled-components";
import { FLEX_COLUMN_FULL, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { avatarClasses } from "@mui/material";

export const ProfileBannerWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  gap: ${({ theme }) => theme.spacing(3)};

  width: 370px;

  margin-top: ${({ theme }) => theme.spacing(20)};

  .email-status__wrapper {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
      ${FLEX_COLUMN_FULL};
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;

  width: calc(100% + 32px);
  height: 100px;

  margin: ${({ theme }) => theme.spacing(-4)};

  background-color: ${({ theme }) => theme.palette.primary.main};

  .${avatarClasses.root} {
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translate(-50%, 0);
  }
`;

export const ProfileBannerActions = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;

  width: 100%;

  margin-top: ${({ theme }) => theme.spacing(6)};
`;
