import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useChangeContractStatusMutation,
  useRestartRejectedAgreementMutation,
  useReturningRejectedAgreementMutation,
} from "api";

import { Typography } from "@mui/material";
import { Button, Input } from "@sbm/ui-components";
import { ApproverActions, IContractStatusChanging } from "@types";

import { ButtonWrapper, TitleWrapper } from "./styles";
import { Wrapper } from "./styles";

interface Props {
  id: number;
  type: string;
  data: {
    title: string;
    comment: string;
    warning?: string;
  } | null;
  onClose: () => void;
}

export const ApproverActionsContainer: React.FC<Props> = ({
  id,
  type,
  data,
  onClose,
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<IContractStatusChanging>({
    defaultValues: {
      comment: data?.comment,
    },
  });

  const { mutate: changeStatusMutation, isLoading: changeStatusLoading } =
    useChangeContractStatusMutation(onClose);

  const {
    mutate: repeatRouteMutation,
    isLoading: returningRejectedStatusLoading,
  } = useReturningRejectedAgreementMutation(onClose);

  const {
    mutate: restartRouteMutation,
    isLoading: restartRejectedStatusLoading,
  } = useRestartRejectedAgreementMutation(onClose);

  const onSubmit = handleSubmit(async (fields: Partial<FieldValues>) => {
    if (!data) return;

    if (
      type === ApproverActions.approved ||
      type === ApproverActions.rejected
    ) {
      changeStatusMutation({
        singleApprovalId: id,
        comment: fields.comment,
        status: type,
      });
    }

    if (type === ApproverActions.returning) {
      repeatRouteMutation({
        singleApprovalId: id,
        comment: fields.comment,
      });
    }
    if (type === ApproverActions.start) {
      restartRouteMutation({
        singleApprovalId: id,
        comment: fields.comment,
      });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Wrapper>
        {data?.warning && (
          <TitleWrapper className={"warning__message"}>
            <Typography variant={"body1"} fontWeight={700}>
              {data.warning}
            </Typography>
          </TitleWrapper>
        )}

        <TitleWrapper>
          <Typography variant="h6">{data?.title}</Typography>
        </TitleWrapper>

        <Input
          label={t("comment")}
          variant="outlined"
          key={"comment"}
          size="medium"
          maxLength={500}
          multiline
          required
          {...register("comment", { required: true })}
        />
        <ButtonWrapper>
          <Button
            type="submit"
            size="medium"
            loading={
              changeStatusLoading ||
              returningRejectedStatusLoading ||
              restartRejectedStatusLoading
            }
            variant="contained"
          >
            {t("confirm")}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </form>
  );
};
