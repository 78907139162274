import React from "react";

import { getUrlParam } from "@sbm/fe-utils";

interface Props {
  page?: number;
  pageSize?: number;
  sortModel?: string;
  search?: string;
  filter?: string[];
  setPage?: (arg: number) => void;
  setPageSize?: (arg: number) => void;
  setSortModel?: (arg: string) => void;
  setSearch?: (arg: string) => void;
  setFilter?: (arg: string[]) => void;
}

export function useTableQuerySet({
  page,
  setPage,
  pageSize,
  search,
  filter,
  setPageSize,
  sortModel,
  setSortModel,
  setSearch,
  setFilter,
}: Props) {
  React.useEffect(() => {
    const pageQueryFromUrl = getUrlParam("page", "single") as string;

    if (!pageQueryFromUrl) return;
    if (Number(pageQueryFromUrl) === page) return;

    setPage?.(Number(pageQueryFromUrl));
  }, [page, setPage]);

  React.useEffect(() => {
    const pagesizeFromUrl = getUrlParam("pagesize", "single") as string;

    if (!pagesizeFromUrl) return;
    if (Number(pagesizeFromUrl) === pageSize) return;

    setPageSize?.(Number(pagesizeFromUrl));
  }, [pageSize, setPageSize]);

  React.useEffect(() => {
    const sortByFromUrl = getUrlParam("sortBy", "single") as string;

    if (!sortByFromUrl || sortByFromUrl === sortModel) return;

    setSortModel?.(sortByFromUrl);
  }, [setSortModel, sortModel]);

  React.useEffect(() => {
    if (!setSearch) return;

    const searchQueryFromUrl = getUrlParam("search", "single") as string;

    if (!searchQueryFromUrl || searchQueryFromUrl === search) return;

    setSearch(searchQueryFromUrl);
  }, [search, setSearch]);

  React.useEffect(() => {
    if (!setFilter || !filter) return;

    const filterQueryFromUrl = getUrlParam("filter", "all");
    if (!filterQueryFromUrl?.length || typeof filterQueryFromUrl === "string")
      return;

    const isEqual =
      filterQueryFromUrl.length === filter.length &&
      filterQueryFromUrl.every(function (value, index) {
        return value === filter[index];
      });

    if (isEqual) return;

    setFilter(filterQueryFromUrl);
  }, [filter, setFilter, setPage]);
}
