import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";

import Legal_entity_image_en from "../../../../../assets/backgrounds/legalEntity_img_en.png";
import Legal_entity_image_ru from "../../../../../assets/backgrounds/legalEntity_img_ru.png";

export const CardWrapper = styled(Card)`
  ${FLEX_COLUMN};
  position: relative;
  padding: ${({ theme }) => theme.spacing(3, 4, 4, 4)};
  margin: ${({ theme }) => theme.spacing(4, 0)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(4)};
  z-index: 1;
`;

export const LegalEntityImg = styled.div<{
  $isEnglish: boolean;
  $isSender?: boolean;
}>`
  background-image: ${({ $isEnglish }) =>
    $isEnglish
      ? `url(${Legal_entity_image_en})`
      : `url(${Legal_entity_image_ru})`};
  background-position: center;
  height: 180px;
  width: 210px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: ${({ theme, $isSender }) =>
    $isSender ? theme.spacing(-4) : theme.spacing(-8)};
  right: ${({ theme, $isSender }) =>
    $isSender ? theme.spacing(-6) : theme.spacing(-10)};
  z-index: -1;
`;
