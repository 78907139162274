import styled from "styled-components";
import { FLEX_COLUMN_FULL, FLEX_ROW } from "ui-kit";

export const DocumentCardWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing(4)};

  width: 100%;
  min-height: 230px;

  border-radius: 16px;
  background: linear-gradient(
    91deg,
    ${({ theme }) => theme.palette.primary.main} 100%,
    ${({ theme }) => theme.palette.primary.main} 100%
  );

  box-shadow: ${({ theme }) => theme.shadows[4]};

  .recipientCount {
    height: 24px;
    width: 24px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 50%;
    color: white;
    ${FLEX_COLUMN_FULL}
`;

export const EllipsisTextWrapper = styled.div<{ $height?: number }>`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: ${({ $height }) => `${$height}px`};
    max-height:  ${({ $height }) => `${$height}px`};
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      cursor: pointer
    },
}
`;

export const DocumentCardImage = styled.img`
  width: 100%;
  height: 100%;
`;
