export const capitalize = (str: string) => {
  if (!str) return "";

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getFilterOptionName = (name: string | string[]) => {
  const value = name && typeof name === "string" ? name : name[0];

  switch (value) {
    case "true":
      return "yes";
    case "false":
      return "no";
    default:
      return value;
  }
};
