import React from "react";

import Lottie from "lottie-react";

import { Stack, useTheme } from "@mui/material";

import animationData from "../../../assets/lottie/global-loading-animation.json";

export const GlobalLoader = () => {
  const { palette } = useTheme();
  return (
    <Stack
      position="absolute"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: palette.primary.contrastText, zIndex: 99999 }}
    >
      <Lottie animationData={animationData} loop={true} autoplay={true} />
    </Stack>
  );
};
