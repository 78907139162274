import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

export type InputProps = TextFieldProps & {
  shrink?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  className?: string;
  linesCount?: number;
  endAdornment?: React.ReactNode;
  onChangeListener?: (arg: number) => void;
};

export const Input = React.forwardRef(
  (
    {
      fullWidth = true,
      required = false,
      disabled = false,
      error,
      label,
      type = "text",
      helperText,
      multiline,
      readOnly,
      value,
      size = "medium",
      color = "secondary",
      shrink,
      maxLength,
      linesCount,
      endAdornment,
      ...rest
    }: InputProps,
    ref
  ) => {
    return (
      <TextField
        variant="outlined"
        color={color}
        fullWidth={fullWidth}
        disabled={disabled}
        required={required}
        type={type}
        label={label}
        size={size}
        error={error}
        rows={multiline ? (linesCount ? linesCount : "2") : "1"}
        multiline={multiline}
        helperText={helperText}
        inputRef={ref}
        value={value}
        InputProps={{
          readOnly,
          endAdornment: endAdornment && endAdornment,
        }}
        inputProps={{
          maxLength,
        }}
        InputLabelProps={{
          shrink: shrink && (Boolean(value) || value === 0),
        }}
        sx={{
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
        {...rest}
      />
    );
  }
);
