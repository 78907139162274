import React, { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useGetStandardRouteForContract } from "api";
import { AxiosError } from "axios";
import { DotsLoader } from "ui-kit";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { ClickAwayListener, Typography } from "@mui/material";
import { arrayEquals, getAPIErrorMessage } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import {
  EnumApproverType,
  ICreateStandardRoute,
  IRoute,
  ModalVariants,
  TApproverTypicalApprovalRoute,
  TAssignApprovalRouteForContract,
} from "@types";

import { ChangeDefaultRoutePopup, TransitionPrompt } from "../../../decorators";
import { useAppDispatch } from "../../../hooks";
import {
  assignApprovalRouteToContract,
  deleteApprovalRouteToContract,
  IModal,
  setModal,
} from "../../../store";
import {
  ApproversGanttChart,
  AssignmentTable,
} from "../CreateStandardRouteContainer/components";
import {
  StandardRouteGeneralInfo,
  DeleteStandardRoutePopup,
} from "./components";
import { ActionsWrapper, Wrapper, TitleWrapper } from "./styles";

interface ViewStandardRouteContainerProps {
  id: string | undefined;
}

export const ViewStandardRouteContainer: React.FC<
  ViewStandardRouteContainerProps
> = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [changeDefaultRouteModal, setChangeDefaultRouteModal] =
    useState<IModal | null>(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);

  const onError = (error: unknown) => {
    const errorMsg = getAPIErrorMessage(error as AxiosError);
    toast.error(errorMsg);
  };

  const { data, isLoading, refetch, error } = useGetStandardRouteForContract({
    id,
  });

  if (error) {
    onError(error);
  }

  const defaultRoutes =
    (data?.assignApprovalRouteForContract.map((item) => {
      return {
        id: item.id,
        contractSubtypeId: item.contractSubtypeId,
        contractTypeId: item.contractTypeId,
        routeByDefault: item.routeByDefault,
      };
    }) as IRoute[]) || [];

  const defaultRoutesWithoutId = defaultRoutes
    .map(({ id, ...keepAttrs }) => keepAttrs)
    .sort(
      (a, b) =>
        a.contractTypeId - b.contractTypeId &&
        a.contractSubtypeId - b.contractSubtypeId
    );

  const {
    setValue,
    setError,
    reset,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<ICreateStandardRoute>({
    defaultValues: {
      routes: defaultRoutes,
    },
  });

  const { routes } = useWatch({ control });

  const currentRoutes =
    routes?.sort(
      (a, b) =>
        a.contractTypeId! - b.contractTypeId! &&
        a.contractSubtypeId! - b.contractSubtypeId!
    ) || [];

  const isEqual = arrayEquals(defaultRoutesWithoutId, currentRoutes, true);

  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const handleCloseChangeModal = () => setChangeDefaultRouteModal(null);

  const handleConfirmTransition = () => {
    handleCloseChangeModal();

    if (changeDefaultRouteModal?.content?.confirmNavigation) {
      changeDefaultRouteModal.content.confirmNavigation();
    }
  };

  const resetForm = () => {
    handleCloseChangeModal();

    dispatch(setModal(null));
    reset();
    clearErrors();
  };

  // :any because ts doesn't understand the target.localName on MouseEvent | TouchEvent
  const handleClose = (event?: any) => {
    // Disabling close event for portals (Select, Popper)
    if (event?.target?.localName === "body") return;

    if (!isEqual) {
      setShowPrompt(true);
      return;
    }

    resetForm();
  };

  const confirmDeleteRoute = async () => {
    if (!data) return;

    setLoading(true);
    await dispatch(deleteApprovalRouteToContract(data.id));
    setLoading(false);
    await dispatch(setModal(null));
  };

  const handleDeleteRoute = () => {
    setDeletePopupOpen(true);
  };

  const isChangeDefaultRoutePopupOpen = Boolean(
    changeDefaultRouteModal?.open &&
      changeDefaultRouteModal.variant === ModalVariants.changeDefaultRoute
  );

  const onSubmit = handleSubmit(async (form) => {
    if (!data?.id || !form?.routes) return;

    setLoading(true);

    const sortedRoutes = form.routes.sort(
      (a, b) =>
        a.contractTypeId - b.contractTypeId &&
        a.contractSubtypeId - b.contractSubtypeId
    );

    const isEqual = arrayEquals(defaultRoutesWithoutId, sortedRoutes, true);

    if (!isEqual) {
      await dispatch(
        assignApprovalRouteToContract(data.id, { routes: form.routes })
      );

      await refetch();
    }

    setLoading(false);
    dispatch(setModal(null));
  });

  const approvers = useMemo(() => {
    if (!data) return [];

    return data.approverTypicalApprovalRoute.map(
      (item: TApproverTypicalApprovalRoute, index: number) => ({
        ...item,
        id: index,
        approverType:
          item.approverType === EnumApproverType.structural_unit
            ? item.structuralUnit?.nameOfStructuralUnit
            : t(item.approverType),
        queueNumber: item.queueNumber,
        structuralUnitId: item.structuralUnitId || undefined,
        statusForAction: item.status || "",
      })
    );
  }, [data, t]);

  const assignedRoutes = useMemo(() => {
    if (!data) return [];

    return data.assignApprovalRouteForContract.map(
      (i: TAssignApprovalRouteForContract) => ({
        id: i.id,
        contractType: i.contractType.nameContractType,
        contractSubtype: i.contractSubtype.nameContractSubtype,
        defaultRoute: i.routeByDefault,
      })
    );
  }, [data]);

  return (
    <ClickAwayListener disableReactTree={false} onClickAway={handleClose}>
      <Wrapper onSubmit={onSubmit} noValidate>
        {isLoading || !data ? (
          <DotsLoader />
        ) : (
          <>
            <TitleWrapper>
              <Typography variant="h6" fontWeight={500}>
                {t("standard.route.for.contract.approval")}
              </Typography>
              <Button
                fullWidth={false}
                variant="contained"
                color="inherit"
                onClick={toggleEditMode}
                startIcon={isEditMode ? <ArrowBackIcon /> : <EditIcon />}
              >
                {isEditMode ? t("back") : t("edit")}
              </Button>
            </TitleWrapper>

            <StandardRouteGeneralInfo
              organizationName={
                data.organization.briefTextOrganizationName ?? ""
              }
              routeNumber={data.routeNumber}
              dateCreationRoute={data.dateCreationRoute}
              usageScorer={data.usageScorer}
              additionalInformation={data.additionalInformation}
            />

            <ApproversGanttChart approvers={approvers} />

            <AssignmentTable
              setValue={setValue}
              organizationId={data?.organizationId}
              setModal={setChangeDefaultRouteModal}
              setError={setError}
              errors={errors}
              clearErrors={clearErrors}
              isEditMode={isEditMode}
              isViewMode
              assignedRoutes={assignedRoutes}
            />

            <ChangeDefaultRoutePopup
              onClose={handleCloseChangeModal}
              onConfirm={handleConfirmTransition}
              open={isChangeDefaultRoutePopupOpen}
              data={changeDefaultRouteModal?.content?.data}
            />

            <DeleteStandardRoutePopup
              open={deletePopupOpen}
              onClose={() => setDeletePopupOpen(false)}
              onConfirm={confirmDeleteRoute}
            />

            {showPrompt && isEditMode ? (
              <TransitionPrompt
                open={showPrompt && isEditMode}
                onClose={() => setShowPrompt(false)}
                onConfirm={resetForm}
              />
            ) : null}

            <ActionsWrapper>
              {isEditMode ? (
                <Button
                  color="error"
                  variant="contained"
                  disabled={loading}
                  loading={loading}
                  onClick={handleDeleteRoute}
                >
                  {t("delete")}
                </Button>
              ) : (
                <div />
              )}

              <div className="primary-buttons">
                {isEditMode && !isEqual ? (
                  <Button
                    color="success"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                  >
                    {t("save")}
                  </Button>
                ) : null}

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClose}
                >
                  {t("close")}
                </Button>
              </div>
            </ActionsWrapper>
          </>
        )}
      </Wrapper>
    </ClickAwayListener>
  );
};
