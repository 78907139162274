import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { formGroupClasses } from "@mui/material";

export const FormWrapper = styled.form`
  ${FLEX_COLUMN};
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const RadioButtonsWrapper = styled.div`
  width: 100%;
  min-width: 270px;

  padding: ${({ theme }) => theme.spacing(2, 4)};
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing()};

  .${formGroupClasses.root} {
    align-items: flex-start;
  }
`;

export const ActionsSection = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(4)};

  width: 100%;

  padding-top: ${({ theme }) => theme.spacing(9)};

  button {
    width: fit-content;
  }
`;
