import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  getTaskStatusChipConfig,
  openDrawer,
  UI_CONFIG,
  useAppDispatch,
  useQueryParams,
} from "app";
import classNames from "classnames";

import {
  Typography,
  SvgIconProps,
  Tooltip,
  Chip,
  useTheme,
  Stack,
  Zoom,
} from "@mui/material";
import {
  unstable_useTreeItem2 as useTreeItem,
  TreeItem2Provider,
  TreeItem2Icon,
  TreeItem2Root,
  UseTreeItem2Parameters,
} from "@mui/x-tree-view";
import {
  DrawerVariantsEnum,
  StatusForAuthorEnum,
  StatusForExecutorEnum,
} from "@types";

import {
  CustomTreeItemContent,
  CustomTreeItemIconContainer,
  CustomTreeItemGroupTransition,
} from "./styles";

const parser = new DOMParser();

export interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    React.HTMLAttributes<HTMLLIElement> {
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo: string;
  titleForChildEvent: boolean;
  taskId?: number;
  childTaskId?: number;
  taskStatus?: StatusForAuthorEnum | StatusForExecutorEnum;
}

export const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  {
    id,
    itemId,
    label,
    disabled,
    children,
    labelIcon: LabelIcon,
    labelInfo,
    titleForChildEvent,
    taskId,
    childTaskId,
    taskStatus,
    ...other
  }: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { palette } = useTheme();
  const { t } = useTranslation("tasks");
  const { set } = useQueryParams();
  const dispatch = useAppDispatch();

  const [isHovered, setIsHovered] = React.useState(false);

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });

  const handleLabelClick = (
    e: MouseEvent,
    id: string,
    titleForChildEvent: boolean
  ) => {
    e.stopPropagation();

    if (!titleForChildEvent || !id) return;

    dispatch(openDrawer(DrawerVariantsEnum.taskPreview));
    set("selectedTaskId", id);
  };

  const parsedHtmlDoc = parser.parseFromString(label, "text/html");
  const htmlDoc = parsedHtmlDoc.getElementsByTagName("body");
  const labelBody = htmlDoc?.[0].innerHTML.replaceAll("classname", "class");

  const { backgroundColor, color } = getTaskStatusChipConfig(
    taskStatus,
    palette,
    t,
    false
  );

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps({ ...other })}>
        <CustomTreeItemContent
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          {...getContentProps({
            className: classNames("content", {
              expanded: status.expanded,
              selected: status.selected,
              focused: status.focused,
              "cursor-pointer": titleForChildEvent,
            }),
          })}
        >
          <CustomTreeItemIconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </CustomTreeItemIconContainer>

          <Stack flexDirection="row" alignItems="center" width="100%">
            <Stack flexDirection="row" alignItems="center" flexGrow={1}>
              <LabelIcon />

              <Typography
                variant={titleForChildEvent ? "h8_bold" : "body1"}
                sx={{
                  textDecoration: titleForChildEvent ? "underline" : "none",
                }}
                onClick={(e) =>
                  handleLabelClick(e, childTaskId, titleForChildEvent)
                }
                dangerouslySetInnerHTML={{ __html: labelBody }}
              />

              {titleForChildEvent && taskStatus && isHovered && (
                <Tooltip
                  arrow
                  placement="top"
                  title={t(`statuses.${taskStatus}`)}
                >
                  <Zoom in className="cursor-pointer">
                    <Chip
                      label={t(`statuses.${taskStatus}`)}
                      sx={{
                        ".MuiChip-label": {
                          display: "block",
                        },
                        width: UI_CONFIG.chipWidth,
                        fontWeight: 600,
                        backgroundColor,
                        color,
                        borderColor: "transparent",
                        ml: 4,
                        transition: "all 0.9s linear",
                      }}
                    />
                  </Zoom>
                </Tooltip>
              )}
            </Stack>

            {!titleForChildEvent && (
              <Typography variant="body3" color="text.secondary" minWidth={110}>
                {labelInfo}
              </Typography>
            )}
          </Stack>
        </CustomTreeItemContent>

        {children && (
          <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});
