import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(30)};

  .approvalRoute {
    &__title {
      margin-bottom: ${({ theme }) => theme.spacing(4)};
    }
    &__subtitle {
      ${FLEX_ROW_ALIGN_CENTER};
      gap: ${({ theme }) => theme.spacing(2)};
    }
  }

  .approval-table__position-cell {
    background-color: ${({ theme }) => theme.palette.info["4p"]};

    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .agreed-column__status-cell {
    width: 100%;
    justify-content: center;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.palette.other.divider};
  }

  .active-queue__cell {
    border-left: 1px solid ${({ theme }) => theme.palette.secondary.main};
    border-right: 1px solid ${({ theme }) => theme.palette.secondary.main};

    background-color: ${({ theme }) => theme.palette.secondary["8p"]};
  }
`;

export const CCWrapper = styled.div`
  max-width: 33%;
  margin-bottom: ${({ theme }) => theme.spacing(10)};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: space-between;

  text-align: right;

  &.start_approval_process {
    padding: ${({ theme }) => theme.spacing(4)};
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  .approvalButton {
    margin-left: ${({ theme }) => theme.spacing(3)};
  }
`;
