import styled from "styled-components";
import { FLEX_BETWEEN } from "ui-kit";

import { inputBaseClasses } from "@mui/material";

export const FiltersWrapper = styled.div`
  .custom-filters__title {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};

    margin: ${({ theme }) => theme.spacing(-3, -4)};

    padding: ${({ theme }) => theme.spacing(5)};
  }
`;

export const FiltersActions = styled.div`
  ${FLEX_BETWEEN};
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

export const InputWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(4)};

  .${inputBaseClasses.root} {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;
