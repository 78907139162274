import React from "react";

import { WidgetWithChart } from "ui-kit";

import { Grid } from "@mui/material";
import { TIconNames } from "@types";

const MODULES: {
  icon: TIconNames | null;
  title: string;
  maintenanceMode: boolean;
  href?: string;
  Chart?: JSX.Element | null;
}[] = [
  {
    icon: "FileSignature",
    title: "nomenclature",
    maintenanceMode: true,
  },
  {
    icon: "MousePointerClick",
    title: "orders",
    maintenanceMode: true,
  },
  {
    icon: "Timer",
    title: "limits",
    maintenanceMode: true,
  },
  {
    icon: "CircleDollarSign",
    title: "payments",
    maintenanceMode: true,
  },
  {
    icon: "Truck",
    title: "supply",
    maintenanceMode: true,
  },
  {
    icon: "Warehouse",
    title: "warehouses",
    maintenanceMode: true,
  },
];

export const MaterialsPage = () => {
  return (
    <Grid container spacing={5}>
      {MODULES.map(({ title, icon, href, Chart, maintenanceMode }) => {
        return (
          <Grid key={title} item xs={12} sm={12} md={6} xl={4}>
            <WidgetWithChart
              title={title}
              icon={icon as TIconNames | null}
              href={href}
              maintenanceMode={maintenanceMode}
              chart={Chart}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
