import React from "react";

import en from "date-fns/locale/en-US";
import ru from "date-fns/locale/ru";
import { DefaultTFuncReturn } from "i18next";

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ruRU, enUS } from "@mui/x-date-pickers/locales";
import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";
import { Control, Controller } from "react-hook-form";
import { addDays } from "date-fns";

export interface FormDatePickerProps extends MuiDatePickerProps<any> {
  name: string;
  control: Control<any>;
  size?: "small" | "medium";
  disabledCurrentDay?: boolean;
  variant?: "standard" | "filled" | "outlined";
  color?: "error" | "info" | "primary" | "secondary" | "success" | "warning";
  label?: DefaultTFuncReturn;
  required?: boolean;
}

const locale = getStorageItem(STORAGE_KEYS.lang);
const isEnglish = locale === "en";

const dateFormat = `dd/${isEnglish ? "MMM" : "MM"}/yyyy`;

export const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {
  const {
    size = "medium",
    disabledCurrentDay = false,
    label = "",
    color = "secondary",
    variant = "outlined",
    readOnly,
    control,
    name,
    required = false,
    defaultValue,
    disabled,
  } = props;

  const minDate = addDays(new Date(), 1);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={isEnglish ? en : ru}
            localeText={
              isEnglish
                ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
                : ruRU.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <MuiDatePicker
              value={new Date(field.value)}
              onChange={(value) => {
                field.onChange(value?.toISOString());
              }}
              inputRef={field.ref}
              format={dateFormat}
              disabled={disabled}
              readOnly={readOnly}
              minDate={disabledCurrentDay ? minDate : undefined}
              slotProps={{
                textField: {
                  focused: false,
                  error: false,
                  disabled,
                  required,
                  size,
                  fullWidth: true,
                  variant,
                  color,
                },
              }}
              label={label}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
