import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  flex: 1;
`;

export const UpperSection = styled.div``;
export const BottomSection = styled.div``;
