import { TFunction } from "i18next";

import { GridColDef } from "@mui/x-data-grid-pro";
import { COLUMN_SIZE, getLocaleDateFormat } from "@sbm/fe-utils";

export const getTableColumns = (t: TFunction): GridColDef[] => {
  return [
    {
      field: "organizationName",
      headerName: t("organization") as string,
      flex: 1,
      minWidth: COLUMN_SIZE * 3,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: "routeNumber",
      headerName: t("route.number") as string,
      flex: 1,
      minWidth: COLUMN_SIZE * 1.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: "dateCreationRoute",
      headerName: t("date.of.creation") as string,
      flex: 1,
      minWidth: COLUMN_SIZE * 2,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      valueGetter: (params) =>
        getLocaleDateFormat(params.row.dateCreationRoute),
    },
    {
      field: "usageScorer",
      headerName: t("usageScorer") as string,
      flex: 1,
      minWidth: COLUMN_SIZE * 1.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: "additionalInformation",
      headerName: t("description") as string,
      flex: 1,
      minWidth: COLUMN_SIZE * 2.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];
};
