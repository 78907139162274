import styled from "styled-components";

import { formGroupClasses } from "@mui/material";

export const Wrapper = styled.div`
  gap: ${({ theme }) => theme.spacing(6)};
`;

export const RadioButtonsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 0, 4, 0)};

  .${formGroupClasses.root} {
    align-items: flex-start;
  }
`;
