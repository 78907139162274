import React from "react";

import { Modal } from "ui-kit";

import { CreateOrganizationContainer } from "../../../../containers";

export const CreateOrganizationPopup = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;

  const content = {
    title: "",
    body: <CreateOrganizationContainer />,
    secondaryButton: null,
    mainButton: null,
  };

  return <Modal isOpen={open} onClose={onClose} content={content} sizeLarge />;
};
