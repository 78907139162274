import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

export const CardWrapper = styled(Card)`
  padding: ${({ theme }) => theme.spacing(4)};
  margin: ${({ theme }) => theme.spacing(4, 0)};
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const CardTitleWrapper = styled.div`
  ${FLEX_BETWEEN};
`;

export const FileItemWrapper = styled.div`
  ${FLEX_BETWEEN};
  background-color: ${({ theme }) => hexToRgba(theme.palette.info.main, 0.04)};
`;

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  padding: ${({ theme }) => theme.spacing(6, 0)};
`;
