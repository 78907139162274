import { useQuery } from "@tanstack/react-query";

import { getStandardRouteForContract } from "../../requests";

export const useGetStandardRouteForContract = ({ id }: { id?: string }) => {
  return useQuery(
    ["get_standard_route_for_contract", id],
    async () => await getStandardRouteForContract(id)
  );
};
