import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useConfirmExecutionMutation } from "api";
import { Modal } from "ui-kit";

import { FormControlLabel, Stack, Switch } from "@mui/material";
import { Input, Button } from "@sbm/ui-components";

export const ConfirmExecutionPopup = ({
  open,
  onClose,
  data: {
    data: { id, reportRequired },
  },
}: {
  open: boolean;
  onClose: () => void;
  data: { data: { id: number; reportRequired: boolean } };
}) => {
  const { t } = useTranslation("tasks");

  const [comment, setComment] = useState("");
  const [checked, setChecked] = React.useState(false);

  const { mutate: confirmExecution, isLoading } =
    useConfirmExecutionMutation(onClose);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment("");
    setChecked(event.target.checked);
  };

  const handleConfirmExecution = () => {
    confirmExecution({ id, comment });
  };

  const content = {
    title: t(reportRequired ? "confirm.reason.with.report" : "confirm.reason"),
    body: (
      <Stack gap={3}>
        {!reportRequired && (
          <FormControlLabel
            value={checked}
            label={t("leave.comment")}
            labelPlacement="end"
            control={
              <Switch
                color="secondary"
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        )}

        {!reportRequired && !checked ? null : (
          <Input
            label={t("comment")}
            multiline
            required={reportRequired || checked}
            maxLength={1000}
            onChange={(e) => setComment(e.target.value)}
            sx={{ my: 2 }}
          />
        )}
      </Stack>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        onClick={handleConfirmExecution}
        loading={isLoading}
        disabled={
          (!comment.trim().length && reportRequired) ||
          (!reportRequired && checked && !comment.trim().length)
        }
      >
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
