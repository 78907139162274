export interface TreeNode {
  id: number;
  parentId: number | null;
  node: any;
  children: TreeNode[];
}

function convertTreeToArray(tree: TreeNode) {
  const result: Node[] = [];

  function traverse(treeItem: TreeNode, parentList: string[] = []) {
    const currentNode = { ...treeItem.node, node: [...parentList, treeItem.node.id] };
    result.push(currentNode);

    if (treeItem.children.length > 0) {
      const updatedParentList = [...parentList, currentNode.id];
      treeItem.children.forEach((child) => traverse(child, updatedParentList));
    }
  }

  traverse(tree);

  return result;
}

export const getFormattedTreeData = (tree: { nodes: TreeNode[] } | null) => {
  if (!tree) return;

  let result: any[] = [];

  tree.nodes.forEach((item) => {
    const flatArray = convertTreeToArray(item);
    result = [...result, ...flatArray];
  });

  return result;
};

export const mockTree = {
  nodes: [
    {
      id: 10,
      parentId: null,
      node: {
        id: 10,
        organizationId: 1,
        parentStructuralUnitId: null,
        mainGoverningBodyId: 2,
        governingBodyId: null,
        functionalBlockId: null,
        departmentId: null,
        divisionId: null,
        sectorId: null,
        directorateForManufacturingId: null,
        unitForManufacturingId: null,
        groupForManufacturingId: null,
        additionalInformation: "main govering",
        nameOfStructuralUnit: "main govering something name",
        dateOfListingStructuralUnit: "2023-07-17T09:37:56.784Z",
        dateDelistingStructuralUnit: null,
        structuralUnitType: "main_govering_body",
        numberInTheList: 1,
        validity: true,
        createdAt: "2023-07-17T09:37:56.769Z",
        updatedAt: "2023-07-17T09:37:56.769Z",
        staffUnitsTotal: 0,
        staffUnitsFilled: 0,
        staffUnitsVacancies: 0,
        staffUnitsInternalAllocations: 0,
      },
      children: [
        {
          id: 13,
          parentId: 10,
          node: {
            id: 13,
            organizationId: 1,
            parentStructuralUnitId: 10,
            mainGoverningBodyId: 2,
            governingBodyId: 4,
            functionalBlockId: null,
            departmentId: null,
            divisionId: null,
            sectorId: null,
            directorateForManufacturingId: null,
            unitForManufacturingId: null,
            groupForManufacturingId: null,
            additionalInformation: "govering_body 1",
            nameOfStructuralUnit: "govering_body 1 name",
            dateOfListingStructuralUnit: "2023-07-17T09:40:07.704Z",
            dateDelistingStructuralUnit: null,
            structuralUnitType: "govering_body",
            numberInTheList: 1,
            validity: true,
            createdAt: "2023-07-17T09:40:07.661Z",
            updatedAt: "2023-07-17T09:40:07.661Z",
            staffUnitsTotal: 0,
            staffUnitsFilled: 0,
            staffUnitsVacancies: 0,
            staffUnitsInternalAllocations: 0,
          },
          children: [
            {
              id: 130,
              parentId: 13,
              node: {
                id: 130,
                organizationId: 1,
                parentStructuralUnitId: 10,
                mainGoverningBodyId: 2,
                governingBodyId: 4,
                functionalBlockId: null,
                departmentId: null,
                divisionId: null,
                sectorId: null,
                directorateForManufacturingId: null,
                unitForManufacturingId: null,
                groupForManufacturingId: null,
                additionalInformation: "govering_body 130",
                nameOfStructuralUnit: "govering_body 130 name",
                dateOfListingStructuralUnit: "2023-07-17T09:40:07.704Z",
                dateDelistingStructuralUnit: null,
                structuralUnitType: "govering_body",
                numberInTheList: 1,
                validity: true,
                createdAt: "2023-07-17T09:40:07.661Z",
                updatedAt: "2023-07-17T09:40:07.661Z",
                staffUnitsTotal: 0,
                staffUnitsFilled: 0,
                staffUnitsVacancies: 0,
                staffUnitsInternalAllocations: 0,
              },
              children: [],
            },
          ],
        },
        {
          id: 14,
          parentId: 10,
          node: {
            id: 14,
            organizationId: 1,
            parentStructuralUnitId: 10,
            mainGoverningBodyId: 2,
            governingBodyId: 5,
            functionalBlockId: null,
            departmentId: null,
            divisionId: null,
            sectorId: null,
            directorateForManufacturingId: null,
            unitForManufacturingId: null,
            groupForManufacturingId: null,
            additionalInformation: "govering_body 2",
            nameOfStructuralUnit: "govering_body 2 name",
            dateOfListingStructuralUnit: "2023-07-17T09:40:18.470Z",
            dateDelistingStructuralUnit: null,
            structuralUnitType: "govering_body",
            numberInTheList: 1,
            validity: true,
            createdAt: "2023-07-17T09:40:18.462Z",
            updatedAt: "2023-07-17T09:40:18.462Z",
            staffUnitsTotal: 0,
            staffUnitsFilled: 0,
            staffUnitsVacancies: 0,
            staffUnitsInternalAllocations: 0,
          },
          children: [],
        },
      ],
    },
  ],
};

export const getTransformedOrgChartData = (
  staffAllocation: { nodes: TreeNode[] } | null,
  structuralUnits: { nodes: TreeNode[] } | null,
  mainGoveringBody: string,
  goveringBody: string
) => {
  if (!staffAllocation || !structuralUnits) return;

  const staffAllocationTree = getFormattedTreeData(staffAllocation);
  const structuralUnitsTree = getFormattedTreeData(structuralUnits);

  if (staffAllocationTree && structuralUnitsTree) {
    return structuralUnitsTree.map((unit) => {
      const staff = staffAllocationTree.find((staff) => staff.id === unit.id);
      return {
        id: unit.id,
        name: staff?.employee?.fullName || staff?.externalSubstitution?.employee?.fullName || "",
        position: staff?.staffUnit?.positionName || "",
        nameOfStructuralUnit: unit?.nameOfStructuralUnit || "",
        parentId: unit?.node[unit?.node.length - 2] || "",
        isDirector: unit?.structuralUnitType === mainGoveringBody,
        isAssistantDirector: unit?.structuralUnitType === goveringBody,
      };
    });
  }

  return;
};
