import {
  endOfMonth,
  endOfWeek,
  isThisMonth,
  isThisWeek,
  isToday,
  parse,
  startOfMonth,
  startOfWeek,
  isSameDay,
  isSameWeek,
} from "date-fns";

import { isValidDate } from "@sbm/fe-utils";

export const formatDateFiltersQuery = (
  dateQuery: string,
  dateFormat: string
) => {
  const dates = dateQuery.split(" | ");
  const startDateString = dates[0];
  const endDateString = dates[1];

  const parsedStartDate = parse(startDateString, dateFormat, new Date());
  const parsedEndDate = parse(endDateString, dateFormat, new Date());

  const isTodaySelected = isToday(parsedStartDate) && isToday(parsedEndDate);

  const isThisWeekSelected =
    isThisWeek(parsedStartDate, { weekStartsOn: 1 }) &&
    isThisWeek(parsedEndDate, { weekStartsOn: 1 }) &&
    isSameWeek(startOfWeek(new Date(), { weekStartsOn: 1 }), parsedStartDate) &&
    isSameWeek(endOfWeek(new Date(), { weekStartsOn: 1 }), parsedEndDate);

  const isThisMonthSelected =
    isThisMonth(parsedStartDate) &&
    isThisMonth(parsedEndDate) &&
    isSameDay(startOfMonth(new Date()), parsedStartDate) &&
    isSameDay(endOfMonth(new Date()), parsedEndDate);

  const isRangeSelection =
    !isTodaySelected &&
    !isThisWeekSelected &&
    !isThisMonthSelected &&
    isValidDate(parsedStartDate) &&
    isValidDate(parsedEndDate);

  const rangeOptions = { startDate: parsedStartDate, endDate: parsedEndDate };

  return {
    isTodaySelected,
    isThisWeekSelected,
    isThisMonthSelected,
    isRangeSelection,
    ...rangeOptions,
  };
};
