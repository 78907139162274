import { useQuery } from "@tanstack/react-query";
import { IEmployee } from "@types";

import { fetchEmployeeById, fetchEmployees } from "../../requests";

export const useGetEmployees = ({
  search,
  organizationId,
  subordinate,
  limit,
  headOfStructuralUnit,
}: {
  search?: string;
  organizationId?: number;
  subordinate?: boolean;
  limit?: number;
  headOfStructuralUnit?: boolean;
}) => {
  return useQuery<{ items: IEmployee[] }>(
    [
      "get_employees",
      search,
      organizationId,
      subordinate,
      headOfStructuralUnit,
    ],
    async () =>
      await fetchEmployees(
        search,
        organizationId,
        subordinate,
        limit,
        headOfStructuralUnit
      ),
    {
      onError: (err) => {
        console.error(err, "get_employees");
      },
    }
  );
};

export const useGetEmployeeById = (id?: number) => {
  return useQuery<IEmployee>(
    ["get_employee_by_id", id],
    async () => await fetchEmployeeById(id)
  );
};
