import React from "react";
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";

import { axiosService } from "app";
import { AxiosError } from "axios";
import { TFunction } from "i18next";

import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { Checkbox } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { getAPIErrorMessage } from "@sbm/fe-utils";
import { ICreateStandardRoute, IDefaultRoute, IRoute } from "@types";

import { IContractPartialData } from "./index";

export const getContractTypeAndSubtype = async () => {
  try {
    const { data } = await axiosService({
      endpoint: `directories`,
      body: {
        directories: ["contractType", "contractSubtype"],
      },
    });

    return data as IContractPartialData;
  } catch (err) {
    const errorMsg = getAPIErrorMessage(err as AxiosError);
    console.error(errorMsg, "getContractTypeAndSubtype()");
  }
};

export const getFormattedRoutesToAssign = (
  routes: {
    contractType: string;
    contractSubtype: string;
    defaultRoute: boolean;
    value: string;
  }[],
  contractData: IContractPartialData | null
): IRoute[] => {
  if (!routes?.length) return [];

  return routes
    .map((item) => {
      const contractTypeId =
        contractData?.contractType?.find(
          (i) =>
            i.nameContractType === item.value ||
            i.nameContractType === item.contractType
        )?.id || 0;

      const contractSubtypeId =
        contractData?.contractSubtype?.find(
          (i) => i.nameContractSubtype === item.contractSubtype
        )?.id || 0;

      return {
        contractTypeId,
        contractSubtypeId,
        routeByDefault: item.defaultRoute,
      };
    })
    .filter((i) => i.contractSubtypeId && i.contractTypeId);
};

export const getTableColumns = (
  t: TFunction,
  apiRef: React.MutableRefObject<any>,
  rowModesModel: GridRowModesModel,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
  setRows: React.Dispatch<React.SetStateAction<GridValidRowModel[]>>,
  rows: GridValidRowModel[],
  contractData: IContractPartialData | null,
  defaultRoutes: IDefaultRoute[],
  openAlreadyAssignedRoutePopup: (
    cb: (id: GridRowId) => void,
    route?: IDefaultRoute
  ) => void,
  setError: UseFormSetError<ICreateStandardRoute>,
  clearErrors: UseFormClearErrors<ICreateStandardRoute>,
  isEditMode?: boolean,
  isViewMode?: boolean,
  errors?: FieldErrors
): GridColDef[] => {
  let selectedType = "";

  const contractTypeOptions = contractData
    ? contractData.contractType.map((item) => item.nameContractType)
    : undefined;

  const handleSaveClick = (id: GridRowId) => () => {
    const rowState = apiRef.current?.state?.editRows?.[id];

    const isDataFilled = Boolean(
      rowState && rowState.contractType.value && rowState.contractSubtype.value
    );

    if (!isDataFilled) {
      setError("routes", {
        type: "validate",
        message: id as string,
      });

      setRowModesModel({ ...rowModesModel });
      return;
    }

    clearErrors("routes");
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    if (errors?.routes?.message === id) {
      clearErrors("routes");
    }

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);

    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const checkIfRouteIsAlreadyAssigned = (id: GridRowId) => {
    const state = apiRef.current?.state;

    const isNewRow = Boolean(state?.editRows?.[id]);

    const rowsState = !isNewRow
      ? state?.rows?.dataRowIdToModelLookup?.[id]
      : state?.editRows?.[id];

    const currentTypeAndSubtype = {
      contractType: !isNewRow
        ? rowsState?.contractType
        : rowsState?.contractType?.value,
      contractSubtype: !isNewRow
        ? rowsState?.contractSubtype
        : rowsState?.contractSubtype?.value,
    };

    const currentTypeAndSubtypeIds = {
      contractTypeId: contractData?.contractType?.find(
        (i) => i.nameContractType === currentTypeAndSubtype.contractType
      )?.id,
      contractSubtypeId: contractData?.contractSubtype?.find(
        (i) => i.nameContractSubtype === currentTypeAndSubtype.contractSubtype
      )?.id,
    };

    const existingRoute = defaultRoutes?.find(
      (route) =>
        route.contractTypeId === currentTypeAndSubtypeIds.contractTypeId &&
        route.contractSubtypeId === currentTypeAndSubtypeIds.contractSubtypeId
    );

    return existingRoute;
  };

  const toggleCheckbox = (id: GridRowId) => {
    setRows((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            defaultRoute: !item.defaultRoute,
          };
        }

        return item;
      })
    );
  };

  const handleSelectRoute = (params: GridRenderCellParams) => {
    const { id, defaultRoute } = params.row;

    const existingRoute = checkIfRouteIsAlreadyAssigned(id);

    if (existingRoute && !defaultRoute) {
      const callback = () => toggleCheckbox(id);
      return openAlreadyAssignedRoutePopup(callback, existingRoute);
    }

    toggleCheckbox(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const columns: GridColDef[] = [
    {
      field: "contractType",
      headerName: t("type.of.contract") as string,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      editable: true,
      type: "singleSelect",
      valueOptions: contractTypeOptions,
      valueGetter: (params) => {
        return params.row.value || params.row.contractType;
      },
      valueSetter: (params) => {
        selectedType = params.value;

        const newRow = {
          ...params.row,
          value: selectedType,
        };

        return newRow;
      },
    },
    {
      field: "contractSubtype",
      headerName: t("subtype.of.contract") as string,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      editable: true,
      type: "singleSelect",
      valueOptions: () => {
        if (!selectedType) return [];

        const selectedTypeId = contractData?.contractType.find(
          (item) => item.nameContractType === selectedType
        )?.id;

        const contractSubtypeOptions = contractData
          ? contractData.contractSubtype
              .filter((i) => i.contractTypeId === selectedTypeId)
              .map((item) => item.nameContractSubtype)
          : undefined;

        return contractSubtypeOptions as string[];
      },
    },
    {
      field: "defaultRoute",
      headerName: t("default.route") as string,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const row = params.row;

        const rowState = apiRef.current?.state?.editRows?.[row.id];

        const isDataFilled = Boolean(
          rowState &&
            rowState.contractType.value &&
            rowState.contractSubtype.value
        );

        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

        return (
          <div className="MuiDataGrid-cellContent" style={{ padding: 4 }}>
            <Checkbox
              checked={params.row.defaultRoute}
              onClick={() => handleSelectRoute(params)}
              disabled={!isEditMode && (!isDataFilled || !isInEditMode)}
            />
          </div>
        );
      },
    },
  ];

  if (!isViewMode || (isViewMode && isEditMode)) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: t("actions") as string,
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (!isEditMode && isViewMode) return [];

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key="save-button"
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key="cancel-button"
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="Delete"
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    });
  }

  return columns;
};
