import React from "react";

import { MuiTelInput } from "mui-tel-input";
import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";
import { DefaultTFuncReturn } from "i18next";

interface PhoneInputProps {
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  label?: string | DefaultTFuncReturn;
  helperText?: string;
  size?: "small" | "medium";
}
export const PhoneInput = React.forwardRef((props: PhoneInputProps, ref) => {
  const { error, disabled, label, helperText, required = false, size = "medium", ...rest } = props;

  const lang = getStorageItem(STORAGE_KEYS.lang) || "en";

  return (
    <MuiTelInput
      langOfCountryName={lang}
      defaultCountry="RU"
      inputRef={ref}
      error={error}
      label={label}
      required={required}
      disabled={disabled}
      size={size}
      helperText={helperText}
      fullWidth
      variant="outlined"
      {...rest}
    />
  );
});
