import toast from "react-hot-toast";

import { AxiosError } from "axios";
import { cloneDeep } from "lodash";

import { getAPIErrorMessage, getUrlParam } from "@sbm/fe-utils";
import { IApprovalRoute, ICreateStandardRoute } from "@types";

import { axiosService } from "../../services";
import {
  AppDispatch,
  setCheckedApprovalRoute,
  setLoading,
  setStandardApprovalRoutes,
} from "../index";

export const getStandardApprovalRoutes = (
  page = 1,
  limit = 10,
  search = "",
  sortBy = ""
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    const params = {
      search,
      sortBy,
      limit,
      page,
    };

    try {
      const { data } = await axiosService({
        endpoint: `/typical-approval-route-for-contracts`,
        body: params,
      });

      dispatch(setStandardApprovalRoutes(data));
    } catch (err) {
      const errorMsg = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMsg);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const createTypicalApprovalRoute = (
  body: Omit<ICreateStandardRoute, "routes">
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: "/typical-approval-route-for-contracts",
        method: "POST",
        body,
      });

      const pageQueryFromUrl = getUrlParam("page", "single") as string;
      const pageSizeQueryFromUrl = getUrlParam("pagesize", "single") as string;
      const searchQueryFromUrl = getUrlParam("search", "single") as string;
      const sortByFromUrl = getUrlParam("sortBy", "single") as string;

      await dispatch(
        getStandardApprovalRoutes(
          Number(pageQueryFromUrl) || undefined,
          Number(pageSizeQueryFromUrl) || undefined,
          searchQueryFromUrl,
          sortByFromUrl || "dateCreationRoute:DESC"
        )
      );

      return data;
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const assignApprovalRouteToContract = (
  typicalApprovalRouteForContractId: number,
  body: Pick<ICreateStandardRoute, "routes">
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `/assign-approval-route-for-contracts/${typicalApprovalRouteForContractId}`,
        method: "POST",
        body,
      });

      const pageQueryFromUrl = getUrlParam("page", "single") as string;
      const pageSizeQueryFromUrl = getUrlParam("pagesize", "single") as string;
      const searchQueryFromUrl = getUrlParam("search", "single") as string;
      const sortByFromUrl = getUrlParam("sortBy", "single") as string;

      await dispatch(
        getStandardApprovalRoutes(
          Number(pageQueryFromUrl) || undefined,
          Number(pageSizeQueryFromUrl) || undefined,
          searchQueryFromUrl,
          sortByFromUrl || "dateCreationRoute:DESC"
        )
      );

      return data;
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const deleteApprovalRouteToContract = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `/typical-approval-route-for-contracts/${id}`,
        method: "DELETE",
      });

      const pageQueryFromUrl = getUrlParam("page", "single") as string;
      const pageSizeQueryFromUrl = getUrlParam("pagesize", "single") as string;
      const searchQueryFromUrl = getUrlParam("search", "single") as string;
      const sortByFromUrl = getUrlParam("sortBy", "single") as string;

      await dispatch(
        getStandardApprovalRoutes(
          Number(pageQueryFromUrl) || undefined,
          Number(pageSizeQueryFromUrl) || undefined,
          searchQueryFromUrl,
          sortByFromUrl || "dateCreationRoute:DESC"
        )
      );

      return data;
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const updateApprovalRoute = (selectedRow: IApprovalRoute | null) => {
  return (dispatch: AppDispatch) => {
    dispatch(setCheckedApprovalRoute(cloneDeep(selectedRow)));
  };
};
