import React from "react";

import styled from "styled-components";

import { Divider, Typography } from "@mui/material";

export const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const FormDivider = ({ title }: { title: string }) => {
  return (
    <Wrapper>
      <Typography variant="body2" fontWeight={700}>
        {title}
      </Typography>
      <Divider light />
    </Wrapper>
  );
};
