import React from "react";
import { NavigateAction, ToolbarProps, View, Views } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import { useEffectOnce } from "app";
import { Icon } from "ui-kit";

import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import { capitalize } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";

import { CalendarFilters } from "./CalendarFilters";

enum NAVIGATION {
  "PREV" = "PREV",
  "NEXT" = "NEXT",
  "TODAY" = "TODAY",
  "DATE" = "DATE",
}

export const CalendarToolbar = ({
  onNavigate,
  label,
  view,
  onView,
}: ToolbarProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation("tasks");

  const handleTodayClick = () => onNavigate(NAVIGATION.TODAY as NavigateAction);
  const handleNextClick = () => onNavigate(NAVIGATION.NEXT as NavigateAction);
  const handlePrevClick = () => onNavigate(NAVIGATION.PREV as NavigateAction);

  const handleViewChange = (_: React.MouseEvent<HTMLElement>, value: View) => {
    onView(value);
  };

  useEffectOnce(() => {
    setTimeout(() => {
      onView(view);
    }, 1000);
  });

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      mb={4}
    >
      <Stack flexDirection="row" alignItems="center">
        <CalendarFilters />

        <Button color="secondary" size="large" onClick={handleTodayClick}>
          {t("today")}
        </Button>
        <IconButton onClick={handlePrevClick}>
          <Icon
            name="ChevronLeft"
            className="cursor-pointer"
            color={palette.secondary.main}
          />
        </IconButton>
        <IconButton onClick={handleNextClick}>
          <Icon
            name="ChevronRight"
            className="cursor-pointer"
            color={palette.secondary.main}
          />
        </IconButton>
      </Stack>

      <Typography variant="h7" fontWeight={700} color="text.secondary">
        {capitalize(label)}
      </Typography>

      <ToggleButtonGroup
        color="secondary"
        size="small"
        exclusive
        value={view}
        onChange={handleViewChange}
      >
        <ToggleButton value={Views.WEEK}>{t("week")}</ToggleButton>
        <ToggleButton value={Views.MONTH}>{t("month")}</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
