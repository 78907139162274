import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_COLUMN_FULL, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import {
  formControlLabelClasses,
  formGroupClasses,
  radioClasses,
} from "@mui/material";

export const Wrapper = styled.div`
  height: 100%;
  margin-top: ${({ theme }) => theme.spacing(-3)};

  .sa__radio__buttons {
    > label {
      margin-left: 0;
      margin-right: 0;
    }
  }

  form {
    height: 100%;
    margin-top: ${({ theme }) => theme.spacing(6)};
  }

  .radio__buttons {
    > label {
      margin-left: 0;
    }
  }

  .drop_area {
    height: 70px;
    max-width: 100%;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
    border: dashed 1px ${({ theme }) => theme.palette.grey[400]};

    path {
      fill: ${({ theme }) => theme.palette.info.main};
    }

    span {
      font-size: 14px;
      margin-left: ${({ theme }) => theme.spacing(2)};
    }

    .file-types {
      display: none;
    }
  }

  .${formGroupClasses.root} {
    .${formControlLabelClasses.root} {
      .${radioClasses.root} {
        flex: 0;
      }
    }
  }
`;

export const ActionsWrapper = styled.div<{ isEditMode?: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  button {
    min-width: ${UI_CONFIG.modalButton}px;
  }
  justify-content: ${({ isEditMode }) =>
    isEditMode ? "space-between" : "flex-end"};

  > div {
    ${FLEX_ROW_ALIGN_CENTER};
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing(2)};
  }
`;
export const AlertWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  gap: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
export const LabelWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
`;
