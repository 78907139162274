import toast from "react-hot-toast";

import {
  AppDispatch,
  axiosService,
  closeDrawer,
  setStaffAllocation,
  setStaffUnits,
  setStructuralUnitById,
  setStructuralUnits,
} from "app";
import { AxiosError } from "axios";

import {
  getAPIErrorMessage,
  getUrlParam,
  SnackbarMessages,
} from "@sbm/fe-utils";
import {
  ICreateEmployee,
  IOrganization,
  ICreateStaffUnit,
  ICreateStructuralUnit,
} from "@types";

import { getFilterValues } from "../../containers/organizations/OrganizationStaffAllocationContainer/helpers";

export const createOrganization = (organization: IOrganization) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: "organizations",
        method: "POST",
        body: organization,
      });

      dispatch(closeDrawer());
      toast.success(SnackbarMessages.success);
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

/**
 * STRUCTURAL UNITS
 */

export const getStructuralUnits = (orgId: string, validity: boolean) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `structural-units/organization-tree/${orgId}?validity=${validity}`,
      });

      dispatch(setStructuralUnits(data));
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMessage);
      window.location.replace("/");
    }
  };
};

export const getStructuralUnitById = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `structural-units/${id}`,
      });

      dispatch(setStructuralUnitById(data));
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const createStructuralUnit = (
  structuralUnit: ICreateStructuralUnit,
  organizationId: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: "structural-units",
        method: "POST",
        body: structuralUnit,
      });

      await dispatch(getStructuralUnits(organizationId, true));

      toast.success(SnackbarMessages.success);
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const updateStructuralUnit = ({
  structuralUnitId,
  structuralUnit,
  organizationId,
}: {
  structuralUnitId: number;
  structuralUnit: Pick<ICreateStructuralUnit, "additionalInformation"> & {
    validity?: boolean;
  };
  organizationId: string;
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: `structural-units/${structuralUnitId}`,
        method: "PUT",
        body: structuralUnit,
      });

      await dispatch(getStructuralUnits(organizationId, true));

      toast.success(SnackbarMessages.success);
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const getStructuralUnitsAutocomplete = (
  search = "",
  organizationId: string
) => {
  const params = organizationId
    ? {
        limit: 200,
        page: 1,
        search,
        "filter.organizationId": organizationId,
      }
    : {
        limit: 200,
        page: 1,
        search,
      };

  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `structural-units`,
        body: params,
      });

      return data;
    } catch (e) {
      const errorMsg = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMsg);
    }
  };
};

/**
 * Staff Units
 */
export const getStaffUnits = (orgId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `/staff-units/organization-staff-schedule-tree/${orgId}`,
      });

      dispatch(setStaffUnits(data));
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const createStaffUnit = (
  body: ICreateStaffUnit,
  organizationId: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: "staff-units",
        method: "POST",
        body: body,
      });

      await dispatch(getStaffUnits(organizationId));

      toast.success(SnackbarMessages.success);
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const getPositions = (search = "") => {
  const params: {
    limit: number;
    page: number;
    search: string;
  } = {
    limit: 249,
    page: 1,
    search,
  };

  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `positions`,
        body: params,
      });

      return data;
    } catch (e) {
      const errorMsg = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMsg);
    }
  };
};

/**
 * Staff Allocation
 */
export const getStaffAllocation = (
  orgId: string,
  search: string,
  filter: string[]
) => {
  const filterParams: { [key: string]: string | string[] } = {};

  if (filter.length > 0) {
    filter.forEach((item) => {
      if (!item) return;

      const splitName = item?.split("=");
      const key = `filter.${splitName[0]}`;
      const result: string[] = [];

      splitName[1].split(",").forEach((i) => {
        if (i) {
          result.push(i);
        }
      });

      filterParams[key] = result;
    });
  }

  const getDatesQuery = () => {
    const dateParams: typeof filterParams = {};

    if (filterParams["filter.dateOfRecruitment"]) {
      dateParams["filter.dateOfRecruitment"] =
        filterParams["filter.dateOfRecruitment"][0];
    }

    delete filterParams["filter.dateOfRecruitment"];

    return dateParams;
  };

  const dateParams = getDatesQuery();

  const params = {
    search,
    ...filterParams,
    ...dateParams,
  };

  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `/staff-units/organization-staff-allocation-tree/${orgId}`,
        body: params,
      });

      dispatch(setStaffAllocation(data));
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMessage);
    }
  };
};

/**
 * Employees
 */
export const getEmployees = (
  search = "",
  organizationId?: string | number,
  subordinate?: boolean,
  messageRecipient?: boolean,
  rightToSignStaff?: boolean
) => {
  const params: {
    limit: number;
    page: number;
    search: string;
    "filter.organizationId"?: string;
    "filter.subordinate"?: string;
    "filter.messageRecipient"?: string;
    "filter.rightToSignStaff"?: string;
  } = {
    limit: 200,
    page: 1,
    search,
  };

  if (organizationId && !subordinate) {
    params["filter.organizationId"] = String(organizationId);
  }

  if (subordinate) {
    params["filter.subordinate"] = "true";
  }

  if (rightToSignStaff) {
    params["filter.rightToSignStaff"] = "true";
  }

  if (messageRecipient) {
    params["filter.messageRecipient"] = "true";
  }

  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `employees`,
        body: params,
      });

      return data;
    } catch (e) {
      const errorMsg = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMsg);
    }
  };
};

export const getPersonalData = (search = "") => {
  const params: {
    limit: number;
    page: number;
    search: string;
  } = {
    limit: 200,
    page: 1,
    search,
  };

  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axiosService({
        endpoint: `personal-data`,
        body: params,
      });

      return data;
    } catch (e) {
      const errorMsg = getAPIErrorMessage(e as AxiosError);
      toast.error(errorMsg);
    }
  };
};

export const createEmployee = (
  body: ICreateEmployee,
  organizationId: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: "employees",
        method: "POST",
        body: body,
      });

      const filterQueryFromUrl = getUrlParam("filter", "all") as string[];
      const searchQueryFromUrl = getUrlParam("search", "single") as string;

      const filtersToSend = filterQueryFromUrl?.length
        ? getFilterValues(filterQueryFromUrl)
        : filterQueryFromUrl;

      await dispatch(
        getStaffAllocation(organizationId, searchQueryFromUrl, filtersToSend)
      );

      toast.success(SnackbarMessages.success);
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const updateEmployee = (
  body: Partial<ICreateEmployee>,
  employeeId: string,
  organizationId: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: `employees/${employeeId}`,
        method: "PUT",
        body: body,
      });

      const filterQueryFromUrl = getUrlParam("filter", "all") as string[];
      const searchQueryFromUrl = getUrlParam("search", "single") as string;

      const filtersToSend = filterQueryFromUrl?.length
        ? getFilterValues(filterQueryFromUrl)
        : filterQueryFromUrl;

      await dispatch(
        getStaffAllocation(organizationId, searchQueryFromUrl, filtersToSend)
      );

      toast.success(SnackbarMessages.success);
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};

export const updateStaffUnit = (
  body: Partial<ICreateStaffUnit>,
  staffUnitId: string,
  organizationId: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: `staff-units/${staffUnitId}`,
        method: "PUT",
        body: body,
      });

      await dispatch(getStaffUnits(organizationId));

      toast.success(SnackbarMessages.success);
    } catch (err) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage);
    }
  };
};
