import React from "react";
import { NumericFormat, NumberFormatBaseProps, NumericFormatProps } from "react-number-format";
import { Input, InputProps } from "../Input";

export const NumericInput = React.forwardRef((props: NumericFormatProps & NumberFormatBaseProps & InputProps, ref) => {
  return (
    <NumericFormat
      {...props}
      value={props.value || ""}
      getInputRef={ref}
      onChange={() => null}
      onValueChange={({ value: rawValue }) => {
        const valueToSet = Number(rawValue);

        props.onChangeListener?.(valueToSet);
        // @ts-ignore
        return props.onChange?.(valueToSet);
      }}
      // There is a wrong interface in the library for customInput prop, which is not working with forwardRef
      // @ts-ignore
      customInput={Input}
    />
  );
});
