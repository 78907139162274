import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAppSelector, useEffectOnce, usePermissions } from "app";
import { Icon } from "ui-kit";

import { Fade, ListItem, Typography, useTheme } from "@mui/material";
import { SBM_LINK } from "@sbm/fe-utils";

import sbm_logo from "../../../../../assets/images/logos/SBM-logo.png";
import { SidebarCollapse, SidebarItems } from "./components";
import { SIDEBAR_ITEMS } from "./constants";
import {
  Drawer,
  ListWrapper,
  Logo,
  ArrowWrapper,
  SidebarWrapper,
  Instruction,
} from "./styles";

interface SidebarProps {
  open: boolean;
  onToggle: () => void;
}
export function Sidebar(props: SidebarProps) {
  const { open, onToggle } = props;

  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { userProfile } = useAppSelector((state) => state.auth);

  const [openCollapse, setOpenCollapse] = useState("");

  const email = userProfile
    ? typeof userProfile.email === "string"
      ? userProfile.email
      : userProfile.email.email
    : undefined;

  const { hasPermission } = usePermissions(email);

  const handleCloseCollapse = useCallback(() => {
    setOpenCollapse("");
  }, []);

  const toggleCollapse = (route: string, collapse?: boolean) => {
    setOpenCollapse(route);
  };

  const handleClick = (route: string, collapse?: boolean) => {
    if (collapse) {
      openCollapse === route ? handleCloseCollapse() : setOpenCollapse(route);
    }

    if (collapse === false) handleCloseCollapse();

    navigate({ pathname: route }, { state: { prevPath: location.pathname } });
  };

  useEffect(() => {
    if (!open) return handleCloseCollapse();

    if (location.pathname) {
      const splitPaths = location.pathname.split("/");
      toggleCollapse(`/${splitPaths[1]}`);
    }
  }, [open, location.pathname, handleCloseCollapse]);

  useEffectOnce(() => {
    return () => handleCloseCollapse();
  });

  return (
    <Drawer variant="permanent" open={open}>
      <SidebarWrapper>
        <div>
          <Logo>
            <Link to="/">
              <img
                src={sbm_logo}
                alt="SBM"
                width={open ? 74 : 40}
                height={open ? 25 : 14}
              />
            </Link>
          </Logo>
          <ListWrapper>
            {open && (
              <ArrowWrapper>
                <Icon
                  name="ChevronLeft"
                  color={theme.palette.secondary.main}
                  size={16}
                  onClick={onToggle}
                />
              </ArrowWrapper>
            )}

            {SIDEBAR_ITEMS.map((item) => {
              const { name, icon, route, collapse, withPermission } = item;

              if (withPermission && !hasPermission) return null;

              return (
                <ListItem
                  key={name}
                  disablePadding
                  sx={{ display: "block", px: 4, py: 2 }}
                >
                  <SidebarItems
                    open={open}
                    openCollapse={openCollapse}
                    onClick={handleClick}
                    collapse={collapse}
                    icon={icon}
                    name={name}
                    route={route}
                  />

                  {collapse &&
                    collapse.length > 0 &&
                    openCollapse === route && (
                      <SidebarCollapse
                        open={open}
                        openCollapse={openCollapse}
                        onClick={handleClick}
                        collapse={collapse}
                      />
                    )}
                </ListItem>
              );
            })}
          </ListWrapper>
        </div>
        <div
          className="instruction__link"
          title=""
          onClick={() => window.open(SBM_LINK, "_blank")}
        >
          <Instruction
            sx={{
              justifyContent: open ? "initial" : "center",
            }}
          >
            <Icon name="InfoIcon" color={theme.palette.primary.contrastText} />
            {open && (
              <Fade in timeout={500}>
                <Typography
                  variant="subtitle2"
                  fontWeight={600}
                  sx={{ flex: 1 }}
                  color={open ? "primary.contrastText" : "primary.contrastText"}
                >
                  {t("instruction")}
                </Typography>
              </Fade>
            )}
          </Instruction>
        </div>
      </SidebarWrapper>
    </Drawer>
  );
}
