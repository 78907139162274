import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  width: 100%;
`;

export const UpperSection = styled.div<{ $open: boolean }>`
  ${FLEX_ROW};
  align-items: ${({ $open }) => ($open ? "start" : "center")};
  gap: ${({ theme }) => theme.spacing()};

  width: 100%;
`;

export const BottomSection = styled.div<{ $open: boolean }>`
  display: ${({ $open }) => ($open ? "block" : "none")};
  width: 100%;

  transition: all 3ms ease-in;
`;
