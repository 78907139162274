// ToDo: Fix the types after PO approve
// @ts-nocheck
import { TFunction } from "i18next";

import { StructuralUnitTypesEnum } from "@types";

type TNode = any;

const blankSpaceString = "\xa0";
const emptyName = "";

const chiefStructuralUnits = [
  StructuralUnitTypesEnum.mainGoverningBody,
  StructuralUnitTypesEnum.goveringBody,
  StructuralUnitTypesEnum.functionalBlock,
];

export const getTreeNodeLabel = (node: TNode, t: TFunction) => {
  const nestedNode = node.node;

  if (!nestedNode) return "";

  const staffUnit = nestedNode.staffUnit;
  const structuralUnit = nestedNode.structuralUnit;
  const employee = nestedNode.employee;
  const externalSubstitution = nestedNode.externalSubstitution;

  const isHeadOfUnit = staffUnit?.headOfStructuralUnit;

  const getHeadOfUnitName = () => {
    if (employee) {
      const isChief = chiefStructuralUnits.includes(
        structuralUnit?.structuralUnitType
      );

      if (isChief) return employee.fullName;

      return `${staffUnit?.positionName || ""} - ${employee.fullName}`;
    }

    if (externalSubstitution?.employee) {
      const isChief = chiefStructuralUnits.includes(
        structuralUnit?.structuralUnitType
      );

      if (isChief) {
        return `${externalSubstitution.employee.fullName}, ${t(
          "external-overlap"
        ).toLowerCase()}`;
      }

      return `${staffUnit?.positionName || ""} - ${
        externalSubstitution.employee.fullName
      }, ${t("external-overlap").toLowerCase()}`;
    }

    return emptyName;
  };

  const getEmployeeName = () => {
    if (employee) return employee.fullName;

    if (externalSubstitution?.employee) {
      return `${externalSubstitution.employee.fullName}, ${t(
        "external-overlap"
      ).toLowerCase()}`;
    }

    return emptyName;
  };

  const firstPart = isHeadOfUnit
    ? structuralUnit?.nameOfStructuralUnit || ""
    : `${
        staffUnit
          ? staffUnit.positionName
          : structuralUnit?.nameOfStructuralUnit
      }${blankSpaceString}`;

  const secondPart = isHeadOfUnit
    ? `(${getHeadOfUnitName()})`
    : getEmployeeName()
    ? `||${blankSpaceString} ${getEmployeeName()}`
    : "";

  return `${firstPart} ${secondPart}`;
};

export const addExpandedProperty = (node: TNode, t: TFunction) => {
  const newNode = {
    ...node,
    id: node.node?.staffUnit?.headOfStructuralUnit
      ? `head-unit__${node.node.id}`
      : String(node.node.id),
    label: getTreeNodeLabel(node, t),
  };

  newNode.children = newNode.children
    .map((child) => addExpandedProperty(child, t))
    .filter((i) => i);

  return newNode;
};
