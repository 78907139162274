import toast from "react-hot-toast";

import {
  getCorrespondenceIncoming,
  fetchDocumentById,
  fetchCorrespondenceFilesById,
  fetchCorrespondenceLinksById,
  uploadCorrespondenceFile,
  uploadCorrespondenceLinks,
  sendToResolution,
  createIncomingDocument,
  fetchCorrespondenceEvents,
  createOutgoingDocument,
} from "api";
import { AxiosError } from "axios";

import {
  getAPIErrorMessage,
  getFiltersQueryCacheKey,
  SnackbarMessages,
} from "@sbm/fe-utils";
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IMeta,
  IIncomingDocument,
  IAttachmentCorrespondenceFile,
  IAttachesTaskLinkBody,
  IAttachmentCorrespondenceLink,
  TDocumentExecutionTrackerNode,
  IDocumentById,
  ResolutionRecipientEmployeeEnums,
} from "@types";

export const useGetCorrespondenceIncoming = (
  filter: (string | undefined)[] = [],
  sortBy = "",
  search = "",
  options?: Record<string, unknown>
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{
    items: IIncomingDocument[];
    meta: IMeta;
  }>(
    ["get_correspondence_incoming", search, ...queryKeyFilter, sortBy],
    (params) => getCorrespondenceIncoming(params, filter, sortBy, search),
    {
      ...options,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetDocumentById = (
  id: string | undefined,
  options: Record<string, unknown>
) => {
  return useQuery<IDocumentById>(
    ["get_document_by_id", id],
    async () => await fetchDocumentById(id),
    {
      ...options,
      retry: false,
    }
  );
};

export const useGetCorrespondenceEvents = (
  id: string | undefined,
  options: Record<string, unknown>
) => {
  return useQuery<TDocumentExecutionTrackerNode[]>(
    ["get_correspondence_events", id],
    async () => await fetchCorrespondenceEvents(id),
    {
      ...options,
      retry: false,
    }
  );
};

export const useGetCorrespondenceFilesById = (id: number | undefined) => {
  return useQuery<{ id: number; files: IAttachmentCorrespondenceFile[] }>(
    ["get_correspondence_files_by_id", id],
    async () => await fetchCorrespondenceFilesById(id)
  );
};

export const useCorrespondenceLinksById = (id: number | undefined) => {
  return useQuery<{ id: number; links: IAttachmentCorrespondenceLink[] }>(
    ["get_correspondence_links_by_id", id],
    async () => await fetchCorrespondenceLinksById(id)
  );
};

export const useUploadFileToCorrespondenceMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { requestBody: FormData; correspondenceId: number }) => {
      await uploadCorrespondenceFile(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence_files_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useUploadLinkToCorrespondenceMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: {
      requestBody: IAttachesTaskLinkBody;
      correspondenceId: number;
    }) => {
      await uploadCorrespondenceLinks(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence_links_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useSendToResolutionMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: {
      correspondenceId: number;
      recipientEmployeeId: number | null;
      recipientEmployeeType: ResolutionRecipientEmployeeEnums;
    }) => {
      await sendToResolution(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_document_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useCreateIncomingDocumentMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: FormData) => {
      await createIncomingDocument(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_correspondence_incoming"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCreateOutgoingDocumentMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: FormData) => {
      await createOutgoingDocument(body);
    },
    {
      onSuccess: async () => {
        // ToDo: fix the query name after implementing outgoing endpoint
        await queryClient.invalidateQueries(["get_correspondence_outgoing"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};
