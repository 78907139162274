import { UsersStatus } from "@types";

export const LangOptions = [
  { option: "Languages.ru", value: "ru" },
  { option: "Languages.en", value: "en" },
];

export const StatusOptions = [
  { option: "Status.active", value: UsersStatus.active },
  { option: "Status.deactivated", value: UsersStatus.deactivated },
];

export const PositionOptions = [{ option: "None", value: "" }];
