import styled from "styled-components";
import { FLEX_ROW } from "ui-kit";

export const TaskCardWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing(4)};

  width: 100%;
  height: 240px;

  border-radius: 16px;
  background: linear-gradient(
    91deg,
    ${({ theme }) => theme.palette.primary.main} 34.53%,
    ${({ theme }) => theme.palette.secondary.main} 100%
  );

  box-shadow: ${({ theme }) => theme.shadows[4]};
`;

export const TaskCardImage = styled.img`
  width: 100%;
  height: 100%;
`;
