import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN } from "ui-kit";

import coming_soon from "../../../../../assets/backgrounds/ComingSoon.svg";

export const WidgetComingSoonBg = styled.div`
  background-image: url(${coming_soon});
  height: 203px;
  width: calc(100% - 48px);
  margin: ${({ theme }) => theme.spacing(0, 6)};
  background-repeat: no-repeat;
  ${FLEX_BETWEEN};
  align-items: unset;
  .right__section {
    text-align: left;
  }
`;
export const WidgetComingSection = styled.div`
  ${FLEX_COLUMN};
`;
