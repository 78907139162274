import styled from "styled-components";
import { FLEX_ROW, FLEX_COLUMN_FULL } from "ui-kit";

const DOT_SIZE = 14;

export const Wrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  flex: 1;
`;

export const DotsWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: center;
  align-items: center;

  margin-top: ${({ theme }) => theme.spacing(11)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

export const Dot = styled.div`
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;

  margin: ${({ theme }) => theme.spacing()};

  background-color: ${({ theme }) => theme.palette.primary.main};

  border-radius: 50%;
  opacity: 1;

  animation: bounce 0.6s infinite alternate;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    to {
      opacity: 0.1;
      transform: translateY(-12px);
    }
  }
`;
