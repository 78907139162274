import React from "react";
import {
  Control,
  FieldNamesMarkedBoolean,
  UseFormHandleSubmit,
  UseFormReset,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUpdateExternalOrganizationStaff, useUpdateOrganization } from "api";
import { createOrganization, useAppDispatch } from "app";

import { Button } from "@sbm/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import { IOrganization, IOrganizationEmployee } from "@types";

import {
  EMPLOYEES_REQUIRED_FIELDS,
  innLength,
  kppLength,
  ogrnLength,
  okpoLength,
} from "../../constants";
import {
  getFormattedCreateOrganizationData,
  getFormattedEmployeesData,
  getFormattedUpdateOrganizationData,
} from "../helpers";

interface Props {
  reset: UseFormReset<IOrganization>;
  control: Control<IOrganization>;
  handleSubmit: UseFormHandleSubmit<IOrganization>;
  dirtyFields: FieldNamesMarkedBoolean<IOrganization>;
  isEditMode: boolean;
  isEmployeesRemoved?: boolean;
  organizationId?: number;
}

export const SubmitButton = ({
  control,
  handleSubmit,
  isEditMode,
  dirtyFields,
  organizationId,
  isEmployeesRemoved,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const fields = useWatch({ control });

  const { mutate: updateExternalOrganizationStaff, isLoading } =
    useUpdateExternalOrganizationStaff();

  const { mutate: updateOrganization, isLoading: isLoadingUpdateOrganization } =
    useUpdateOrganization(organizationId);

  const { briefTextOrganizationName, inn, kpp, ogrn, okpo, employees } = fields;

  const getRequiredFieldsFilledEmployees = (
    employees: IOrganizationEmployee[]
  ): boolean => {
    return employees.every((employee) =>
      EMPLOYEES_REQUIRED_FIELDS.every(
        (field) =>
          employee[field as keyof IOrganizationEmployee] !== undefined &&
          employee[field as keyof IOrganizationEmployee] !== null &&
          employee[field as keyof IOrganizationEmployee] !== ""
      )
    );
  };

  const getButtonDisabledState = () => {
    if (isEditMode) {
      const isTouched = Object.values(dirtyFields).length > 0;
      const isEmployeesTouched = dirtyFields.employees;

      const isEmployeesRequiredFieldFilled =
        employees &&
        employees.length > 0 &&
        getRequiredFieldsFilledEmployees(employees as IOrganizationEmployee[]);

      if (isEmployeesTouched) {
        return isTouched && isEmployeesRequiredFieldFilled;
      }

      if (isEmployeesRemoved) return isEmployeesRemoved;

      return isTouched;
    }

    return (
      Boolean(briefTextOrganizationName) &&
      inn &&
      `${inn}`.length > innLength.min - 1 &&
      `${inn}`.length < innLength.max + 1 &&
      (kpp ? `${kpp}`.length > kppLength.min - 1 : true) &&
      (ogrn ? `${ogrn}`.length > ogrnLength.min - 1 : true) &&
      (okpo ? `${okpo}`.length > okpoLength.min - 1 : true)
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    const dataToSend: IOrganization = {
      ...data,
      inn: data.inn ? String(data.inn) : undefined,
      kpp: data.kpp ? String(data.kpp) : undefined,
      ogrn: data.ogrn ? String(data.ogrn) : undefined,
      okpo: data.okpo ? String(data.okpo) : undefined,
    };

    if (isEditMode) {
      if (!organizationId) return;

      // External Staff
      if (dataToSend.employees && dataToSend.employees.length > 0) {
        const staff = getFormattedEmployeesData(dataToSend);
        updateExternalOrganizationStaff({ organizationId, staff });
      } else if (isEmployeesRemoved) {
        updateExternalOrganizationStaff({ organizationId, staff: [] });
      }

      // Organization Info
      const updateData = getFormattedUpdateOrganizationData(dataToSend);
      updateOrganization(updateData);
    } else {
      // Create Organization
      const createData = getFormattedCreateOrganizationData(dataToSend);
      await dispatch(createOrganization(createData));
    }

    void queryClient.invalidateQueries(["get_organizations_list"]);
    void queryClient.invalidateQueries(["get_organization", organizationId]);
  });

  const isDisabledSaveButton = getButtonDisabledState();

  return (
    <Button
      variant="contained"
      color="secondary"
      autoFocus
      onClick={onSubmit}
      disabled={!isDisabledSaveButton}
      loading={isLoading || isLoadingUpdateOrganization}
    >
      {t(isEditMode ? "save" : "create")}
    </Button>
  );
};
