import React, { useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { Input } from "@sbm/ui-components";
import {
  CreateTaskDefaultValuesPerUser,
  TExecutionRequirement,
  ICreateTask,
} from "@types";

import { orderTextMaxLength } from "../../../../constants";
import { CardWrapper, Wrapper } from "../styles";
import { IWorkingDateData, WorkingDay } from "./WorkingDay";

interface Props {
  form: UseFormReturn<ICreateTask>;
  requirements?: TExecutionRequirement | undefined;
  isResolutionForTheDocument?: boolean;
}

export const ExecutionRequirements = ({
  form: { control, register, setValue },
  requirements,
  isResolutionForTheDocument,
}: Props) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { t } = useTranslation("tasks");

  const { defaultValuesPerUser, executorIds } = useWatch({ control });

  const {
    personalControl,
    reportRequired,
    scheduledDateOfExecution,
    workingDaysForExecution,
    calendarDaysForExecution,
  } = defaultValuesPerUser as CreateTaskDefaultValuesPerUser;

  React.useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
  }, [isFirstRender]);

  const handleWorkingDateChanged = (data: IWorkingDateData) => {
    setValue("defaultValuesPerUser.scheduledDateOfExecution", data.date);
    setValue("defaultValuesPerUser.workingDaysForExecution", data.workingDays);
    setValue(
      "defaultValuesPerUser.calendarDaysForExecution",
      data.calendarDays
    );
  };

  return (
    <CardWrapper>
      <Typography variant="h9_semiBold" color="text.disabled">
        {t("execution_requirements")}
      </Typography>
      <Wrapper>
        <Input
          label={t("order_text")}
          variant="outlined"
          required
          maxLength={orderTextMaxLength}
          size="medium"
          multiline
          {...register("defaultValuesPerUser.textOfTheTask")}
        />
        <WorkingDay
          scheduledDateOfExecution={scheduledDateOfExecution}
          workingDaysForExecution={workingDaysForExecution}
          calendarDaysForExecution={calendarDaysForExecution}
          onChange={handleWorkingDateChanged}
          requirements={requirements}
          isResolutionForTheDocument={isResolutionForTheDocument}
        />
        <Grid container>
          <Grid item>
            <FormControlLabel
              label={t("personal_control")}
              control={
                <Switch
                  color="secondary"
                  {...register("defaultValuesPerUser.personalControl")}
                />
              }
              checked={personalControl}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label={t("report_required")}
              control={
                <Switch
                  color="secondary"
                  {...register("defaultValuesPerUser.reportRequired")}
                />
              }
              checked={reportRequired}
            />
          </Grid>
        </Grid>
      </Wrapper>
    </CardWrapper>
  );
};
