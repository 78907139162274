import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker as MuiDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getStorageItem, isValidDate, STORAGE_KEYS } from "@sbm/fe-utils";
import { Chip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from "date-fns";

const locale = getStorageItem(STORAGE_KEYS.lang);
const isEnglish = locale === "en";

const dateFormat = `dd/${isEnglish ? "MMM" : "MM"}/yyyy`;

export interface FilterRangePickerProps {
  onChange: (date: string) => void;
}

export const FilterRangePicker = (props: FilterRangePickerProps) => {
  const { onChange } = props;

  const { t } = useTranslation();

  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  const handleTodayClick = () => {
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const handleThisWeekClick = () => {
    const dateCopy = new Date();
    const startOfThisWeek = startOfWeek(dateCopy, { weekStartsOn: 1 });
    const endOfTheWeek = endOfWeek(dateCopy, { weekStartsOn: 1 });

    setStartDate(startOfThisWeek);
    setEndDate(endOfTheWeek);
  };

  const handleThisMonthClick = () => {
    const dateCopy = new Date();
    const startOfTheMonth = startOfMonth(dateCopy);
    const endOfTheMonth = endOfMonth(dateCopy);

    setStartDate(startOfTheMonth);
    setEndDate(endOfTheMonth);
  };

  React.useEffect(() => {
    if (!startDate || !endDate || !isValidDate(startDate) || !isValidDate(endDate)) return;

    onChange(format(startDate, dateFormat) + " | " + format(endDate, dateFormat));
  }, [startDate, endDate, onChange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item>
          <Chip label={t("today")} onClick={handleTodayClick} />
        </Grid>
        <Grid item>
          <Chip label={t("this.week")} onClick={handleThisWeekClick} />
        </Grid>
        <Grid item>
          <Chip label={t("this.month")} onClick={handleThisMonthClick} />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <MuiDatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
            format={dateFormat}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                variant: "standard",
                color: "info",
              },
            }}
            label="start"
          />
        </Grid>
        <Grid item xs={12}>
          <MuiDatePicker
            value={endDate}
            onChange={(date) => setEndDate(date)}
            format={dateFormat}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                variant: "standard",
                color: "info",
              },
            }}
            label="end"
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};
