import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TEditStructuralUnitForm, useAppDispatch } from "app";
import { Table } from "ui-kit";

import {
  GridColDef,
  GridRowOrderChangeParams,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { IStructuralUnitTree } from "@types";

export interface PositionsTableProps {
  branch: IStructuralUnitTree | null;
  isEditMode: boolean;
  organizationId?: string;
  currentStructuralUnitId?: number;
  setValue: UseFormSetValue<TEditStructuralUnitForm>;
}

const getRows = (data: IStructuralUnitTree | null) => {
  if (!data?.parentWithChildren) return [];

  const result = [...data.parentWithChildren.children];
  return result as GridValidRowModel[];
};

export const PositionsTable = (props: PositionsTableProps) => {
  const {
    branch,
    isEditMode,
    organizationId,
    currentStructuralUnitId,
    setValue,
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const apiRef = useGridApiRef();

  const [loading, setLoading] = React.useState(false);

  const [rows, setRows] = React.useState<GridValidRowModel[]>(getRows(branch));

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "nameOfStructuralUnit",
        headerName: t("name.of.the.structural.unit") as string,
        flex: 1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const isCurrent = branch?.currentStructuralUnit.id === params.row.id;

          return (
            <div className="MuiDataGrid-cellContent">
              <span style={{ fontWeight: isCurrent ? 700 : 400 }}>
                {params.row.nameOfStructuralUnit}
              </span>
            </div>
          );
        },
      },
      {
        field: "numberForChildList",
        headerName: t("position.in.the.list") as string,
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
    ],
    [branch?.currentStructuralUnit.id, t]
  );

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    if (!params.row || !organizationId || !currentStructuralUnitId) return;

    setLoading(true);

    const newRows = apiRef.current?.getSortedRows?.() || [];
    const newRowsSorted = newRows.map((row, index) => ({
      id: row.id,
      nameOfStructuralUnit: row.nameOfStructuralUnit,
      numberForChildList: index + 1,
    }));

    const orders = newRowsSorted.map((i) => {
      return {
        structuralUnitId: i.id,
        numberForChildList: i.numberForChildList,
      };
    });

    setRows(newRowsSorted);
    setValue("orders", orders);

    setLoading(false);
  };

  return (
    <Table
      apiRef={apiRef}
      rowReordering={isEditMode}
      onRowOrderChange={handleRowOrderChange}
      loading={loading}
      rows={rows}
      columns={columns}
      hasToolbar={false}
      hideFooterRowCount
    />
  );
};
