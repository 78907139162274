import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

export const StyledGridOverlay = styled.div`
  ${FLEX_COLUMN_FULL};
  margin-top: ${({ theme }) => theme.spacing(5)};

  svg {
    width: 548px;
    height: 231px;
  },
`;
