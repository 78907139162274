import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";

export const Wrapper = styled(Card)`
  ${FLEX_COLUMN};
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const FieldsWrapper = styled.div<{
  $isMaximized?: boolean;
}>`
  display: ${({ $isMaximized }) => ($isMaximized ? "flex" : "")};
  gap: ${({ theme }) => theme.spacing(4)};
  .numberOfAppendices {
    margin-bottom: ${({ $isMaximized, theme }) =>
      theme.spacing($isMaximized ? 0 : 4)};
  }
  .storageOrderForOriginal {
    margin-bottom: ${({ theme }) => theme.spacing()};
  }
`;
