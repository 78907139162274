import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_ROW_FULL } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const AttachedLink = styled.div`
  ${FLEX_BETWEEN};
  width: 100%;
  height: 32px;
  padding: ${({ theme }) => theme.spacing(2, 1.5)};
  margin: ${({ theme }) => theme.spacing(2, 1.5)};
  cursor: pointer;
}

  .attached_link_item {
    ${FLEX_ROW_FULL};
  }

  &:hover {
    background-color: ${({ theme }) =>
      hexToRgba(theme.palette.secondary.main, 0.04)};
  }
}
`;
