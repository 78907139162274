import { createSlice } from "@reduxjs/toolkit";

export interface IModal {
  open: boolean;
  variant: string;
  content?: any;
}

export const initialState: {
  modal: IModal | null;
} = {
  modal: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload;
    },
  },
});

export const { setModal } = modalSlice.actions;

export default modalSlice.reducer;
