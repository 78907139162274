import styled from "styled-components";

import { hexToRgba } from "@sbm/fe-utils";

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};

  a,
  p {
    font-size: 14px;
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }

  a::first-letter {
    text-transform: uppercase;
  }

  a {
    color: ${({ theme }) => theme.palette.common.white};
    opacity: 0.7;
    text-decoration: none;

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .chevron {
    opacity: 0.7;
  }
`;

export const BreadcrumbTrigger = styled.div<{ opened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  margin: ${({ theme }) => theme.spacing(0, 4, 0, 3)};
  border-radius: ${({ theme }) => theme.spacing(2)};

  background-color: ${({ opened, theme }) =>
    opened ? theme.palette.primary.dark : theme.palette.primary.main};

  &:hover {
    background-color: ${({ opened, theme }) =>
      opened
        ? theme.palette.primary.darkHover
        : hexToRgba(theme.palette.primary.contrastText, 0.08)};

    cursor: pointer;
  }
`;

export const BreadcrumbPathsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
