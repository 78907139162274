import { Palette } from "@mui/material";
import { TIconNames, TypeEventEnum } from "@types";

// ToDo: Add hardcoded numbers to TypeEventEnum after BE will do it
export const getIconName = (typeEventId: number): TIconNames | null => {
  switch (typeEventId) {
    case TypeEventEnum.CreateTask:
    case TypeEventEnum.CreateIncomingDocument:
    case TypeEventEnum.CreateOutgoingDocument:
    case TypeEventEnum.CreateServiceNote:
      return "FilePlus2";
    case TypeEventEnum.DelegateTask:
      return "CornerDownRight";
    case TypeEventEnum.EditTaskAssignee:
    case 31:
    case 32:
      return "Pencil";
    case TypeEventEnum.EditTaskDueDate:
    case 33:
      return "Calendar";
    case TypeEventEnum.DeleteTask:
      return "Trash2";
    case TypeEventEnum.SendTaskToAssignee:
    case TypeEventEnum.SendIncomingDocumentForResolution:
      return "Send";
    case TypeEventEnum.TaskAccepted:
      return "Check";
    case TypeEventEnum.MarkTaskAsPersonallyCompleted:
    case TypeEventEnum.AutoMarkTaskAsCompleted:
    case TypeEventEnum.ApproveTaskCompletion:
    case TypeEventEnum.CompleteDocumentExecution:
    case TypeEventEnum.IncomingDocumentExecutionOfAllResolutions:
      return "CheckCheck";
    case TypeEventEnum.AutoMarkTaskAsRemovedFromControl:
      return "Settings";
    case TypeEventEnum.ReturnTaskForRework:
      return "Reply";
    case TypeEventEnum.NotifyAssigneeOfReturnForRework:
    case TypeEventEnum.NotifyAssigneeOfOverdueTask:
    case TypeEventEnum.NotifyAuthorOfOverdueTask:
    case TypeEventEnum.NotifyAssigneeOfDueDateChangeRepeat:
    case TypeEventEnum.NotifyAuthorOfTaskRejection:
    case TypeEventEnum.NotifyAuthorOfTaskCompletion:
    case TypeEventEnum.NotifyAssigneeOfDueDateChange:
    case TypeEventEnum.NotifyAssigneeOfAssigneeChange:
    case TypeEventEnum.NotifyAuthorOfExecutionInterruption:
    case TypeEventEnum.NotifyAssigneeOfNewTask:
    case 34:
    case 38:
      return "Info";
    case TypeEventEnum.RejectTask:
    case TypeEventEnum.CancellationOfAPreviouslyIssuedResolution:
      return "XCircle";
    case TypeEventEnum.IssueResolutionOnDocument:
      return "FileSignature";
    case TypeEventEnum.AttachFile:
      return "Paperclip";
    case TypeEventEnum.ViewTaskByAssignee:
    case TypeEventEnum.ViewTaskByAuthor:
      return "Eye";
    case TypeEventEnum.AttachLinkToDocument:
      return "Link";
    case TypeEventEnum.TaskExecutionInterrupted:
      return "Unplug";
    default:
      return null;
  }
};

export const getIconBGColor = (typeEventId: number, palette: Palette) => {
  switch (typeEventId) {
    case TypeEventEnum.EditTaskDueDate:
    case TypeEventEnum.DeleteTask:
    case TypeEventEnum.AttachLinkToDocument:
    case TypeEventEnum.AttachFile:
    case TypeEventEnum.EditTaskAssignee:
    case 31:
    case 32:
    case 33:
    case 42:
      return palette.secondary.main;

    case TypeEventEnum.MarkTaskAsPersonallyCompleted:
    case TypeEventEnum.ApproveTaskCompletion:
    case TypeEventEnum.CompleteDocumentExecution:
    case TypeEventEnum.IncomingDocumentExecutionOfAllResolutions:
      return palette.success.main;

    case TypeEventEnum.AutoMarkTaskAsCompleted:
    case TypeEventEnum.AutoMarkTaskAsRemovedFromControl:
      return palette.primary.contrastText;

    case TypeEventEnum.ReturnTaskForRework:
    case TypeEventEnum.RejectTask:
    case TypeEventEnum.TaskExecutionInterrupted:
    case TypeEventEnum.CancellationOfAPreviouslyIssuedResolution:
      return palette.error.main;
    default:
      return palette.primary.main;
  }
};

export const getIconBorderColor = (typeEventId: number, palette: Palette) => {
  switch (typeEventId) {
    case TypeEventEnum.AutoMarkTaskAsRemovedFromControl:
      return palette.primary.main;
    case TypeEventEnum.AutoMarkTaskAsCompleted:
      return palette.success.main;
    default:
      return "";
  }
};

export const getIconColor = (typeEventId: number, palette: Palette) => {
  switch (typeEventId) {
    case TypeEventEnum.AutoMarkTaskAsRemovedFromControl:
      return palette.primary.main;
    case TypeEventEnum.AutoMarkTaskAsCompleted:
      return palette.success.main;
    default:
      return palette.primary.contrastText;
  }
};
