import React from "react";

import { Modal } from "ui-kit";

import { FrameworkContractContainer } from "../../../../containers";

export const FrameworkContractPopup = (props: {
  open: boolean;
  onClose: () => void;
  data: {
    addFrameworkContract: (e: string) => void;
    handleFrameworkContractDateChange: (d?: Date) => void;
    contractCategoryId: number;
  };
}) => {
  const {
    open,
    onClose,
    data: {
      addFrameworkContract,
      contractCategoryId,
      handleFrameworkContractDateChange,
    },
  } = props;

  const handleSetFrameworkContractDate = (date?: Date) => {
    handleFrameworkContractDateChange(date);
    onClose();
  };

  const handleChooseContract = (id: string) => {
    addFrameworkContract(id);
    onClose();
  };

  const content = {
    title: "",
    body: (
      <FrameworkContractContainer
        handleChooseContract={handleChooseContract}
        handleSetDate={handleSetFrameworkContractDate}
        contractCategoryId={contractCategoryId}
      />
    ),
    secondaryButton: null,
    mainButton: null,
  };

  return <Modal isOpen={open} onClose={onClose} content={content} sizeLarge />;
};
