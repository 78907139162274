import { IPersonalData } from "./organizations";

export enum UsersStatus {
  active = "active",
  deactivated = "deactivated",
}

export interface IUser {
  id?: number;
  name: string;
  surname: string;
  patronymic: string;
  gender: "male" | "female";
  hasRigthToCreatePresignedContract: boolean;
  headOfStructuralUnit: boolean;
  phoneNumber?:
    | string
    | {
        phoneNumber: string;
      };
  staff: boolean;
  status: UsersStatus;
  preferableLanguage: string;
  position?: string;
  createdAt?: string;
  notifyOldEmail?: boolean;
  emailVerified?: boolean;
  passwordSet?: boolean;
  keycloakId: string;
  personalDataId?: number;
  emailId: number;
  phoneNumberId: null;
  positionId: null;
  updatedAt: string;
  deletedAt: null;
  email:
    | {
        id: number;
        email: string;
        additionalInformation: null;
        createdAt: string;
        updatedAt: string;
      }
    | string;
  personalData?: IPersonalData;
}

export interface IUserRole {
  id: number;
  userId: number;
  roleId: number;
  assignedByEmployeeId: number;
  deactivatedByEmployeeId: number | null;
  deactivationDate: string | null;
  expirationDate: string;
  temporaryRole: boolean;
  validity: boolean;
  additionalInformation: string;
  createdAt: string;
  updatedAt: string;
  role: {
    id: number;
    nameRole: string;
    validity: boolean;
    additionalInformation: string;
    createdAt: string;
    updatedAt: string;
  };
}
