import { EnumEmployeeType, ICreateEmployee } from "@types";

export const getStructuredPayload = (
  data: ICreateEmployee,
  isViewMode?: boolean
) => {
  delete data["structuralUnitId"];
  delete data["positionId"];

  if (data.validity === true) {
    delete data["validity"];
  }

  if (!isViewMode) {
    delete data["employeeIdUpdate"];
  }

  Object.keys(data).forEach((key) => {
    if (key === "additionalInformation" && isViewMode) return;

    // @ts-ignore
    if (!data[key] && data[key] !== false) {
      // @ts-ignore
      delete data[key];
    }
  });

  if (data.employeeType === EnumEmployeeType.employee) {
    data["personalDataId"] = data.employeeId;
    delete data["employeeId"];
  }

  if (data.employeeType === EnumEmployeeType.substitution) {
    delete data["additionalInformation"];
  }

  return data;
};
