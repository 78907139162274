import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { CreateStructuralUnitContainer } from "../../../../containers";

export const CreateStructuralUnitPopup = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { open, onClose } = props;

  const content = {
    title: t("adding.structural.unit"),
    body: <CreateStructuralUnitContainer />,
    secondaryButton: null,
    mainButton: null,
  };

  return <Modal isOpen={open} onClose={onClose} content={content} sizeLarge />;
};
