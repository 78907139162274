export const getFilterParams = (
  filter: (string | undefined)[],
  dateFilters?: string[],
  type?: "incoming" | "outgoing"
) => {
  const filterParams: { [key: string]: string | string[] } = {};

  if (filter.length > 0) {
    filter.forEach((item) => {
      if (!item || item.includes("NaN") || item.includes("null")) return;

      const splitName = item?.split("=");

      if (splitName[1]) {
        const key =
          splitName[0] === "read"
            ? type === "incoming"
              ? "filter.taskViewedByExecutor"
              : "filter.neededToBeViewedByAuthor"
            : `filter.${splitName[0]}`;
        const result: string[] = [];

        splitName[1].split(",").forEach((i) => {
          result.push(i);
        });

        filterParams[key] = result;
      }
    });
  }

  const dateParams: typeof filterParams = {};

  if (dateFilters && dateFilters.length > 0) {
    dateFilters.forEach((dateFilter) => {
      if (filterParams[dateFilter]) {
        dateParams[dateFilter] = filterParams[dateFilter][0];

        delete filterParams[dateFilter];
      }
    });
  }

  return {
    ...dateParams,
    ...filterParams,
  };
};

export const getFiltersQueryCacheKey = (filters: (string | undefined)[]) => {
  return filters.filter((filter) => {
    const [key, value] = (filter || "=").split("=");
    return Boolean(key) && Boolean(value);
  });
};
