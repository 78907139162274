import React from "react";

import { useTheme } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import { TableProps } from "../../types";
import { TableFilters } from "../TableFilters";

export const CustomToolbar: React.FC<
  Pick<
    TableProps,
    | "filterableFields"
    | "onSearchOptionChange"
    | "onFilterChange"
    | "filteringButtons"
  >
> = ({
  onSearchOptionChange,
  onFilterChange,
  filterableFields,
  filteringButtons,
}) => {
  const theme = useTheme();

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: filteringButtons ? "initial" : "space-between",
        py: 4,
      }}
    >
      {filteringButtons}
      <TableFilters
        filterableFields={filterableFields}
        onSearchOptionChange={onSearchOptionChange}
        onFilterChange={onFilterChange}
      />

      <div>
        <GridToolbarColumnsButton
          sx={{
            color: theme.palette.secondary.main,
          }}
        />
        <GridToolbarExport
          printOptions={{
            hideToolbar: true,
            hideFooter: true,
            allColumns: true,
          }}
          sx={{ color: theme.palette.secondary.main }}
        />
      </div>
    </GridToolbarContainer>
  );
};
