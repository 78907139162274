export function formatSize(sizeInBytes: number): string {
  let result: string;

  switch (true) {
    case sizeInBytes < 1024: {
      result = `${sizeInBytes} B`;
      break;
    }
    case sizeInBytes < 1024 * 1024: {
      const sizeInKB = sizeInBytes / 1024;
      result = `${sizeInKB.toFixed(2)} KB`;
      break;
    }
    default: {
      const sizeInMB = sizeInBytes / (1024 * 1024);
      result = `${sizeInMB.toFixed(2)} MB`;
    }
  }

  return result;
}

export function getRandomValue(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function createObjectURL(file: File) {
  if (!file.type) {
    return undefined;
  } else return URL.createObjectURL(file) || "";
}
