import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const Wrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(4, 0)};
`;

export const LinksItemWrapper = styled.div`
  background-color: ${({ theme }) =>
    hexToRgba(theme.palette.secondary.main, 0.04)};

  border-radius: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2, 4)};

  .link_delete_icon {
    ${FLEX_ROW_ALIGN_CENTER}
  }
`;
