import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useSendToResolutionMutation } from "api";
import { Modal } from "ui-kit";

import { Button } from "@sbm/ui-components";
import {
  IDocumentById,
  ISendResolutionForm,
  ResolutionRecipientEmployeeEnums,
} from "@types";

import { SendToResolutionContainer } from "../../../../containers";

export const SendToResolutionPopup = (props: {
  open: boolean;
  onClose: () => void;
  data: { data: IDocumentById };
}) => {
  const { open, onClose, data } = props;
  const { t } = useTranslation("correspondence");

  const { register, reset, control, handleSubmit, setValue, getValues } =
    useForm<ISendResolutionForm>({
      defaultValues: {
        recipientEmployeeType: ResolutionRecipientEmployeeEnums.CURRENT,
        resCheckbox: false,
      },
    });

  const { recipientEmployeeType, recipientEmployeeId } = useWatch({
    control,
  });

  const handleConfirmTransition = () => {
    reset();
    onClose();
  };

  const { mutate: sendToResolution, isLoading } = useSendToResolutionMutation(
    handleConfirmTransition,
    handleConfirmTransition
  );

  const onSubmit = handleSubmit(async (formData) => {
    const requestData = {
      correspondenceId: data.data.id,
      recipientEmployeeType: formData.recipientEmployeeType,
      recipientEmployeeId: formData.recipientEmployeeId,
    };
    sendToResolution(requestData);
  });

  const getDisableState = () => {
    if (recipientEmployeeType === ResolutionRecipientEmployeeEnums.OTHER) {
      return !recipientEmployeeId;
    }
    return false;
  };

  const content = {
    title: t("sendToResolution.select_addressee"),
    body: (
      <SendToResolutionContainer
        data={data.data}
        register={register}
        control={control}
        setValue={setValue}
      />
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={onSubmit}
        loading={isLoading}
        disabled={getDisableState()}
      >
        {t("sendToResolution.confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
