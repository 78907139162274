import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCompleteExecutionMutation } from "api";
import { Modal } from "ui-kit";

import { Input, Button } from "@sbm/ui-components";

export const CompleteExecutionPopup = ({
  open,
  onClose,
  data: {
    data: { id },
  },
}: {
  open: boolean;
  onClose: () => void;
  data: { data: { id: number } };
}) => {
  const { t } = useTranslation("tasks");

  const [comment, setComment] = useState("");

  const { mutate: completeExecution, isLoading } =
    useCompleteExecutionMutation(onClose);

  const handleCompleteExecution = () => {
    completeExecution({ id, comment });
  };

  const content = {
    title: t("complete.reason"),
    body: (
      <Input
        label={t("comment")}
        multiline
        required
        maxLength={1000}
        onChange={(e) => setComment(e.target.value)}
        sx={{ my: 2 }}
      />
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        onClick={handleCompleteExecution}
        loading={isLoading}
        disabled={!comment.trim().length}
      >
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
