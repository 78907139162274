import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN_FULL, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const IconWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.spacing(0, 0, 3, 3)};
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const WidgetHeaderWrapper = styled.div`
  ${FLEX_BETWEEN};
  min-height: 72px;
  .widget_icon_title {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(4)};
  }
`;
