import React, { useEffect, useState } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { FormRadio } from "@sbm/ui-components";
import {
  ExecutorType,
  ICreateTask,
  IExecutorOption,
  PotentialExecutor,
  SubordinationType,
  TaskExecutor,
} from "@types";

import { haveSameOrganizationName } from "../../../../helpers";
import { CardWrapper, ExecutorWrapper } from "../styles";

interface Props {
  form: UseFormReturn<ICreateTask>;
  data: PotentialExecutor[];
  selectedOptions: IExecutorOption[];
  setSelectedOptions: (arg: IExecutorOption[]) => void;
}

export const Executors = ({
  form: { control, setValue, getValues },
  data,
  setSelectedOptions,
  selectedOptions,
}: Props) => {
  const theme = useTheme();
  const [allSubordinates, setAllSubordinates] = useState<PotentialExecutor[]>(
    []
  );
  const [immediateSubordinates, setImmediateSubordinates] = useState<
    PotentialExecutor[]
  >([]);

  const { executorIds } = useWatch({ control });

  useEffect(() => {
    if (data.length) {
      setAllSubordinates(data);
      setImmediateSubordinates(
        data.filter((d) => d.subordinationType === SubordinationType.immediate)
      );
    }
  }, [data]);

  const handleSelectChange = (
    event: React.SyntheticEvent,
    option: IExecutorOption
  ) => {
    const selectedIndex = selectedOptions.findIndex(
      (selectedOption) =>
        selectedOption.uniqueId === option.uniqueId &&
        selectedOption.position === option.position &&
        selectedOption.organizationName === option.organizationName
    );
    const newSelected = [...selectedOptions];

    if (selectedIndex === -1) {
      newSelected.push(option);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelectedOptions(newSelected);
    handleExecutorsChange(event, newSelected);
  };

  const handleExecutorsChange = (
    _: React.SyntheticEvent,
    values: IExecutorOption[]
  ) => {
    setValue("executorIds", values as unknown as TaskExecutor[], {
      shouldDirty: true,
    });
  };

  const { t } = useTranslation("tasks");

  const { remove } = useFieldArray({
    control,
    name: "executorIds",
  });

  const allSubordinatesOptions = allSubordinates.map((i) => ({
    title: i.personalData.fullName,
    value: i.id,
    key: i.id,
    uniqueId: i.employeeId,
    id: i.personalData.id,
    position: i.position,
    externalSubstitutionId: i.externalSubstitutionId,
    authorEmployeeId: i.author.employeeId,
    organizationId: i.organization.id,
    organizationName: i.organization.name,
  }));

  const immediateSubordinatesOptions = immediateSubordinates.map((i) => ({
    title: i.personalData.fullName,
    value: i.id,
    key: i.id,
    uniqueId: i.employeeId,
    id: i.personalData.id,
    position: i.position,
    externalSubstitutionId: i.externalSubstitutionId,
    authorEmployeeId: i.author.employeeId,
    organizationId: i.organization.id,
    organizationName: i.organization.name,
  }));

  const getOptions = () => {
    if (!immediateSubordinatesOptions.length) {
      return allSubordinatesOptions;
    } else {
      return getValues("structuralUnitType") ===
        ExecutorType.Immediate_subordinates
        ? immediateSubordinatesOptions
        : allSubordinatesOptions;
    }
  };

  const getDefaultValues = () => {
    const options = getOptions();
    const filteredByUniqId = options?.filter((executor) =>
      executorIds?.map((e) => e.uniqueId).includes(executor.uniqueId)
    );
    const filteredByOrganizationName = filteredByUniqId?.filter((executor) =>
      executorIds
        ?.map((e) => e.organizationName)
        .includes(executor.organizationName)
    );
    const filteredByPosition = filteredByOrganizationName?.filter((executor) =>
      executorIds?.map((e) => e.position).includes(executor.position)
    );
    return filteredByPosition;
  };

  const RadioGroupsData = [
    {
      option: t("immediate_subordinates"),
      value: ExecutorType.Immediate_subordinates,
    },
    {
      option: t("all_subordinates"),
      value: ExecutorType.All_subordinates,
    },
  ];

  const isSameOrganizationName = haveSameOrganizationName(getOptions());

  return (
    <CardWrapper>
      <Typography variant="h9_semiBold" color="text.disabled">
        {t("executors")}
      </Typography>
      <>
        {immediateSubordinates.length ? (
          <Stack
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            paddingRight={30}
          >
            <FormRadio
              name="structuralUnitType"
              control={control}
              values={RadioGroupsData}
              fullWidth={false}
            />
          </Stack>
        ) : null}
        {data ? (
          <ExecutorWrapper>
            <Autocomplete
              multiple
              sx={{ pt: !immediateSubordinates.length ? 0 : 0 }}
              options={getOptions()}
              value={getDefaultValues() || selectedOptions}
              onChange={(_, newValue, reason) => {
                if (reason === "clear") {
                  setSelectedOptions([]);
                  setValue("executorIds", []);
                }

                setSelectedOptions(newValue);
              }}
              getOptionLabel={(option) => option.title || ""}
              disableCloseOnSelect
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={props.id}
                  onClick={(event) => handleSelectChange(event, option)}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        onChange={(event) => handleSelectChange(event, option)}
                        checked={
                          selectedOptions.some((o: IExecutorOption) =>
                            Boolean(
                              o.uniqueId === option.uniqueId &&
                                o.position === option.position &&
                                o.organizationName === option.organizationName
                            )
                          ) ||
                          getDefaultValues()?.some(
                            (o) =>
                              o.uniqueId === option.uniqueId &&
                              o.position === option.position &&
                              o.organizationName === option.organizationName
                          )
                        }
                      />
                    }
                    label={
                      <Stack
                        flexDirection="column"
                        justifyContent="center"
                        gap={1}
                        marginLeft={8}
                      >
                        <Typography
                          color="text.primary"
                          variant="subtitle1"
                          sx={{ lineHeight: "16px" }}
                        >
                          {option.title}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={hexToRgba(theme.palette.text.primary, 0.7)}
                          sx={{ lineHeight: "16px" }}
                        >
                          {option.position}
                        </Typography>
                        {!isSameOrganizationName ? (
                          <Typography
                            variant="subtitle2"
                            color={hexToRgba(theme.palette.text.primary, 0.7)}
                            sx={{ lineHeight: "16px" }}
                          >
                            {option.organizationName}
                          </Typography>
                        ) : null}
                      </Stack>
                    }
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  label={t("executor(s)")}
                />
              )}
              renderTags={(values) =>
                values.map((value) => (
                  <Chip
                    key={value.uniqueId}
                    label={value.title}
                    onDelete={() => {
                      remove(
                        selectedOptions.findIndex(
                          (f) =>
                            f.id === value.id &&
                            f.organizationName === value.organizationName &&
                            f.position === value.position
                        )
                      );

                      const a = selectedOptions.findIndex(
                        (f) =>
                          f.id === value.id &&
                          f.organizationName === value.organizationName &&
                          f.position === value.position
                      );
                      const newSelected = [...selectedOptions];

                      newSelected.splice(a, 1);
                      setSelectedOptions(newSelected);
                    }}
                  />
                ))
              }
            />
          </ExecutorWrapper>
        ) : (
          <Stack
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={80}
          >
            Loading...
          </Stack>
        )}
      </>
    </CardWrapper>
  );
};
