import { createSlice } from "@reduxjs/toolkit";

interface IInstruction {
  id: number;
  fileId: number;
  additionalInformation: string;
  createdAt: string;
  updatedAt: string;
  file: {
    id: number;
    fileAttachedById: number;
    fileNameForUsers: string;
    fileNameForSystem: string;
    fileFormat: string;
    fileUploadDate: string;
    bucket: string;
    additionalInformation: null;
  };
}
interface IInitialState {
  instructions: { items: IInstruction[]; meta: { totalItems: number } } | null;
}

export const initialStateInstructions: IInitialState = {
  instructions: null,
};

export const instructionsSlice = createSlice({
  name: "instructions",
  initialState: initialStateInstructions,
  reducers: {
    setInstructions: (state, action) => {
      state.instructions = action.payload;
    },
  },
});

export const { setInstructions } = instructionsSlice.actions;

export default instructionsSlice.reducer;

export * from "./operations";
