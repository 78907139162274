import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "@types";

interface IUsersState {
  users: { items: IUser[]; meta: { totalItems: number } } | null;
  userById: IUser | null;
}

export const initialStateUsers: IUsersState = {
  users: null,
  userById: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialStateUsers,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUserById: (state, action) => {
      state.userById = action.payload;
    },
  },
});

export const { setUsers, setUserById } = usersSlice.actions;

export default usersSlice.reducer;

export * from "./operations";
