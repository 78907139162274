import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { axiosService } from "app";
import { Table } from "ui-kit";

import {
  GridColDef,
  GridRowOrderChangeParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { ICreateStaffUnit, IStaffUnit } from "@types";

export interface StaffPositionsTableProps {
  isEditMode: boolean;
  organizationId?: string;
  staffUnitId?: number;
  setValue: UseFormSetValue<ICreateStaffUnit>;
}

const getStaffUnit = async (staffUnitId: number) => {
  try {
    const { data } = await axiosService({
      endpoint: `staff-units/${staffUnitId}`,
    });

    return data;
  } catch (e) {
    console.log(e, "eee");
  }
};

export const StaffPositionsTable = (props: StaffPositionsTableProps) => {
  const { isEditMode, organizationId, staffUnitId, setValue } = props;

  const { t } = useTranslation();

  const apiRef = useGridApiRef();

  const [loading, setLoading] = React.useState(false);
  const [staffUnit, setStaffUnit] = React.useState<{
    currentStaffUnit?: IStaffUnit;
    staffUnits: IStaffUnit[];
  } | null>(null);

  const [headStaffUnit, setHeadStaffUnit] = React.useState<{
    staffUnitId: number;
    numberForTheList: number;
  } | null>(null);

  const rows = React.useMemo(() => {
    if (!staffUnit?.staffUnits?.length) return [];

    return staffUnit.staffUnits.filter((i) => {
      const isHead = i.headOfStructuralUnit;

      if (isHead) {
        setHeadStaffUnit({
          staffUnitId: i.id,
          numberForTheList: i.numberForTheList,
        });
        return false;
      }

      return true;
    });
  }, [staffUnit]);

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "positionName",
        headerName: t("staff_position.name") as string,
        flex: 1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const isCurrent = staffUnit?.currentStaffUnit?.id === params.row.id;
          return (
            <div className="MuiDataGrid-cellContent">
              <span style={{ fontWeight: isCurrent ? 700 : 400 }}>
                {params.row.positionName}
              </span>
            </div>
          );
        },
      },
      {
        field: "numberForTheList",
        headerName: t("position.in.the.list") as string,
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
    ],
    [staffUnit, t]
  );

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    if (!params.row || !organizationId || !staffUnitId) return;

    setLoading(true);

    const newRows = apiRef.current?.getSortedRows?.() || [];
    const newRowsSorted = newRows.map((row, index) => ({
      id: row.id,
      positionName: row.positionName,
      numberForTheList: index + 1,
    })) as IStaffUnit[];

    const staffUnitToSet = {
      currentStaffUnit: staffUnit?.currentStaffUnit,
      staffUnits: newRowsSorted,
    };

    setStaffUnit(staffUnitToSet);

    const orders = newRowsSorted.map((i) => {
      return {
        staffUnitId: i.id,
        numberForTheList: i.numberForTheList,
      };
    });

    const valueToSet = headStaffUnit
      ? [
          headStaffUnit,
          ...orders.map((i) => ({
            ...i,
            numberForTheList: i.numberForTheList + 1,
          })),
        ]
      : orders;

    setValue("orders", valueToSet);

    setLoading(false);
  };

  React.useEffect(() => {
    if (!staffUnitId) return;

    setLoading(true);

    getStaffUnit(staffUnitId)
      .then((resp) => setStaffUnit(resp))
      .finally(() => setLoading(false));
  }, [staffUnitId]);

  return (
    <Table
      apiRef={apiRef}
      hideFooterRowCount
      rowReordering={isEditMode}
      onRowOrderChange={handleRowOrderChange}
      loading={loading}
      rows={rows}
      columns={columns}
      hasToolbar={false}
    />
  );
};
