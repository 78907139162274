import React from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "app";
import { Modal } from "ui-kit";

import { Button } from "@mui/material";

export const EditRolePopup = (props: {
  open: boolean;
  onClose: () => void;
  data?: any;
}) => {
  const {
    open,
    onClose,
    data: { data },
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const content = {
    title: t("permissionsSetPage.confirm_title"),
    body: t("rolesPage.edit_confirm_text"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          data.handleSave();
          onClose();
        }}
      >
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
