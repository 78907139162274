import { COLUMN_SIZE } from "@sbm/fe-utils";

export const COLUMNS = [
  {
    field: "structuralUnitType",
    width: COLUMN_SIZE * 3,
    headerName: "type.of.structural.unit",
    sortable: false,
    filterable: false,
  },
  {
    field: "validity",
    width: COLUMN_SIZE * 2,
    headerName: "effective.structural.unit",
    sortable: false,
    filterable: false,
  },
];
