import { UI_CONFIG } from "app";

import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  styled,
} from "@mui/material";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  height: UI_CONFIG.header,
  width: `calc(100% - ${
    open ? UI_CONFIG.drawerWidth : UI_CONFIG.drawerMobileWidth
  }px)`,
  display: "flex",
  justifyContent: "center",
  zIndex: theme.zIndex.appBar + 1,
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
