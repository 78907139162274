import styled from "styled-components";

import { FLEX_BETWEEN, FLEX_ROW_ALIGN_CENTER } from "../../theme";

export const ToggleSectionHeader = styled.div`
  ${FLEX_BETWEEN};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
}}`;

export const ToggleSectionTitle = styled.div<{ $isDisabled: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "initial" : "pointer")};
  .expand__icon {
    cursor:  ${({ $isDisabled }) =>
      $isDisabled ? "initial" : "pointer !important"};
}}`;
export const ToggleSectionAction = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  height: ${({ theme }) => theme.spacing(6)};
}}`;
