import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(2)};

  min-width: ${UI_CONFIG.modalWidth}px;
  padding: ${({ theme }) => theme.spacing(4)};

  background-color: ${({ theme }) => theme.palette.grey[100]};
`;

export const ActionsWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(3)};

  margin-top: ${({ theme }) => theme.spacing(5)};

  > button {
    min-width: ${UI_CONFIG.modalButton}px;
  }
`;
