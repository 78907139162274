import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

export const CorrespondentWrapper = styled.div<{
  $isMessageViewed: boolean;
  $isIncomingMessage: boolean;
  $fromSystem?: boolean;
}>`
  font-weight: ${({ $isMessageViewed, $isIncomingMessage }) =>
    $isMessageViewed ? 500 : $isIncomingMessage ? 700 : 500};
  ${FLEX_ROW_FULL};
  overflow: visible !important;
  gap: ${({ theme }) => theme.spacing(2)};
  .correspondent__avatar {
    ${FLEX_ROW_FULL};
    width: 40px;
    height: 40px;
    color: ${({ theme, $fromSystem }) =>
      $fromSystem ? theme.palette.warning.dark : theme.palette.text.black};
    font-weight: ${({ $isMessageViewed, $isIncomingMessage }) =>
      $isMessageViewed ? 500 : $isIncomingMessage ? 700 : 500};
    border-width: ${({ $isMessageViewed, $isIncomingMessage }) =>
      $isMessageViewed ? "1.5px" : $isIncomingMessage ? "3px" : "1.5px"};
    border-style: solid;
    border-color: ${({ theme, $fromSystem }) =>
      $fromSystem ? theme.palette.warning.dark : theme.palette.primary.main};
    border-radius: 50%;
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;
