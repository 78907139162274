import React from "react";

const ALLOWED_TO_MANAGE_USERS = [
  "admin@sbm.systems",
  "evolution.sbm@gmail.com",
  "gapondmitry1@gmail.com",
  "churilov.v@mtrest136.ru",
  "hayrapetyandiana@gmail.com",
  "narek.sargsyan@priotix.com",
  "samvelhovsepyan@yandex.com",
  "arpiighazaryan@yandex.com",
  "vahagn@priotix.com",
  "aidkasparova@gmail.com",
  "albert.tonoyan@priotix.com",
];

export function usePermissions(email?: string) {
  const hasPermission = React.useMemo(() => {
    if (!email) return false;

    return ALLOWED_TO_MANAGE_USERS.includes(email);
  }, [email]);

  return { hasPermission };
}
