import React from "react";
import { Controller, FieldValues, useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useCreateUser } from "api";
import { matchIsValidTel } from "mui-tel-input";

import { Checkbox, FormControlLabel, Grid, Switch } from "@mui/material";
import { EMAIL_REGEX, SnackbarMessages } from "@sbm/fe-utils";
import {
  Button,
  FormRadio,
  FormSelect,
  Input,
  PhoneInput,
} from "@sbm/ui-components";
import { IUser, ModalVariants, UsersStatus } from "@types";

import { ActionsWrapper } from "../../../../ui-kit/components/FormActionButtons/styles";
import { TransitionPrompt } from "../../../decorators";
import { useAppDispatch, useCallbackPrompt } from "../../../hooks";
import { Paths } from "../../../routes";
import { setModal } from "../../../store";
import { LangOptions, PositionOptions, StatusOptions } from "./constants";
import { CheckboxWrapper, FormWrapper } from "./styles";

const DEFAULT_VALUES: Partial<IUser> = {
  name: "",
  surname: "",
  patronymic: "",
  email: "",
  phoneNumber: "",
  preferableLanguage: "ru",
  status: UsersStatus.active,
  staff: false,
  position: "",
  gender: "male",
  notifyOldEmail: true,
};

interface UserAccountDetailsProps {
  data?: IUser | null;
  id?: string;
  isSelf?: boolean;
  onUpdateUser?: (user: IUser, id: string, isSelf?: boolean) => void;
}

export const UserAccountDetails = (props: UserAccountDetailsProps) => {
  const { data = null, id, isSelf, onUpdateUser } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isEditMode = Boolean(id) || isSelf;

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, defaultValues, dirtyFields, isSubmitting },
  } = useForm({
    defaultValues: data || DEFAULT_VALUES,
  });

  const [loading, setLoading] = React.useState(false);

  const { phoneNumber, staff } = useWatch({ control });

  const genderOptions = [
    { option: t("profilePage.accountTab.male"), value: "male" },
    { option: t("profilePage.accountTab.female"), value: "female" },
  ];

  const languageOptions = LangOptions.map((item) => ({
    ...item,
    option: t(item.option),
  }));

  const statusOptions = StatusOptions.map((item) => ({
    ...item,
    option: t(item.option),
  }));

  const onSubmitSuccess = () => {
    toast.success(SnackbarMessages.success);
    navigate(Paths.USERS);
  };

  const onSubmitError = (error: any) => {
    const message =
      error?.response?.data?.errors?.[0]?.message ===
      "User with the email already exists"
        ? error.response.data.errors[0].message
        : SnackbarMessages.error;

    toast.error(message);
  };

  const { mutate: createUser, isLoading } = useCreateUser(
    onSubmitSuccess,
    onSubmitError
  );

  const onSubmit = handleSubmit(async (data: Partial<FieldValues>) => {
    if (data) {
      setLoading(true);

      try {
        if (isEditMode) {
          const dataToSend: Partial<FieldValues> = {};

          for (const key in dirtyFields) {
            dataToSend[key] = data[key];
          }

          if (dataToSend.email && !dirtyFields.notifyOldEmail) {
            dataToSend["notifyOldEmail"] = defaultValues?.notifyOldEmail;
          }

          onUpdateUser?.(dataToSend as IUser, id as string, isSelf);
        } else {
          delete data.notifyOldEmail;

          createUser(data as IUser);
          reset();
        }

        if (!isEditMode) {
          const prevPath = window.history.state?.usr?.prevPath;

          if (prevPath) return navigate(-1);

          navigate(Paths.USERS);
        }
      } catch (err: any) {
        const message =
          err?.response?.data?.errors?.[0]?.message ===
          "User with the email already exists"
            ? err.response.data.errors[0].message
            : SnackbarMessages.error;

        toast.error(message);
      }

      setLoading(false);
    }
  });

  const handleCancel = () => {
    if (isDirty) {
      return dispatch(
        setModal({
          open: true,
          variant: ModalVariants.discardChanges,
          content: { confirmNavigation },
        })
      );
    }

    const prevPath = window.history.state?.usr?.prevPath;

    if (prevPath) return navigate(-1);

    navigate(Paths.USERS);
  };

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isDirty && !isSubmitting
  );

  React.useEffect(() => {
    if (isEditMode) {
      !data ? setLoading(true) : setLoading(false);
    }
  }, [isEditMode, data]);

  return (
    <FormWrapper onSubmit={onSubmit} noValidate>
      {showPrompt ? (
        <TransitionPrompt
          open={showPrompt}
          onClose={cancelNavigation}
          onConfirm={() => {
            reset();
            confirmNavigation();
          }}
        />
      ) : null}

      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            label={t("first.name")}
            error={Boolean(errors.name)}
            disabled={isSelf}
            {...register("name", {
              required: true,
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            label={t("last.name")}
            error={Boolean(errors.surname)}
            disabled={isSelf}
            {...register("surname", {
              required: true,
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            label={t("profilePage.accountTab.patronymic")}
            error={Boolean(errors.patronymic)}
            disabled={isSelf}
            {...register("patronymic", {
              required: !isEditMode,
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormRadio
            label={t("profilePage.accountTab.gender")}
            name="gender"
            control={control}
            values={genderOptions}
            fullWidth={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Input
            label={t("profilePage.accountTab.email")}
            error={Boolean(errors.email)}
            disabled={isSelf}
            {...register("email", {
              required: true,
              pattern: EMAIL_REGEX,
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            key="phoneNumber"
            name="phoneNumber"
            control={control}
            rules={{
              validate: phoneNumber ? matchIsValidTel : undefined,
            }}
            render={({ field, fieldState }) => {
              return (
                <PhoneInput
                  error={fieldState.invalid}
                  label={t("phone.number")}
                  {...field}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControlLabel
            label={t("staff.member")}
            disabled={isSelf}
            control={
              <Switch
                {...register("staff", { required: false })}
                defaultChecked={!!defaultValues?.staff}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormSelect
            variant="outlined"
            size="medium"
            label={t("position")}
            name="position"
            control={control}
            values={PositionOptions}
            error={!!errors.position}
            disabled={!staff || isSelf}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormSelect
            label={t("preferable.language")}
            name="preferableLanguage"
            variant="outlined"
            size="medium"
            control={control}
            values={languageOptions}
            error={!!errors.preferableLanguage}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormSelect
            label={t("status")}
            name="status"
            variant="outlined"
            size="medium"
            disabled={isSelf}
            control={control}
            values={statusOptions}
            error={!!errors.status}
          />
        </Grid>
      </Grid>

      {dirtyFields.email && isEditMode ? (
        <CheckboxWrapper>
          <FormControlLabel
            label={t("notify.prev.email")}
            control={
              <Checkbox
                defaultChecked={!!defaultValues?.notifyOldEmail}
                {...register("notifyOldEmail", { required: false })}
              />
            }
          />
        </CheckboxWrapper>
      ) : null}

      {Object.keys(dirtyFields).length > 0 && (
        <ActionsWrapper>
          {handleCancel ? (
            <Button
              onClick={handleCancel}
              variant="outlined"
              size="large"
              color="secondary"
            >
              {t("cancel")}
            </Button>
          ) : null}

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            loading={loading || isLoading}
            disabled={isEditMode && !Object.keys(dirtyFields).length}
          >
            {isEditMode ? t("update") : t("create")}
          </Button>
        </ActionsWrapper>
      )}
    </FormWrapper>
  );
};
