import React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";

import { Icon } from "ui-kit";

import { Typography, Stack, useTheme, MenuItem } from "@mui/material";

import { SidebarItemsProps } from "./index";

export const PopperContent: React.FC<Partial<SidebarItemsProps>> = ({
  name = "",
  route = "",
  collapse,
  onClick,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleClick = (route: string) => {
    onClick?.(route);
  };

  return (
    <>
      <MenuItem sx={{ mb: 3.5, px: 4 }} onClick={() => handleClick(route)}>
        <Typography variant="body1" fontWeight={700}>
          {t(name)}
        </Typography>
      </MenuItem>

      {collapse?.map((item) => {
        const isActive =
          matchPath(item.route, pathname) ||
          (item.route !== "/" && pathname.includes(item.route));

        const toggledColor = isActive
          ? theme.palette.secondary.main
          : theme.palette.text.primary;

        return (
          <MenuItem
            key={item.name}
            onClick={() => handleClick(item.route)}
            sx={{ px: 4, mb: 2 }}
          >
            <Stack flex={1} flexDirection="row" alignItems="center" gap={8}>
              <Icon name={item.icon} color={toggledColor} />

              <Typography
                variant="body2"
                sx={{ flex: 1 }}
                color={toggledColor}
                fontWeight={isActive ? 600 : 400}
              >
                {t(item.name)}
              </Typography>

              {isActive && (
                <Icon name="Dot" strokeWidth={8} color={toggledColor} />
              )}
            </Stack>
          </MenuItem>
        );
      })}
    </>
  );
};
