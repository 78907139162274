import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  flex: 1;

  padding: ${({ theme }) => theme.spacing(5, 0)};

  .cell-customize {
    font-weight: 700;
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: space-between;
`;

export const SearchWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(8)};
`;

export const EmployeeColumnWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};

  width: 100%;
`;

export const AdditionalInformation = styled.div`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing()};
`;

export const EmployeeName = styled.span<{ hasEmployee: boolean }>`
  flex: 1;
  color: ${({ theme, hasEmployee }) =>
    hasEmployee ? theme.palette.primary.main : theme.palette.warning.dark};
`;
