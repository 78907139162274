import styled from "styled-components";

import { hexToRgba } from "@sbm/fe-utils";

export const CalendarWrapper = styled.div<{ $weekView: boolean }>`
  font-family: "Montserrat", sans-serif;

  height: ${({ $weekView }) => ($weekView ? "100%" : "850px")};

  .rbc-time-view .rbc-row {
    height: 100%;
  }

  .rbc-off-range-bg {
    background: initial;
  }
  .rbc-time-header-gutter,
  .rbc-time-content {
    display: none;
  }

  .rbc-today {
    background-color: ${({ theme }) => theme.palette.secondary["8p"]};
  }
  .rbc-current {
    p {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  .rbc-header {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    font-family: "Montserrat", sans-serif;

    color: ${({ theme }) => theme.palette.info.main};
    background-color: ${({ theme }) =>
      hexToRgba(theme.palette.info.main, 0.12)};

    padding: ${({ theme }) => theme.spacing(4, 0)};

    span {
      text-transform: capitalize;
    }
  }
  .rbc-show-more {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
