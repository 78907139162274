import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";

export const ConfirmApprovalRoutePopup = (props: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const { open, onClose, onConfirm } = props;

  const { t } = useTranslation();

  const content = {
    title: t("confirm.action"),
    body: t("approval.route.confirm.modal.description"),
    secondaryButton: (
      <Button autoFocus onClick={onClose}>
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button variant="contained" autoFocus onClick={onConfirm}>
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
