import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";

export interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

export const DeleteStandardRoutePopup: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const content = {
    title: t("delete.route"),
    body: t("delete.route.description"),
    secondaryButton: <Button onClick={onClose}>{t("back")}</Button>,
    mainButton: (
      <Button autoFocus variant="contained" onClick={onConfirm}>
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
