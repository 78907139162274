import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { Card } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

export const CardWrapper = styled(Card)`
  height: auto;
  padding: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const CardTitle = styled.div`
  ${FLEX_BETWEEN}
`;

export const ResourceTypeSection = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  padding: ${({ theme }) => theme.spacing()};
  border-radius: ${({ theme }) => theme.spacing()};
  gap: ${({ theme }) => theme.spacing()};
  background-color: ${({ theme }) =>
    hexToRgba(theme.palette.primary.main, 0.04)};
`;
