import React from "react";

import {
  DataGridPro,
  DataGridProProps,
  GridPaginationModel,
  GridRowParams,
  GridSortItem,
  GridValidRowModel,
  ruRU,
  GridColumnVisibilityModel,
  GridRowId,
} from "@mui/x-data-grid-pro";

import { CustomToolbar } from "./CustomToolbar";
import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";
import { LinearProgress } from "@mui/material";

type TableProps = {
  onRowClick?: (arg: GridRowParams) => void;
  onSelectionChange?: (arg: (GridValidRowModel | undefined)[]) => void;
  rowCount?: number;
  page?: number;
  sortModel?: string;
  pageSize?: number;
  rowsPerPageOptions?: number[];
  hasToolbar?: boolean;
  onPageChange?: (arg: number) => void;
  onPageSizeChange?: (arg: number) => void;
  setColumnVisibilityModel?: (newModel: GridColumnVisibilityModel) => void;
  groupingColDefName?: string;
} & Omit<DataGridProProps, "sortModel">;

export const Table = (props: TableProps) => {
  const {
    rows = [],
    columns,
    loading,
    rowCount = 0,
    page,
    pageSize,
    sortModel,
    onRowClick,
    onCellClick,
    onSelectionChange,
    onSortModelChange,
    onPageChange,
    onPageSizeChange,
    rowsPerPageOptions,
    hasToolbar = false,
    columnVisibilityModel,
    setColumnVisibilityModel,
    treeData,
    getTreeDataPath,
    groupingColDefName,
    checkboxSelection = false,
    rowReordering,
    onRowOrderChange,
    hideFooterPagination = false,
    apiRef,
    getRowHeight,
    rowModesModel,
    onRowModesModelChange,
    slots,
    editMode = "row",
    ...rest
  } = props;

  const DataGridComponents = React.useMemo(() => {
    if (slots) return slots;

    return {
      toolbar: hasToolbar ? () => <CustomToolbar /> : null,
      loadingOverlay: LinearProgress,
    };
  }, [hasToolbar, slots]);

  const locale = getStorageItem(STORAGE_KEYS.lang);
  const localeText = locale === "ru" ? ruRU.components.MuiDataGrid.defaultProps.localeText : undefined;

  const handleRowClick = (params: GridRowParams) => onRowClick?.(params);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    onPageChange?.(model.page);
    onPageSizeChange?.(model.pageSize);
  };

  const handleRowsSelection = (ids: GridRowId[]) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));

    onSelectionChange?.(selectedRowsData);
  };

  const SortingModel = React.useMemo(() => {
    if (!sortModel) return [];

    const parts = sortModel.split(":");
    const field = parts[0];
    const sort = parts[1].toLowerCase();

    return [{ field, sort }] as GridSortItem[];
  }, [sortModel]);

  if (!columns) return null;

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: groupingColDefName,
    flex: 1,
    minWidth: 320,
    valueGetter: (params) => params.row.name,
    cellClassName: (params) => (params.row.headOfStructuralUnit ? "cell-customize" : ""),
    hideDescendantCount: true,
  };

  return (
    <DataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      loading={loading}
      getRowHeight={getRowHeight}
      paginationModel={{
        page: page ?? 0,
        pageSize: pageSize ?? 10,
      }}
      groupingColDef={groupingColDef}
      rowCount={rowCount}
      sortModel={SortingModel}
      pageSizeOptions={rowsPerPageOptions || [10, 20, 50]}
      onSortModelChange={onSortModelChange}
      onPaginationModelChange={handlePaginationModelChange}
      onRowSelectionModelChange={handleRowsSelection}
      onRowClick={handleRowClick}
      onCellClick={onCellClick}
      sortingMode={treeData ? "client" : "server"}
      filterMode={treeData ? "client" : "server"}
      paginationMode="server"
      editMode={editMode}
      localeText={localeText}
      pagination
      autoHeight
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick
      slots={DataGridComponents}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel?.(newModel)}
      treeData={treeData}
      getTreeDataPath={getTreeDataPath}
      isGroupExpandedByDefault={() => Boolean(treeData)}
      rowsLoadingMode="server"
      rowReordering={rowReordering}
      onRowOrderChange={onRowOrderChange}
      hideFooterPagination={hideFooterPagination}
      rowModesModel={rowModesModel}
      onRowModesModelChange={onRowModesModelChange}
      {...rest}
    />
  );
};

export * from "./hooks/useTableQuerySet";
export * from "./hooks/useTableQueryMethods";
