import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetCorrespondenceEvents } from "api";
import { DotsLoader, Icon } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { getAPIErrorMessage, getLocaleDateFormat } from "@sbm/fe-utils";

import { TrackerTreeItemIcon } from "../../tasks/components/TaskExecutionTracker/components/TrackerTreeItemIcon";
import { CorrespondenceEventWrapper, TitleWrapper } from "./styles";

const parser = new DOMParser();

interface Props {
  registrationDate: Date | string;
  documentNumber: string;
}

export const CorrespondenceEventsContainer = ({
  registrationDate,
  documentNumber,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation("correspondence");
  const { palette } = useTheme();

  const { data, isLoading, error } = useGetCorrespondenceEvents(id, {
    enabled: Boolean(id),
  });

  if (isLoading) return <DotsLoader />;

  if (error) {
    const errorMSG = getAPIErrorMessage(error);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
  if (!data) return null;

  return (
    <>
      <TitleWrapper>
        <Typography variant="h8_bold" lineHeight={2} color="text.primary">
          {t("execution.tracker.number")}
        </Typography>
        <Typography variant="h8_bold" lineHeight={2} color="text.primary">
          {documentNumber}
        </Typography>
        <Typography variant="h8_bold" lineHeight={2} color="text.primary">
          {` ${t(
            "createLetterDrawer.registration_area.from"
          )} ${getLocaleDateFormat(registrationDate)}`}
        </Typography>

        <Icon name="FileSignature" color={palette.secondary.main} size={20} />
      </TitleWrapper>
      {data?.map((item, index) => {
        const parsedHtmlDoc = parser.parseFromString(
          item.generatedText,
          "text/html"
        );
        const htmlDoc = parsedHtmlDoc.getElementsByTagName("body");
        const labelBody = htmlDoc?.[0].innerHTML.replaceAll(
          "classname",
          "class"
        );
        return (
          <CorrespondenceEventWrapper key={index}>
            <Stack display="flex" flexDirection="row" alignItems="center">
              <TrackerTreeItemIcon typeEventId={item.typeEventId} />
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: labelBody }}
              />
            </Stack>
            <Stack display="flex" flexDirection="row" alignItems="center">
              <Typography variant="body3" color="text.secondary" minWidth={110}>
                {getLocaleDateFormat(item.createdAt, "hh:mm")}
              </Typography>
            </Stack>
          </CorrespondenceEventWrapper>
        );
      })}
    </>
  );
};
