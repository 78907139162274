import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { formControlLabelClasses } from "@mui/material";

export const FormWrapper = styled.form`
  ${FLEX_COLUMN};
  width: 100%;
  height: 100%;
  .MuiFormHelperText-root {
    line-height: 16px;
  }
`;
export const FormContent = styled.div`
  ${FLEX_COLUMN};
  flex: 1;

  margin-bottom: ${({ theme }) => theme.spacing(6)};

  .${formControlLabelClasses.root} {
    height: 100%;
    margin-left: 0;
  }
`;
