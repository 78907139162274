import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const FilterButtonContainer = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};

  width: fit-content;
  background-color: ${({ theme }) => theme.palette.secondary["12p"]};

  padding: ${({ theme }) => theme.spacing(2, 2.5)};
  border-radius: ${({ theme }) => theme.spacing()};
`;
