import React from "react";

import styled from "styled-components";

import {
  tabClasses,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  tabsClasses,
} from "@mui/material";

import { FLEX_ROW_ALIGN_CENTER } from "../../theme";

export interface TabsProps extends MuiTabsProps {
  children: React.ReactNode;
}

export const StyledTabs = styled(MuiTabs)`
  position: relative;
  top: 22px;

  margin: ${({ theme }) => theme.spacing(-2, 0)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  transition: all ease 350ms;

  z-index: ${({ theme }) => theme.zIndex.fab};

  .${tabsClasses.scroller} {
    flex: inherit;
    width: fit-content;
  }
  .${tabsClasses.indicator} {
    display: none;
  }
  .${tabsClasses.flexContainer} {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .${tabClasses.root} {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(2)};
    min-width: unset;
    padding: ${({ theme }) => theme.spacing(2, 4)};

    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.4px !important;

    color: ${({ theme }) => theme.palette.text.secondary};
    border-bottom: 2px solid transparent;

    transition: color 0.3s 0.1s, border-bottom 0.3s 0.1s;

    svg {
      width: 18px;
      height: 18px;
      margin-bottom: 0;
    }
  }

  .${tabClasses.selected} {
    color: ${({ theme }) => theme.palette.secondary.main};
    border-bottom: 2px solid ${({ theme }) => theme.palette.secondary.main};
    letter-spacing: 0.4px !important;
  }
`;

export const Tabs = (props: TabsProps) => {
  const { children, value, onChange, ...rest } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (onChange) {
      onChange(event, newValue);
    }
  };

  return (
    <StyledTabs value={value} onChange={handleChange} {...rest}>
      {children}
    </StyledTabs>
  );
};
