import React, { useEffect } from "react";

import { useAppDispatch } from "app";
import { setLoadedFilesCount } from "app";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { hexToRgba } from "@sbm/fe-utils";

import { createObjectURL, formatSize } from "./helpers";

interface Props {
  file: File;
  uploadProgress: {
    name: string;
    progress: number;
  }[];
  removeFile: () => void;
}

export const FileItem: React.FC<Props> = ({
  file,
  uploadProgress,
  removeFile,
}) => {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const [progress, setUploadProgress] = React.useState<number>(0);

  const isFileUploaded = progress >= 100;

  const myUploadedFile = uploadProgress.filter((u) => u.name === file.name);

  useEffect(() => {
    if (myUploadedFile[0]) {
      setUploadProgress(myUploadedFile[0].progress);
    }
  }, [myUploadedFile]);

  React.useEffect(() => {
    if (!isFileUploaded) return;
    dispatch(setLoadedFilesCount(1));
    return () => {
      dispatch(setLoadedFilesCount(-1));
    };
  }, [isFileUploaded, dispatch]);

  if (!file) return null;

  return (
    <Stack mb={2}>
      <div className="dropzone__uploadingProcess">
        <div style={{ display: "flex", gap: "4px" }}>
          <a
            href={createObjectURL(file)}
            target="_blank"
            rel="noopener noreferrer"
            className="uploaded_file"
          >
            <Typography variant="body2" fontWeight={600} color="primery.main">
              {`${file.name}`}
            </Typography>
          </a>
          <Typography
            variant="body2"
            fontWeight={600}
            color={hexToRgba(theme.palette.primary.main, 0.45)}
          >
            {`(${formatSize(file.size)})`}
          </Typography>
        </div>

        <div className="dropzone__liner">
          <IconButton onClick={removeFile}>
            <ClearIcon fontSize="small" fontWeight="600" color="primary" />
          </IconButton>
        </div>
      </div>
      {!isFileUploaded && (
        <LinearProgress
          variant="determinate"
          value={progress || 0}
          valueBuffer={progress + 10}
          color="secondary"
          style={{ background: hexToRgba(theme.palette.text.primary, 0.04) }}
        />
      )}
    </Stack>
  );
};
