import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW } from "ui-kit";

export const Wrapper = styled.form`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(5)};

  padding-top: ${({ theme }) => theme.spacing(3)};
`;

export const ActionsWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;
  width: 100%;

  .primary-buttons {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(2)};
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
