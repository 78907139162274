import { configureStore } from "@reduxjs/toolkit";

import { authSlice } from "./auth";
import { contractsSlice } from "./contracts";
import { correspondenceSlice } from "./correspondence";
import { globalSlice } from "./global";
import { instructionsSlice } from "./instructions";
import { messageSlice } from "./message";
import { modalSlice } from "./modals";
import { organizationsSlice } from "./organizations";
import { taskSlice } from "./task";
import { usersSlice } from "./users";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["modals/setModal", "contracts/setContractById"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.content"],
        // Ignore these paths in the state
        ignoredPaths: ["modal.content"],
      },
    }),
  reducer: {
    global: globalSlice.reducer,
    contracts: contractsSlice.reducer,
    modals: modalSlice.reducer,
    users: usersSlice.reducer,
    auth: authSlice.reducer,
    instructions: instructionsSlice.reducer,
    organizations: organizationsSlice.reducer,
    message: messageSlice.reducer,
    task: taskSlice.reducer,
    correspondence: correspondenceSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export * from "./modals";
export * from "./global";
export * from "./auth";
export * from "./users";
export * from "./contracts";
export * from "./instructions";
export * from "./organizations";
export * from "./message";
export * from "./task";
export * from "./correspondence";
