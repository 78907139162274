import { TFunction } from "i18next";

import { ContractFieldsEnum, formatDateToQuery } from "@sbm/fe-utils";
import { ContractStatusEnum, IContractDirectories } from "@types";

import { CONTRACTS_FILTERABLE_FIELDS } from "./constants";

export const getContractOptions = (
  contractDirectories: IContractDirectories | null,
  fieldName: string,
  t: TFunction
) => {
  if (!contractDirectories) return [];

  const {
    contractCategory,
    contractType,
    contractSubtype,
    organization,
    constructionComplex,
    typicalRoleContractParty,
    responsibleForPreparationContractId,
  } = contractDirectories;

  switch (fieldName) {
    case ContractFieldsEnum.contractCategoryId:
      return contractCategory.map((i) => i.nameContractCategory);
    case ContractFieldsEnum.statusContract:
      return Object.values(ContractStatusEnum).map((status) => t(status));
    case ContractFieldsEnum.contractTypeId:
      return contractType.map((i) => i.nameContractType);
    case ContractFieldsEnum.contractSubtypeId:
      return contractSubtype.map((i) => i.nameContractSubtype);
    case ContractFieldsEnum.constructionComplexId:
      return constructionComplex.map((i) => i.briefTextConstructionComplexName);
    case ContractFieldsEnum.party1OrganizationId:
    case ContractFieldsEnum.party2OrganizationId:
    case ContractFieldsEnum.party3OrganizationId:
      return organization.map((i) => i.briefTextOrganizationName);
    case ContractFieldsEnum.party1RoleId:
    case ContractFieldsEnum.party2RoleId:
      return typicalRoleContractParty.map(
        (i) => i.nameTypicalRoleContractParty
      );
    case ContractFieldsEnum.responsibleForPreparationContractId:
      return responsibleForPreparationContractId?.map(
        (employee) => employee.name
      );
    default:
      return [];
  }
};

export const getColumnNameFromArray = (
  value: string | string[] | undefined,
  t: TFunction
): string => {
  if (typeof value === "string") return t(value);

  if (!value?.length) return "";

  let result = "";

  value.forEach((str, index) => {
    const isLast = index === value.length - 1;
    const stringToAdd = `${t(str)}${isLast ? "" : " "}`;

    result = result.concat(stringToAdd);
  });

  return result;
};

const findIdByName = (
  contractDirectories: IContractDirectories,
  name: ContractFieldsEnum,
  value: string,
  t: TFunction
) => {
  const {
    contractCategory,
    contractType,
    contractSubtype,
    organization,
    constructionComplex,
    typicalRoleContractParty,
    responsibleForPreparationContractId,
  } = contractDirectories;

  let result: number | string | undefined;

  switch (name) {
    case ContractFieldsEnum.contractCategoryId:
      result = contractCategory.find(
        (i) => i.nameContractCategory === value
      )?.id;
      break;
    case ContractFieldsEnum.statusContract:
      result = Object.values(ContractStatusEnum).find(
        (status) => t(status) === value
      );
      break;
    case ContractFieldsEnum.contractTypeId:
      result = contractType.find((i) => i.nameContractType === value)?.id;
      break;
    case ContractFieldsEnum.contractSubtypeId:
      result = contractSubtype.find((i) => i.nameContractSubtype === value)?.id;
      break;
    case ContractFieldsEnum.constructionComplexId:
      result = constructionComplex.find(
        (i) => i.briefTextConstructionComplexName === value
      )?.id;
      break;
    case ContractFieldsEnum.dateContract:
      result = value.toString().replaceAll(" ", "").replaceAll("/", "-");
      break;
    case ContractFieldsEnum.dateValidityExpiryContract:
      result = value.toString().replaceAll(" ", "").replaceAll("/", "-");
      break;
    case ContractFieldsEnum.party1OrganizationId:
    case ContractFieldsEnum.party2OrganizationId:
    case ContractFieldsEnum.party3OrganizationId:
      result = organization.find(
        (i) => i.briefTextOrganizationName === value
      )?.id;
      break;
    case ContractFieldsEnum.party1RoleId:
    case ContractFieldsEnum.party2RoleId:
      result = typicalRoleContractParty.find(
        (i) => i.nameTypicalRoleContractParty === value
      )?.id;
      break;
    case ContractFieldsEnum.dateOfSigning:
    case ContractFieldsEnum.expirationDate:
      result = formatDateToQuery(value);
      break;
    case ContractFieldsEnum.responsibleForPreparationContractId:
      result = responsibleForPreparationContractId?.find(
        (i) => i.name === value
      )?.id;
      break;
  }

  if (!result) return;

  return result;
};

export const getContractFilterOptions = (
  contractDirectories: IContractDirectories | null,
  t: TFunction
) => {
  return CONTRACTS_FILTERABLE_FIELDS.map((item) => {
    const { field } = item;

    return {
      ...item,
      title: getColumnNameFromArray(item.title, t),
      options: getContractOptions(contractDirectories, field, t),
    };
  });
};

export const getContractFilterValues = (
  contractDirectories: IContractDirectories | null,
  selectedFilters: string[],
  t: TFunction
) => {
  if (!contractDirectories || !selectedFilters?.length) return [];

  return selectedFilters.map((filter) => {
    const parts = filter.split("=");
    const filterKey = parts[0];
    const filterValue = parts[1];
    const values = filterValue.split(",");

    let query = `${filterKey}=`;

    values.forEach((value, index) => {
      const isLast = index === values.length - 1;

      const idOfFilter = findIdByName(
        contractDirectories,
        filterKey as ContractFieldsEnum,
        value,
        t
      );

      if (idOfFilter) {
        query = query + idOfFilter;
        if (!isLast) query = query + ",";
      }
    });

    return query;
  });
};
