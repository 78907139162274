import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW } from "ui-kit";

export const FormWrapper = styled.form`
  ${FLEX_COLUMN};
  min-height: 320px;

  padding: ${({ theme }) => theme.spacing(8, 6, 5)};
`;

export const StepContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(5, 0)};
  flex: 1;
`;

export const StepperActions = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;
  width: 100%;
`;
