import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const SearchWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(8)};
`;

export const TableActions = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(8)};

  width: 100%;

  margin-bottom: ${({ theme }) => theme.spacing(5)};

  .search-input {
    width: 100%;
  }

  button {
    max-width: 220px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
    align-items: flex-end;

    button {
      max-width: 100%;
    }
  }
`;
