import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";
import { IDefaultRoute } from "@types";

export const ChangeDefaultRoutePopup = (props: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  data?: IDefaultRoute | null;
}) => {
  const { open, onClose, onConfirm, data } = props;

  const { t } = useTranslation();

  const routeNumber = data
    ? data.typicalApprovalRouteForContract.routeNumber
    : "";

  const body = `${t("change.default.route.body.start")} ${routeNumber} ${t(
    "change.default.route.body.end"
  )}`;

  const content = {
    title: t("change.default.route"),
    body,
    secondaryButton: (
      <Button autoFocus onClick={onClose}>
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button variant="contained" autoFocus onClick={onConfirm}>
        {t("yes")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
