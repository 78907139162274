import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { DefaultTFuncReturn } from "i18next";

type TSize = "small" | "medium";

export interface SelectProps {
  fullWidth?: boolean;
  autoClear?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  error?: boolean;
  errorText?: string;
  label?: string | DefaultTFuncReturn;
  name: string;
  id?: string;
  size?: TSize;
  control: Control<any>;
  values: {
    value: string | number | undefined;
    option: string | JSX.Element | undefined;
    disabled?: boolean | undefined;
  }[];
  defaultSelected?: string;
  helperText?: string;
  className?: string;
  color?: "error" | "info" | "primary" | "secondary" | "success" | "warning";
  variant?: "standard" | "filled" | "outlined";
  onChangeListener?: (e: SelectChangeEvent) => void;
}

export const FormSelect = React.forwardRef((props: SelectProps, ref) => {
  const {
    fullWidth = true,
    autoClear = false,
    label,
    values,
    required = false,
    disabled,
    readOnly,
    error,
    errorText,
    control,
    name,
    size = "medium",
    className,
    color = "secondary",
    variant = "outlined",
    defaultSelected,
    id,
    onChangeListener,
  } = props;

  const getTooltipValue = React.useCallback(
    (selectedValue: string) => {
      if (!selectedValue) return label;

      const selected = values.find((item) => item?.value === selectedValue);

      if (!selected) return label;

      return selected.option;
    },
    [label, values]
  );

  const options = React.useMemo(() => {
    if (!values?.length) return [{ option: "", value: "" }];

    return values;
  }, [values]);

  if (!values || !control) return null;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue=""
      render={({ field }) => {
        return (
          <FormControl fullWidth={fullWidth} disabled={disabled} required={required} error={error}>
            <InputLabel
              id={label || ""}
              sx={{ top: 2 }}
              size={size === "small" ? "small" : "normal"}
              color={color}
              // shrink={readOnly && field.value}
            >
              {label}
            </InputLabel>
            <Tooltip arrow placement="top" title={getTooltipValue(field.value)}>
              <MuiSelect
                variant={variant}
                inputRef={ref}
                labelId={label || ""}
                label={label}
                size={size}
                color={color}
                className={className}
                id={id}
                readOnly={readOnly}
                defaultValue={defaultSelected}
                {...field}
                value={autoClear ? field.value || "" : field.value || ""}
                onChange={(e) => {
                  field.onChange(e);
                  onChangeListener?.(e);
                }}
              >
                {options.map(({ value, option, disabled }) => {
                  return (
                    <MenuItem key={value} value={value} disabled={disabled}>
                      {option}
                    </MenuItem>
                  );
                })}
              </MuiSelect>
            </Tooltip>

            {error ? <FormHelperText>{errorText}</FormHelperText> : null}
          </FormControl>
        );
      }}
    />
  );
});
