import { DrawerVariantsEnum } from "@types";

import { useAppSelector } from "../../../hooks";
import { OrganizationMainForm } from "./components";

export const CreateOrganizationContainer = () => {
  const { drawer } = useAppSelector((state) => state.global);

  if (drawer !== DrawerVariantsEnum.createOrganization) return null;

  return <OrganizationMainForm />;
};
