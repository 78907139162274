import { IAttachLink } from "./messages";
import {
  IConstructionComplex,
  IOrganization,
  IPersonalData,
  IPosition,
  IStaffUnit,
  IStructuralUnit,
} from "./organizations";

export enum TasksViewEnum {
  table = "table",
  calendar = "calendar",
}

export type PotentialExecutorAuthor = {
  employeeId: number;
  externalSubstitution: number | null;
  externalSubstitutionId?: number | null;
};

export enum TypeOfTheTaskEnum {
  resolutionForTheDocument = "resolution_for_the_document",
  taskByTheDocument = "task_by_the_document",
  directTask = "direct_task",
  selfAssignment = "self_assignment",
}

export enum TypeOfTheTaskAuthorEnum {
  system = "system",
  user = "user",
}

export enum StatusForAuthorEnum {
  draft = "draft",
  sentToExecutor = "sent_to_executor",
  done = "done",
  rejected = "rejected_by_executor",
  canceled = "canceled",
  removalFromControl = "removal_from_control",
}

export enum StatusForExecutorEnum {
  newTask = "new_task",
  acceptedForExecution = "accepted_for_execution",
  delegated = "delegated",
  done = "done",
  doneInPerson = "done_in_person",
  rejected = "rejected",
  canceled = "canceled",
  resolutionIssued = "resolution_issued",
  resolutionExecuted = "resolution_executed",
  executionInterrupted = "execution_interrupted",
  returnForRevision = "return_for_revision",
  executionCompleted = "execution_completed",
}

export type TExecutionRequirement = {
  textOfTheTask: string;
  controlDate: string;
  numberOfWorkingDays: number;
  numberOfCalendarDays: number;
  reportRequired: boolean;
  personalControl: boolean;
};

export type TWorkingDaysEditMode = {
  scheduledDateDisableState: boolean;
  workingDaysDisableState: boolean;
  calendarDaysDisableState: boolean;
};

export interface ITask {
  id: number;
  typeOfTheTask: TypeOfTheTaskEnum;
  statusForAuthor?: StatusForAuthorEnum;
  statusForExecutor?: StatusForExecutorEnum;
  subject: string;
  taskAuthorShortName: string;
  shortNameOfTheDocument?: string;
  standartTextForTheTask?: string;
  textOfTheTask: string;
  taskExecutorShortName: string;
  highPriority: boolean;
  createdAt: string | Date | null;
  scheduledDateOfExecution: string | Date | null;
  actualDateOfExecution: string | Date | null;
}

export interface ITaskForCalendar {
  delayed: boolean;
  id: number;
  typeOfTheTask: TypeOfTheTaskEnum;
  statusForExecutor: StatusForExecutorEnum;
  statusForAuthor: StatusForAuthorEnum;
  taskAuthorShortName: string;
  taskExecutorShortName: string;
  textOfTheTask: string;
  startDate: Date | string | null;
  endDate: Date | string | null;
}

export type TRegistration = {
  taskNumber: number;
  taskDate: string;
  priority: boolean;
  constructionComplexAffiliation: Partial<IConstructionComplex>[];
  status: StatusForExecutorEnum;
};

export type TExecutorOfTheTask = {
  position: Partial<IPosition>;
  organization: Partial<IOrganization>;
  employee: { id: number; fullName: string };
  structuralUnit?: {
    id: number;
    nameOfStructuralUnit: string;
  };
};

export interface ITaskById {
  authorOfTheTask: {
    position: Partial<IPosition>;
    organization: Partial<IOrganization>;
    employee: { id: number; fullName: string };
    structuralUnit?: {
      id: number;
      nameOfStructuralUnit: string;
    };
  };
  executorOfTheTask: TExecutorOfTheTask;
  registration: TRegistration;
  executionRequirements: TExecutionRequirement;
  typeOfTheTask: TypeOfTheTaskEnum;
  statusForExecutor: StatusForExecutorEnum;
  statusForAuthor: StatusForAuthorEnum;
  rootTaskId: number;
  subjectOfTask?: {
    id?: number;
    longNameOfTheDocument?: string;
    correspondenceId?: number;
  };
  hasOneSiblingTask?: boolean;
  areAllSiblingTasksDone?: boolean;
  hasValidChildTasks?: boolean;
  parentTaskId?: number | null;
}

export enum ExecutorType {
  Immediate_subordinates = "immediate_subordinates",
  All_subordinates = "all_subordinates",
}

export enum SubordinationType {
  immediate = "immediate", // непосредственные
  direct = "direct", // прямые
  notSubordinate = "not_subordinate", // не находятся в подчинении
}

export type PotentialExecutor = {
  organization: IOrganization;
  staffUnit: IStaffUnit;
  employeeId: number;
  externalSubstitutionId: number | null;
  structuralUnitId: number;
  personalData: IPersonalData;
  userId: number;
  id: number;
  subordinationType: SubordinationType;
  author: PotentialExecutorAuthor;
  structuralUnit: IStructuralUnit;
  position?: string;
};

export type IExecutorOption = {
  title?: string;
  value: number;
  key?: number | undefined;
  uniqueId: number;
  id?: number | string;
  position?: string;
  organizationName?: string;
  authorExternalSubstitutionId?: number | null | undefined;
  externalSubstitutionId: number | null | undefined;
  authorEmployeeId: number;
  organizationId: number | undefined;
};

export type TaskExecutor = {
  employeeId?: number;
  uniqueId?: number;
  externalSubstitutionId?: number;
  authorEmployeeId?: number;
  authorExternalSubstitutionId?: number;
  organizationId: number;
  organizationName: string;
  position: string;
  textOfTheTask: string;
  highPriority: boolean;
  scheduledDateOfExecution: Date | string;
  calendarDaysForExecution: number;
  workingDaysForExecution: number;
  reportRequired: boolean;
  personalControl: boolean;
  constructionComplexes?: number[];
  affiliationConstructionComplex?: boolean;
  title?: string;
  id?: number;
};

export type CreateTaskDefaultValuesPerUser = {
  highPriority: boolean;
  textOfTheTask: string;
  personalControl: boolean;
  reportRequired: boolean;
  scheduledDateOfExecution?: Date | string;
  workingDaysForExecution: number;
  calendarDaysForExecution?: number;
  constructionComplexes: number[];
  affiliationConstructionComplex: boolean;
  disabledConstructionComplexes: number[];
};

export interface CustomFile extends File {
  id?: string;
  path?: string;
  fileId?: string;
}

export type ICreateTask = {
  executorIds: TaskExecutor[];
  links?: IAttachLink[];
  files?: CustomFile[] | null;
  allSubordinates?: boolean;
  structuralUnitType?: ExecutorType;
  defaultValuesPerUser?: CreateTaskDefaultValuesPerUser;
};

export type IEditTask = {
  affiliationConstructionComplex: boolean;
  highPriority: boolean;
  personalControl: boolean;
  reportRequired: boolean;
  textOfTheTask: string;
  constructionComplexAffiliation:
    | {
        id: number;
        briefTextConstructionComplexName: string;
      }[]
    | number[];
  scheduledDateOfExecution: Date | string;
  numberOfWorkingDays: number;
  calendarDaysForExecution: number;
  executor: { title?: string; id?: number };
};

export type IEditTaskBody = {
  textOfTheTask?: string;
  scheduledDateOfExecution?: Date | string;
  calendarDaysForExecution?: number;
  workingDaysForExecution?: number;
  employeeId?: number;
};
export type IEditDraftTaskBody = {
  textOfTheTask?: string;
  scheduledDateOfExecution?: Date | string;
  calendarDaysForExecution?: number;
  workingDaysForExecution?: number;
  highPriority?: boolean;
  personalControl?: boolean;
  reportRequired?: boolean;
  constructionComplexes?:
    | {
        id: number;
        briefTextConstructionComplexName: string;
      }[]
    | number[];
};

export enum FileAttachedToTaskTypeOfAttachmentEnum {
  taskReport = "task_report",
  auxiliaryFile = "auxiliary_file",
  attachment = "attachment",
}

export enum TypeOfAttachmentEnum {
  taskReport = "task_report",
  other = "other",
  notClassified = "not_classified",
  taskSubject = "task_subject",
  attachmentToTask = "attachment_to_task",
  supportingDocument = "supporting_document",
}

export enum TypeOfAttachmentFileEnum {
  taskReport = "task_report",
  additionalFile = "auxiliary_file",
  attachmentToTheTask = "attachment",
}

export enum TypeOfAttachmentLinksEnum {
  taskReport = "task_report",
  additionalDocument = "additional_document",
  attachmentToTheTask = "attachment_to_task",
}

export type IAttachmentFile = {
  additionalInformation: string | null;
  createdAt: string;
  fileNameForSystem: string;
  fileFormat: string;
  fileName: string;
  id: number;
  typeOfAttachment: FileAttachedToTaskTypeOfAttachmentEnum;
  uploadedBy: string;
};
export type IAttachmentLink = {
  createdAt: string;
  attachedAt: string;
  createdBy: string;
  descriptionForAttachment: string | null;
  id: number;
  link: string;
  typeOfAttachment: TypeOfAttachmentEnum;
  contractId: null;
  messageId: number | null;
  correspondenceId: number | null;
  regularTaskId: number | null;
  supplementaryAgreementId: number | null;
  taskId: number | null;
  typeOfTheDocument: string;
};

export enum TaskCategoryEnum {
  requiresAction = "requires_action",
  inTheSpotlight = "in_the_spotlight",
  completed = "completed",
}

export interface IViewTask {
  id: number;
  view: boolean;
}

export type TExecutionTrackerNode = {
  id: number;
  taskId: number;
  childTaskId: number;
  generatedEventText: string;
  createdAt: string;
  titleForChildEvent: boolean;
  typeEventId: number;
  statusForExecutor: StatusForExecutorEnum;
  statusForAuthor: StatusForAuthorEnum;
  children: TExecutionTrackerNode[];
};

export interface IAttachesTaskLinkBody {
  fileAppointment?: TypeOfAttachmentLinksEnum;
  descriptionForAttachment?: string;
  links: number[];
}

export enum TypeEventEnum {
  CreateTask = 1,
  DelegateTask = 2,
  EditTaskDueDate = 3,
  EditTaskAssignee = 4,
  NotifyAssigneeOfDueDateChange = 5,
  NotifyAssigneeOfAssigneeChange = 6,
  DeleteTask = 7,
  SendTaskToAssignee = 8,
  TaskAccepted = 9,
  MarkTaskAsPersonallyCompleted = 10,
  AutoMarkTaskAsRemovedFromControl = 11,
  AutoMarkTaskAsCompleted = 12,
  ApproveTaskCompletion = 13,
  ReturnTaskForRework = 14,
  NotifyAssigneeOfReturnForRework = 15,
  NotifyAssigneeOfOverdueTask = 16,
  NotifyAuthorOfOverdueTask = 17,
  NotifyAssigneeOfDueDateChangeRepeat = 18,
  RejectTask = 19,
  NotifyAuthorOfTaskRejection = 20,
  NotifyAuthorOfTaskCompletion = 21,
  IssueResolutionOnDocument = 22,
  AttachFile = 23,
  ViewTaskByAssignee = 24,
  AttachLinkToDocument = 25,
  CompleteDocumentExecution = 26,
  Title = 27,
  TaskExecutionInterrupted = 28,
  NotifyAuthorOfExecutionInterruption = 29,
  NotifyAssigneeOfNewTask = 30,
  EditTaskExecutorField = 31,
  EditTaskTextField = 32,
  EditTaskScheduledDateOfExecutionField = 33,
  NotifyAssigneeOfTaskChange = 34,
  CreateIncomingDocument = 35,
  CreateOutgoingDocument = 36,
  CreateServiceNote = 37,
  NotifyExecutorOfTaskDeletion = 38,
  SendIncomingDocumentForResolution = 39,
  CancellationOfAPreviouslyIssuedResolution = 40,
  ViewTaskByAuthor = 41,
  UpdateParentAssignmentStatus = 42,
  IncomingDocumentExecutionOfAllResolutions = 43,
}

export interface IRejectExecution {
  id: number;
  comment: string;
}
