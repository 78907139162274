import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { BackgroundImageSvg } from "./BackgroundImageSvg";
import { StyledGridOverlay } from "./styles";

export const CustomNoRowsOverlay = () => {
  const { t } = useTranslation("");
  return (
    <StyledGridOverlay>
      <BackgroundImageSvg />
      <Typography variant="h5" color="secondary" sx={{ mt: 4 }}>
        {t("table.empty_rows")}
      </Typography>
    </StyledGridOverlay>
  );
};
