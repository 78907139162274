import React from "react";
import { FieldErrors, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getOrganizations, useAppDispatch } from "app";

import { Typography } from "@mui/material";
import { FormAutocomplete } from "@sbm/ui-components";
import { ICreateStandardRoute } from "@types";

export interface SelectOrganizationProps {
  organizationId?: number;
  setValue: UseFormSetValue<ICreateStandardRoute>;
  errors: FieldErrors;
}

export const SelectOrganization = (props: SelectOrganizationProps) => {
  const { organizationId, setValue, errors } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [inputOrganizationValue, setInputOrganizationValue] =
    React.useState("");
  const [organizationOptions, setOrganizationOptions] = React.useState<
    { title: string; id?: number }[]
  >([]);

  const getOrganizationDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !organizationOptions.length) return;

      const organization = organizationOptions.find((i) => i.id === id);
      if (!organization) return;

      return organization.title;
    },
    [organizationOptions]
  );

  const fetchOrganizations = React.useCallback(
    async (search: string) => {
      const { items } = await dispatch(getOrganizations(search, true));

      return items
        .map((i: any) => ({
          title: i.briefTextOrganizationName || i.shortName,
          id: i.id,
        }))
        .filter((i: any) => Boolean(i.title));
    },
    [dispatch]
  );

  const handleOrganizationInputChange = (val: string, reason: string) => {
    setInputOrganizationValue(val);

    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      return setValue("organizationId", undefined);
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = organizationOptions.find((i) => i.title === val);
      if (!option) return;
      return setValue("organizationId", option.id);
    }
  };

  return (
    <div>
      <Typography variant="body1" fontWeight={600} sx={{ mb: 2 }}>
        {t("select.organization")}
      </Typography>

      <FormAutocomplete
        required
        inputValue={inputOrganizationValue}
        freeSolo={false}
        label={t("organization")}
        defaultSelected={getOrganizationDefaultValue(organizationId)}
        fetchData={fetchOrganizations}
        onFetchDataSuccess={setOrganizationOptions}
        onInputChange={handleOrganizationInputChange}
        error={!!errors.organizationId}
      />
    </div>
  );
};
