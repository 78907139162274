import {
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
} from "@types";

export interface IAttachmentForm {
  typeOfAttachment?: FileAttachedToCorrespondenceTypeOfAttachmentEnum;
  fileStatusAttachment?: FileAttachedToCorrespondenceFileStatusEnum;
  file: null | File;
}

export const ATTACHMENT_DEFAULT_VALUES: IAttachmentForm = {
  typeOfAttachment: undefined,
  fileStatusAttachment: undefined,
  file: null,
};
