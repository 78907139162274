import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const WarningWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.error.main};
  border-radius: ${({ theme }) => theme.spacing()};
  background-color: ${({ theme }) => hexToRgba(theme.palette.error.main, 0.12)};
  .error_icon {
    ${FLEX_ROW_ALIGN_CENTER}
  }
  .x {
    justify-content: end;
  }
`;
