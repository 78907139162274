import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW } from "ui-kit";

export const FormWrapper = styled.form`
  ${FLEX_COLUMN};
  flex: 1;

  .button-wrapper {
    ${FLEX_ROW};
    justify-content: flex-end;

    width: 100%;

    margin-top: ${({ theme }) => theme.spacing(9)};

    button {
      width: fit-content;
    }
  }

  .cell-customize {
    font-weight: 700;
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;

  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;
