import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

import { Stack, Typography } from "@mui/material";

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(6, 0)};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: 0.7;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const CorrespondentsCount = styled(Stack)`
  ${FLEX_COLUMN_FULL}
  width: 24px;
  height: 24px;
  padding: ${({ theme }) => `${(theme.spacing(1), theme.spacing(3))}`};
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  font-size: 13px;
`;
