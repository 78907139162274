import { axiosService } from "app";

import { IStandardRouteForContract } from "@types";

export const getStandardRouteForContract = async (
  id: string | undefined
): Promise<IStandardRouteForContract | null> => {
  if (!id) return null;

  const { data } = await axiosService({
    endpoint: `/typical-approval-route-for-contracts/${id}`,
  });

  return data;
};
