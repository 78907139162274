export const DEFAULT_FILE_MAX_SIZE = 5 * 1024 * 1024; // 5MB

export enum EnumFilesErrorMessages {
  size = "file-too-large",
  type = "file-invalid-type",
  count = "too-many-files",
}

export interface IMessageForm {
  messageTo?: string;
  messageSubject: string;
  employeeId: number[];
  messageBody: string;
  files: File[] | undefined;
  isImportant: boolean;
}

export const LINKS_LIST = [];
