import React from "react";

import { Popover } from "@mui/material";

import { IOption } from "../../types";
import { FilterFields } from "../FilterFields";

interface SelectFiltersPopoverProps {
  onClose: () => void;
  anchorEl: null | HTMLButtonElement;
  filterableFields: IOption[];
}

export const SelectFiltersPopover: React.FC<SelectFiltersPopoverProps> = ({
  anchorEl,
  filterableFields,
  onClose,
}) => {
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          width: 238,
          maxHeight: 464,
        },
      }}
    >
      <FilterFields filterableFields={filterableFields} onClose={onClose} />
    </Popover>
  );
};
