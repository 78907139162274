import React from "react";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Typography } from "@mui/material";

import { WarningWrapper } from "./styles";

interface Props {
  message: string;
}

export const ErrorItem: React.FC<Props> = ({ message }) => {
  return (
    <WarningWrapper>
      <PriorityHighIcon color="error" />
      <Typography>{message}</Typography>
    </WarningWrapper>
  );
};
