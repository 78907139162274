import styled from "styled-components";
import { FLEX_ROW, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const FileItemWrapper = styled.div`
  background-color: ${({ theme }) => hexToRgba(theme.palette.info.main, 0.04)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2, 4)};
  .link_delete_icon {
    ${FLEX_ROW_ALIGN_CENTER}
  }
  .uploaded_file {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(2)};
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
