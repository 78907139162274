import React from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, closeDrawerWithData } from "app";
import { Modal } from "ui-kit";

import { Button } from "@mui/material";

export const RemoveFilePopup = (props: {
  open: boolean;
  onClose: () => void;
  data: { data: { onRemove: () => void } };
}) => {
  const {
    open,
    onClose,
    data: { data },
  } = props;
  const { t } = useTranslation();
  const { t: translate } = useTranslation("attachments");
  const dispatch = useAppDispatch();

  const content = {
    title: translate("confirm_title"),
    body: translate("confirm_text"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          data.onRemove();
          onClose();
          dispatch(closeDrawerWithData());
        }}
      >
        {t("yes")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
