export function insertUrlParam(key: string, value: string) {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.append(key, value);

    const newurl =
      window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();

    window.history.pushState({ path: newurl }, "", newurl);
  }
}

// to remove the specific key
export function removeUrlParameter(paramKey: string) {
  const url = window.location.href;
  const r = new URL(url);

  r.searchParams.delete(paramKey);

  const newUrl = r.href;

  window.history.pushState({ path: newUrl }, "", newUrl);
}

export function getUrlParam(key: string, type?: "single" | "all") {
  const searchParams = new URLSearchParams(window.location.search);

  let params: null | string | string[] = null;

  if (type === "all") {
    params = searchParams.getAll(key);
  } else {
    params = searchParams.get(key);
  }

  return params;
}
