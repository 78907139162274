import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCreateEmployee, useGetEmployeeById } from "api";
import {
  axiosService,
  getEmployees,
  getPersonalData,
  setModal,
  updateEmployee,
  useAppDispatch,
  useAppSelector,
} from "app";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import {
  Button,
  FormAutocomplete,
  FormRadio,
  FormSelect,
  Input,
} from "@sbm/ui-components";
import { EnumEmployeeType, ICreateEmployee, ModalVariants } from "@types";

import { getStructuredPayload } from "./helpers";
import { ActionsSection, FormWrapper, TitleWrapper } from "./styles";

interface Props {
  data?: ICreateEmployee & { title?: string; employeeIdUpdate?: number };
  isViewMode: boolean;
}

export const CreateStaffMemberContainer = (props: Props) => {
  const { data, isViewMode } = props;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { modal } = useAppSelector((state) => state.modals);

  const organizationId = modal?.content.data.organizationId || "";

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    clearErrors,
  } = useForm<ICreateEmployee>({
    defaultValues: {
      structuralUnitId: data?.structuralUnitId,
      positionId: data?.positionId,
      staffUnitId: data?.staffUnitId,
      employeeType: data?.employeeType || EnumEmployeeType.employee,
      employeeId: data?.employeeId,
      employeeIdUpdate: data?.employeeIdUpdate,
      additionalInformation: data?.additionalInformation,
      validity: data?.validity || true,
    },
  });

  const employeeTypePrevRef = React.useRef(EnumEmployeeType.employee);

  const {
    positionId,
    employeeId,
    validity,
    staffUnitId,
    employeeType,
    additionalInformation,
    employeeIdUpdate,
  } = useWatch({
    control,
  });

  const [loading, setLoading] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const [inputEmployeeValue, setInputEmployeeValue] = React.useState("");

  const [employeeOptions, setEmployeeOptions] = React.useState<
    { title: string; id: number; briefTextOrganizationName?: string }[]
  >([]);

  const { data: employeeData } = useGetEmployeeById(data?.employeeIdUpdate);

  const structuralUnitOptions = React.useMemo(() => {
    if (!data) return [];

    return [{ value: data.structuralUnitId!, option: data.name! }];
  }, [data]);

  const positionOptions = React.useMemo(() => {
    if (!data) return [];

    return [{ value: data.positionId!, option: data.nameOfPosition! }];
  }, [data]);

  const isRequiredFieldsFilled = Boolean(
    staffUnitId && positionId && employeeId
  );

  const additionTypeOptions = [
    {
      option: t("adding.full.time.employee"),
      value: EnumEmployeeType.employee,
    },
    {
      option: t("adding.external.overlap"),
      value: EnumEmployeeType.substitution,
    },
  ];

  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const handleValidityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      data?.employeeType === EnumEmployeeType.employee &&
      employeeData?.hasExternalSubstitutions
    ) {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.invalidateEmployee,
          content: data,
        })
      );
    } else {
      register("validity").onChange(event);
    }
  };

  const handleEmployeeInputChange = (val: string, reason: string) => {
    if (val === inputEmployeeValue) return;

    setInputEmployeeValue(val);
    // Delete action
    if (reason === "clear") {
      clearErrors("employeeId");
      setValue("additionalInformation", "");
      return setValue("employeeId", undefined);
    }
    // Action is select from the list
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;

      if (employeeType === EnumEmployeeType.substitution) {
        setValue("additionalInformation", option.briefTextOrganizationName);
      }
      return setValue("employeeId", option.id);
    }
  };

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return;

      const employee = employeeOptions.find((i) => i.id === id);
      if (!employee) return;

      return employee.title;
    },
    [employeeOptions]
  );

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      const isEmployee = employeeType === EnumEmployeeType.employee;

      const getDataCallback = isEmployee ? getPersonalData : getEmployees;

      // if selected full-time we should use getPersonalData api, if external  - getEmployees
      const { items } = await dispatch(getDataCallback(search));

      const options = items
        .map((i: any) => ({
          title: getPersonalDataName(isEmployee ? i : i.personalData),
          id: i.id,
          briefTextOrganizationName:
            i.staffUnit?.organization?.briefTextOrganizationName,
        }))
        .filter((i: { title: string | undefined }) => Boolean(i.title));

      // We have pagination limit 200 for getting employee list, in case the selected employee is not included adding it manually
      if (employeeId && isViewMode && isEmployee) {
        const exists = options.findIndex(
          (i: typeof items) => i.id === employeeId
        );

        if (exists === -1) {
          try {
            const resp = await axiosService({
              endpoint: `personal-data/${employeeId}`,
            });

            const optionToAdd = {
              title: getPersonalDataName(resp.data),
              id: resp.data.id,
            };

            options.push(optionToAdd);
          } catch (e) {
            console.log(e, "eee");
          }
        }
      }

      return options;
    },
    [dispatch, employeeType, employeeId, isViewMode]
  );

  const handleUpdateEmployee = async (form: Partial<ICreateEmployee>) => {
    if (!employeeId || !organizationId) return;

    await dispatch(
      updateEmployee(form, String(employeeIdUpdate), organizationId)
    );

    setLoading(false);
    dispatch(setModal(null));
  };

  const ModalTitle = React.useMemo(() => {
    if (isViewMode && data && data.title) return data.title;

    if (employeeType === EnumEmployeeType.employee)
      return t("adding.staff.member");

    return t("adding.external.overlap");
  }, [isViewMode, data, employeeType, t]);

  const { mutate: createEmployee, isLoading } = useCreateEmployee();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    const dataToSend = getStructuredPayload(data, isViewMode);

    if (isEditMode) {
      const editedData = {
        additionalInformation: dataToSend.additionalInformation,
        validity: dataToSend.validity,
        employeeType: dataToSend.employeeType,
      };

      if (data.validity === false) {
        return dispatch(
          setModal({
            open: true,
            variant: ModalVariants.confirmDisableStaffMember,
            content: {
              confirmNavigation: () => handleUpdateEmployee(editedData),
            },
          })
        );
      }

      await handleUpdateEmployee(editedData);
    } else {
      createEmployee(dataToSend, organizationId);
    }

    setLoading(false);
    dispatch(setModal(null));
  });

  React.useEffect(() => {
    if (employeeType && !isViewMode) {
      setValue("additionalInformation", "");
      setValue("employeeId", undefined);
    }
  }, [employeeType, setValue, isViewMode]);

  React.useEffect(() => {
    if (employeeType && employeeType !== employeeTypePrevRef.current) {
      employeeTypePrevRef.current = employeeType;
    }
  }, [employeeType]);

  return (
    <FormWrapper noValidate onSubmit={onSubmit}>
      <TitleWrapper>
        <Typography variant="h6" fontWeight={500}>
          {ModalTitle}
        </Typography>

        {isViewMode ? (
          <Button
            fullWidth={false}
            variant="contained"
            color="inherit"
            size="small"
            onClick={toggleEditMode}
            startIcon={isEditMode ? <ArrowBackIcon /> : <EditIcon />}
          >
            {isEditMode ? t("back") : t("edit")}
          </Button>
        ) : null}
      </TitleWrapper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormSelect
            readOnly
            label={t("structural.unit")}
            name="structuralUnitId"
            control={control}
            values={structuralUnitOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            readOnly
            label={t("position")}
            name="positionId"
            control={control}
            values={positionOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormRadio
            disabled={isViewMode}
            name="employeeType"
            control={control}
            values={additionTypeOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormAutocomplete
            required
            readOnly={isViewMode}
            disabled={!employeeType}
            inputValue={inputEmployeeValue}
            freeSolo={false}
            label={t("employee.fullname")}
            fetchData={fetchEmployees}
            defaultSelected={getEmployeeDefaultValue(employeeId)}
            onFetchDataSuccess={setEmployeeOptions}
            onInputChange={handleEmployeeInputChange}
            error={!!errors.employeeId}
          />
        </Grid>

        <Grid item xs={12} sm={7}>
          <Input
            shrink
            value={additionalInformation}
            readOnly={
              employeeType === EnumEmployeeType.substitution ||
              (isViewMode && !isEditMode)
            }
            label={
              employeeType === EnumEmployeeType.employee
                ? t("additional.information")
                : t("name.of.organization")
            }
            maxLength={300}
            {...register("additionalInformation")}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <FormControlLabel
            label={
              employeeType === EnumEmployeeType.substitution
                ? t("valid.substitution")
                : t("valid.employee")
            }
            labelPlacement="start"
            disabled={(isViewMode && !isEditMode) || !isViewMode}
            control={
              <Switch
                defaultChecked={validity}
                {...register("validity")}
                onChange={handleValidityChange}
              />
            }
          />
        </Grid>
      </Grid>

      <ActionsSection>
        {isViewMode && !isEditMode ? (
          <Button
            variant="contained"
            fullWidth
            onClick={() => dispatch(setModal(null))}
          >
            {t("close")}
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            type="submit"
            loading={loading}
            disabled={
              loading ||
              !isRequiredFieldsFilled ||
              (isEditMode && !Object.keys(dirtyFields).length)
            }
          >
            {t("save")}
          </Button>
        )}
      </ActionsSection>
    </FormWrapper>
  );
};
