import { MuiTheme } from "ui-kit";

import { SxProps, Theme } from "@mui/material";

export const UI_CONFIG = {
  drawerWidth: 270,
  drawerMobileWidth: 72,
  submitButton: 240,
  header: 64,
  rightDrawerWidth: "600px",
  rightDrawerFullWidth: "90%",
  modalWidth: 600,
  modalButton: 150,
  contractCardMaxWidth: 522,
  chipWidth: 150,
  chipWidthLarge: 240,
  typography: {
    title: { textTransform: "uppercase", textAlign: "right" } as
      | SxProps<Theme>
      | undefined,
    subtitle: {
      textTransform: "uppercase",
      color: MuiTheme.palette.warning.dark,
    } as SxProps<Theme> | undefined,
  },
};
