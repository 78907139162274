import React from "react";
import { UseFormReturn } from "react-hook-form";

import {
  ICreateTask,
  IExecutorOption,
  PotentialExecutor,
  TExecutionRequirement,
  TExecutorOfTheTask,
  TRegistration,
} from "@types";

import {
  Executors,
  AdditionalInformation,
  ExecutionRequirements,
} from "./sections";

interface Props {
  form: UseFormReturn<ICreateTask>;
  data: PotentialExecutor[];
  selectedOptions: IExecutorOption[];
  setSelectedOptions: (arg: IExecutorOption[]) => void;
  requirements?: TExecutionRequirement | undefined;
  registration?: TRegistration | undefined;
  executorOfTheTask?: TExecutorOfTheTask | undefined;
  isResolutionForTheDocument?: boolean;
}

export const SelectOfExecutors = ({
  form,
  data,
  selectedOptions,
  setSelectedOptions,
  requirements,
  registration,
  isResolutionForTheDocument,
}: Props) => {
  return (
    <>
      <Executors
        form={form}
        data={data}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <ExecutionRequirements
        form={form}
        requirements={requirements}
        isResolutionForTheDocument={isResolutionForTheDocument}
      />
      <AdditionalInformation form={form} registration={registration} />
    </>
  );
};
