import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { TIconNames } from "@types";

import { WidgetAction } from "./components/footer";
import { WidgetHeader } from "./components/header";
import { WidgetMaintenanceMode } from "./components/maintenanceMode";
import {
  WidgetWrapper,
  WidgetDescriptionWrapper,
  WidgetChartWrapper,
} from "./styles";

export interface WidgetWithChartProps {
  title: string;
  description?: string;
  icon?: TIconNames | null;
  href?: string;
  chart?: JSX.Element | null;
  maintenanceMode?: boolean;
}

export const WidgetWithChart = ({
  title,
  icon,
  href,
  maintenanceMode,
  chart,
}: WidgetWithChartProps) => {
  const { t } = useTranslation();

  return (
    <WidgetWrapper>
      <WidgetHeader href={href} icon={icon} title={title} />

      {!maintenanceMode ? (
        <>
          <WidgetDescriptionWrapper>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Общее количество действующих договоров
              </Typography>
              <Typography fontSize={20} fontWeight={700} color="text.secondary">
                {250}
              </Typography>
            </div>
          </WidgetDescriptionWrapper>

          <WidgetChartWrapper>{chart}</WidgetChartWrapper>

          <WidgetAction href={href} />
        </>
      ) : (
        <WidgetMaintenanceMode />
      )}
    </WidgetWrapper>
  );
};
