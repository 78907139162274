import { TFunction } from "i18next";

import {
  EnumApproverType,
  IApprover,
  ICreateStandardRoute,
  IStructuralUnit,
} from "@types";

const isStructuralUnitApprover = (approverType: EnumApproverType | string) =>
  approverType !== EnumApproverType.project_manager &&
  approverType !== EnumApproverType.signatory_of_the_contract &&
  approverType !== EnumApproverType.initiator_of_the_contract;

const getApproverValue = (approverType: string, t: TFunction) => {
  switch (approverType) {
    case t(EnumApproverType.project_manager):
      return EnumApproverType.project_manager;
    case t(EnumApproverType.initiator_of_the_contract):
      return EnumApproverType.initiator_of_the_contract;
    case t(EnumApproverType.signatory_of_the_contract):
      return EnumApproverType.signatory_of_the_contract;
    default:
      return approverType;
  }
};

export const getFormattedDataToSend = (
  form: ICreateStandardRoute,
  structuralUnits: Partial<IStructuralUnit>[],
  t: TFunction
) => {
  const approvers = form?.approvers?.map((i) => {
    const approverValue = getApproverValue(i.approverType!, t);

    const initialData: IApprover = {
      approverType: approverValue,
      queueNumber: i.queueNumber,
    };

    if (isStructuralUnitApprover(initialData.approverType!)) {
      const structuralUnitId = structuralUnits.find(
        (unit) => unit.nameOfStructuralUnit === initialData.approverType
      )?.id;

      initialData["structuralUnitId"] = structuralUnitId;
      initialData["approverType"] = EnumApproverType.structural_unit;
    } else {
      delete initialData["structuralUnitId"];
    }

    return initialData;
  });

  delete form["routes"];

  return { ...form, approvers };
};
