import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Icon } from "ui-kit";

import { useTheme } from "@mui/material";
import { Button } from "@sbm/ui-components";

import { WidgetActionWrapper } from "./styles";

export interface WidgetActionProps {
  href?: string;
}

export const WidgetAction = ({ href }: WidgetActionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  if (!href) return <div />;

  return (
    <WidgetActionWrapper>
      <Button
        variant="text"
        color="inherit"
        size="medium"
        onClick={() => navigate(href)}
      >
        <div className="manage_button">
          {t("manage")}
          <Icon name={"ArrowRight"} color={theme.palette.text.primary} />
        </div>
      </Button>
    </WidgetActionWrapper>
  );
};
