import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

import {
  formControlLabelClasses,
  formGroupClasses,
  radioClasses,
} from "@mui/material";

export const Wrapper = styled.div`
  height: 100%;

  form {
    height: 100%;
    .dropzone__uploadingProcess {
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }
  }

  .radio__buttons {
    > label {
      margin-left: 0;
    }
  }

  .drop_area {
    height: 70px;
    max-width: 100%;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
    border: dashed 1px ${({ theme }) => theme.palette.grey[400]};

    path {
      fill: ${({ theme }) => theme.palette.info.main};
    }

    span {
      font-size: 14px;
      margin-left: ${({ theme }) => theme.spacing(2)};
    }

    .file-types {
      display: none;
    }
  }

  .${formGroupClasses.root} {
    .${formControlLabelClasses.root} {
      .${radioClasses.root} {
        flex: 0;
      }
    }
  }
`;

export const LabelWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
`;
