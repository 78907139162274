import { TSupAgreementParty } from "../app/containers/sup-agreement/CreateSAContainer/types";
import { IEmployee, IPersonalData } from "./organizations";
import { EnumApproverStatusType } from "./standardRoutes";

export interface IContractEntity {
  id: number;
  subjectBriefly: string;
  subjectInMoreDetails: string;
  party1OrganizationName: string;
  party1OrganizationId: number;
  party2OrganizationName: string;
  party2PersonalDataFullName: string;
  party2OrganizationId: number;
  party3OrganizationName: string | null;
  party3OrganizationId: number | null;
  party1RoleName: string;
  party1RoleId: number;
  party2RoleName: string;
  party2RoleId: number;
  party3RoleName: string | null;
  party3RoleId: number | null;
  contractNumber: string;
  contractCategoryName: string;
  contractCategoryId: number;
  contractTypeName: string;
  contractTypeId: number;
  contractSubtypeName: string;
  contractSubtypeId: number;
  contractStatus: string;
  constructionComplexName: string;
  constructionComplexId: number;
  dateOfSigning: string;
  expirationDate: string;
  contractTotalPrice: number;
  supplementaryAgreementsSigned: number;
  createdAt: string;
  updatedAt: string;
}

export interface IContractFrameEntity {
  id: number | string;
  contractNumber: number | string;
  dateOfSigning: string;
  expirationDate: string;
  party1OrganizationName: string;
  party2OrganizationName: string;
  contractStatus: string;
  category: string;
}

export enum OperatesOnBasisEnum {
  charter = "charter",
  powerOfAttorney = "power_of_attorney",
}

export enum ContractStatusEnum {
  draft = "draft",
  preparation = "preparation",
  inAgreement = "in_agreement",
  inAction = "in_action",
  finalSigned = "final_signed",
  finalSigning = "final_signing",
  underSigning = "under_signing",
  rejected = "rejected",
  paused = "paused",
  terminated = "terminated",
  disputed = "disputed",
  canceled = "canceled",
  executed = "executed",
}

export interface ICurrentUserApprovals {
  position: string;
  personalData: IPersonalData | null;
  date: string;
  comment: string;
  result: EnumApproverStatusType;
  iterationNumber: number;
}

export interface IApprovalForHomepage {
  document: string;
  dateReceived: string;
  plannedApprovalDate: string;
  actualApprovalDate: string;
  status: EnumApproverStatusType;
  statusForDocument: EnumApproverStatusType;
  contractId: number;
}

export enum StorageOrderEnum {
  general = "general",
  special = "special",
}

export interface ISupAgreementForm {
  id?: number;
  contractId?: number;
  typeSupplementaryAgreementId?: number;
  initiatorEmployeeId?: number;
  numberSupplementaryAgreement?: string;
  basisSupplementaryAgreement?: string;
  storageOrderForOriginal?: "common" | "special";
  statusSupplementaryAgreement?: ContractStatusEnum;
  dateOfSigningSupplementaryAgreement?: Date;
  dateOfStatusChanging?: Date;
  briefTextMeaningSupplementaryAgreement?: string;
  fullTextMeaningSupplementaryAgreement?: string;
  numberOfAppendices?: number;
  updatedAt?: Date;
  createdAt?: Date;
  parties?: TSupAgreementParty[];
  responsibleEmployeeId?: number;
  initiatorEmployee?: IEmployee | { title: string; id: number };
  presigned: boolean;
}
