import toast from "react-hot-toast";

import { useAppDispatch } from "app";
import { AxiosError } from "axios";

import { getAPIErrorMessage, SnackbarMessages } from "@sbm/fe-utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IMessage,
  IViewMessage,
  MessageViewResponse,
  ISendMessage,
  IMeta,
  ConversationResponse,
  IAttachLink,
} from "@types";

import {
  getMessageById,
  viewMessage,
  fetchMessages,
  sendMessage,
  getConversation,
  getLinks,
} from "../../requests";

export const useUserMessages = () => {
  return useInfiniteQuery<{ items: IMessage[]; meta: IMeta }>(
    ["get_user_messages"],
    fetchMessages,
    {
      retry: false,
      cacheTime: 10000,
      staleTime: 10000,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetMessageInfo = ({ id }: { id: number | undefined }) => {
  const queryClient = useQueryClient();

  return useQuery<MessageViewResponse | undefined>(
    ["get_message_by_id", id],
    async () => await getMessageById(id),
    {
      enabled: Boolean(id),
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_user_messages"]);
      },
      onError: (err) => {
        console.log(err, "get_message_by_id");
      },
    }
  );
};

export const useGetConversation = ({
  conversationId,
  userId,
  isMessageFromSystem,
}: {
  conversationId: number;
  userId: number;
  isMessageFromSystem: boolean;
}) => {
  return useQuery<ConversationResponse[]>(
    ["get_conversation", conversationId, userId, isMessageFromSystem],
    async () =>
      await getConversation(userId, conversationId, isMessageFromSystem),
    {
      enabled: Boolean(userId),
      retry: false,
      refetchInterval: 10000, // Polling interval
    }
  );
};

export const useGetDocumentLinks = (
  type: string | undefined = "",
  search = ""
) => {
  return useInfiniteQuery<{
    items: IAttachLink[];
    meta: IMeta;
  }>(
    ["get_links", search, type],
    (params) => getLinks(params, type, search),

    {
      retry: false,
      enabled: Boolean(type),
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useViewMessageMutation = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IViewMessage) => {
      await viewMessage(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_user_messages"]);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useSendMessageMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: ISendMessage) => {
      await sendMessage(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_user_messages"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};

export const useSendMessageFromChatMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: ISendMessage) => {
      await sendMessage(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_conversation"]);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};
