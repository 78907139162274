import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormControlLabel, Grid, Switch } from "@mui/material";
import { FormSelect, Input, NumericInput } from "@sbm/ui-components";
import { IOrganization, TaxSystemEnum } from "@types";

import {
  defaultValues,
  innLength,
  kppLength,
  ogrnLength,
  okpoLength,
} from "../../constants";
import { FormContent, FormWrapper } from "./styles";

interface Props {
  control: Control<IOrganization>;
  register: UseFormRegister<IOrganization>;
  errors: FieldErrors<IOrganization>;
}

export const IdentificationForm = (props: Props) => {
  const { t } = useTranslation();

  const { control, register, errors } = props;

  const fields = useWatch({ control });

  const {
    fullTextOrganizationName,
    briefTextOrganizationName,
    inHolding,
    abbreviation,
    inn,
    kpp,
    ogrn,
    okpo,
  } = fields;

  const taxSystemOptions = Object.values(TaxSystemEnum).map((value) => ({
    option: t(value),
    value,
  }));

  const categoryOptions = [
    {
      option: "None",
      value: "",
    },
  ];

  const serviceTypeOptions = [
    {
      option: "None",
      value: "",
    },
  ];

  return (
    <FormWrapper noValidate>
      <FormContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              autoFocus
              value={fullTextOrganizationName}
              label={t("full.name.of.organization")}
              maxLength={500}
              {...register("fullTextOrganizationName")}
              error={!!errors.fullTextOrganizationName}
              helperText={
                errors.fullTextOrganizationName &&
                "The field is mandatory to fill in"
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              required
              value={briefTextOrganizationName}
              label={t("short.name.of.organization")}
              maxLength={100}
              {...register("briefTextOrganizationName")}
              error={!!errors.briefTextOrganizationName}
              helperText={
                errors.briefTextOrganizationName &&
                "The field is mandatory to fill in"
              }
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="inn"
              control={control}
              rules={{
                validate: (value) => {
                  const pattern = /^[0-9]*$/;
                  const errorMessage =
                    "Must contain only an integer number of not less than 8 and not more than 12 digits.";

                  if (value && !pattern.test(value)) return errorMessage;

                  if (
                    String(value).length > innLength.min - 1 &&
                    String(value).length < innLength.max + 1
                  )
                    return;

                  return errorMessage;
                },
              }}
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    required
                    label={t("inn")}
                    type="number"
                    maxLength={12}
                    error={
                      (inn &&
                        `${inn}`.length > 0 &&
                        `${inn}`.length < innLength.min) ||
                      `${inn}`.length > innLength.max ||
                      !!errors.inn
                    }
                    helperText={
                      (inn &&
                        `${inn}`.length > 0 &&
                        `${inn}`.length < innLength.min) ||
                      `${inn}`.length > innLength.max ||
                      !!errors.inn
                        ? "Must contain only an integer number of not less than 8 and not more than 12 digits."
                        : null
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="kpp"
              control={control}
              rules={{
                validate: (value) => {
                  if (!value) return;
                  if (String(value).length === kppLength.min) return;
                  return "Must contain only an integer number of 9 digits.";
                },
              }}
              render={({ field }) => {
                return (
                  <NumericInput
                    {...field}
                    decimalScale={0}
                    allowNegative={false}
                    label={t("kpp")}
                    maxLength={9}
                    error={
                      kpp
                        ? `${kpp}`.length > 0 && `${kpp}`.length < kppLength.min
                        : false
                    }
                    helperText={
                      kpp
                        ? `${kpp}`.length > 0 && `${kpp}`.length < kppLength.min
                          ? "Must contain only an integer number of 9 digits."
                          : null
                        : null
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="ogrn"
              control={control}
              rules={{
                validate: (value) => {
                  if (!value) return;
                  if (
                    String(value).length > ogrnLength.min - 1 &&
                    String(value).length < ogrnLength.max + 1
                  )
                    return;
                  return "Must contain only an integer number of not less than 13 and not more than 15 digits.";
                },
              }}
              render={({ field }) => {
                return (
                  <NumericInput
                    {...field}
                    decimalScale={0}
                    allowNegative={false}
                    label={t("ogrn")}
                    maxLength={15}
                    error={
                      ogrn
                        ? `${ogrn}`.length > 0 &&
                          `${ogrn}`.length < ogrnLength.min
                        : false
                    }
                    helperText={
                      ogrn
                        ? `${ogrn}`.length > 0 &&
                          `${ogrn}`.length < ogrnLength.min
                          ? "Must contain only an integer number of not less than 13 and not more than 15 digits."
                          : null
                        : null
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="okpo"
              control={control}
              rules={{
                validate: (value) => {
                  if (!value) return;
                  if (
                    String(value).length === okpoLength.min ||
                    String(value).length === okpoLength.max
                  )
                    return;
                  return "Must contain only an integer number of not less than 8 and not more than 10 digits.";
                },
              }}
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    required
                    label={t("okpo")}
                    maxLength={10}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      field.onChange(numericValue);
                    }}
                    error={okpo ? !/^\d{8,10}$/.test(okpo) : !!errors.okpo}
                    helperText={
                      okpo
                        ? !/^\d{13,15}$/.test(okpo)
                          ? "Must contain only an integer number of not less than 8 and not more than 10 digits."
                          : null
                        : null
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="taxSystem"
              label={t("taxation.system")}
              control={control}
              values={taxSystemOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="serviceType"
              label={t("type.of.service")}
              disabled
              control={control}
              values={serviceTypeOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="category"
              label={t("counterpartys.category")}
              control={control}
              values={categoryOptions}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label={t("included.in.ev.group")}
              control={
                <Switch
                  color="secondary"
                  {...register("inHolding")}
                  defaultChecked={!!defaultValues?.inHolding}
                />
              }
            />
          </Grid>
          {inHolding ? (
            <>
              <Grid item xs={12}>
                <Input
                  value={abbreviation}
                  label={t("abbreviation")}
                  maxLength={100}
                  {...register("abbreviation")}
                  error={!!errors.abbreviation}
                  helperText={
                    errors.abbreviation && "The field is mandatory to fill in"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={t("widget.on")}
                  control={
                    <Switch
                      color="secondary"
                      {...register("widgetOn")}
                      defaultChecked={false}
                    />
                  }
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </FormContent>
    </FormWrapper>
  );
};
