import styled from "styled-components";
import {
  FLEX_COLUMN,
  FLEX_ROW,
  FLEX_ROW_ALIGN_CENTER,
  FLEX_ROW_FULL,
} from "ui-kit";

import { Chip } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { hexToRgba } from "@sbm/fe-utils";

import SA_image_en from "../../../assets/backgrounds/SA_image_en.svg";
import SA_image_ru from "../../../assets/backgrounds/SA_image_ru.svg";

export const CCWrapper = styled.div<{ $isMaximized: boolean }>`
  ${FLEX_COLUMN};

  max-width: ${({ $isMaximized }) => ($isMaximized ? "" : "552px")};
  min-height: 231px;
  display: flex;
  flex-direction: row;

  background-color: ${({ theme }) => theme.palette.background.saCard};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(4)};

  .cc__date-section {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(5)};
  }

  .cc__date-wrapper {
    ${FLEX_ROW};
    align-items: flex-end;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(5)};

    width: 100%;

    position: absolute;
    bottom: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(0, 4)};
  }
`;

export const CCContent = styled.div`
  ${FLEX_COLUMN};
  flex: 1;

  .cc__title {
    min-height: 22px;
  }

  .cc__subtitle {
    min-height: 24px;
    color: ${({ theme }) => hexToRgba(theme.palette.primary.contrastText, 0.7)};
  }

  .cc__title-wrapper {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(2)};
  }

  .cc__title-text {
    max-width: 100%;
  }
`;

export const EmptyStatusWrapper = styled(Chip)`
  ${FLEX_ROW_FULL};
  background-color: ${blueGrey[500]};
  color: white;
  font-weight: 600;
`;
export const CardImageWrapper = styled.div<{ $isEnglish: boolean }>`
  background-image: ${({ $isEnglish }) =>
    $isEnglish ? `url(${SA_image_en})` : `url(${SA_image_ru})`};
  background-position: center;
  height: 221px;
  width: 444px;
  background-repeat: no-repeat;
`;

export const ImageWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: end;
  width: 100%;
`;

export const PartiesWrapper = styled.div`
  ${FLEX_ROW};
  gap: ${({ theme }) => theme.spacing(2)};

  .left_borders_section {
    padding: 6px 0;
    .left_borders {
      height: 100%;
      width: ${({ theme }) => theme.spacing()};
      border-left: 1px solid ${({ theme }) => theme.palette.secondary.main};
      border-right: 1px solid ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;
export const CountWrapper = styled.div`
  width: 40px;
  margin-left: ${({ theme }) => theme.spacing()};
`;
