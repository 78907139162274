import React from "react";

import { TFunction } from "i18next";

import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { EnumApproverType, IStructuralUnit } from "@types";

export const getTableColumns = (
  t: TFunction,
  rowModesModel: GridRowModesModel,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
  setRows: React.Dispatch<React.SetStateAction<GridValidRowModel[]>>,
  rows: GridValidRowModel[],
  structuralUnits: Partial<IStructuralUnit>[],
  apiRef: React.MutableRefObject<any>
) => {
  const approverOptions = [
    t(EnumApproverType.project_manager),
    ...structuralUnits.map((i) => i.nameOfStructuralUnit),
  ];

  const newRows = apiRef?.current?.getSortedRows?.() || [];
  const isProjectManagerSelected = newRows.findIndex(
    (item: { approverType: EnumApproverType }) =>
      item.approverType === t(EnumApproverType.project_manager)
  );

  if (isProjectManagerSelected > -1) {
    approverOptions.shift();
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);

    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  return [
    {
      field: "approverType",
      headerName: t("approval.subject") as string,
      flex: 1,
      sortable: false,
      filterable: false,
      editable: true,
      type: "singleSelect",
      valueOptions: approverOptions.length > 0 ? approverOptions : undefined,
      valueGetter: (params) => t(params.row.approverType),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        if (
          row.approverType === t(EnumApproverType.initiator_of_the_contract) ||
          row.approverType === t(EnumApproverType.signatory_of_the_contract)
        )
          return [];

        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key="save-button"
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key="cancel-button"
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            key="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="Delete"
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ] as GridColDef[];
};
