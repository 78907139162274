import React from "react";

export const BackgroundImageSvg = () => {
  return (
    <svg
      width="549"
      height="231"
      viewBox="0 0 549 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M349.353 231C304.565 231 279.55 231 240.17 231C192.687 231 136.041 229.108 119.643 193.37C97.8259 149.483 88.6321 96.0877 137.854 73.394C187.075 50.7002 241.597 70.4329 299.88 55.4551C326.493 49.9104 335.546 -5.67563 388.738 0.47432C416.537 3.6898 457.738 44.7953 477.491 86.2075C499.7 138.724 435.877 224.832 373.444 229.142L349.353 231Z"
        fill="#3B608C"
        fillOpacity="0.04"
      />
      <path
        d="M320.183 231C271.622 231 244.501 231 201.805 231C150.323 231 114.934 227.832 100 212.5C62.5 174 66.3778 122.377 119.744 101.353C173.111 80.3294 203.352 82.2495 266.544 68.374C295.398 63.2374 301.829 12.3026 359.5 18C389.64 20.9788 437.695 58.4987 459.111 96.8633C504.961 179 413.993 225.286 346.303 229.279L320.183 231Z"
        fill="#3B608C"
        fillOpacity="0.04"
      />
      <path
        d="M274.121 30.748C277.248 23.7319 276.432 13.2232 265.719 14.1732C258.04 14.8549 246.835 10.3903 243.711 17.4047C240.584 24.4208 248.658 34.7604 255.701 37.8507C262.747 40.9446 270.99 37.7571 274.111 30.7445L274.121 30.748Z"
        fill="#3B608C"
        fillOpacity="0.12"
      />
      <path
        d="M548.943 230.414C548.943 230.682 426.048 230.911 274.486 230.911C122.923 230.911 0 230.682 0 230.414C0 230.146 122.866 229.916 274.486 229.916C426.105 229.916 548.943 230.146 548.943 230.414Z"
        fill="#1B2C41"
      />
      <path
        d="M255.316 123.549L237.301 217.244L234.943 229.51L359.678 228.912C365.073 228.886 369.857 224.037 371.594 216.837L392.921 128.378C394.24 122.91 391.346 117.225 387.25 117.225L261.058 117.354C258.368 117.354 256.019 119.895 255.316 123.549Z"
        fill="#3B608C"
      />
      <path
        d="M341.403 58.7928L278.541 38.6146L249.018 132.734L311.88 152.912L341.403 58.7928Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M282.284 57.7962L305.092 65.1172L305.436 64.0185L282.629 56.6975L282.284 57.7962ZM325.142 81.2273L279.527 66.5853L279.872 65.4866L325.487 80.1286L325.142 81.2273ZM276.77 75.3746L322.385 90.0166L322.729 88.9179L277.114 74.2759L276.77 75.3746ZM319.628 98.8058L274.013 84.1638L274.358 83.0651L319.973 97.7071L319.628 98.8058ZM316.871 107.595L271.256 92.9529L271.601 91.8543L317.215 106.496L316.871 107.595Z"
        fill="#1B2C41"
        fillOpacity="0.45"
      />
      <path
        d="M237.189 46.4258L176.274 71.9941L213.683 163.199L274.598 137.63L237.189 46.4258Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.359 84.2167L213.46 74.94L213.024 73.8754L190.922 83.1521L191.359 84.2167ZM239.055 74.1805L194.852 92.7338L194.416 91.6692L238.618 73.1159L239.055 74.1805ZM198.346 101.251L242.548 82.6975L242.111 81.6329L197.909 100.186L198.346 101.251ZM246.042 91.2146L201.84 109.768L201.403 108.703L245.605 90.1499L246.042 91.2146ZM249.535 99.7315L205.333 118.285L204.896 117.22L249.098 98.6669L249.535 99.7315Z"
        fill="#1B2C41"
        fillOpacity="0.45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M348.143 210.895L335.411 107.093C334.891 102.841 331.539 99.666 327.586 99.6815L299.661 99.7742C297.593 99.7794 295.611 100.671 294.144 102.248L280.887 116.503L203.229 116.916C198.485 116.941 194.838 121.482 195.464 126.589L207.289 222.527C207.814 226.773 211.156 229.943 215.104 229.933L350.446 229.665C365.521 229.979 366.958 225.48 366.958 225.48C349.681 229.35 348.143 210.91 348.143 210.9V210.895ZM291.546 180.678L286.981 185.641L271.926 171.939L258.303 186.739L253.259 182.147L266.881 167.347L251.827 153.646L256.392 148.688L271.446 162.387L285.065 147.591L290.114 152.178L276.491 166.978L291.546 180.678Z"
        fill="#7E97B5"
      />
    </svg>
  );
};
