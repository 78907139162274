import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from "date-fns";

import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  capitalize,
  getStorageItem,
  isValidDate,
  STORAGE_KEYS,
} from "@sbm/fe-utils";

import { formatDateFiltersQuery } from "./helpers";

const locale = getStorageItem(STORAGE_KEYS.lang);
const isEnglish = locale === "en";

const dateFormat = `dd/${isEnglish ? "MMM" : "MM"}/yyyy`;

export interface FilterRangePickerProps {
  filterValue: string | string[];
  onChange: (date: string) => void;
}

export const FilterRangePicker = (props: FilterRangePickerProps) => {
  const { onChange, filterValue } = props;

  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [activeOption, setActiveOption] = useState("");

  const handleTodayClick = useCallback(() => {
    setStartDate(new Date());
    setEndDate(new Date());
    setActiveOption(t("today"));
  }, [t]);

  const handleThisWeekClick = useCallback(() => {
    const dateCopy = new Date();
    const startOfThisWeek = startOfWeek(dateCopy, { weekStartsOn: 1 });
    const endOfTheWeek = endOfWeek(dateCopy, { weekStartsOn: 1 });

    setStartDate(startOfThisWeek);
    setEndDate(endOfTheWeek);
    setActiveOption(t("this.week"));
  }, [t]);

  const handleThisMonthClick = useCallback(() => {
    const dateCopy = new Date();
    const startOfTheMonth = startOfMonth(dateCopy);
    const endOfTheMonth = endOfMonth(dateCopy);

    setStartDate(startOfTheMonth);
    setEndDate(endOfTheMonth);
    setActiveOption(t("this.month"));
  }, [t]);

  const handleRangeClick = () => {
    setActiveOption(t("range"));
    setStartDate(null);
    setEndDate(null);
  };

  const options = [
    {
      name: t("today"),
      onChange: handleTodayClick,
    },
    {
      name: t("this.week"),
      onChange: handleThisWeekClick,
    },
    {
      name: t("this.month"),
      onChange: handleThisMonthClick,
    },
    {
      name: t("range"),
      onChange: handleRangeClick,
    },
  ];

  useEffect(() => {
    if (
      !startDate ||
      !endDate ||
      !isValidDate(startDate) ||
      !isValidDate(endDate)
    )
      return;

    onChange(
      format(startDate, dateFormat) + " | " + format(endDate, dateFormat)
    );
  }, [startDate, endDate, onChange]);

  useEffect(() => {
    if (!startDate && !endDate && filterValue.length > 0) {
      const filterToSet = filterValue[0];
      const {
        isTodaySelected,
        isThisWeekSelected,
        isThisMonthSelected,
        isRangeSelection,
        startDate,
        endDate,
      } = formatDateFiltersQuery(filterToSet, dateFormat);

      switch (true) {
        case isTodaySelected:
          return handleTodayClick();
        case isThisWeekSelected:
          return handleThisWeekClick();
        case isThisMonthSelected:
          return handleThisMonthClick();
        case isRangeSelection:
          setActiveOption(t("range"));
          setStartDate(startDate);
          setEndDate(endDate);
          return;
      }
    }
  }, [
    endDate,
    filterValue,
    handleThisMonthClick,
    handleThisWeekClick,
    handleTodayClick,
    startDate,
    t,
  ]);

  useEffect(() => {
    const handleResetFilters = () => {
      setStartDate(null);
      setEndDate(null);
      setActiveOption("");
    };

    // This event is triggered from the parent component (CustomFilters.tsx), in order to update state in the child we are listening to this event
    window.addEventListener("reset_date_selection", handleResetFilters);

    return () =>
      window.removeEventListener("reset_date_selection", handleResetFilters);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <RadioGroup>
        {options.map(({ name, onChange }, index) => {
          return (
            <FormControlLabel
              key={`${name}-${index}`}
              value={name}
              checked={name === activeOption}
              control={<Radio color="secondary" onClick={onChange} />}
              label={capitalize(name || "")}
            />
          );
        })}
      </RadioGroup>

      {activeOption === t("range") && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={11.2} sm={5.6}>
            <MuiDatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              format={dateFormat}
              slotProps={{
                textField: {
                  size: "small",
                  variant: "outlined",
                },
              }}
              label={t("from")}
              sx={{ maxWidth: 130 }}
            />
          </Grid>
          <Grid item xs={0.8}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 2.5 }}
            >
              -
            </Stack>
          </Grid>
          <Grid item xs={11.2} sm={5.6}>
            <MuiDatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
              format={dateFormat}
              slotProps={{
                textField: {
                  size: "small",
                  variant: "outlined",
                },
              }}
              label={t("to")}
              sx={{ maxWidth: 130 }}
            />
          </Grid>
        </Grid>
      )}
    </LocalizationProvider>
  );
};
