import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { Card, formControlLabelClasses } from "@mui/material";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const SinglePartWrapper = styled(Card)`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4, 2, 0, 2)};

  .single-part__form {
    background-color: ${({ theme }) => theme.palette.grey[50]};

    padding: ${({ theme }) => theme.spacing(4)};
    border-radius: ${({ theme }) => theme.spacing()};

    &-title {
      ${FLEX_ROW_ALIGN_CENTER};
      justify-content: space-between;

      margin-bottom: ${({ theme }) => theme.spacing(3)};
    }

    .${formControlLabelClasses.root} {
      margin-left: ${({ theme }) => theme.spacing(-1)};
      margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
  }

  .parties-form__actions {
    ${FLEX_ROW};

    button {
      max-width: fit-content;
    }
  }

  .singlePartyForm_item {
    margin: ${({ theme }) => theme.spacing(0, 2, 4, 2)};
  }
`;
