import React from "react";
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  axiosService,
  IModal,
  setLoading,
  useAppDispatch,
  useAppSelector,
} from "app";
import { AxiosError } from "axios";

import { Alert, Typography } from "@mui/material";
import {
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { getAPIErrorMessage } from "@sbm/fe-utils";
import { Table } from "@sbm/ui-components";
import {
  IContractSubtype,
  IContractType,
  ICreateStandardRoute,
  IDefaultRoute,
  ModalVariants,
} from "@types";

import { EditToolbar } from "../SelectApprovers/EditToolbar";
import { SectionWrapper } from "../styles";
import {
  getContractTypeAndSubtype,
  getFormattedRoutesToAssign,
  getTableColumns,
} from "./helpers";

interface AssignmentTableProps {
  setValue: UseFormSetValue<ICreateStandardRoute>;
  organizationId?: number;
  setModal: (arg: IModal | null) => void;
  setError: UseFormSetError<ICreateStandardRoute>;
  clearErrors: UseFormClearErrors<ICreateStandardRoute>;
  errors: FieldErrors;
  isEditMode?: boolean;
  isViewMode?: boolean;
  assignedRoutes?: GridValidRowModel[];
}

export interface IContractPartialData {
  contractType: IContractType[];
  contractSubtype: IContractSubtype[];
}

export const AssignmentTable: React.FC<AssignmentTableProps> = ({
  setValue,
  organizationId,
  setModal,
  setError,
  errors,
  clearErrors,
  isEditMode,
  isViewMode,
  assignedRoutes,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const apiRef = useGridApiRef();

  const { loading } = useAppSelector((state) => state.global);

  const [defaultRoutes, setDefaultRoutes] = React.useState<IDefaultRoute[]>([]);
  const [rows, setRows] = React.useState<GridValidRowModel[]>(
    assignedRoutes || []
  );

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [contractData, setContractData] =
    React.useState<IContractPartialData | null>(null);

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const openAlreadyAssignedRoutePopup = React.useCallback(
    (cb: (id: GridRowId) => void, route: IDefaultRoute | undefined) => {
      setModal({
        open: true,
        variant: ModalVariants.changeDefaultRoute,
        content: {
          data: route,
          confirmNavigation: cb,
        },
      });
    },
    [setModal]
  );

  const columns: GridColDef[] = React.useMemo(
    () =>
      getTableColumns(
        t,
        apiRef,
        rowModesModel,
        setRowModesModel,
        setRows,
        rows,
        contractData,
        defaultRoutes,
        openAlreadyAssignedRoutePopup,
        setError,
        clearErrors,
        isEditMode,
        isViewMode,
        errors
      ),
    [
      apiRef,
      contractData,
      defaultRoutes,
      openAlreadyAssignedRoutePopup,
      rowModesModel,
      rows,
      t,
      setError,
      clearErrors,
      isViewMode,
      isEditMode,
      errors,
    ]
  );

  React.useEffect(() => {
    if (!contractData) {
      dispatch(setLoading(true));

      getContractTypeAndSubtype()
        .then((resp) => {
          if (!resp) return;
          setContractData(resp);
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  }, [dispatch, contractData]);

  React.useEffect(() => {
    if (!organizationId) return;

    axiosService({
      endpoint: `/assign-approval-route-for-contracts/get-defaults/${organizationId}`,
    })
      .then(({ data }) => {
        setDefaultRoutes(data);
      })
      .catch((e) => {
        const errMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errMsg);
      });
  }, [dispatch, organizationId]);

  React.useEffect(() => {
    const currentRoutes = rows || [];

    const routes = getFormattedRoutesToAssign(
      currentRoutes as {
        contractType: string;
        contractSubtype: string;
        defaultRoute: boolean;
        value: string;
      }[],
      contractData
    );

    setValue("routes", routes);
  }, [setValue, rows, contractData]);

  return (
    <SectionWrapper className="agreement-queue__actions">
      {errors.routes ? (
        <Alert
          severity="error"
          sx={{
            "&.MuiPaper-root": {
              maxWidth: "100% !important",
              mb: 4,
            },
          }}
        >
          {t("error.routes.all.field.not.selected")}
        </Alert>
      ) : null}

      <Typography variant="body1" fontWeight={600} sx={{ mb: 2 }}>
        {t("route.assignment")}
      </Typography>

      <Table
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        loading={loading}
        rowCount={0}
        hideFooter
        hideFooterPagination
        checkboxSelection={false}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar:
            !isViewMode || (isViewMode && isEditMode) ? EditToolbar : null,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, isContractTypeSelection: true },
        }}
      />
    </SectionWrapper>
  );
};
