import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  height: 17px;
`;

export const InfoContent = styled.div`
  ${FLEX_COLUMN};
`;

export const InfoItem = styled.div`
  ${FLEX_BETWEEN};
  align-items: self-start;
  gap: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(1, 0)};
  .sending__user {
    ${FLEX_BETWEEN};
    gap: ${({ theme }) => theme.spacing(1)};
  }
  .message__to {
    max-height: 300px;
    overflow: auto;
  }
`;
