import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN } from "ui-kit";

export const Wrapper = styled.div`
  height: 100%;
  .message_create_container {
    ${FLEX_COLUMN};
    height: 100%;
  }

  & .message__body {
    height: 300px;
    width: 100%;
    border: none;
    resize: none;
  }
`;

export const ActionsWrapper = styled.div`
  .message_create_footer {
    ${FLEX_BETWEEN};
    button {
      min-width: 134px;
    }
  }
  .drawer_action_section {
    padding-right: ${({ theme }) => theme.spacing(-4)};
  }
`;
