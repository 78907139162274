import {
  StatusForAuthorEnum,
  StatusForExecutorEnum,
  TaskCategoryEnum,
} from "@types";

export enum TasksTabEnum {
  incoming = "incoming",
  outgoing = "outgoing",
}

export enum TasksFieldsEnum {
  typeOfTheTask = "typeOfTheTask",
  highPriority = "highPriority",
  createdAt = "createdAt",
  actualDateOfExecution = "actualDateOfExecution",
  scheduledDateOfExecution = "scheduledDateOfExecution",
  dateRange = "dateRange",
  taskAuthorEmployeeId = "taskAuthorEmployeeId",
  taskExecutorEmployeeId = "taskExecutorEmployeeId",
  statusForAuthor = "statusForAuthor",
  status = "status",
  statusForExecutor = "statusForExecutor",
  group = "group",
  overdue = "overdue",
  read = "read",
  includeOverdue = "includeOverdue",
}

export const TASKS_FILTERABLE_FIELDS = [
  {
    title: "type",
    field: "typeOfTheTask",
    options: [],
    multiSelect: true,
    value: "",
  },
  {
    title: "priority",
    field: "highPriority",
    options: [],
    optionType: "radio",
    value: "",
  },
  {
    title: "overdue",
    field: "overdue",
    options: [],
    optionType: "radio",
    value: "",
  },
  {
    title: "taskViewed.view",
    field: "read",
    options: [],
    optionType: "radio",
    value: "",
  },
  {
    title: "assignment.date",
    field: "createdAt",
    options: [],
    value: "",
    optionType: "range",
  },
  {
    title: "control.date",
    field: "scheduledDateOfExecution",
    options: [],
    value: "",
    optionType: "range",
  },
  {
    title: "execution.date",
    field: "actualDateOfExecution",
    options: [],
    value: "",
    optionType: "range",
  },
  {
    title: "author",
    field: "taskAuthorEmployeeId",
    options: [],
    value: "",
    multiSelect: true,
  },
  {
    title: "executor",
    field: "taskExecutorEmployeeId",
    options: [],
    value: "",
    multiSelect: true,
  },
  {
    title: "status.for.author",
    field: "statusForAuthor",
    options: [],
    value: "",
    multiSelect: true,
  },
  {
    title: "status.for.executor",
    field: "statusForExecutor",
    options: [],
    value: "",
    multiSelect: true,
  },
];

export const TaskStatusHierarchyIncoming = {
  [TaskCategoryEnum.requiresAction]: [
    StatusForExecutorEnum.newTask,
    StatusForExecutorEnum.returnForRevision,
    StatusForExecutorEnum.acceptedForExecution,
    StatusForAuthorEnum.removalFromControl,
    StatusForAuthorEnum.draft,
  ],
  [TaskCategoryEnum.inTheSpotlight]: [
    StatusForExecutorEnum.delegated,
    StatusForExecutorEnum.rejected,
    StatusForExecutorEnum.executionInterrupted,
    StatusForAuthorEnum.sentToExecutor,
  ],
  [TaskCategoryEnum.completed]: [
    StatusForExecutorEnum.done,
    StatusForExecutorEnum.doneInPerson,
    StatusForExecutorEnum.canceled,
    StatusForExecutorEnum.executionCompleted,
    StatusForAuthorEnum.done,
    StatusForAuthorEnum.canceled,
    StatusForExecutorEnum.resolutionExecuted,
    StatusForExecutorEnum.resolutionIssued,
  ],
};

export const TaskStatusHierarchyOutgoing = {
  [TaskCategoryEnum.requiresAction]: [
    StatusForExecutorEnum.newTask,
    StatusForExecutorEnum.returnForRevision,
    StatusForExecutorEnum.acceptedForExecution,
    StatusForAuthorEnum.removalFromControl,
    StatusForAuthorEnum.draft,
    StatusForAuthorEnum.rejected,
  ],
  [TaskCategoryEnum.inTheSpotlight]: [
    StatusForExecutorEnum.delegated,
    StatusForExecutorEnum.executionInterrupted,
    StatusForExecutorEnum.resolutionIssued,
    StatusForAuthorEnum.sentToExecutor,
  ],
  [TaskCategoryEnum.completed]: [
    StatusForExecutorEnum.done,
    StatusForExecutorEnum.doneInPerson,
    StatusForExecutorEnum.canceled,
    StatusForExecutorEnum.executionCompleted,
    StatusForAuthorEnum.done,
    StatusForAuthorEnum.canceled,
  ],
};
