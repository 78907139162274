import styled from "styled-components";
import { FLEX_COLUMN_FULL, FLEX_ROW } from "ui-kit";

import { Card } from "@mui/material";

export const PageWrapper = styled(Card)<{ $hasData: boolean }>`
  height: ${({ theme, $hasData }) => ($hasData ? "100%" : "300px")};
  .MuiDataGrid-overlayWrapperInner {
    height: 100px !important;
  }
`;
export const ApprovalTabsWrapper = styled.div`
  ${FLEX_ROW};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(4, 1)};
`;

export const MyApprovalTableWrapper = styled.div``;

export const SkeletonWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.paper};
  .skeleton_item {
    margin: ${({ theme }) => theme.spacing(-4, 0)};
    opacity: 0.5;
  }
`;

export const ApprovalTab = styled.div<{ $isActive: boolean }>`
  ${FLEX_ROW};
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2, 4)};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.palette.secondary.main : theme.palette.text.secondary};
  border-bottom: 2px solid
    ${({ theme, $isActive }) =>
      $isActive ? theme.palette.secondary.main : "inherit"};
  position: relative;
`;

export const ApprovalCountWrappper = styled.div`
  ${FLEX_COLUMN_FULL};
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  padding: ${({ theme }) => theme.spacing(0.5, 1)};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;
