import React from "react";

import {
  getStructuralUnitById,
  setStructuralUnitById,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  ViewStructuralUnitContainer,
} from "app";
import { Modal } from "ui-kit";

export const ViewStructuralUnitPopup = (props: {
  open: boolean;
  onClose: () => void;
  structuralUnitId?: string;
  name?: string;
  organizationId?: string;
}) => {
  const { open, onClose, structuralUnitId, name, organizationId } = props;

  const dispatch = useAppDispatch();

  const { structuralUnitById } = useAppSelector((state) => state.organizations);

  React.useEffect(() => {
    if (!structuralUnitId) return;

    void dispatch(getStructuralUnitById(structuralUnitId));
  }, [dispatch, structuralUnitId]);

  useEffectOnce(() => () => {
    dispatch(setStructuralUnitById(null));
  });

  const content = {
    title: "",
    body: (
      <ViewStructuralUnitContainer
        branch={structuralUnitById}
        name={name}
        organizationId={organizationId}
      />
    ),
    secondaryButton: null,
    mainButton: null,
  };

  return (
    <Modal
      isOpen={Boolean(structuralUnitById && open)}
      onClose={onClose}
      content={content}
      sizeLarge
    />
  );
};
