import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const ActionsWrapper = styled.div<{ $isEditMOde: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: ${({ $isEditMOde }) =>
    $isEditMOde ? "space-between" : "flex-end"};
  .main_buttons {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(2)};
    justify-content: flex-end;
    > button {
      min-width: ${UI_CONFIG.modalButton}px;
    }
  }
  textarea {
    height: 156px;
  }
`;
export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  .role__name {
    textarea {
      height: 56px !important;
    }
  }
  .role__description {
    textarea {
      height: 110px !important;
    }
  }
`;
