import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDebounce, useEffectOnce, useQueryParams } from "app";

import { Button, Divider, FormHelperText } from "@mui/material";
import { Input } from "@sbm/ui-components";

import { FilterValue } from "../FilterValue";
import { FiltersWrapper, FiltersActions, InputWrapper } from "./styles";

export interface IFilterValue {
  field?: string;
  options?: string[];
  optionType?: string;
  multiSelect?: boolean;
}
export interface CustomFiltersProps {
  title?: string;
  filters: IFilterValue;
  onClose: () => void;
  onApply: (arg: string | string[]) => void;
}

export const CustomFilters = (props: CustomFiltersProps) => {
  const { filters, onClose, onApply } = props;

  const { get } = useQueryParams();
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState<string | string[]>([]);
  const [error, setError] = useState(false);

  const debouncedSearch = useDebounce(search);

  const handleReset = () => {
    setFilterValue([]);

    if (filters?.optionType === "range") {
      const customEvent = new Event("reset_date_selection", { bubbles: true });
      window.dispatchEvent(customEvent);
    }
  };

  const handleChange = (val: string | string[]) => {
    if (error) setError(false);

    setFilterValue(val);
  };

  const handleApply = () => {
    if (!filterValue?.length) {
      onApply([""]);
      return onClose();
    }

    onApply(filterValue);
    onClose();
  };

  useEffect(() => {
    if (!filters?.options) return;

    if (filters.optionType === "radio" && filters.options.length > 0) {
      setFilterValue(filters.options[0]);
    }
  }, [filters]);

  useEffectOnce(() => {
    if (!filterValue.length && filters?.field) {
      const filterValueFromUrl = get("filter", true) as string[];

      const currentFilterString = filterValueFromUrl.find((i) =>
        i.includes(filters.field!)
      );

      if (!currentFilterString) return;

      const currentFilter = currentFilterString.split("=")[1];
      const currentFilterValues = currentFilter.split(",");

      setFilterValue(currentFilterValues);
    }
  });

  return (
    <FiltersWrapper>
      {!filters?.optionType && (
        <InputWrapper>
          <Input
            size="small"
            placeholder={t("search")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputWrapper>
      )}

      <FilterValue
        filterValue={filterValue}
        onChange={handleChange}
        field={filters.field}
        options={filters.options?.filter((option) =>
          option.toLowerCase().includes(debouncedSearch.toLowerCase())
        )}
        optionType={filters.optionType}
        multiSelect={filters.multiSelect}
        error={error}
      />

      {error ? (
        <FormHelperText error={error}>
          {t("please.select.the.option")}
        </FormHelperText>
      ) : null}

      <Divider />

      <FiltersActions>
        <Button size="small" color="secondary" onClick={handleReset}>
          {t("reset")}
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleApply}
        >
          {t("apply")}
        </Button>
      </FiltersActions>
    </FiltersWrapper>
  );
};
