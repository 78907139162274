import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { FormControlLabel, Grid, Stack, Switch } from "@mui/material";
import { Button, Input } from "@sbm/ui-components";
import {
  ICreateStructuralUnit,
  structuralUnitConfigurations,
  StructuralUnitTypesEnum,
} from "@types";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { createStructuralUnit, setModal } from "../../../store";
import { StructuralUnitRadioGroup } from "./StructuralUnitRadioGroup";
import { ActionsSection, FormWrapper } from "./styles";

export const CreateStructuralUnitContainer = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);

  const { modal } = useAppSelector((state) => state.modals);

  const organizationId = params.id || modal?.content.data.organizationId || "";
  const parentId = modal?.content.data.id || "";
  const selectedUnitType = modal?.content.data
    .structuralUnitType as StructuralUnitTypesEnum;

  const isAllowedUnitTypeToCreate = (type: StructuralUnitTypesEnum) => {
    if (!selectedUnitType) return true;

    const allowedUnits =
      structuralUnitConfigurations[selectedUnitType].allowedChildren;

    // @ts-ignore
    return allowedUnits.includes(type);
  };

  const { register, control, handleSubmit } = useForm<ICreateStructuralUnit>({
    defaultValues: {
      organizationId: Number(organizationId),
      structuralUnitType: undefined,
      nameOfStructuralUnit: "",
      additionalInformation: "",
      parentStructuralUnitId: parentId || null,
    },
  });

  const isParentUnit = !modal?.content.data.id;

  const { nameOfStructuralUnit, additionalInformation, structuralUnitType } =
    useWatch({ control });

  const isRequiredFieldsFilled = Boolean(
    nameOfStructuralUnit && organizationId && structuralUnitType
  );

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    if (!data["parentStructuralUnitId"]) {
      delete data["parentStructuralUnitId"];
    }

    await dispatch(createStructuralUnit(data, organizationId));

    setLoading(false);
    dispatch(setModal(null));
  });

  const RadioGroupsData = [
    {
      label: t("top.management"),
      values: [
        {
          option: t(StructuralUnitTypesEnum.mainGoverningBody),
          value: StructuralUnitTypesEnum.mainGoverningBody,
          disabled: !isAllowedUnitTypeToCreate(
            StructuralUnitTypesEnum.mainGoverningBody
          ),
        },
        {
          option: t(StructuralUnitTypesEnum.goveringBody),
          value: StructuralUnitTypesEnum.goveringBody,
          disabled:
            isParentUnit ||
            !isAllowedUnitTypeToCreate(StructuralUnitTypesEnum.goveringBody),
        },
      ],
      control,
    },
    {
      label: t("functional_block"),
      values: [
        {
          option: t(StructuralUnitTypesEnum.functionalBlock),
          value: StructuralUnitTypesEnum.functionalBlock,
          disabled:
            isParentUnit ||
            !isAllowedUnitTypeToCreate(StructuralUnitTypesEnum.functionalBlock),
        },
      ],
      control,
    },
  ];

  const RadioGroupsDataFlexRow = [
    {
      label: t("administrative.unit"),
      values: [
        {
          option: t(StructuralUnitTypesEnum.department),
          value: StructuralUnitTypesEnum.department,
          disabled:
            isParentUnit ||
            !isAllowedUnitTypeToCreate(StructuralUnitTypesEnum.department),
        },
        {
          option: t(StructuralUnitTypesEnum.division),
          value: StructuralUnitTypesEnum.division,
          disabled:
            isParentUnit ||
            !isAllowedUnitTypeToCreate(StructuralUnitTypesEnum.division),
        },
        {
          option: t(StructuralUnitTypesEnum.sector),
          value: StructuralUnitTypesEnum.sector,
          disabled:
            isParentUnit ||
            !isAllowedUnitTypeToCreate(StructuralUnitTypesEnum.sector),
        },
      ],
      control,
    },
    {
      label: t("manufacturing.unit"),
      values: [
        {
          option: t(StructuralUnitTypesEnum.directoriateForManufactoring),
          value: StructuralUnitTypesEnum.directoriateForManufactoring,
          disabled:
            isParentUnit ||
            !isAllowedUnitTypeToCreate(
              StructuralUnitTypesEnum.directoriateForManufactoring
            ),
        },
        {
          option: t(StructuralUnitTypesEnum.unitForManufactoring),
          value: StructuralUnitTypesEnum.unitForManufactoring,
          disabled:
            isParentUnit ||
            !isAllowedUnitTypeToCreate(
              StructuralUnitTypesEnum.unitForManufactoring
            ),
        },
        {
          option: t(StructuralUnitTypesEnum.groupForManufactoring),
          value: StructuralUnitTypesEnum.groupForManufactoring,
          disabled:
            isParentUnit ||
            !isAllowedUnitTypeToCreate(
              StructuralUnitTypesEnum.groupForManufactoring
            ),
        },
      ],
      control,
    },
  ];

  return (
    <FormWrapper noValidate onSubmit={onSubmit}>
      {RadioGroupsData.map((group) => {
        return <StructuralUnitRadioGroup key={group.label} {...group} />;
      })}

      <Stack
        direction={["column", "column", "row"]}
        gap={5}
        sx={{ width: "100%" }}
      >
        {RadioGroupsDataFlexRow.map((group) => {
          return <StructuralUnitRadioGroup key={group.label} {...group} />;
        })}
      </Stack>

      <Input
        required
        value={nameOfStructuralUnit}
        label={t("name.of.the.structural.unit")}
        maxLength={100}
        {...register("nameOfStructuralUnit")}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          <Input
            value={additionalInformation}
            label={t("additional.information")}
            maxLength={300}
            {...register("additionalInformation")}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <FormControlLabel
            label={t("actualStructuralUnit")}
            labelPlacement="start"
            control={<Switch disabled={true} defaultChecked={true} />}
          />
        </Grid>
      </Grid>

      <ActionsSection>
        <Button
          variant="contained"
          type="submit"
          loading={loading}
          disabled={loading || !isRequiredFieldsFilled}
        >
          {t("save")}
        </Button>
      </ActionsSection>
    </FormWrapper>
  );
};
