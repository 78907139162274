import React from "react";
import { Control } from "react-hook-form";

import { TFunction } from "i18next";

import { GridColDef } from "@mui/x-data-grid-pro";
import { FormSelect } from "@sbm/ui-components";
import { EnumApproverType, IApprover, ICreateStandardRoute } from "@types";

export const getTableColumns = (
  t: TFunction,
  control: Control<ICreateStandardRoute>,
  queueNumbers: number[]
) => {
  return [
    {
      field: "approverType",
      headerName: t("agreeing.subject") as string,
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      valueGetter: (params) => t(params.row.approverType),
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("queue.agreement"),
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const values = queueNumbers.map((i) => ({
          option: String(i),
          value: i,
        }));

        return [
          <FormSelect
            readOnly={
              row.approverType === t(EnumApproverType.signatory_of_the_contract)
            }
            key={id + row.approverType}
            defaultSelected={row.queueNumber}
            name={`approvers.${[row.id]}.queueNumber`}
            control={control}
            values={values}
          />,
        ];
      },
    },
  ] as GridColDef[];
};

export const getSignatoryQueueNumber = (
  queueNumber: number,
  approvers: IApprover[],
  t: TFunction
) => {
  const matches = approvers.filter((i) => i.queueNumber === queueNumber);
  const queueNumbers = approvers.map((i) => i.queueNumber!);
  const max = Math.max(...queueNumbers);

  if (matches.length > 1) return max + 1;

  const otherQueueNumbers = approvers
    .filter(
      (i) => i.approverType !== t(EnumApproverType.signatory_of_the_contract)
    )
    .map((i) => i.queueNumber!);

  const maxFromOtherQueueNumbers = Math.max(...otherQueueNumbers);

  if (queueNumber - maxFromOtherQueueNumbers === 1) return queueNumber;

  return maxFromOtherQueueNumbers + 1;
};
