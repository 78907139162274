import React from "react";
import * as ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { MuiTheme, MyGlobalStyles, PageLoader } from "ui-kit";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { oidcConfigContext } from "./app/configs";
import { ModalsDecorator, ScrollToTop } from "./app/decorators";
import "./app/i18n";
import { MainRouter } from "./app/MainRouter";
import { store } from "./app/store";
import { environment } from "./environments/environment";

LicenseInfo.setLicenseKey(environment.muiLicenseKey);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={MuiTheme}>
    <AuthProvider {...oidcConfigContext}>
      <StoreProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename="/">
            <React.Suspense fallback={<PageLoader />}>
              <ScrollToTop />
              <MainRouter />
            </React.Suspense>

            <ModalsDecorator />
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>

        <CssBaseline />
        <MyGlobalStyles />
      </StoreProvider>
    </AuthProvider>
  </ThemeProvider>
);
