import styled from "styled-components";

import { FLEX_ROW_ALIGN_CENTER } from "../../theme";

export const TitleWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};

  margin-bottom: ${({ theme }) => theme.spacing(5)};

  .page-title {
    &__icon {
      fill: ${({ theme }) => theme.palette.text.disabled};
      cursor: pointer;
    }

    &__text {
      margin-left: ${({ theme }) => theme.spacing(2)};
    }
  }
`;
