import styled from "styled-components";

export const FiltersWrapper = styled.div`
  width: 300px;

  padding: ${({ theme }) => theme.spacing(3, 4)};

  .custom-filters__title {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};

    margin: ${({ theme }) => theme.spacing(-3, -4)};

    padding: ${({ theme }) => theme.spacing(5)};
  }
`;

export const FiltersActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(3)};

  margin-top: ${({ theme }) => theme.spacing(5)};
`;
