import styled from "styled-components";
import { FLEX_ROW } from "ui-kit";

import profile_banner_image from "@/assets/backgrounds/user_profile_banner.png";

export const BannerImage = styled.div`
  width: calc(100% + ${({ theme }) => theme.spacing(5)}); // paddings
  height: 200px;

  margin-left: ${({ theme }) => theme.spacing(-2.5)};

  background-image: url(${profile_banner_image});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;
export const BannerWrapper = styled.div`
  position: relative;
`;

export const BannerContent = styled.div`
  position: relative;
  left: 63px;
  top: -100px;

  ${FLEX_ROW};
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing(6)};
`;
