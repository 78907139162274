import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";

export const RemoveExecutorPopup = (props: {
  open: boolean;
  onClose: () => void;
  data: { data: { onRemove: () => void } };
}) => {
  const {
    open,
    onClose,
    data: {
      data: { onRemove },
    },
  } = props;
  const { t } = useTranslation("tasks");

  const content = {
    title: t("confirm_title"),
    body: t("confirm_text"),
    secondaryButton: (
      <Button onClick={onClose} color="secondary">
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        onClick={() => {
          onRemove();
          onClose();
        }}
      >
        {t("yes")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
