export enum ModalVariants {
  discardChanges = "discardChanges",
  createOrganization = "createOrganization",
  createStructuralUnit = "createStructuralUnit",
  createStaffUnit = "createStaffUnit",
  createStaffMember = "createStaffMember",
  viewStaffMember = "viewStaffMember",
  viewStructuralUnit = "viewStructuralUnit",
  viewStaffUnit = "viewStaffUnit",
  confirmDisableStructuralUnit = "confirmDisableStructuralUnit",
  confirmDisableStaffUnit = "confirmDisableStaffUnit",
  confirmDisableStaffMember = "confirmDisableStaffMember",
  createStandardRoute = "createStandardRoute",
  changeDefaultRoute = "changeDefaultRoute",
  viewStandardRoute = "viewStandardRoute",
  confirmApprovalRoute = "confirmApprovalRoute",
  addApprover = "addApprover",
  contractApprovalActions = "contractApprovalActions",
  permissionsSet = "permissionsSet",
  editRole = "editRole",
  removePermissionsSet = "removePermissionsSet",
  removeRole = "removeRole",
  removeExecutor = "removeExecutor",
  removeUserRole = "removeUserRole",
  editUserRole = "editUserRole",
  attachLink = "attachLink",
  rejectExecution = "rejectExecution",
  sendForRevision = "sendForRevision",
  abortExecution = "abortExecution",
  confirmExecution = "confirmExecution",
  frameworkContract = "frameworkContract",
  completeExecution = "completeExecution",
  removeTask = "removeTask",
  removeFile = "removeFile",
  removeOrganizationEmployee = "removeOrganizationEmployee",
  sendToResolution = "sendToResolution",
  addFileToIncomingDocument = "addFileToIncomingDocument",
  invalidateEmployee = "invalidateEmployee",
}

export enum DrawerVariantsEnum {
  contract = "contract",
  sa = "sa",
  message = "message",
  messageInfo = "messageInfo",
  permissionSet = "permissionSet",
  role = "role",
  assigningRole = "assigningRole",
  supplementaryAgreements = "supplementaryAgreements",
  createContract = "createContract",
  addApprover = "addApprover",
  listOfApprovals = "listOfApprovals",
  changeTypicalRoute = "changeTypicalRoute",
  createOrganization = "createOrganization",
  task = "task",
  editTask = "editTask",
  taskPreview = "taskPreview",
  attachFileInTask = "attachFileInTask",
  attachLinkInTask = "attachLinkInTask",
  attachFileInCorrespondence = "attachFileInCorrespondence",
  attachLinkInCorrespondence = "attachLinkInCorrespondence",
  createIncomingDocument = "createIncomingDocument",
  createStaffUnit = "createStaffUnit",
  createOutgoingDocument = "createOutgoingDocument",
}

export enum DrawerWithDataVariantsEnum {
  permissionEditSet = "permissionEditSet",
  roleEditSet = "roleEditSet",
  userRoleEdit = "userRoleEdit",
  editOrganization = "editOrganization",
  editContractAttachment = "editContractAttachment",
  editSAAttachment = "editSAAttachment",
  createStructuralUnit = "createStructuralUnit",
}
