import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

export const StepperItem = ({
  completed,
  active,
  name,
  label,
}: {
  completed?: boolean;
  active?: boolean;
  label: string;
  name: "Users" | "ScrollText" | "UploadCloud";
}) => {
  const theme = useTheme();
  const { t } = useTranslation("tasks");

  return (
    <Stack
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Stack>
        <Icon
          name={name}
          size={36}
          color={
            completed
              ? theme.palette.primary.main
              : active
              ? theme.palette.secondary.main
              : hexToRgba(theme.palette.primary.main, 0.45)
          }
        />
      </Stack>
      <Stack width={140} height={40}>
        <Typography
          variant="subtitle2"
          fontWeight={600}
          color={
            completed
              ? theme.palette.primary.main
              : active
              ? theme.palette.secondary.main
              : hexToRgba(theme.palette.primary.main, 0.45)
          }
        >
          {t(label)}
        </Typography>
      </Stack>
    </Stack>
  );
};
