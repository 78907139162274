import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const Wrapper = styled.div`
  max-width: 300px;
  .sign__out {
    padding: ${({ theme }) => theme.spacing(4)};
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) =>
        hexToRgba(theme.palette.primary.main, 0.04)};
    }
  }
`;

export const AvatarMenuWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const AvatarMenuItemWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(2, 4)};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      hexToRgba(theme.palette.primary.main, 0.04)};
  }
`;
