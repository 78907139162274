import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

import { Dialog } from "@mui/material";

export const DialogWrapper = styled(Dialog)`
  .close__button {
    display: flex;
    justify-content: flex-end;
  }
`;

export const DialogTitleWrapper = styled.div`
  ${FLEX_ROW_FULL};
  padding: ${({ theme }) => theme.spacing(4, 0, 2, 0)};
`;

export const DialogContentWrapper = styled.div<{
  $updatedVersion?: boolean;
}>`
  padding: ${({ theme, $updatedVersion }) =>
    $updatedVersion ? 0 : theme.spacing(0, 6, 4, 6)};
`;
