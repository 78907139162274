import styled from "styled-components";
import { formLabelClasses } from "@mui/material";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  text-transform: capitalize;

  margin-top: ${({ theme }) => theme.spacing(7)};

  .${formLabelClasses.root} {
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;
