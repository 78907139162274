import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app";
import { Icon } from "ui-kit";

import { Avatar, Typography, useTheme, Divider } from "@mui/material";

import female_avatar from "../../../../../../../../../assets/images/avatars/female_avatar.png";
import male_avatar from "../../../../../../../../../assets/images/avatars/male_avatar.png";
import { AvatarMenuWrapper, AvatarMenuItemWrapper, Wrapper } from "./styles";

interface AvatarMenuProps {
  handleLogout: () => void;
  navigateToProfile: () => void;
}

export const AvatarMenu = (props: AvatarMenuProps) => {
  const { handleLogout, navigateToProfile } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  const { userProfile } = useAppSelector((state) => state.auth);

  const userName = `${userProfile?.personalData?.name} ${userProfile?.personalData?.surname}`;

  return (
    <Wrapper>
      <AvatarMenuWrapper>
        <Avatar
          sx={{
            width: 40,
            height: 40,
          }}
          src={
            userProfile?.personalData?.gender === "female"
              ? female_avatar
              : male_avatar
          }
          alt="Avatar"
        />
        <Typography
          variant="subtitle1"
          fontWeight={700}
          sx={{ textAlign: "center" }}
        >
          {userName}
        </Typography>
      </AvatarMenuWrapper>

      <Divider sx={{ mb: 2 }} />

      <AvatarMenuItemWrapper onClick={navigateToProfile}>
        <Icon name="User2" color={theme.palette.primary.main} />
        <Typography variant="body2" fontWeight={600}>
          {t("profile")}
        </Typography>
      </AvatarMenuItemWrapper>
      <AvatarMenuItemWrapper>
        <Icon name="Settings" color={theme.palette.primary.main} />
        <Typography variant="body2" fontWeight={600}>
          {t("Settings")}
        </Typography>
      </AvatarMenuItemWrapper>

      <Divider sx={{ mt: 2 }} />
      <Typography
        variant="body2"
        color="warning.dark"
        className="sign__out"
        onClick={handleLogout}
      >
        {t("sign.out")}
      </Typography>
    </Wrapper>
  );
};
