import styled from "styled-components";
import { Breadcrumbs, breadcrumbsClasses } from "@mui/material";

export const BreadcrumbsWrapper = styled(Breadcrumbs)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  .${breadcrumbsClasses.li} {
    display: flex;
    align-items: center;

    a,
    p {
      font-size: 12px;
    }

    a {
      padding: ${({ theme }) => theme.spacing(0, 2)};

      background-color: ${({ theme }) => theme.palette.grey[500]};
      border-radius: ${({ theme }) => theme.spacing(2.5)};

      color: white;

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;
