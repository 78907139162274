import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import { Table } from "@sbm/ui-components";
import { IApprover } from "@types";

import { SectionWrapper } from "../styles";
import { getAgreementQueueColumnsByApproverType } from "./helpers";

export const ApproversGanttChart: React.FC<{ approvers?: IApprover[] }> = ({
  approvers,
}) => {
  const { t } = useTranslation();

  const rows: GridValidRowModel[] = React.useMemo(
    () => approvers || [],
    [approvers]
  );

  const columns: GridColDef[] = React.useMemo(
    () => getAgreementQueueColumnsByApproverType(approvers, t),
    [approvers, t]
  );

  return (
    <SectionWrapper>
      <Typography variant="body1" fontWeight={600} sx={{ mb: 2 }}>
        {t("agreement.queue")}
      </Typography>

      <Table
        rows={rows}
        columns={columns}
        rowCount={0}
        hideFooter
        hideFooterPagination
        checkboxSelection={false}
      />
    </SectionWrapper>
  );
};
