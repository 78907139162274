import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const useQueryParams = () => {
  const { pathname } = useLocation();
  const [queryParams] = useSearchParams();

  const params = useMemo(
    () => new URLSearchParams(queryParams?.toString()),
    [queryParams]
  );

  const get = useCallback(
    (key: string, all?: boolean): string | string[] | null | undefined => {
      if (all) {
        return queryParams?.getAll(key);
      }
      return queryParams?.get(key);
    },
    [queryParams]
  );

  const navigate = useNavigate();

  const set = useCallback(
    (key: string, value: string) => {
      params.set(key, value);
      navigate(`${pathname}?${params.toString()}`, { replace: true });
    },
    [params, pathname, navigate]
  );

  const append = useCallback(
    (key: string, value: string) => {
      params.append(key, value);
      navigate(`${pathname}?${params.toString()}`);
    },
    [params, pathname, navigate]
  );

  const remove = useCallback(
    (key: string) => {
      params.delete(key);
      navigate(`${pathname}?${params.toString()}`, { replace: true });
    },
    [params, navigate, pathname]
  );

  const clear = useCallback(() => {
    params.forEach((_, key) => params.delete(key));
    navigate(pathname as string, { replace: true });
  }, [params, pathname, navigate]);

  return {
    get,
    set,
    remove,
    clear,
    append,
  };
};
