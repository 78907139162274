import React, { useState } from "react";

import { UI_CONFIG } from "app";

import { Drawer as MuiDrawer, Fade, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import { Icon } from "../Icon";

type Anchor = "top" | "left" | "bottom" | "right";

interface Props {
  open: boolean;
  anchor?: Anchor;
  children: React.ReactNode;
  width?: number | string;
  onClose?: () => void;
  setIsMaximized?: (arg: boolean) => void;
  resizable?: boolean;
  hasCloseIcon?: boolean;
  title?: string | React.ReactNode;
  clickAwayListener?: boolean;
  actions?: React.ReactNode;
  type?: string;
}

export function Drawer({
  open = false,
  anchor = "right",
  children,
  width = UI_CONFIG.rightDrawerWidth,
  onClose,
  resizable = false,
  hasCloseIcon = true,
  clickAwayListener = true,
  title = "",
  actions,
  setIsMaximized,
  type,
}: Props) {
  const theme = useTheme();

  const [initialWidth, setInitialWidth] = useState(width);

  const handleMaximize = () => {
    setInitialWidth(UI_CONFIG.rightDrawerFullWidth);
    if (setIsMaximized) {
      setIsMaximized(true);
    }
  };

  const handleMinimize = () => {
    setInitialWidth(UI_CONFIG.rightDrawerWidth);
    if (setIsMaximized) {
      setIsMaximized(false);
    }
  };

  if (!open || !children) return null;

  return (
    <MuiDrawer
      anchor={anchor}
      open={open}
      onClose={clickAwayListener ? onClose : () => null}
      PaperProps={{
        sx: {
          height: "100%",
          width: [
            1,
            anchor === "top" || anchor === "bottom" ? "auto" : initialWidth,
          ],
        },
      }}
    >
      <Fade in timeout={700}>
        <Box
          role="presentation"
          sx={{
            position: "relative",
            height: 1,
            padding: type !== "message_view" ? "24px" : 0,
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "24px",
              backgroundColor: theme.palette.background.default,
              borderRadius: "16px 0 0 0",
              width: [
                1,
                anchor === "top" || anchor === "bottom" ? "auto" : initialWidth,
              ],
              zIndex: theme.zIndex.drawer + 1,
            }}
          >
            <Typography variant="h5" fontWeight={700}>
              {title}
            </Typography>

            <div>
              {resizable ? (
                initialWidth === UI_CONFIG.rightDrawerWidth ? (
                  <Box
                    sx={{
                      marginRight: "16px",
                      display: ["none", "none", "unset"],
                    }}
                  >
                    <Icon
                      name="Maximize"
                      color={theme.palette.primary.main}
                      onClick={handleMaximize}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      marginRight: "16px",
                      display: ["none", "none", "unset"],
                    }}
                  >
                    <Icon
                      name="Minimize"
                      color={theme.palette.primary.main}
                      onClick={handleMinimize}
                    />
                  </Box>
                )
              ) : null}
              {hasCloseIcon ? (
                <Icon
                  name="X"
                  color={theme.palette.primary.main}
                  onClick={onClose}
                />
              ) : null}
            </div>
          </Box>
          <Box
            sx={
              type === "createOrgDrawer"
                ? {
                    paddingTop: "66px",
                    paddingBottom: "72px",
                  }
                : {
                    paddingTop: type === "messageDrawer" ? "32px" : "66px",
                    paddingBottom: type !== "message_view" ? "72px" : 0,
                    height: "100%",
                    overflow: "auto",
                  }
            }
          >
            {children}
          </Box>
          {actions ? (
            <Box
              sx={{
                position: "fixed",
                bottom: "0",
                backgroundColor: theme.palette.background.default,
                borderRadius: "0 0 0 16px",
                margin: "0 -24px",
                width: [
                  1,
                  anchor === "top" || anchor === "bottom"
                    ? "auto"
                    : initialWidth,
                ],
                zIndex: theme.zIndex.drawer + 1,
              }}
            >
              <div
                className="drawer_action_section"
                style={{
                  width: "calc(100% - 48px)",
                  margin: "24px",
                  paddingRight: type === "messageDrawer" ? 0 : "16px",
                }}
              >
                {actions}
              </div>
            </Box>
          ) : null}
        </Box>
      </Fade>
    </MuiDrawer>
  );
}
