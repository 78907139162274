import React, { useEffect, useState } from "react";

import { Icon } from "ui-kit";

import { Chip, Divider, Fade, Typography, useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

import {
  ToggleSectionHeader,
  ToggleSectionTitle,
  ToggleSectionAction,
} from "./styles";

export function ToggleSection(props: {
  children: React.ReactNode;
  actions?: React.ReactNode;
  title: string;
  hasDivider?: boolean;
  isDisabled?: boolean;
  isExpandable?: boolean;
  initialOpenState?: boolean;
  checkedCount?: number;
}) {
  const {
    children,
    title,
    actions,
    hasDivider = false,
    isDisabled = false,
    isExpandable = true,
    initialOpenState = false,
    checkedCount,
  } = props;

  const [open, setOpen] = useState(initialOpenState || !isExpandable);

  const theme = useTheme();

  const getColor = () => {
    if (!isDisabled) return theme.palette.primary.light;
    return hexToRgba(theme.palette.primary.light, 0.5);
  };

  const handleToggle = () => {
    if (!isExpandable || isDisabled) {
      return;
    }
    setOpen((prevState) => !prevState);
  };

  const titleColor = getColor();

  useEffect(() => {
    if (isDisabled) {
      setOpen(false);
    }
  }, [isDisabled]);

  return (
    <>
      <ToggleSectionHeader>
        <ToggleSectionTitle onClick={handleToggle} $isDisabled={isDisabled}>
          {isExpandable && (
            <Icon
              name={open ? "ChevronDown" : "ChevronRight"}
              color={titleColor}
              className="expand__icon"
            />
          )}
          <Typography
            variant="h8_bold"
            color={titleColor}
            sx={{ pl: isExpandable ? 4 : 0, fontFamily: "Montserrat" }}
          >
            {title}
          </Typography>
          {checkedCount ? (
            <Chip
              label={checkedCount}
              size="small"
              color="secondary"
              sx={{ fontWeight: 600, minWidth: "24px", margin: "0 4px" }}
            />
          ) : null}
        </ToggleSectionTitle>

        <ToggleSectionAction>{!open ? null : actions}</ToggleSectionAction>
      </ToggleSectionHeader>

      {open ? (
        <Fade in timeout={400}>
          <div style={{ marginBottom: "24px" }}>{children}</div>
        </Fade>
      ) : null}
      {hasDivider && !open && <Divider light sx={{ mb: 6 }} />}
    </>
  );
}
