import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { FileItem } from "../FileItem";
import { EmptyFilesSectionWrapper, Wrapper } from "../styles";
import { ErrorItem } from "./ErrorItem";

interface Props {
  files?: File | File[] | null;
  buttonOnly?: boolean;
  disabled?: boolean;
  additionalClass?: string;
  removeFile: (name: string) => void;
  removeError: (id: string) => void;
  removeMainErrorMessage: () => void;
  errorMessages: { fileName: string; errorMessage: string; id: string }[];
  hasError?: boolean;
  maxSize?: number;
  maxFiles: number;
  uploadProgress: {
    name: string;
    progress: number;
  }[];
}

export function UploadedFilesSection(props: Props) {
  const {
    files,
    disabled = false,
    buttonOnly = false,
    additionalClass,
    removeFile,
    hasError,
    maxSize,
    maxFiles,
    errorMessages,
    removeMainErrorMessage,
    removeError,
    uploadProgress,
  } = props;

  const { t: tAttachment } = useTranslation("attachments");
  const { t } = useTranslation();

  const showErrorMessages = () => {
    if (errorMessages.length > maxFiles) {
      return (
        <ErrorItem
          message={{
            fileName: "",
            id: "maxFileCount",
            errorMessage: `${t("wrong.dropped.file.count", {
              fileMaxCount: maxFiles,
            })}`,
          }}
          removeError={removeMainErrorMessage}
        />
      );
    } else
      return errorMessages.map((item, index) => (
        <ErrorItem
          message={item}
          key={index}
          removeError={() => removeError(item.id)}
        />
      ));
  };

  return (
    <Wrapper disabled={disabled}>
      <div>
        <div
          className={`${
            hasError && maxSize
              ? "uploaded_files_section_with_warning"
              : `uploaded_files_section  ${additionalClass} `
          }`}
        >
          {!buttonOnly && hasError && maxSize ? (
            <>{showErrorMessages()}</>
          ) : null}
          {files ? (
            Array.isArray(files) && files.length ? (
              files.map((item) => (
                <FileItem
                  key={item.name + item.size}
                  file={item}
                  removeFile={() => removeFile(item.name)}
                  uploadProgress={uploadProgress}
                />
              ))
            ) : errorMessages.length ? null : (
              <>
                <EmptyFilesSectionWrapper />
                <div className="empty__text">
                  <Typography variant="body1" color="text.primary">
                    {tAttachment("noAttachedFiles")}
                  </Typography>
                </div>
              </>
            )
          ) : errorMessages.length ? null : (
            <>
              <EmptyFilesSectionWrapper />
              <div className="empty__text">
                <Typography variant="body1" color="text.primary">
                  {tAttachment("noAttachedFiles")}
                </Typography>
              </div>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
