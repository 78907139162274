import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  padding-top: ${({ theme }) => theme.spacing(6)};
`;

export const CCWrapper = styled.div`
  max-width: 33%;

  margin-bottom: ${({ theme }) => theme.spacing(10)};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: space-between;

  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;
