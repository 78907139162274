import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const ActionsWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};

  > button {
    min-width: ${UI_CONFIG.modalButton}px;
  }
`;
