import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW } from "ui-kit";

import { drawerClasses } from "@mui/material";

import { UI_CONFIG } from "../../configs";

export const Wrapper = styled.div<{ $overflowHidden: boolean }>`
  ${FLEX_ROW};

  width: 100%;
  height: 100vh;

  overflow: ${({ $overflowHidden }) =>
    $overflowHidden ? "hidden" : "visible"};

  .${drawerClasses.paper} {
    margin-top: ${UI_CONFIG.header}px;
  }
`;

export const Main = styled.main`
  position: relative;

  ${FLEX_COLUMN};
  flex: 1;

  width: 100%;

  overflow: hidden;

  height: fit-content;
  min-height: calc(100% - ${UI_CONFIG.header}px);

  margin-top: ${UI_CONFIG.header}px;
  padding: ${({ theme }) => theme.spacing(6)};

  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-top-left-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
  border-top-right-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: -12px;
  width: 24px;
  height: 24px;

  background: ${({ theme }) => theme.palette.primary.main};
  border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  transform: rotate(-45deg);
  z-index: ${({ theme }) => theme.zIndex.tooltip * 10};
  cursor: pointer;

  svg {
    position: relative;
    top: 8px;
    right: -8px;

    transform: rotate(45deg);
  }
  &:hover {
    box-shadow: 0 0 4px 0 ${({ theme }) => theme.palette.primary.main};
  }
`;
