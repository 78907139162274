import { axiosService } from "app";

import { IUser } from "@types";

export const createUserMutation = async (body: IUser) => {
  const response = await axiosService({
    endpoint: "/users",
    method: "POST",
    body,
  });

  return response.data;
};

export const getUserRoles = async (userId: number) => {
  const { data } = await axiosService({
    endpoint: `/role-for-user/user/${userId}`,
  });

  return data;
};

export const getUsers = async ({
  page,
  limit,
  filter,
  sortBy,
  search,
}: {
  page: number;
  limit: number;
  filter: string[];
  sortBy: string;
  search: string;
}) => {
  let filterQuery = "";

  if (filter.length > 0) {
    filter.forEach((item, index) => {
      filterQuery =
        filterQuery +
        `filter.${item}${
          filter.length > 1 && index !== filter.length - 1 ? "&" : ""
        }`;
    });
  }
  let query = `limit=${limit}&page=${page}`;
  if (filterQuery) query = query + `&${filterQuery}`;
  if (search) query = query + `&search=${search}`;
  if (sortBy) query = query + `&sortBy=${sortBy}`;

  try {
    const response = await axiosService({
      endpoint: `/users?${query}`,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch users");
  }
};
