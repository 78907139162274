import styled from "styled-components";

import {
  inputBaseClasses,
  inputClasses,
  textFieldClasses,
} from "@mui/material";

export const AutocompleteWrapper = styled.div`
  .${textFieldClasses.root} {
    .${inputBaseClasses.root}, .${inputClasses.input} {
      padding: ${({ theme }) => theme.spacing(1.5, 0)};
    }

    input::placeholder {
      width: fit-content;
      border: 2px solid rgba(0, 0, 0, 0.23);
      border-radius: 20px;
      padding: ${({ theme }) => theme.spacing(1.5, 3)};
      font-size: 14px;
    }
  }
`;
