import React, { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { getUrlParam } from "@sbm/fe-utils";
import { Input } from "@sbm/ui-components";

import { TableProps } from "../../types";

export const SearchComponent: React.FC<
  Pick<TableProps, "onSearchOptionChange">
> = ({ onSearchOptionChange }) => {
  const { t } = useTranslation();

  const searchValue = getUrlParam("search");

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onSearchOptionChange) {
      onSearchOptionChange(e.target.value);
    }
  };

  return (
    <Input
      autoFocus
      placeholder={t("search")}
      size="small"
      value={searchValue || ""}
      onChange={handleSearchChange}
      sx={{ width: 200 }}
    />
  );
};
