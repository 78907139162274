import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "@sbm/ui-components";

import { getTableColumns } from "./helpers";

interface StandardRouteGeneralInfoProps {
  organizationName: string;
  routeNumber: string;
  dateCreationRoute: string;
  additionalInformation: string | null;
  usageScorer: number;
}

export const StandardRouteGeneralInfo: React.FC<
  StandardRouteGeneralInfoProps
> = ({
  organizationName,
  routeNumber,
  dateCreationRoute,
  usageScorer,
  additionalInformation,
}) => {
  const { t } = useTranslation();

  const rows = useMemo(
    () => [
      {
        id: 1,
        organizationName,
        routeNumber,
        dateCreationRoute,
        usageScorer,
        additionalInformation,
      },
    ],
    [
      organizationName,
      routeNumber,
      dateCreationRoute,
      usageScorer,
      additionalInformation,
    ]
  );

  const columns = useMemo(() => {
    return getTableColumns(t);
  }, [t]);

  return (
    <Table
      rows={rows}
      columns={columns}
      rowCount={0}
      hideFooter
      hideFooterPagination
      checkboxSelection={false}
    />
  );
};
