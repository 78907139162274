import styled from "styled-components";

export const AvatarWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 3)};

  .avatar {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
  }
`;
