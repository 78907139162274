import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";

export const Wrapper = styled(Card)`
  ${FLEX_COLUMN};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
  p {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;
