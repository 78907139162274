import React from "react";
import { Control } from "react-hook-form";

import { DefaultTFuncReturn } from "i18next";

import { Typography } from "@mui/material";
import { FormRadio } from "@sbm/ui-components";

import { RadioButtonsWrapper } from "./styles";

interface Props {
  control: Control<any>;
  values: { value: string | boolean; option: string | DefaultTFuncReturn }[];
  label: string;
  disabled?: boolean;
}

export const StructuralUnitRadioGroup = (props: Props) => {
  const { control, values, label, disabled } = props;

  return (
    <RadioButtonsWrapper>
      <Typography variant="body2" fontWeight={600} sx={{ mb: 1 }}>
        {label}
      </Typography>

      <FormRadio
        name="structuralUnitType"
        control={control}
        values={values}
        flexDirection="column"
        fullWidth={false}
        disabled={disabled}
      />
    </RadioButtonsWrapper>
  );
};
