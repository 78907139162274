import { MouseEvent } from "react";

import * as icons from "lucide-react";

import { useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { TIconNames } from "@types";

interface IconProps {
  name: TIconNames;
  color?: string;
  size?: number;
  strokeWidth?: number;
  fill?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (e?: MouseEvent<SVGSVGElement>) => void;
}

export const Icon = (props: IconProps) => {
  const theme = useTheme();

  const {
    name,
    color = theme.palette.primary.contrastText,
    size = 24,
    fill = "none",
    disabled = false,
    ...rest
  } = props;

  const LucideIcon = icons[name] as icons.LucideIcon;

  return (
    <LucideIcon
      color={disabled ? hexToRgba(color, 0.26) : color}
      size={size}
      style={{
        fill,
        cursor:
          rest.onClick || rest.className === "cursor-pointer"
            ? "pointer"
            : "initial",
      }}
      {...rest}
    />
  );
};
