import React from "react";

import { UI_CONFIG } from "app";
import { TFunction } from "i18next";

import { Chip, SxProps, Theme, Tooltip } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { GridColDef } from "@mui/x-data-grid-pro";
import { COLUMN_SIZE } from "@sbm/fe-utils";
import { IApprover } from "@types";

export function transformQueueStructureToGantt(
  data: Record<string, IApprover[]>,
  isApproverType?: boolean
) {
  const transformedData: string[][] = [];

  // Find the maximum queue number to determine the number of placeholders
  const maxQueueNumber = Math.max(
    ...Object.values(data).map((arr) => arr[arr.length - 1].queueNumber!)
  );

  const getAllPlaceholders = (index: number) => {
    let count = 0;
    let i = 0;

    const result: "Placeholder"[] = [];

    for (let i = 1; i < index; i++) {
      if (data[i]) {
        count += data[i].length;
      }
    }

    while (i < count) {
      result.push("Placeholder");
      i++;
    }

    return result;
  };

  for (let i = 1; i <= maxQueueNumber; i++) {
    let rowData: string[] = [];

    // Iterate through the keys (1, 2, 3) and push the corresponding data or a placeholder
    for (const key in data) {
      const queueItems = data[key].filter((item) => {
        return item.queueNumber === i;
      });

      if (queueItems.length > 0) {
        queueItems.forEach((item) => {
          rowData.push(isApproverType ? item.approverType! : item.status!);
        });

        // Get placeholders if there are
        const placeholders = getAllPlaceholders(i);
        const copy = rowData;
        rowData = [...placeholders, ...copy];
      }
    }

    // Add the row data to the transformed data array
    transformedData.push(rowData);
  }

  return transformedData;
}

export function groupByQueueNumber(approvers: IApprover[]) {
  const groupedByQueue: Record<string, IApprover[]> = {};

  for (const approver of approvers) {
    const { queueNumber } = approver;

    const groupKey = String(queueNumber!);

    if (!groupedByQueue[groupKey]) {
      groupedByQueue[groupKey] = [];
    }

    groupedByQueue[groupKey].push(approver);
  }

  return groupedByQueue;
}

export function getAgreementQueueTableColumns(
  approvers: IApprover[] | undefined,
  t: TFunction,
  theme: Theme,
  isDraft?: boolean,
  currentQueueNumber?: number
) {
  if (!approvers) return [];

  const groupedQueues = groupByQueueNumber(approvers);

  const columnIndexes = Object.keys(groupedQueues);

  const transformedData = transformQueueStructureToGantt(groupedQueues);

  const columns: GridColDef[] = columnIndexes.map((columnIndex) => {
    return {
      field: columnIndex,
      headerName: `${t("queue")} ${columnIndex}`,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: COLUMN_SIZE * 2.6,
      cellClassName:
        currentQueueNumber === Number(columnIndex) ? "active-queue__cell" : "",
      headerClassName:
        currentQueueNumber === Number(columnIndex) ? "active-queue__cell" : "",
      key: columnIndex,
      resizable: false,
      renderCell: (params) => {
        const groupKey = Number(params.field) - 1;
        const itemKey = Number(params.id);

        const label = transformedData[groupKey]?.[itemKey];

        if ((!label && label !== "") || label === "Placeholder") {
          return null;
        }

        const status = params.row.statusForAction;
        const isPending = label === "";

        const getColor = () => {
          switch (true) {
            case isPending:
              return "inherit";
            case status === "under_consideration":
              return theme.palette.secondary.main;
            case status === "approved":
              return theme.palette.success.main;
            case status === "rejected":
            case status === "canceled":
              return theme.palette.error.main;
            default:
              return undefined;
          }
        };

        return (
          <div
            className="MuiDataGrid-cellContent"
            style={{ padding: 8, width: "100%" }}
          >
            <Tooltip arrow placement="top" title={label}>
              <Chip
                label={
                  status ? label : isPending && !isDraft ? t("pending") : ""
                }
                variant={!status || isPending ? "outlined" : "filled"}
                sx={{
                  minWidth: UI_CONFIG.chipWidth,
                  backgroundColor: getColor(),
                  fontWeight: 600,
                  color: isPending
                    ? blueGrey[500]
                    : theme.palette.primary.contrastText,
                  borderColor: isPending ? blueGrey[500] : "inherit",
                }}
              />
            </Tooltip>
          </div>
        );
      },
    };
  });

  return columns;
}

export function getAgreementQueueColumnsByApproverType(
  approvers: IApprover[] | undefined,
  t: TFunction,
  type?: "secondary" | "default"
) {
  if (!approvers) return [];

  const groupedQueues = groupByQueueNumber(approvers);

  const columnIndexes = Object.keys(groupedQueues);

  const transformedData = transformQueueStructureToGantt(groupedQueues, true);

  const columns: GridColDef[] = columnIndexes.map((columnIndex) => {
    return {
      field: columnIndex,
      headerName: `${t("queue")} ${columnIndex}`,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: COLUMN_SIZE * 2.5,
      cellClassName: `approvers-gantt__queue-${columnIndex}`,
      headerClassName: "centered",
      key: columnIndex,
      renderCell: (params) => {
        const groupKey = Number(params.field) - 1;
        const itemKey = Number(params.id);

        const label = transformedData[groupKey]?.[itemKey];

        if (!label || label === "Placeholder") return null;

        const sx: SxProps = {
          fontWeight: 600,

          ".MuiChip-label": {
            display: "block",
          },
        };

        const isSecondary = type === "secondary";

        if (!isSecondary) {
          sx["borderColor"] = blueGrey[500];
        }

        return (
          <div
            className="MuiDataGrid-cellContent"
            style={{
              padding: 8,
              width: "100%",
              display: isSecondary ? "flex" : "block",
              justifyContent: isSecondary ? "center" : "flex-start",
            }}
          >
            <Tooltip arrow placement="top" title={label}>
              <Chip
                label={label}
                variant="outlined"
                className="ellipsis"
                color={isSecondary ? "secondary" : "default"}
                sx={sx}
              />
            </Tooltip>
          </div>
        );
      },
    };
  });

  return columns;
}
