import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_ROW_FULL } from "ui-kit";

export const ContentWrapper = styled.div`
  ${FLEX_ROW_FULL};
  padding: ${({ theme }) => theme.spacing(6, 0)};

  .no-permissions__image {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};
`;

export const TitleWrapper = styled.div`
  ${FLEX_BETWEEN}
`;
