import { createTheme } from "@mui/material/styles";

import { components } from "./components";
import palette from "./palette";
import typography from "./typography";

export let MuiTheme = createTheme({
  palette,
  spacing: 4,
  shape: { borderRadius: 4 },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    htmlFontSize: 16,
    ...typography,
  },
});

MuiTheme = createTheme(MuiTheme, {
  components: components(MuiTheme),
});
