import {
  IConstructionComplex,
  IEmployee,
  IOrganization,
  IStructuralUnit,
} from "./organizations";
import { PermissionNameEnum } from "./PermissionNameEnum";

/**
 * contextData: {
 *       contractId: 2,
 *       organizationId: 1,
 *       someOtherInfo: "...."
 *     }
 */
export type TPermission = {
  permissionName: PermissionNameEnum;
  contextData?: Record<string, unknown>;
};

export type TPermissionResponse = {
  hasPermission: boolean;
  allow: boolean;
  permissionName: PermissionNameEnum;
};

export interface IPermission {
  id: number;
  displayNamePermission: string;
  service: {
    id: number;
    name: string;
  };
  dateOfCreationOfThePermission: string;
  description: string;
}

export interface IPermissionSet {
  id: number;
  nameSetOfPermissions: string;
  service: {
    id: number;
    nameOfService: string;
  };
  createdAt: string;
  additionalInformation: string;
}

export interface IPermissionSetById {
  id: number;
  nameSetOfPermissions: string;
  createdAt: string;
  additionalInformation: "string";
  service: IService;
  typeResource: IResourceType | undefined;
  organizationMatchingRule: OrganizationMatchingRuleEnum;
  structuralUnitMatchingRule: StructuralUnitMatchingRuleEnum;
  employeeMatchingRule: EmployeeMatchingRuleEnum;
  constructionComplexMatchingRule: ConstructionComplexMatchingRuleEnum;
  confidentiallyMatchingRule: ConfidentiallyMatchingRuleEnum;
  organizations?: IOrganization[];
  structuralUnits?: IStructuralUnit[];
  employees?: { id: number; longName: string }[] | IEmployee[];
  constructionComplexes?: IConstructionComplex[];
  deactivationDate: string | null;
  permissions: IRolePermission[];
}

export interface IPermissionListForRole {
  id: number;
  nameSetOfPermissions: string;
  createdAt: string;
  additionalInformation: "string";
  service: IService;
  typeResource: IResourceType | undefined;
  organizationMatchingRule: OrganizationMatchingRuleEnum;
  structuralUnitMatchingRule: StructuralUnitMatchingRuleEnum;
  employeeMatchingRule: EmployeeMatchingRuleEnum;
  constructionComplexMatchingRule: ConstructionComplexMatchingRuleEnum;
  confidentiallyMatchingRule: ConfidentiallyMatchingRuleEnum;
  organizations?: IOrganization[];
  structuralUnits?: IStructuralUnit[];
  employees?: { id: number; longName: string }[];
  constructionComplexes?: IConstructionComplex[];
  deactivationDate: string | null;
  permissions: IRolePermission[];
}

export interface IRolePermission {
  id: number;
  serviceId: number;
  displayNamePermission: string;
  namePermission: string;
  descriptionPermission: string;
  creationDate: string;
  validity: boolean;
  additionalInformation: string;
  createdAt: string;
  updatedAt: string;
}
export interface IResourceType {
  id: number;
  nameOfTypeResource: string;
  createdAt: string;
  updatedAt: string;
}

export interface IService {
  id: number;
  nameOfService: string;
  createdAt: string;
  updatedAt: string;
}

export interface IRoleList {
  id: number;
  roleName: string;
  createdAt: string;
  updatedAt: string;
  additionalInformation: string;
}

export interface IPermissionSetForm {
  serviceId?: number;
  dataAccessRestrictionType?: string;
  resource_type?: number | null;
  selectedPermissionsForResourceType: number[];
  additionalInformation: string;
  nameSetOfPermissions: string;
  confidentiallyMatchingRule: ConfidentiallyMatchingRuleEnum;
  constructionComplexMatchingRule: ConstructionComplexMatchingRuleEnum;
  typeResourceId?: number;
  organizationMatchingRule: OrganizationMatchingRuleEnum;
  structuralUnitMatchingRule: StructuralUnitMatchingRuleEnum;
  employeeMatchingRule: EmployeeMatchingRuleEnum;
  organizationIds: number[];
  structuralUnitIds: number[];
  employeeIds: number[];
  constructionComplexIds: number[];
  permissionIds?: number[];
  typeResource?: IResourceType;
}
export interface ISetOfPermissionForm {
  serviceId?: number | undefined;
  dataAccessRestrictionType?: string;
  resource_type?: number | null;
  selectedPermissionsForResourceType: number[];
  additionalInformation: string;
  nameSetOfPermissions: string;
  confidentiallyMatchingRule: ConfidentiallyMatchingRuleEnum;
  constructionComplexMatchingRule: ConstructionComplexMatchingRuleEnum;
  typeResourceId?: number;
  organizationMatchingRule: OrganizationMatchingRuleEnum;
  structuralUnitMatchingRule: StructuralUnitMatchingRuleEnum;
  employeeMatchingRule: EmployeeMatchingRuleEnum;
  organizationIds: number[];
  structuralUnitIds: number[];
  employeeIds: number[];
  constructionComplexIds: number[];
  permissionIds?: number[];
  typeResource?: IResourceType;
  createdAt?: string;
  service?: IService;
  organizations?: IOrganization[];
  structuralUnits?: IStructuralUnit[];
  employees?: IEmployee[];
  constructionComplexes?: IConstructionComplex[];
  deactivationDate?: string | null;
  permissions?: IRolePermission[];
}

export interface IResourcesType {
  createdAt: string;
  id: number;
  typeResourceId: number;
  serviceId: number;
  updatedAt: number;
  typeResource: {
    id: number;
    nameOfTypeResource: string;
    createdAt: string;
    updatedAt: number;
  };
}

export interface IRole {
  id: number;
  name: string;
  nameRole?: string;
  additionalInformation: string;
  setOfPermissions: IPermissionSetById[];
  role?: { id: number };
}

export interface IAssignedRole {
  id: number;
  nameRole: string;
  assignedToUser?: boolean;
}
export interface IAssignedRoleForm {
  userId?: number;
  roleId: number | undefined;
  temporaryRole: "true" | "false";
  additionalInformation: string;
  expirationDate?: Date;
}
export interface IRoleForm {
  id: number;
  name?: string;
  nameRole?: string;
  additionalInformation: string;
  setOfPermissions?: {
    setOfPermissions: IPermissionSetById;
  }[];
  setOfPermissionsIds?: number[];
}

/**** ENUMS ****/
export enum RestrictionTypes {
  organizationMatchingRule = "organizationMatchingRule",
  employeeMatchingRule = "employeeMatchingRule",
  structuralUnitMatchingRule = "structuralUnitMatchingRule",
}

export enum EmployeeMatchingRuleEnum {
  notSpecified = "not_specified",
  currentEmployee = "current_employee",
  manualEmployee = "manual_employee",
}
export enum StructuralUnitMatchingRuleEnum {
  notSpecified = "not_specified",
  employeesStructuralUnit = "employees_structural_unit",
  employeesStructuralUnitSubordinate = "employees_structural_unit_subordinate",
  manualStructuralUnit = "manual_structural_unit",
  substituteUnits = "substitute_structural_units",
  substituteUnitsSubordinate = "substitute_structural_subordinate",
}
export enum OrganizationMatchingRuleEnum {
  notSpecified = "not_specified",
  employeesOrganization = "employees_organization",
  manualOrganizations = "manual_organizations",
  allOrganizations = "all_organizations",
  substituteOrganizations = "substitute_organizations",
}

export enum ConstructionComplexMatchingRuleEnum {
  notSpecified = "not_specified",
  manualConstructionComplex = "manual_construction_complex",
}
export enum ConfidentiallyMatchingRuleEnum {
  exceptSecret = "except_secret",
  includeSecret = "include_secret",
}
