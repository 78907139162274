import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { cardClasses } from "@mui/material";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .page__wrapper {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(4)};

    width: 100%;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      ${FLEX_COLUMN}
    }
  }

  .${cardClasses.root} {
    width: 100%;
  }
`;

export const LeftSection = styled.div`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(4)};

  width: 100%;
  max-width: 33%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 100%;
  }
`;

export const RightSection = styled.div`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(4)};
  flex: 1;

  width: 100%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 100%;
  }

  .registration-terms__wrapper {
    ${FLEX_ROW};
    gap: ${({ theme }) => theme.spacing(4)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
      ${FLEX_COLUMN}
    }
  }
`;

export const ActionsSection = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};
  .main_buttons {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(2)};
    justify-content: flex-end;
  }

  button {
    max-width: ${UI_CONFIG.submitButton}px;
  }
`;

export const FormContainer = styled.div`
  ${FLEX_ROW};
  gap: ${({ theme }) => theme.spacing(3)};
  flex: 1;

  background-color: ${({ theme }) => theme.palette.grey[50]};

  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing()};
`;

export const ButtonWrapper = styled.div`
  .send_to_do_button {
    min-width: ${UI_CONFIG.modalButton}px;
  }
`;

export const CardWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(4, 0, 8, 0)};
`;
