import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";

export const Wrapper = styled.div<{
  $isMaximized?: boolean;
}>`
  display: ${({ $isMaximized }) => ($isMaximized ? "flex" : "")};
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const DepartmentWrapper = styled(Card)`
  ${FLEX_COLUMN};
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(4)};
  width: 100%;
`;
