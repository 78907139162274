import React from "react";

import { Breadcrumbs } from "ui-kit";

import { Toolbar } from "@mui/material";

import { UserAvatar } from "./components";
import { AppBar } from "./styles";

interface HeaderProps {
  open: boolean;
}

export const Header = (props: HeaderProps) => {
  const { open } = props;

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Breadcrumbs />
        <UserAvatar />
      </Toolbar>
    </AppBar>
  );
};
