import React from "react";
import { useAuth } from "react-oidc-context";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";

import { PageLoader } from "ui-kit";

import { useAppDispatch, useAppSelector, usePermissions } from "../../hooks";
import { setToken } from "../../store";
import { Paths } from "../constants";

const USERS_PATHS = [Paths.USERS, Paths.USERS_CREATE, Paths.PROFILE];

export const RoutesDecorator = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { userProfile } = useAppSelector((state) => state.auth);

  const loading = auth.isLoading || auth.activeNavigator;

  const email = userProfile
    ? typeof userProfile.email === "string"
      ? userProfile.email
      : userProfile.email.email
    : undefined;

  const { hasPermission } = usePermissions(email);

  React.useEffect(() => {
    // Handle users management view access
    const isAuthenticated = auth.isAuthenticated && !auth.error;

    if (!isAuthenticated) return;

    let match = false;

    USERS_PATHS.forEach((path) => {
      match = Boolean(matchPath(path, location.pathname));

      if (match && !hasPermission && email) {
        navigate(Paths.HOME);
      }
    });
  }, [
    email,
    auth.error,
    auth.isAuthenticated,
    hasPermission,
    location.pathname,
    navigate,
  ]);

  React.useEffect(() => {
    const isNotAuthenticated = !auth.isAuthenticated || auth.error;

    if (!loading && isNotAuthenticated) {
      void auth.signinRedirect({
        state: `${location.pathname}${location.search}`,
      });
    }
    // eslint-disable-next-line
  }, [loading, auth]);

  React.useEffect(() => {
    void auth.clearStaleState();

    return auth.events.addUserSignedOut(() => {
      void auth.removeUser();
    });
  }, [auth]);

  React.useEffect(() => {
    const isAuthenticated = auth.user && auth.isAuthenticated;
    const token = auth.user?.access_token;

    if (isAuthenticated && !loading) {
      dispatch(setToken(token));
    }
  }, [auth, dispatch, loading]);

  if (
    (loading || !auth.isAuthenticated) &&
    auth.activeNavigator !== "signinSilent"
  ) {
    return <PageLoader />;
  }

  return auth.isAuthenticated ? <Outlet /> : <div />;
};
