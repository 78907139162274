import {
  IOrganization,
  IOrganizationEmployee,
  OrganizationEmployeeOptionEnum,
} from "@types";

export const getFormattedEmployeesData = (dataToSend: IOrganization) => {
  return dataToSend.employees!.map((employee) => {
    const itemToReturn: IOrganizationEmployee = {
      id: employee.id,
      additionalInformation: employee.additionalInformation,
      divisionExternalOrganization: employee.divisionExternalOrganization,
      positionExternalOrganization: employee.positionExternalOrganization,
      personalDataId: employee.personalDataId,
      typeStaff: employee.typeStaff,
      phone: String(employee.phone),
      email: employee.email,
    };

    if (employee.operatesOnBasis) {
      itemToReturn["operatesOnBasis"] = employee.operatesOnBasis;
    }

    if (employee.typeStaff === OrganizationEmployeeOptionEnum.firstPerson) {
      delete itemToReturn["divisionExternalOrganization"];
    }

    if (employee.typeStaff === OrganizationEmployeeOptionEnum.employee) {
      delete itemToReturn["operatesOnBasis"];
    }

    return itemToReturn;
  });
};

export const getFormattedUpdateOrganizationData = (
  dataToSend: IOrganization
) => {
  delete dataToSend["serviceType"];
  delete dataToSend["externalOrganizationStaff"];
  delete dataToSend["employees"];
  delete dataToSend["updatedAt"];
  delete dataToSend["createdAt"];
  delete dataToSend["id"];

  Object.keys(dataToSend).forEach((value) => {
    const key = value as keyof IOrganization;
    if (!dataToSend[key] && dataToSend[key] !== false) {
      delete dataToSend[key];
    }
  });

  return dataToSend;
};

export const getFormattedCreateOrganizationData = (
  dataToSend: IOrganization
) => {
  delete dataToSend["externalOrganizationStaff"];
  delete dataToSend["employees"];
  delete dataToSend["serviceType"];

  Object.keys(dataToSend).forEach((value) => {
    const key = value as keyof IOrganization;
    if (!dataToSend[key] && dataToSend[key] !== false) {
      delete dataToSend[key];
    }
  });

  return dataToSend;
};
