import styled from "styled-components";

import { Typography } from "@mui/material";

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(6, 0)};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: 0.7;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;
