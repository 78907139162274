import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { MainLayout } from "./layouts";
import { PRIVATE_ROUTES, RoutesDecorator } from "./routes";

export const MainRouter = () => {
  return (
    <Routes>
      <Route element={<RoutesDecorator />}>
        {PRIVATE_ROUTES.map(({ path, Element }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <MainLayout>
                  <Element />
                </MainLayout>
              }
            />
          );
        })}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};
