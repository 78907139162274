import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const FormWrapper = styled.form<{ noValidate: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(6)};
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  height: 40px;

  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;

  margin-top: ${({ theme }) => theme.spacing(10)};
  margin-bottom: ${({ theme }) => theme.spacing(-5)};
`;
