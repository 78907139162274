import styled from "styled-components";
import { FLEX_ROW } from "ui-kit";

export const FormContainer = styled.div`
  ${FLEX_ROW};
  gap: ${({ theme }) => theme.spacing(3)};
  flex: 1;

  background-color: ${({ theme }) => theme.palette.grey[50]};

  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing()};
`;
