import React from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import {
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import { Button } from "@sbm/ui-components";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  isContractTypeSelection?: boolean;
}

export function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, isContractTypeSelection = false } = props;

  const { t } = useTranslation();

  const handleClick = () => {
    const id = Math.floor(Math.random() * 100);

    if (isContractTypeSelection) {
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          contractType: "",
          contractSubtype: "",
          defaultRoute: false,
          isNew: true,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "contractType" },
      }));
    } else {
      setRows((oldRows) => [...oldRows, { id, approverType: "", isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "approverType" },
      }));
    }
  };

  return (
    <GridToolbarContainer sx={{ p: 3 }}>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        {t("add")}
      </Button>
    </GridToolbarContainer>
  );
}
