export enum UsersFieldsEnum {
  staff = "staff",
  status = "status",
}

export enum UsersFieldNamesEnum {
  staff = "staff",
  status = "status",
}

enum GenderEnum {
  male = "male",
  female = "female",
}

interface IPersonalData {
  id: number;
  longName: string | null;
  surname: string;
  name: string;
  fullName?: string;
  shortName?: string;
  patronymic?: string;
  additionalInformation: string | null;
  createdAt: string;
  updatedAt: string;
  gender?: GenderEnum;
  employees?: IEmployee[];
}

export interface IEmployee {
  id: number;
  staffUnitId: number;
  personalDataId: number;
  personnelNumber: string;
  dateOfRecruitment: string;
  dateOfDismissal: null;
  additionalInformation: string;
  validity: true;
  createdAt: string;
  updatedAt: string;
  personalData: IPersonalData;
}

export const getPersonalDataName = (personalData: IPersonalData, isShortName?: boolean) => {
  if (isShortName) {
    return personalData.shortName;
  }
  return (
    personalData.longName ??
    (!personalData.surname && !personalData.patronymic && !personalData.name
      ? undefined
      : `${personalData.surname || ""} ${personalData.name || ""} ${personalData.patronymic || ""}`)
  );
};
