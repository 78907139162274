export const setStorageItem = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getStorageItem = (key: string) => {
  const name = localStorage.getItem(key);

  if (name) return JSON.parse(name);
};

export const removeStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

export const STORAGE_KEYS = {
  lang: "lang",
  token: "token",
  appliedFilters: "appliedFilters",
  usersSearchValue: "usersSearchValue",
};
