import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetPersonalData } from "api";
import { useDebounce, useEffectOnce } from "app";
import { Icon } from "ui-kit";

import {
  Autocomplete,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { IOrganization, IPersonalData } from "@types";

import { AddEmployeePopup } from "./AddEmployeePopup";

interface EmployeeAutocompleteProps {
  setValue: UseFormSetValue<IOrganization>;
  employees: IOrganization["employees"];
  organizationId: number;
  position: number;
}

export const EmployeeAutocomplete: React.FC<EmployeeAutocompleteProps> = ({
  organizationId,
  setValue: setFormValue,
  position,
  employees: formEmployees,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const [search, setSearch] = React.useState("");
  const [value, setValue] = React.useState<IPersonalData | null>(null);
  const [openAddModal, setOpenAddModal] = React.useState(false);

  const debouncedSearch = useDebounce(search, 500);
  const { data: employees, isLoading } = useGetPersonalData(debouncedSearch);

  const handleClose = () => {
    setOpenAddModal(false);
  };

  const handleChange = (
    _: React.SyntheticEvent,
    newValue: IPersonalData | null
  ) => {
    setValue(newValue);
    const formValueToSet = newValue ? newValue.id : undefined;

    // @ts-ignore
    setFormValue(`employees[${position}].personalDataId`, formValueToSet, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
      required: true,
    });
  };

  const options: IPersonalData[] = React.useMemo(
    () => employees?.items || [],
    [employees]
  );

  useEffectOnce(() => {
    const personalData = formEmployees && formEmployees[position].personalData;
    if (personalData) {
      setValue(personalData);
    }
  });

  return (
    <React.Fragment>
      <Autocomplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        value={value}
        options={options}
        loading={isLoading}
        onChange={handleChange}
        onInputChange={(_, newInputValue) => {
          setSearch(newInputValue);
        }}
        noOptionsText={
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">{t("nothing.found")}</Typography>
            <Button
              size="small"
              variant="text"
              color="secondary"
              startIcon={<Icon name="Plus" color={palette.secondary.main} />}
              onClick={() => setOpenAddModal(true)}
            >
              {t("add")}
            </Button>
          </Stack>
        }
        getOptionLabel={(option) => {
          if (!option) return "";
          if (typeof option === "string") return option;
          return getPersonalDataName(option as IPersonalData) || "";
        }}
        renderOption={(props, option) => (
          <li {...props}>{getPersonalDataName(option as IPersonalData)}</li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            color="secondary"
            label={t("search.fullname")}
          />
        )}
      />

      <AddEmployeePopup
        organizationId={organizationId}
        open={openAddModal}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};
