import styled from "styled-components";
import { FLEX_COLUMN_FULL, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const AlertWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  gap: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
export const LabelWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
`;
