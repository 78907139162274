export enum StaffAllocationFilterKeys {
  positionId = "positionId",
  employeeId = "employeeId",
  structuralUnitId = "structuralUnitId",
  dateOfRecruitment = "dateOfRecruitment",
}

export const StaffAllocationFilterNames = {
  positionId: "staff_position.name",
  employeeId: "employee.fullname",
  structuralUnitId: "name.of.the.structural.unit",
  dateOfRecruitment: "employee.assignment.date",
};
