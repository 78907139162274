import React from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Table } from "ui-kit";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { getFormattedTreeData, TreeNode } from "@sbm/fe-utils";
import { Button, Input } from "@sbm/ui-components";
import { IStructuralUnitTree, ModalVariants } from "@types";

import { useAppDispatch } from "../../../hooks";
import { setModal, updateStructuralUnit } from "../../../store";
import { COLUMNS } from "./constants";
import { PositionsTable } from "./PositionsTable";
import { TitleWrapper, FormWrapper } from "./styles";

interface Props {
  branch: IStructuralUnitTree | null;
  name?: string;
  organizationId?: string;
}

export type TEditStructuralUnitForm = {
  additionalInformation: string;
  validity: boolean;
  orders?: { structuralUnitId: number; numberForChildList: number }[];
};

export const ViewStructuralUnitContainer = (props: Props) => {
  const { branch, name, organizationId } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const tree = getFormattedTreeData(
    branch?.treeBranch as unknown as { nodes: TreeNode[] }
  );

  const currentUnit = tree && tree.length > 0 ? tree.at(-1) : null;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { dirtyFields, defaultValues },
  } = useForm<TEditStructuralUnitForm>({
    defaultValues: React.useMemo(() => {
      return {
        additionalInformation: currentUnit?.additionalInformation || "",
        validity: currentUnit?.validity,
        orders: [],
      };
    }, [currentUnit]),
  });

  const { additionalInformation } = useWatch({ control });

  const { fields: orders } = useFieldArray({
    control,
    name: "orders",
  });

  const handleClose = () => dispatch(setModal(null));

  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const handleUpdateStructuralUnit = async (form: TEditStructuralUnitForm) => {
    if (!currentUnit || !organizationId) return;

    setIsLoading(true);

    if (orders.length > 0) {
      form["orders"] = orders.map((i) => {
        return {
          structuralUnitId: i.structuralUnitId,
          numberForChildList: i.numberForChildList,
        };
      });
    } else {
      delete form["orders"];
    }

    await dispatch(
      updateStructuralUnit({
        structuralUnitId: currentUnit.id as number,
        structuralUnit: form,
        organizationId,
      })
    );

    setIsLoading(false);
    dispatch(setModal(null));
  };

  const onSubmit = handleSubmit(async (form) => {
    if (!currentUnit || !organizationId) return;

    if (form.validity === false) {
      return dispatch(
        setModal({
          open: true,
          variant: ModalVariants.confirmDisableStructuralUnit,
          content: {
            confirmNavigation: () => handleUpdateStructuralUnit(form),
          },
        })
      );
    }

    await handleUpdateStructuralUnit(form);
  });

  const TableRows = React.useMemo(() => {
    let result: any[] = [];

    if (tree) {
      result = tree.map((item) => {
        return {
          id: item.id,
          name: item.nameOfStructuralUnit,
          node: item.node,
          validity: item.validity ? t("yes") : t("no"),
          structuralUnitType: t(item.structuralUnitType),
          headOfStructuralUnit: item.id === currentUnit?.id,
        };
      });
    }

    return result;
  }, [tree, t, currentUnit?.id]);

  const TableColumns: GridColDef[] = React.useMemo(() => {
    const columns: GridColDef[] = COLUMNS.map((item) => ({
      ...item,
      headerName: t(item.headerName || "") as string,
      renderCell: (params) => {
        const isCurrent = params.row.id === currentUnit?.id;
        return (
          <div className="MuiDataGrid-cellContent">
            <span style={{ fontWeight: isCurrent ? 700 : 400 }}>
              {params.row[params.field]}
            </span>
          </div>
        );
      },
    }));

    return columns;
  }, [currentUnit?.id, t]);

  return (
    <FormWrapper noValidate onSubmit={onSubmit}>
      <TitleWrapper>
        <Typography variant="h6" fontWeight={500}>
          {name}
        </Typography>

        <Button
          fullWidth={false}
          variant="contained"
          color="inherit"
          size="small"
          onClick={toggleEditMode}
          startIcon={isEditMode ? <ArrowBackIcon /> : <EditIcon />}
        >
          {isEditMode ? t("back") : t("edit")}
        </Button>
      </TitleWrapper>

      <Table
        treeData
        getTreeDataPath={(row) => row.node}
        rowCount={1}
        checkboxSelection={false}
        rowsPerPageOptions={[]}
        rows={TableRows}
        columns={TableColumns}
        groupingColDefName={t("name.of.the.structural.unit") as string}
      />

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={7}>
          <Input
            shrink
            readOnly={!isEditMode}
            value={additionalInformation}
            label={t("additional.information")}
            maxLength={300}
            {...register("additionalInformation")}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <FormControlLabel
            label={t("actualStructuralUnit")}
            labelPlacement="start"
            control={
              <Switch
                disabled={!isEditMode}
                defaultChecked={!!defaultValues?.validity}
                {...register("validity")}
              />
            }
          />
        </Grid>
      </Grid>

      <Accordion
        sx={{
          mt: 8,
          width: "100%",
          backgroundColor: theme.palette.grey[50],
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={500}>
            {t("position.in.the.list")}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <PositionsTable
            isEditMode={isEditMode}
            branch={branch}
            organizationId={organizationId}
            currentStructuralUnitId={currentUnit.id}
            setValue={setValue}
          />
        </AccordionDetails>
      </Accordion>

      <div className="button-wrapper">
        {isEditMode ? (
          <Button
            type="submit"
            variant="contained"
            size="small"
            fullWidth={false}
            loading={isLoading}
            disabled={!Object.keys(dirtyFields).length && !orders?.length}
          >
            {t("save")}
          </Button>
        ) : (
          <Button
            variant="contained"
            size="small"
            fullWidth={false}
            onClick={handleClose}
          >
            {t("close")}
          </Button>
        )}
      </div>
    </FormWrapper>
  );
};
