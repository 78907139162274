import { Theme } from "@mui/material";

export const components = (theme: Theme) => {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: `${theme.palette.secondary.main} !important`,
            },
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "16px !important",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 16,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          [theme.breakpoints.up("lg")]: {
            maxHeight: "calc(100% - 340px)!important",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeMedium: {
          fontSize: 13,
        },
        label: {
          display: "flex",
          alignItems: "center",
          gap: "4px",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          maxWidth: "100% !important",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px !important",
        },
        asterisk: {
          color: theme.palette.error.main,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          fontSize: "14px !important",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "16px !important",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          ".MuiPaper-root": {
            padding: "16px",
            borderRadius: "16px",
            minWidth: "600px",

            [theme.breakpoints.down("sm")]: {
              maxWidth: "100%",
            },
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
        main: {
          flexGrow: 0,
          height: "100%",
          maxHeight: 600,
        },
        footerContainer: { minHeight: 50 },
        cell: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),

          "&[data-field=__detail_panel_toggle__]": {
            justifyContent: "center",

            ".MuiButtonBase-root svg": {
              height: 20,
            },
            ".Mui-disabled": {
              "&.MuiButtonBase-root svg": {
                display: "none",
              },
            },
          },

          "& .MuiDataGrid-cellContent": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& div": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& p": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& span": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        columnHeaderTitle: {
          fontSize: 14,
          letterSpacing: 0.17,
          fontWeight: 600,
        },
        virtualScrollerContent: {
          minHeight: 520,
        },
        overlayWrapper: {
          minHeight: 350,
        },
        detailPanelToggleCell: {
          width: 48,
          height: 48,
        },
        detailPanel: {
          backgroundColor: theme.palette.background.paper,
          ".MuiDataGrid-cell": {
            alignItems: "start",
            paddingTop: "16px",
            paddingBottom: "16px",

            "&.centered": {
              alignItems: "center",
            },
          },
        },
        row: {
          "&--detailPanelExpanded": {
            backgroundColor: theme.palette.action.selected,

            "&:hover": {
              backgroundColor: theme.palette.action.selected,
            },
          },
          "&--lastVisible .MuiDataGrid-cell": {
            borderBottomColor: "rgba(224,224,224, 1) !important", // Didn't find the right color from MUI system
          },
        },
        // treeDataGroupingCellToggle: {
        //   display: "none",
        // },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          display: "flex",
          alignItems: "self-end",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: "16px 0 0 16px",
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        /* Components/Button */
        root: {
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 600,
          textTransform: "initial",
          borderRadius: "4px",
          minWidth: "60px",
          height: "fit-content",
        },
        /* Components/Button Large */
        sizeLarge: {
          fontSize: "15px",
          lineHeight: " 26px",
          letterSpacing: "0.46px",
          padding: "8px 22px",
        },
        /* Components/Button Medium */
        sizeMedium: {
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "0.4px",
          padding: "6px 16px",
        },
        /* Components/Button Small */
        sizeSmall: {
          fontSize: "13px",
          letterSpacing: "0.46px",
          padding: "4px 10px",
        },
        /* Components/Button Text */
        textSizeSmall: {
          padding: "8px 11px",
        },
        textSizeMedium: {
          padding: "6px 8px",
        },
        textSizeLarge: {
          padding: "4px 5px",
        },
      },
    },
  };
};
