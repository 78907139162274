import React from "react";

import { useAppSelector } from "app";

import {
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  useTheme,
} from "@mui/material";

import { StepperItem } from "./StepperItem";

const steps = ["Details", "Attachments"];

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    margin: 0,
    top: 4,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.secondary.main} 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 100%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
  },
}));

const CustomStepIconComponentRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(() => ({
  zIndex: 1,
  width: 50,
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function CustomStepIconComponent({
  active,
  completed,
  className,
  icon,
}: StepIconProps) {
  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <StepperItem
        completed={completed}
        active={active}
        name="Info"
        label="createLetterDrawer.step1_label"
      />
    ),
    2: (
      <StepperItem
        completed={completed}
        active={active}
        name="UploadCloud"
        label="createLetterDrawer.step2_label"
      />
    ),
  };

  return (
    <CustomStepIconComponentRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </CustomStepIconComponentRoot>
  );
}

export const CreateLetterStepper = () => {
  const theme = useTheme();

  const { letterCreationStep } = useAppSelector(
    (state) => state.correspondence
  );

  return (
    <Stack
      position="fixed"
      width="552px"
      height="100px"
      justifyContent="center"
      top={80}
      zIndex={10}
      bgcolor={theme.palette.background.default}
    >
      <Stack>
        <Stepper
          alternativeLabel
          activeStep={letterCreationStep}
          connector={<StyledStepConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={CustomStepIconComponent} />
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Stack>
  );
};
