import { GridColDef } from "@mui/x-data-grid-pro";
import { COLUMN_SIZE } from "@sbm/fe-utils";
import { UsersStatus } from "@types";

export const TABLE_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "id",
    minWidth: COLUMN_SIZE * 1.5,
    sortable: false,
    filterable: false,
  },
  {
    field: "name",
    headerName: "first.name",
    minWidth: COLUMN_SIZE * 2,
    flex: 1,
    sortable: true,
    filterable: false,
  },
  {
    field: "surname",
    headerName: "last.name",
    flex: 1,
    minWidth: COLUMN_SIZE * 2,
    sortable: true,
    filterable: false,
  },
  {
    field: "email",
    headerName: "email",
    flex: 1,
    minWidth: COLUMN_SIZE * 2,
    sortable: true,
    filterable: false,
  },

  {
    field: "status",
    headerName: "status",
    flex: 1,
    minWidth: COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "staff",
    headerName: "staff.member",
    flex: 1,
    minWidth: COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "created.at",
    flex: 1,
    minWidth: COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
];
export const USERS_FILTERABLE_FIELDS = [
  {
    title: "status",
    field: "status",
    options: [UsersStatus.active, UsersStatus.deactivated],
    value: "",
  },
  {
    title: "staff",
    field: "staff",
    options: ["true", "false"],
    optionType: "radio",
    value: "",
  },
];
