import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";

export const getFilterValue = (val: string | string[]) => {
  switch (val) {
    case "yes":
      return "true";
    case "no":
      return "false";
    default:
      return val;
  }
};

const locale = getStorageItem(STORAGE_KEYS.lang);
const isEnglish = locale === "en";

export const dateFormat = `dd/${isEnglish ? "MMM" : "MM"}/yyyy`;
