import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import {
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModesModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Table } from "@sbm/ui-components";
import {
  EnumApproverType,
  IApprover,
  ICreateStandardRoute,
  IStructuralUnit,
} from "@types";

import { EditToolbar } from "./EditToolbar";
import { getTableColumns } from "./helpers";

interface SelectApproversProps {
  setValue: UseFormSetValue<ICreateStandardRoute>;
  organizationId?: number;
  approvers?: IApprover[];
  structuralUnits: Partial<IStructuralUnit>[];
}

export const SelectApprovers = (props: SelectApproversProps) => {
  const { setValue, approvers, structuralUnits } = props;

  const { t } = useTranslation();

  const apiRef = useGridApiRef();

  const initialRows: GridValidRowModel[] = [
    { id: 0, approverType: t(EnumApproverType.initiator_of_the_contract) },
    { id: 1, approverType: t(EnumApproverType.signatory_of_the_contract) },
  ];

  const [rows, setRows] = React.useState(approvers || initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns = React.useMemo(
    () =>
      getTableColumns(
        t,
        rowModesModel,
        setRowModesModel,
        setRows,
        rows,
        structuralUnits,
        apiRef
      ),
    [apiRef, rowModesModel, rows, structuralUnits, t]
  );

  React.useEffect(() => {
    const approverTypes = rows.map((item, index) => ({
      approverType: item.approverType,
      queueNumber:
        item.approverType === t(EnumApproverType.signatory_of_the_contract)
          ? 2
          : 1,
      id: index,
    })) as IApprover[];

    setValue("approvers", approverTypes);
  }, [rows, setValue, t]);

  return (
    <div>
      <Typography variant="body1" fontWeight={600} sx={{ mb: 2 }}>
        {t("select.approving.subjects")}
      </Typography>

      <Table
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        rowCount={0}
        hideFooterPagination
        checkboxSelection={false}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </div>
  );
};
