import React from "react";

import { Modal } from "ui-kit";

import { CreateStaffMemberContainer } from "../../../../containers";

export const CreateStaffMemberPopup = (props: {
  open: boolean;
  onClose: () => void;
  data?: any;
  isViewMode?: boolean;
}) => {
  const { open, onClose, data, isViewMode = false } = props;

  const content = {
    title: "",
    body: <CreateStaffMemberContainer data={data} isViewMode={isViewMode} />,
    secondaryButton: null,
    mainButton: null,
  };

  return <Modal isOpen={open} onClose={onClose} content={content} sizeLarge />;
};
