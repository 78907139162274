import React from "react";

import { Stack, Typography } from "@mui/material";

interface TaskDetailTextSectionProps {
  label: string;
  content: string | React.ReactNode;
  disableEllipsis?: boolean;
}

export const TaskDetailTextSection: React.FC<TaskDetailTextSectionProps> = ({
  label,
  content,
  disableEllipsis,
}) => {
  return (
    <Stack>
      <Typography variant="body4" color="text.secondary">
        {label}
      </Typography>
      <Typography
        variant="body2"
        fontWeight={600}
        className={disableEllipsis ? "" : "ellipsis"}
      >
        {content}
      </Typography>
    </Stack>
  );
};
