import styled from "styled-components";

import { EmailStatusProps } from "./index";

export const EmailStatusWrapper = styled.div<EmailStatusProps>`
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ color }) => color || "inherit"};

  outline: 2px solid ${({ color }) => color || "inherit"};
  box-shadow: ${({ theme }) => theme.shadows[3]};
  border-radius: 4px;

  padding: ${({ theme }) => theme.spacing(1, 2)};
`;
