import { WebStorageStateStore, User, InMemoryWebStorage } from "oidc-client-ts";

import { environment } from "../../environments/environment";

const resolveStorage = (type: string) => {
  switch (type) {
    case "local":
      return localStorage;
    case "session":
      return sessionStorage;
    case "in-memory":
      return new InMemoryWebStorage();
    default:
      return sessionStorage;
  }
};

const userStore = resolveStorage(environment.userStorageType);
const stateStore = resolveStorage(environment.stateStorageType);

const oidcConfig = {
  authority: environment.authority,
  client_id: environment.clientId,
  scope: "openid",
  redirect_uri: `${window.origin}/`,
  silent_redirect_uri: `${window.origin}/silent-check-sso.html`,
  post_logout_redirect_uri: `${window.origin}/`,
  response_type: "code",
  staleStateAgeInSeconds: 3600,
  userStore: new WebStorageStateStore({
    prefix: environment.storeKeyPrefix,
    store: userStore,
  }),
  stateStore: new WebStorageStateStore({
    prefix: environment.storeKeyPrefix,
    store: stateStore,
  }),
  monitorSession: true,
};

const oidcConfigContext = {
  ...oidcConfig,
  onSigninCallback: (user: any) => {
    window.location = user?.state;
  },
};

const getUser = () => {
  const userString = userStore.getItem(
    `${environment.storeKeyPrefix}user:${environment.authority}:${environment.clientId}`
  );

  if (!userString) {
    return null;
  }

  return User.fromStorageString(userString);
};

export { oidcConfigContext, getUser };
