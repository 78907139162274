import React from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { arrayEquals } from "@sbm/fe-utils";
import { Table } from "@sbm/ui-components";
import { EnumApproverType, IApprover, ICreateStandardRoute } from "@types";

import { getSignatoryQueueNumber, getTableColumns } from "./helpers";

interface SortApproversProps {
  approvers?: IApprover[];
  control: Control<ICreateStandardRoute>;
  setValue: UseFormSetValue<ICreateStandardRoute>;
}

export const SortApprovers = (props: SortApproversProps) => {
  const { approvers, control, setValue } = props;

  const { t } = useTranslation();

  const rows = React.useMemo(() => {
    if (!approvers) return [];

    const sortedApprovers = approvers
      .filter((i) => i.approverType)
      .sort((a, b) => a.queueNumber! - b.queueNumber!);

    return sortedApprovers.map((item, index) => {
      const isApproverSignatory =
        item.approverType === t(EnumApproverType.signatory_of_the_contract);

      if (isApproverSignatory) {
        return {
          ...item,
          id: index,
          queueNumber: getSignatoryQueueNumber(
            item.queueNumber!,
            sortedApprovers,
            t
          ),
        };
      }

      return {
        ...item,
        id: index,
      };
    });
  }, [approvers, t]);

  const queueNumbers = React.useMemo(() => {
    const max = Math.max(...rows.map((i) => i.queueNumber!));

    const range = [...Array(max + 1).keys()];
    range.shift();

    return range;
  }, [rows]);

  const columns = React.useMemo(() => {
    return getTableColumns(t, control, queueNumbers);
  }, [t, control, queueNumbers]);

  React.useEffect(() => {
    if (approvers && arrayEquals(rows, approvers, true)) return;

    setValue("approvers", rows);
  }, [rows, setValue, approvers]);

  return (
    <>
      <Typography variant="body1" fontWeight={600} sx={{ mb: 2 }}>
        {t("order.approving.subject")}
      </Typography>

      <Table
        rows={rows}
        columns={columns}
        rowCount={0}
        hideFooterPagination
        checkboxSelection={false}
      />
    </>
  );
};
