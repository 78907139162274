import React from "react";

import { CreateStaffUnitContainer } from "app";
import { Modal } from "ui-kit";

export const CreateStaffUnitPopup = (props: {
  open: boolean;
  onClose: () => void;
  data?: any;
}) => {
  const { open, onClose, data } = props;

  const content = {
    title: "",
    body: <CreateStaffUnitContainer data={data} />,
    secondaryButton: null,
    mainButton: null,
  };

  return <Modal isOpen={open} onClose={onClose} content={content} sizeLarge />;
};
