import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const ActionsWrapper = styled.div<{ $isEditMode: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: ${({ $isEditMode }) =>
    $isEditMode ? "space-between" : "flex-end"};
  .main_buttons {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(2)};
    justify-content: flex-end;
    > button {
      min-width: ${UI_CONFIG.modalButton}px;
    }
  }
`;
export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  .role__description {
    textarea {
      height: 110px !important;
    }
  }
  .Mui-checked {
    color: ${({ theme }) => theme.palette.secondary.main} !important;
  }
`;

export const TemporaryRoleWrapper = styled.div`
  ${FLEX_BETWEEN};
  margin-top: ${({ theme }) => theme.spacing(8)};
  .MuiFormGroup-root {
    margin-left: ${({ theme }) => theme.spacing(4)};
  }
`;

export const PermissionWrapper = styled.div`
  ${FLEX_COLUMN};
  margin-top: ${({ theme }) => theme.spacing(6)};
  gap: ${({ theme }) => theme.spacing(4)};
  .permission_name {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing()};
  }
`;
