import React, { useState, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useGetContractsList } from "api";
import { useDebounce } from "app";
import { Table, useTableQueryMethods, useTableQuerySet } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { getAPIErrorMessage, getLocaleDateFormat } from "@sbm/fe-utils";
import { IContractFrameEntity } from "@types";

import { getFrameworkContractsTableColumns, tableTitle } from "./constants";

const DEFAULT_SORT_MODEL = "dateOfSigning:DESC";

interface Props {
  handleChooseContract: (id: string) => void;
  handleSetDate: (date?: Date) => void;
  contractCategoryId: number;
}
export const FrameworkContractContainer = ({
  handleChooseContract,
  handleSetDate,
  contractCategoryId,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODEL);

  const debouncedSearch = useDebounce(search);

  const {
    data,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetContractsList(
    // -2 means the id is 6(Расходный внутри рамки)... we should get the list ContractCategoryId = 4(Расходный рамочный)... etc.
    [`contractCategoryId=${contractCategoryId - 2}`],
    sortModel,
    debouncedSearch
  );

  if (error) {
    const errorMSG = getAPIErrorMessage(error);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }

  const handleRowClick = (row: GridRowParams) => {
    if (!row) return;
    handleChooseContract(`${row.row.contractNumber}`);
    if (row.row.dateOfSigning) {
      let updatedDate = row.row.dateOfSigning;
      if (`${row.row.dateOfSigning}`.includes(".")) {
        const dateParts = `${row.row.dateOfSigning}`.split(".");
        const formattedDateString = `${dateParts[1]}.${dateParts[0]}.${dateParts[2]}`;

        updatedDate = new Date(formattedDateString);
      }
      handleSetDate(new Date(updatedDate));
    } else handleSetDate();
  };

  const columns: GridColDef[] = useMemo(
    () => getFrameworkContractsTableColumns(t, theme),
    [t, theme]
  );

  useTableQuerySet({
    sortModel,
    search,
    setSortModel,
    setSearch,
  });

  const { onSortModelChange, onSearchChange } = useTableQueryMethods({
    setSortModel,
    setSearch,
  });

  const rows: IContractFrameEntity[] = useMemo(
    () =>
      data?.pages?.flatMap((page) => {
        return page.items.map((item, index) => {
          return {
            id: index,
            contractNumber: item.id,
            dateOfSigning: getLocaleDateFormat(item.dateOfSigning),
            expirationDate: getLocaleDateFormat(item.expirationDate),
            party1OrganizationName: item.party1OrganizationName,
            party2OrganizationName:
              item.party2OrganizationName || item.party2PersonalDataFullName,
            contractStatus: item.contractStatus,
            category: item.contractCategoryName,
          };
        });
      }) || [],
    [data]
  );
  const totalItems = data?.pages[0].meta.totalItems ?? 0;

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 6 }}>
        <Typography variant="h5" fontWeight={700}>
          {tableTitle(contractCategoryId, t)}
        </Typography>
      </Stack>

      <Table
        rows={rows}
        columns={columns}
        rowCount={totalItems}
        loading={isLoading || isFetchingNextPage}
        sortModel={sortModel}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        onSortModelChange={onSortModelChange}
        onSearchOptionChange={onSearchChange}
        onRowClick={handleRowClick}
        hasToolbar
        hideFooter
      />
    </>
  );
};
