import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { ApproverActions } from "@types";

import { ApproverActionsContainer } from "../../../../containers/contracts/ApproverActionsContainer";

export const ContractApprovalActionsPopup = (props: {
  open: boolean;
  onClose: () => void;
  data: { actionType: string; id: number; contractId: number };
}) => {
  const { open, onClose, data } = props;

  const { t } = useTranslation();

  const getDataByType = (type: string) => {
    switch (type) {
      case ApproverActions.approved:
        return {
          title: t("approval.action.confirm.title"),
          comment: t("approval.action.confirm.comment"),
        };
      case ApproverActions.rejected:
        return {
          title: t("approval.action.reject.title"),
          comment: "",
        };
      case ApproverActions.start:
        return {
          title: t("approval.action.start.title"),
          warning: t("approval.action.start.warning"),
          comment: "",
        };
      case ApproverActions.returning:
        return {
          title: t("approval.action.repeat.title"),
          comment: "",
        };
      default:
        return null;
    }
  };

  const content = {
    title: "",
    body: (
      <ApproverActionsContainer
        id={data.id}
        type={data.actionType}
        data={getDataByType(data.actionType)}
        onClose={onClose}
      />
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
