import React from "react";

import { CreateStandardRouteContainer } from "app";
import { Modal } from "ui-kit";

export const CreateStandardRoutePopup = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;

  const content = {
    title: "",
    body: <CreateStandardRouteContainer />,
    secondaryButton: null,
    mainButton: null,
  };

  return (
    <Modal
      clickAwayListener
      isOpen={open}
      onClose={onClose}
      content={content}
      disableContentPadding
      sizeLarge
    />
  );
};
