import { createSlice } from "@reduxjs/toolkit";
import {
  IEmployee,
  IPosition,
  IStructuralUnit,
  IStructuralUnitTree,
  OrganizationsListItem,
} from "@types";

interface IOrganizationsState {
  organizationList: {
    items: OrganizationsListItem[];
    meta: { totalItems: number };
  } | null;

  structuralUnits: {
    nodes: {
      id: number;
      parentId: number | null;
      node: any;
      children: IStructuralUnit[];
    }[];
  } | null;

  staffUnits: {
    nodes: {
      id: number;
      parentId: number | null;
      node: any;
      children: IStructuralUnit[];
    }[];
    meta: { totalItems: number };
  } | null;
  // ToDO: fix type
  staffAllocation: any | null;
  organizationStructuralUnits: {
    items: IStructuralUnit[];
    meta: { totalItems: number };
  } | null;
  positions: {
    items: IPosition[];
    meta: { totalItems: number };
  } | null;
  employees: {
    items: IEmployee[];
    meta: { totalItems: number };
  } | null;
  structuralUnitById: IStructuralUnitTree | null;
  organizationName: string | null;
}

export const initialStateOrganizations: IOrganizationsState = {
  organizationList: null,
  structuralUnits: null,
  staffUnits: null,
  staffAllocation: null,
  positions: null,
  organizationStructuralUnits: null,
  employees: null,
  structuralUnitById: null,
  organizationName: null,
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState: initialStateOrganizations,
  reducers: {
    setOrganizationsList: (state, action) => {
      state.organizationList = action.payload;
    },
    setStructuralUnits: (state, action) => {
      state.structuralUnits = action.payload;
    },
    setStaffUnits: (state, action) => {
      state.staffUnits = action.payload;
    },
    setStaffAllocation: (state, action) => {
      state.staffAllocation = action.payload;
    },
    setPositions: (state, action) => {
      state.positions = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setOrganizationStructuralUnits: (state, action) => {
      state.organizationStructuralUnits = action.payload;
    },
    setStructuralUnitById: (state, action) => {
      state.structuralUnitById = action.payload;
    },
    setOrganizationName: (state, action) => {
      state.organizationName = action.payload;
    },
  },
});

export const {
  setOrganizationsList,
  setStructuralUnits,
  setStaffUnits,
  setStaffAllocation,
  setPositions,
  setEmployees,
  setOrganizationStructuralUnits,
  setStructuralUnitById,
  setOrganizationName,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;

export * from "./operations";
