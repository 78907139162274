import React, { useCallback } from "react";
import Dropzone, { Accept, FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Alert, Button, Typography, useTheme } from "@mui/material";

import { Icon } from "../Icon";
import { ErrorItem } from "./components";
import { EnumFilesErrorMessages } from "./constants";
import { FileItem } from "./FileItem";
import { Wrapper } from "./styles";

interface Props {
  files?: File | File[] | null;
  types?: Accept;
  maxSize?: number;
  maxFiles?: number;
  label: string | JSX.Element;
  multiple?: boolean;
  disabled?: boolean;
  onChange?: (arg: File | File[]) => void;
}

export function DragAndDropUploader(props: Props) {
  const {
    files,
    label = "",
    types,
    maxFiles = 1,
    maxSize,
    multiple = false,
    disabled = false,
    onChange,
  } = props;
  const { t } = useTranslation();
  const { t: tAttachment } = useTranslation("attachments");
  const theme = useTheme();

  const [hasError, setHasError] = React.useState(false);
  const [filesCountWarning, setFilesCountWarning] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const timeoutIdRef = React.useRef<NodeJS.Timeout | null>(null);

  const fileTypes = types || {
    "image/*": [".jpeg", ".png"],
    "application/pdf": [".pdf"],
  };

  const handleDrop = async (acceptedFiles: File | File[]) => {
    if (onChange) {
      const newFiles = Array.isArray(acceptedFiles)
        ? acceptedFiles
        : [acceptedFiles];

      const oldFiles = files as File[];

      const filteredFiles = newFiles.filter((newFile) => {
        return !oldFiles?.some(
          (existingFile) => existingFile.name === newFile.name
        );
      });

      const updatedFiles = Array.isArray(files)
        ? [...files, ...filteredFiles] // Append new files to the existing ones
        : filteredFiles;

      if (updatedFiles.length > maxFiles) {
        setHasError(true);
        setErrorMessages([
          `${t("wrong.dropped.file.count", {
            fileMaxCount: maxFiles,
          })}`,
        ]);
        return;
      }

      onChange(updatedFiles);
    }
  };

  const removeFile = useCallback(
    (name?: string) => {
      const dataToSet =
        files && Array.isArray(files)
          ? files.filter((i) => i.name !== name)
          : [];
      if (onChange) {
        onChange(dataToSet);
      }
    },
    [files, onChange]
  );

  React.useEffect(() => {
    if (hasError) {
      timeoutIdRef.current = setTimeout(() => {
        setHasError(false);
        setFilesCountWarning(false);
        setErrorMessages([]);
      }, 5000);
    }
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [setHasError, hasError]);

  const handleDropRejection = (fileRejections: FileRejection[]) => {
    const messages = fileRejections.map(getErrorMessage);
    setHasError(true);
    setErrorMessages(messages);
  };

  const getErrorMessage = ({
    file,
    errors,
  }: {
    file: File;
    errors: { message: string; code: string }[];
  }) => {
    let message = "";
    const errorCode = errors[0].code;

    switch (errorCode) {
      case EnumFilesErrorMessages.type:
        message = t("wrong.dropped.file.type");
        break;
      case EnumFilesErrorMessages.size:
        message = t("wrong.dropped.file.size");
        break;
      case EnumFilesErrorMessages.count:
        message = t("wrong.dropped.file.size");
        setFilesCountWarning(true);
        break;
      default:
        message = "Something went wrong";
    }
    return `${file.name} : ${message}`;
  };

  return (
    <Wrapper disabled={disabled}>
      <Dropzone
        onDrop={handleDrop}
        accept={fileTypes}
        multiple={multiple}
        disabled={disabled}
        maxFiles={maxFiles}
        maxSize={maxSize}
        onDropRejected={(fileRejections: FileRejection[]) =>
          handleDropRejection(fileRejections)
        }
      >
        {({ getRootProps, getInputProps, isDragReject, isDragAccept }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
            ? "reject"
            : "";

          return (
            <section>
              <div
                {...getRootProps({
                  className: `dropzone ${additionalClass}`,
                })}
              >
                <Icon
                  name={disabled ? "CloudOff" : "UploadCloud"}
                  color={theme.palette.secondary.main}
                  size={48}
                />

                <input {...getInputProps()} />
                <Typography variant="body2" sx={{ textAlign: "center" }}>
                  {label}
                </Typography>
                <Button color="secondary" size="large" variant="outlined">
                  {tAttachment("upload.select.file")}
                </Button>
              </div>
            </section>
          );
        }}
      </Dropzone>
      {hasError && maxSize ? (
        <Alert severity="error" sx={{ mb: 2 }} icon={false}>
          {filesCountWarning ? (
            `${t("wrong.dropped.file.count", {
              fileMaxCount: maxFiles,
            })}`
          ) : (
            <>
              {errorMessages.map((item, index) => (
                <ErrorItem message={item} key={index} />
              ))}
            </>
          )}
        </Alert>
      ) : null}
      <div
        className={`${
          hasError && maxSize
            ? "uploaded_files_section_with_warning"
            : "uploaded_files_section "
        }`}
      >
        {files ? (
          Array.isArray(files) ? (
            files.map((item) => (
              <FileItem
                key={item.name + item.size}
                file={item}
                removeFile={() => removeFile(item.name)}
              />
            ))
          ) : (
            <FileItem file={files} removeFile={removeFile} />
          )
        ) : null}
      </div>
    </Wrapper>
  );
}
