import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const FormWrapper = styled.form`
  ${FLEX_COLUMN};
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(5)};

  min-width: 600px;

  // Mobile
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-width: auto;
  }
`;

export const ActionsSection = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(4)};

  width: 100%;

  padding-top: ${({ theme }) => theme.spacing(9)};

  button {
    width: fit-content;
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;

  width: 100%;

  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;
