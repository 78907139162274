import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { Card } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

export const ActionsWrapper = styled.div<{ $isEditMOde: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: ${({ $isEditMOde }) =>
    $isEditMOde ? "space-between" : "flex-end"};
  .main_buttons {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(2)};
    justify-content: flex-end;
    > button {
      min-width: ${UI_CONFIG.modalButton}px;
    }
  }
`;
export const SwitchWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => hexToRgba(theme.palette.primary.main, 0.7)};
`;

export const CardWrapper = styled(Card)`
  padding: ${({ theme }) => theme.spacing(4)};
  margin: ${({ theme }) => theme.spacing(4, 0)};
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const CardTitleWrapper = styled.div``;
