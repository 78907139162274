import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useRejectExecutionMutation } from "api";
import { Modal } from "ui-kit";

import { Input, Button } from "@sbm/ui-components";

export const RejectExecutionPopup = (props: {
  open: boolean;
  onClose: () => void;
  data: { data: { id: number } };
}) => {
  const {
    open,
    onClose,
    data: {
      data: { id },
    },
  } = props;
  const { t } = useTranslation("tasks");

  const [comment, setComment] = useState("");

  const { mutate: rejectExecution, isLoading } =
    useRejectExecutionMutation(onClose);

  const handleRejectExecution = () => {
    rejectExecution({ id, comment });
  };

  const content = {
    title: t("rejecting.reason"),
    body: (
      <Input
        label={t("comment")}
        multiline
        required
        maxLength={1000}
        onChange={(e) => setComment(e.target.value)}
        sx={{ my: 2 }}
      />
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        onClick={handleRejectExecution}
        loading={isLoading}
        disabled={!comment.trim().length}
      >
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
