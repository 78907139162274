import styled from "styled-components";
import { FLEX_BETWEEN } from "ui-kit";

import { inputBaseClasses } from "@mui/material";

export const InputWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(4)};

  .${inputBaseClasses.root} {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

export const ActionsContainer = styled.div`
  ${FLEX_BETWEEN};
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => theme.spacing(2, 4)};
`;
