import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, useMedia } from "app";
import { Icon } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { getLocaleDateFormat, hexToRgba } from "@sbm/fe-utils";
import { ITaskById } from "@types";

import task_card_image from "../../../../../assets/backgrounds/task_card_icon.svg";
import { TaskStatusChip } from "../TaskStatusChip";
import { TaskCardImage, TaskCardWrapper } from "./styles";

interface TaskCardProps {
  data: ITaskById;
  isInDrawer?: boolean;
}

export const TaskCard: React.FC<TaskCardProps> = ({ data, isInDrawer }) => {
  const { t } = useTranslation("tasks");
  const theme = useTheme();
  const [{ lgDown, tabletDown }] = useMedia();

  const { userProfile } = useAppSelector((state) => state.auth);

  const id = data.registration.taskNumber;
  const priority = data.registration.priority;

  const controlDate = getLocaleDateFormat(
    data.executionRequirements.controlDate
  );
  const assignmentDate = getLocaleDateFormat(data.registration.taskDate);

  const text = data.executionRequirements.textOfTheTask;

  const isAuthor =
    userProfile?.personalData?.employees?.some(
      (i) => i.id === data.authorOfTheTask?.employee?.id
    ) || false;

  return (
    <TaskCardWrapper>
      <Stack
        width="100%"
        maxWidth={lgDown || tabletDown || isInDrawer ? "100%" : "60%"}
      >
        <Stack flexDirection="row" alignItems="flex-start" gap={4}>
          <Icon name="ClipboardCheck" size={48} />
          <Stack gap={1}>
            <Typography
              variant="h8_bold"
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t(`types.${data.typeOfTheTask}`)}
            </Typography>
            <Typography
              variant="body3"
              sx={{ color: hexToRgba(theme.palette.primary.contrastText, 0.7) }}
            >
              {t("number")} {id}
            </Typography>
          </Stack>
          <Stack>
            <TaskStatusChip
              status={data.registration.status}
              isAuthor={isAuthor}
            />
          </Stack>
        </Stack>

        <Stack mt={8} mb={4}>
          <Typography
            variant="subtitle2"
            fontWeight={600}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {t("text")}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              "-webkit-line-clamp": "2",
              "-webkit-box-orient": "vertical",
              maxWidth: 520,
              minHeight: 42,
              maxHeight: 42,
              color: hexToRgba(theme.palette.primary.contrastText, 0.7),
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </Typography>
        </Stack>

        <Stack flexDirection="row" justifyContent="space-between">
          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t("priority")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: hexToRgba(theme.palette.primary.contrastText, 0.7),
              }}
            >
              {priority ? t("priority.high") : t("priority.low")}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t("assignment.date")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: hexToRgba(theme.palette.primary.contrastText, 0.7),
              }}
            >
              {assignmentDate}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              {t("control.date")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: hexToRgba(theme.palette.primary.contrastText, 0.7),
              }}
            >
              {controlDate}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {lgDown || tabletDown || isInDrawer ? null : (
        <Stack>
          <TaskCardImage src={task_card_image} alt="task card icon" />
        </Stack>
      )}
    </TaskCardWrapper>
  );
};
