import { styled } from "@mui/material/styles";
import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2IconContainer,
} from "@mui/x-tree-view/TreeItem2";

export const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  gap: 0,
  height: "auto",
  minHeight: 52,
  borderBottom: `1px solid ${theme.palette.other.divider}`,

  color: theme.palette.text.primary,
  paddingRight: theme.spacing(6),
  marginBottom: theme.spacing(),

  cursor: "default",

  "&.expanded": {},
  "&:hover": {},
  "&.focused, &.selected, &.selected.focused": {},

  ".color-primary": {
    color: theme.palette.primary.main,
  },
  ".color-secondary": {
    color: theme.palette.secondary.main,
  },
  ".color-error": {
    color: theme.palette.error.main,
  },
  ".color-success": {
    color: theme.palette.success.main,
  },
}));

export const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(
  ({ theme }) => ({
    width: 48,
    marginRight: theme.spacing(0),
  })
);

export const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
  ({ theme }) => ({
    marginLeft: theme.spacing(6),

    [`& .content`]: {
      paddingLeft: theme.spacing(6),
    },
  })
);
