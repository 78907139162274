import React from "react";

import { Icon } from "ui-kit";

import { Grid, Typography, useTheme } from "@mui/material";

import { WarningWrapper } from "./styles";

interface Props {
  message: { fileName: string; errorMessage: string; id: string };
  removeError: () => void;
}

export const ErrorItem: React.FC<Props> = ({ message, removeError }) => {
  const theme = useTheme();

  return (
    <WarningWrapper>
      <Grid container>
        <Grid item xs={0.8} className="error_icon">
          <Icon name="AlertTriangleIcon" color={theme.palette.error.main} />
        </Grid>
        <Grid item xs={10.2}>
          <Typography variant="body2" fontWeight={600}>
            {message.fileName}
          </Typography>
          <Typography variant="body2" fontWeight={400}>
            {message.errorMessage}
          </Typography>
        </Grid>
        <Grid item xs={1} className="error_icon x">
          <Icon
            name="X"
            color={theme.palette.error.main}
            onClick={removeError}
          />
        </Grid>
      </Grid>
    </WarningWrapper>
  );
};
