import { axiosService } from "app";

import { QueryFunctionContext } from "@tanstack/react-query";
import {
  ISendMessage,
  MessageViewResponse,
  IViewMessage,
  ConversationResponse,
} from "@types";

export const fetchMessages = async ({ pageParam }: QueryFunctionContext) => {
  const page = pageParam ?? 1;
  const limit = 20;
  const sortBy = "dateOfTheMessage:DESC";

  const params = {
    limit,
    page,
    sortBy,
  };
  const { data } = await axiosService({
    endpoint: "messages/user",
    body: params,
  });

  return data;
};

export const sendMessage = async (body: ISendMessage) => {
  const { data } = await axiosService({
    endpoint: "/messages",
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body,
  });

  return data;
};

export const viewMessage = async (body: IViewMessage) => {
  const { data } = await axiosService({
    endpoint: `/messages/view/${body.id}`,
    method: "PUT",
    body: { messageViewed: body.view },
  });

  return data;
};

export const getMessageById = async (id: number | undefined) => {
  const { data } = await axiosService({
    endpoint: `/messages/${id}`,
  });

  return data as MessageViewResponse;
};

export const getConversation = async (
  correspondentId: number,
  conversationId: number,
  isMessageFromSystem: boolean
) => {
  const { data } = await axiosService({
    endpoint: isMessageFromSystem
      ? `/messages/conversation`
      : `/messages/conversation?correspondentId=${correspondentId}`,
  });

  return data as ConversationResponse[];
};

export const getLinks = async (
  { pageParam }: QueryFunctionContext,
  type: string | undefined = "",
  search = ""
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const params = {
    search,
    limit,
    page,
    "filter.typeOfTheDocument": type,
  };

  const { data } = await axiosService({
    endpoint: "/documents",
    body: params,
  });

  return data;
};
