import React from "react";

import { Typography } from "@mui/material";

import { StyledGridOverlay } from "./styles";

interface Props {
  text: string;
}

export const EmptyTable = (props: Props) => {
  const { text } = props;

  return (
    <StyledGridOverlay>
      <Typography variant="h5" color="secondary" sx={{ mt: 4 }}>
        {text}
      </Typography>
    </StyledGridOverlay>
  );
};
