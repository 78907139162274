import React from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, closeDrawerWithData } from "app";
import { Modal } from "ui-kit";

import { Button, Typography } from "@mui/material";
import { IAssignedRole } from "@types";

export const RemovePermissionSetPopup = (props: {
  open: boolean;
  onClose: () => void;
  data?: any;
}) => {
  const {
    open,
    onClose,
    data: { data },
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getRolesIds = (roles: IAssignedRole[]) => {
    return roles.map((r) => `R${r.id}`);
  };

  const contentWithoutRoles = {
    title: t("permissionsSetPage.set_delete_confirm_title"),
    body: t("permissionsSetPage.delete_text"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          data.onRemove();
          onClose();
          dispatch(closeDrawerWithData());
        }}
      >
        {t("yes")}
      </Button>
    ),
  };
  const contentWithRoles = {
    title: t("permissionsSetPage.delete_permission_set"),
    body: (
      <div style={{ textAlign: "center" }}>
        <Typography variant="body2">
          {t("permissionsSetPage.delete_text_with_warning_part1", {
            roles: getRolesIds(data.rolesList),
          })}
        </Typography>
        <Typography variant="body2">
          {t("permissionsSetPage.delete_text_with_warning_part2")}
        </Typography>
      </div>
    ),

    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          onClose();
        }}
      >
        {t("yes")}
      </Button>
    ),
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      content={!data.rolesList.length ? contentWithoutRoles : contentWithRoles}
    />
  );
};
