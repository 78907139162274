import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, closeDrawerWithData } from "app";
import { Modal } from "ui-kit";

import { Button } from "@mui/material";

interface RemoveTaskPopupProps {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      onRemove: () => void;
      state: {
        hasOneSiblingTask?: boolean;
        areAllSiblingTasksDone?: boolean;
        hasValidChildTasks?: boolean;
        parentTaskId?: string | null;
      };
    };
  };
}

export const RemoveTaskPopup: React.FC<RemoveTaskPopupProps> = (props) => {
  const {
    open,
    onClose,
    data: { data },
  } = props;
  const { onRemove, state } = data;
  const { t } = useTranslation("tasks");
  const dispatch = useAppDispatch();

  const contentTitle = useMemo(() => {
    let key = "";

    if (state.parentTaskId) {
      if (state.hasOneSiblingTask) {
        key = "no_child_task";
      } else {
        key = state.areAllSiblingTasksDone ? "all_done" : "default";
      }
    } else {
      key = "default";
    }

    switch (key) {
      case "all_done":
        return {
          title: t("remove_task_popup.done_attribute_title", {
            id: state.parentTaskId,
          }),
          body: t("remove_task_popup.done_attribute_body"),
        };
      case "no_child_task":
        return {
          title: t("remove_task_popup.no_child_task_title", {
            id: state.parentTaskId,
          }),
          body: t("remove_task_popup.no_child_task_body"),
        };
      default:
        return {
          title: t("remove_task_popup.default_title"),
          body: t("remove_task_popup.default_body"),
        };
    }
  }, [t, state]);

  const content = {
    ...contentTitle,
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          onRemove();
          onClose();
          dispatch(closeDrawerWithData());
        }}
      >
        {t("yes")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
