import React from "react";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Input } from "@sbm/ui-components";
import { IApprover, ICreateStandardRoute } from "@types";

import { ApproversGanttChart } from "../ApproversGanttChart";
import { SectionWrapper } from "../styles";

export interface AgreementQueueProps {
  approvers?: IApprover[];
  register: UseFormRegister<ICreateStandardRoute>;
}

export const AgreementQueue = (props: AgreementQueueProps) => {
  const { approvers, register } = props;

  const { t } = useTranslation();

  return (
    <>
      <ApproversGanttChart approvers={approvers} />

      <SectionWrapper className="agreement-queue__actions">
        <Input
          label={t("additional.information")}
          size="medium"
          maxLength={300}
          {...register("additionalInformation")}
        />
      </SectionWrapper>
    </>
  );
};
