import React from "react";

import { Icon } from "ui-kit";

import { Box, useTheme } from "@mui/material";

import {
  getIconBGColor,
  getIconColor,
  getIconName,
  getIconBorderColor,
} from "./helpers";

interface Props {
  typeEventId: number;
}

export const TrackerTreeItemIcon: React.FC<Props> = ({ typeEventId }) => {
  const { palette } = useTheme();

  if (!typeEventId) return null;

  const iconName = getIconName(typeEventId);
  const iconBGColor = getIconBGColor(typeEventId, palette);
  const iconColor = getIconColor(typeEventId, palette);
  const iconBorderColor = getIconBorderColor(typeEventId, palette);

  if (!iconName) return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={40}
      height={40}
      p={2}
      mr={2.5}
      border={iconBorderColor ? 2 : 0}
      borderColor={iconBorderColor}
      borderRadius={1}
      bgcolor={iconBGColor}
    >
      <Icon name={iconName} color={iconColor} size={20} />
    </Box>
  );
};
