import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ClickAwayListener, Step, StepLabel, Stepper } from "@mui/material";
import { Button } from "@sbm/ui-components";
import { ICreateStandardRoute, IRoute, IStructuralUnit } from "@types";

import { TransitionPrompt } from "../../../decorators";
import { useAppDispatch } from "../../../hooks";
import {
  assignApprovalRouteToContract,
  createTypicalApprovalRoute,
  getStructuralUnitsAutocomplete,
  setModal,
} from "../../../store";
import {
  AgreementQueue,
  SelectApprovers,
  SelectOrganization,
  SortApprovers,
  AssignRouteToContract,
} from "./components";
import { StepsEnum } from "./constants";
import { getFormattedDataToSend } from "./helpers";
import { FormWrapper, StepContentWrapper, StepperActions } from "./styles";

const steps = [
  "select.organization",
  "selecting.approving.subjects",
  "order.approving.subject",
  "agreement.queue",
  "assigning.route.to.contract",
];

export const CreateStandardRouteContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isOpenPrompt, setIsOpenPrompt] = React.useState(false);
  const [step, setStep] = React.useState(StepsEnum.selectOrganization);
  const [id, setId] = React.useState<number | undefined>(undefined);

  const [structuralUnits, setStructuralUnits] = React.useState<
    Partial<IStructuralUnit>[]
  >([]);

  const isLastStep = step === steps.length - 1;
  const isFirstStep = step === StepsEnum.selectOrganization;

  const {
    register,
    control,
    setValue,
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<ICreateStandardRoute>({
    defaultValues: {
      organizationId: undefined,
    },
  });

  const { organizationId, approvers, routes } = useWatch({ control });

  const handleBack = () => {
    setStep((prevState) => prevState - 1);
  };

  const handleNext = () => {
    setStep((prevState) => prevState + 1);
  };

  const handleClosePrompt = () => setIsOpenPrompt(false);

  const handleConfirmPrompt = () => {
    handleClosePrompt();
    dispatch(setModal(null));
  };

  const handleClose = React.useCallback(
    (event?: any) => {
      // Disabling close event for portals (Select, Popper)
      if (event?.target?.localName === "body") return;
      // Organization is the first field that user should select,
      // in case it is not defined means that form is not dirty
      if (organizationId) return setIsOpenPrompt(true);

      dispatch(setModal(null));
    },
    [dispatch, organizationId]
  );

  const onSubmit = handleSubmit(async (form) => {
    if (step === StepsEnum.agreementQueue) {
      const dataToSend = getFormattedDataToSend(form, structuralUnits, t);
      const data = await dispatch(createTypicalApprovalRoute(dataToSend));

      setId(data.id);
      setStep(StepsEnum.assigningRouteToContract);
      return;
    }

    if (!id || !routes?.length) return dispatch(setModal(null));

    await dispatch(
      assignApprovalRouteToContract(id, { routes: routes as IRoute[] })
    );

    dispatch(setModal(null));
  });

  React.useEffect(() => {
    if (!organizationId) return;

    dispatch(getStructuralUnitsAutocomplete("", String(organizationId))).then(
      (resp: { items: IStructuralUnit[] }) => {
        const dataToSet: Partial<IStructuralUnit>[] = resp.items.map((i) => ({
          id: i.id,
          nameOfStructuralUnit: i.nameOfStructuralUnit,
        }));

        setStructuralUnits(dataToSet);
      }
    );
  }, [organizationId, dispatch]);

  React.useEffect(() => {
    setValue("approvers", undefined);
    setValue("routes", undefined);
    setValue("additionalInformation", "");
  }, [setValue, organizationId]);

  const StepContent = React.useMemo(() => {
    switch (step) {
      case StepsEnum.selectOrganization:
        return (
          <SelectOrganization
            setValue={setValue}
            errors={errors}
            organizationId={organizationId}
          />
        );
      case StepsEnum.selectApprovingSubjects:
        return (
          <SelectApprovers
            setValue={setValue}
            organizationId={organizationId}
            approvers={approvers}
            structuralUnits={structuralUnits}
          />
        );
      case StepsEnum.orderApprovingSubjects:
        return (
          <SortApprovers
            approvers={approvers}
            control={control}
            setValue={setValue}
          />
        );
      case StepsEnum.agreementQueue:
        return <AgreementQueue approvers={approvers} register={register} />;
      case StepsEnum.assigningRouteToContract:
        return (
          <AssignRouteToContract
            approvers={approvers}
            id={id}
            setValue={setValue}
            organizationId={organizationId}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
          />
        );
      default:
        return null;
    }
  }, [
    step,
    setValue,
    errors,
    organizationId,
    approvers,
    structuralUnits,
    control,
    register,
    id,
    setError,
    clearErrors,
  ]);

  const StepPrimaryButton = React.useMemo(() => {
    if (isFirstStep && !organizationId) {
      return (
        <Button size="small" variant="contained" onClick={handleClose}>
          {t("close")}
        </Button>
      );
    }

    if (isLastStep || step === StepsEnum.agreementQueue) {
      return (
        <Button
          key="submit"
          type="submit"
          size="small"
          variant="contained"
          disabled={Boolean(isLastStep && errors.routes)}
        >
          {t("save")}
        </Button>
      );
    }

    return (
      <Button size="small" variant="contained" onClick={handleNext}>
        {t("next")}
      </Button>
    );
  }, [
    errors.routes,
    handleClose,
    isFirstStep,
    isLastStep,
    organizationId,
    step,
    t,
  ]);

  return (
    <ClickAwayListener disableReactTree={false} onClickAway={handleClose}>
      <FormWrapper noValidate onSubmit={onSubmit}>
        <Stepper activeStep={step}>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <StepContentWrapper>{StepContent}</StepContentWrapper>

        <StepperActions>
          {step > 0 ? (
            <Button
              color="inherit"
              size="small"
              variant="contained"
              onClick={handleBack}
            >
              {t("back")}
            </Button>
          ) : (
            <div />
          )}

          {StepPrimaryButton}
        </StepperActions>

        {isOpenPrompt ? (
          <TransitionPrompt
            open={isOpenPrompt}
            onClose={handleClosePrompt}
            onConfirm={handleConfirmPrompt}
          />
        ) : null}
      </FormWrapper>
    </ClickAwayListener>
  );
};
