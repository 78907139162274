export const FLEX_COLUMN = `
    display: flex;
    flex-direction: column;
`;

export const FLEX_COLUMN_FULL = `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FLEX_ROW = `
    display: flex;
    flex-direction: row;
`;

export const FLEX_ROW_ALIGN_CENTER = `
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FLEX_ROW_FULL = `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const FLEX_BETWEEN = `
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
