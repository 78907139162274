import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";

export const PermissionSetSavePopup = (props: {
  open: boolean;
  onClose: () => void;
  data?: any;
}) => {
  const {
    open,
    onClose,
    data: { data },
  } = props;
  const { t } = useTranslation();

  const content = {
    title: t("permissionsSetPage.confirm_title"),
    body: t("permissionsSetPage.confirm_text"),
    secondaryButton: (
      <Button onClick={onClose} color="secondary">
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        onClick={() => {
          data.handleSave();
          onClose();
        }}
      >
        {t("yes")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
