import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  flex: 1;

  padding: ${({ theme }) => theme.spacing(5, 0)};
`;

export const TitleWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: space-between;
`;
