import React from "react";

import { DotsWrapper, Dot } from "./styles";

export const DotsLoader = () => {
  return (
    <DotsWrapper>
      <Dot />
      <Dot />
      <Dot />
    </DotsWrapper>
  );
};
